import React, { useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

//components
import { DatePicker } from '@/components/organisms/customer/_detail/date-picker'
//models
import {
  AccommodationInput,
  GenderType,
  NationalityType,
  OccupationType,
  isEmployee,
  occupationList,
} from '@/models/self-checkin/accommodation-info'

//api
import { fetchZipCode } from '@/apis/aipass'

// utils
import { changeRequiredIdentify } from '@/utils/required-identify'
import { Button } from '@/components/atoms/button'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { RequireText } from '@/components/atoms/require-text'
import { Checkbox } from '@/components/atoms/checkbox'

type AccommodationFormProps = {
  accommodationInfo: AccommodationInput
  onChange: (name: any, value: any) => void
  requiredAccompanyNameInput: boolean
  requiredFuriganaInput: boolean
  requiredBirthDateInput: boolean
  requiredGenderInput: boolean
  requiredTelephoneInput: boolean
  requiredEmailInput: boolean
  requiredAddressInput: boolean
  isFormValidRequiredFields: boolean
  imageInfo?: {
    identificationFrontImage: string
    identificationBackImage: string
    representativePassportImage: string
    from: string
    uploadImage: (type: 'front' | 'back' | 'representativePassport' | 'companionPassport') => void
  }
  requiredIdentifyInfo?: {
    requiredJapaneseIdentify?: boolean
    requiredForeignIdentify?: boolean
  }
  onChangeRequiredIdentify?: any
  isEmailValid?: any
  isPhoneValid?: any
  isPostCodeValid?: any
  isFirstNameValid?: any
  isLastNameValid?: any
}

export const AccommodationForm: React.FC<AccommodationFormProps> = ({
  accommodationInfo,
  onChange,
  requiredAccompanyNameInput,
  requiredFuriganaInput,
  requiredBirthDateInput,
  requiredGenderInput,
  requiredTelephoneInput,
  requiredEmailInput,
  requiredAddressInput,
  isFormValidRequiredFields,
  imageInfo,
  requiredIdentifyInfo,
  onChangeRequiredIdentify,
  isEmailValid,
  isPhoneValid,
  isPostCodeValid,
  isFirstNameValid,
  isLastNameValid,
}) => {
  const [zipFetchLoading, setZipFetchLoading] = useState(false)

  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const guestsList = Array.from(new Array(20)).map((v, i) => ++i)

  const switchButtonGender = (genderInput: GenderType) => {
    onChange('gender', genderInput)
  }

  const switchButtonNationality = (nationality: NationalityType) => {
    onChange('nationality', nationality)
    if (requiredIdentifyInfo?.requiredJapaneseIdentify !== undefined && requiredIdentifyInfo?.requiredForeignIdentify !== undefined) {
      onChangeRequiredIdentify(
        changeRequiredIdentify(nationality, requiredIdentifyInfo?.requiredJapaneseIdentify, requiredIdentifyInfo?.requiredForeignIdentify),
      )
    }
  }

  const onChangeBirthDate = (value: string): void => {
    onChange('dateOfBirth', value)
  }

  const onChangeAccommodationInfo = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    onChange(name, value)
  }

  const _fetchZipCode = async (value: string) => {
    try {
      setZipFetchLoading(true)
      const response = await fetchZipCode(value)
      const result = response?.results[0]
      onChange('address', `${result?.address1}${result?.address2}${result?.address3}`)
    } catch (err) {
      onChange('address', '')
    } finally {
      setZipFetchLoading(false)
    }
  }

  const getName = (lastName, firstName) => {
    switch (true) {
      case !!lastName?.trim() && !!firstName?.trim():
        return lastName + ' ' + firstName
      case !!lastName?.trim():
        return lastName
      case !!firstName?.trim():
        return firstName
      default:
        return ''
    }
  }

  const onChangeAccommodationInfoPax = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target
    onChange(name, value)
    if (value === '1') {
      onChange('accompany', [])
    } else {
      const selectAccompanyLength = Number(value) - 1
      const stateAccompany = accommodationInfo?.accompany || []
      const diffAccompanyLength = selectAccompanyLength - stateAccompany.length
      if (selectAccompanyLength < 0 || diffAccompanyLength === 0) {
        return
      }
      if (diffAccompanyLength < 0) {
        const reduceAccompany = stateAccompany.slice(0, diffAccompanyLength)
        onChange('accompany', reduceAccompany)
      } else {
        const addAccompany = Array.from(new Array(diffAccompanyLength)).map(() => {
          return { id: uuidv4(), lastName: '', firstName: '', name: '' }
        })
        onChange('accompany', [...stateAccompany, ...addAccompany])
      }
    }
  }

  const onChangeAccommodationInfoAccompanyName = (e, id): void => {
    const { name, value } = e.target
    const stateAccompany = accommodationInfo?.accompany || []
    const changeAccompany = stateAccompany.map(accompany => {
      if (accompany.id === id) {
        const changeLastNameValue = name === 'lastName' ? value : accompany.lastName
        const changeFirstNameValue = name === 'firstName' ? value : accompany.firstName
        const changeNameValue = getName(changeLastNameValue, changeFirstNameValue)
        return {
          id,
          lastName: changeLastNameValue,
          firstName: changeFirstNameValue,
          name: changeNameValue,
        }
      } else {
        return accompany
      }
    })
    onChange('accompany', changeAccompany)
  }

  return (
    <div css={form}>
      {imageInfo && (
        <>
          <p css={formDescriptionStyle}>{t('Representative information')}</p>
          <div css={itemWrapperStyle}>
            {imageInfo?.from === 'upload-license' && (
              <>
                <p>
                  {t('Identification')} <RequireText />
                </p>
                <div css={imageItemWrapperStyle}>
                  <div css={imageItemStyle} onClick={() => imageInfo?.uploadImage('front')}>
                    <img
                      src={
                        imageInfo?.identificationFrontImage
                          ? imageInfo?.identificationFrontImage
                          : require('@/static/images/new_identification_card_front.svg')
                      }
                    />
                    <div css={imageItemTextStyle}>
                      <div>
                        <img src={require('@/static/images/camera_icon_white.svg')} alt={t('Front of the card')} />
                        <p css={scan}>{t('Front of the card')}</p>
                      </div>
                    </div>
                  </div>
                  <div css={imageItemStyle} onClick={() => imageInfo?.uploadImage('back')}>
                    <img
                      src={
                        imageInfo?.identificationBackImage
                          ? imageInfo?.identificationBackImage
                          : require('@/static/images/new_identification_card_back.svg')
                      }
                    />
                    <div css={imageItemTextStyle}>
                      <div>
                        <img src={require('@/static/images/camera_icon_white.svg')} alt={t('Back of the card')} />
                        <p css={scan}>{t('Back of the card')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {imageInfo?.from === 'upload-passport' && (
              <>
                <p>
                  {t('Identification')} <RequireText />
                </p>
                <div css={imageItemWrapperStyle}>
                  <div css={imageItemStyle} onClick={() => imageInfo?.uploadImage('representativePassport')}>
                    <img
                      src={
                        imageInfo?.representativePassportImage
                          ? imageInfo?.representativePassportImage
                          : require('@/static/images/passport.svg')
                      }
                    />
                    <div css={imageItemTextStyle}>
                      <div>
                        <img src={require('@/static/images/camera_icon_white.svg')} alt={t('Take a Photo')} />
                        <p css={scan}>{t('Take a Photo')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}

      <div className="reservationLabel">
        <p>
          {t('Nationality')} <RequireText />
        </p>
        <RadioGroupField
          value={accommodationInfo?.nationality as NationalityType}
          onChange={e => switchButtonNationality(e.target.value)}
          items={[
            { value: NationalityType.Jp, label: t('Japanese') },
            { value: NationalityType.Njp, label: t('Non-Japanese') },
          ]}
          itemCss={radioItemStyle}
          style={{ labelMargin: 16 }}
        />
      </div>
      <div className="reservationLabel">
        <p>
          {t('Full name')} <RequireText />
        </p>
        <div css={nameForm}>
          <input
            type="text"
            className="lastName"
            placeholder={t('Last name')}
            name="lastName"
            value={accommodationInfo?.lastName}
            onChange={onChangeAccommodationInfo}
            css={(!isFormValidRequiredFields && !accommodationInfo?.lastName.trim()) || !isLastNameValid ? errorStyle : ''}
          />
          <input
            type="text"
            placeholder={t('First name')}
            name="firstName"
            value={accommodationInfo?.firstName}
            css={(!isFormValidRequiredFields && !accommodationInfo?.firstName.trim()) || !isFirstNameValid ? errorStyle : ''}
            onChange={onChangeAccommodationInfo}
          />
        </div>
        {!isFormValidRequiredFields && (!accommodationInfo?.lastName.trim() || !accommodationInfo?.firstName.trim()) ? (
          <div css={errorMessage}>{t('Required field has not been entered')}</div>
        ) : null}
        {!isLastNameValid || !isFirstNameValid ? <div css={errorMessage}>{t('Please type using half-width characters')}</div> : null}
      </div>
      {accommodationInfo.nationality === NationalityType.Jp && lang === 'ja' && (
        <div className="reservationLabel">
          <p>
            {t('Furigana')} {requiredFuriganaInput && <RequireText />}
          </p>
          <div css={nameForm}>
            <input
              type="text"
              className="lastName"
              placeholder={t('Last name Furigana')}
              name="lastNameFurigana"
              value={accommodationInfo?.lastNameFurigana}
              css={requiredFuriganaInput && !isFormValidRequiredFields && !accommodationInfo?.lastNameFurigana.trim() ? errorStyle : ''}
              onChange={onChangeAccommodationInfo}
            />
            <input
              type="text"
              placeholder={t('First name Furigana')}
              name="firstNameFurigana"
              value={accommodationInfo?.firstNameFurigana}
              css={requiredFuriganaInput && !isFormValidRequiredFields && !accommodationInfo?.firstNameFurigana.trim() ? errorStyle : ''}
              onChange={onChangeAccommodationInfo}
            />
          </div>
          {requiredFuriganaInput &&
          !isFormValidRequiredFields &&
          (!accommodationInfo?.firstNameFurigana.trim() || !accommodationInfo?.lastNameFurigana.trim()) ? (
            <div css={errorMessage}>{t('Required field has not been entered')}</div>
          ) : null}
        </div>
      )}
      <div className="reservationLabel">
        <p>
          {t('Date of birth')} {requiredBirthDateInput && <RequireText />}
        </p>
        <div css={reservationTelForm}>
          <DatePicker
            value={accommodationInfo?.dateOfBirth}
            placeholder={t('Please select')}
            onChange={onChangeBirthDate}
            error={requiredBirthDateInput && !isFormValidRequiredFields && !accommodationInfo?.dateOfBirth}
          />
        </div>
        {requiredBirthDateInput && !isFormValidRequiredFields && !accommodationInfo?.dateOfBirth ? (
          <div css={errorMessage}>{t('Required field has not been entered')}</div>
        ) : null}
      </div>
      <div className="reservationLabel">
        <p>
          {t('Gender')} {requiredGenderInput && <RequireText />}
        </p>
        <RadioGroupField
          value={accommodationInfo?.gender}
          onChange={e => switchButtonGender(e.target.value)}
          items={[
            { value: GenderType.Male, label: t('Male') },
            { value: GenderType.Female, label: t('Female') },
          ]}
          itemCss={radioItemStyle}
          style={{ labelMargin: 16 }}
        />
      </div>
      <div className="reservationLabel">
        <p>
          {t('Phone number')} {requiredTelephoneInput && <RequireText />}
        </p>
        <div css={reservationTelForm}>
          <input
            type="tel"
            placeholder="08012345678"
            pattern="^[0-9]+$"
            css={
              (requiredTelephoneInput && !isFormValidRequiredFields && !accommodationInfo?.phoneNumber.trim()) || !isPhoneValid
                ? errorStyle
                : ''
            }
            name="phoneNumber"
            value={accommodationInfo?.phoneNumber}
            onChange={onChangeAccommodationInfo}
          />
        </div>
        {requiredTelephoneInput && !isFormValidRequiredFields && !accommodationInfo?.phoneNumber.trim() ? (
          <div css={errorMessage}>{t('Required field has not been entered')}</div>
        ) : null}
        {!isPhoneValid && <div css={errorMessage}>{t('Please enter in single-byte number')}</div>}
      </div>
      <div className="reservationLabel">
        <p>
          {t('Email')} {requiredEmailInput && <RequireText />}
        </p>
        <div css={reservationTelForm}>
          <input
            type="email"
            placeholder="yamada@aipass.com"
            name="mail"
            css={!isEmailValid || (requiredEmailInput && !isFormValidRequiredFields && !accommodationInfo?.mail.trim()) ? errorStyle : ''}
            value={accommodationInfo?.mail}
            onChange={onChangeAccommodationInfo}
          />
        </div>
        {requiredEmailInput && !isFormValidRequiredFields && !accommodationInfo?.mail.trim() && (
          <div css={errorMessage}>{t('Required field has not been entered')}</div>
        )}
        {!isEmailValid && <div css={errorMessage}>{t('Please enter a valid email address')}</div>}
      </div>
      <div className="reservationLabel">
        <p>{t('Occupation')}</p>
        <div css={reservationTelForm}>
          <select css={assignSelectStyle} name="occupation" value={accommodationInfo?.occupation} onChange={onChangeAccommodationInfo}>
            {occupationList.map((item, index) => (
              <option key={index} value={item.value}>
                {t(item.name)}
              </option>
            ))}
          </select>
        </div>
        {!isFormValidRequiredFields && !accommodationInfo?.occupation ? (
          <div css={errorMessage}>{t('Required field has not been entered')}</div>
        ) : null}
      </div>
      {isEmployee(accommodationInfo.occupation) && (
        <div className="reservationLabel">
          <p>{t('Company')}</p>
          <div css={reservationTelForm}>
            <input
              type="text"
              placeholder={t('Aipass Co Ltd')}
              name="company"
              value={accommodationInfo?.company}
              onChange={onChangeAccommodationInfo}
            />
          </div>
        </div>
      )}
      {accommodationInfo.occupation === OccupationType.Other && (
        <div className="reservationLabel">
          <div css={reservationTelForm}>
            <input type="text" name="otherOccupation" value={accommodationInfo?.otherOccupation} onChange={onChangeAccommodationInfo} />
          </div>
        </div>
      )}
      {accommodationInfo.nationality === NationalityType.Jp && (
        <div className="reservationLabel">
          <p>{t('Post code')}</p>
          <div css={[reservationTelForm, { display: 'flex', alignItems: 'center' }]}>
            <input
              style={{ maxWidth: '172px' }}
              type="tel"
              placeholder="1234567"
              pattern="^[0-9]+$"
              name="postCode"
              css={!isPostCodeValid ? errorStyle : ''}
              value={accommodationInfo?.postCode}
              onChange={e => {
                onChangeAccommodationInfo(e)
              }}
            />
            <Button
              isDisabled={!accommodationInfo.postCode}
              buttonType={3}
              borderRadius={40}
              marginLeft={20}
              width={140}
              fontSize={18}
              fontWeight="normal"
              height={62}
              loading={zipFetchLoading}
              onClick={() => {
                if (accommodationInfo.postCode) {
                  _fetchZipCode(accommodationInfo?.postCode)
                }
              }}
            >
              {t('Search by Address')}
            </Button>
          </div>
          {!isPostCodeValid && <div css={errorMessage}>{t('Please enter in single-byte number')}</div>}
        </div>
      )}
      <div className="reservationLabel">
        <p>
          {t('Address (please enter street address and unit number)')} {requiredAddressInput && <RequireText />}
        </p>
        <div css={reservationTelForm}>
          <input
            type="text"
            placeholder={t('1-1 Chiyoda Chiyoda-ku')}
            name="address"
            value={accommodationInfo?.address}
            css={requiredAddressInput && !isFormValidRequiredFields && !accommodationInfo?.address.trim() ? errorStyle : ''}
            onChange={onChangeAccommodationInfo}
          />
        </div>
        {requiredAddressInput && !isFormValidRequiredFields && !accommodationInfo?.address.trim() ? (
          <div css={errorMessage}>{t('Required field has not been entered')}</div>
        ) : null}
      </div>

      <div className="reservationLabel" style={{ borderTop: '1px solid #F2F2F2', marginTop: 24, paddingTop: 40, paddingBottom: 0 }}>
        <p>{t('Number of guests')}</p>
        <div css={accompanyPaxForm}>
          <select css={paxSelectStyle} name="paxTotal" value={accommodationInfo?.paxTotal || 1} onChange={onChangeAccommodationInfoPax}>
            {guestsList.map((value, index) => (
              <option key={index} value={value}>
                {`${value}${t('Person')}`}
              </option>
            ))}
          </select>
        </div>
        {(accommodationInfo?.paxTotal || 1) > 1 && (
          <>
            {!requiredAccompanyNameInput && (
              <div css={checkBoxStyle}>
                <Checkbox
                  value={accommodationInfo.hasContactForAllGuest}
                  onChange={val => {
                    onChange('hasContactForAllGuest', val)
                    if (!val) {
                      onChangeAccommodationInfoPax({
                        target: { name: 'paxTotal', value: `${accommodationInfo?.paxTotal || 1}` },
                      } as any)
                    }
                  }}
                  label={t('has contact information for all guests')}
                />
              </div>
            )}
            <div id="accompanyNameForm">
              {(requiredAccompanyNameInput || !accommodationInfo.hasContactForAllGuest) && (
                <div className="reservationLabel">
                  <div css={accompanyNameForm}>
                    <p>
                      {t('Accompany name')} <RequireText />
                    </p>
                    {(accommodationInfo?.accompany || []).map(accompany => (
                      <div key={accompany?.id} css={nameFormList}>
                        <div css={nameForm}>
                          <input
                            type="text"
                            className="lastName"
                            placeholder={t('Last name')}
                            name="lastName"
                            value={accompany?.lastName}
                            css={!isFormValidRequiredFields && !accompany?.lastName?.trim() ? errorStyle : ''}
                            onChange={e => onChangeAccommodationInfoAccompanyName(e, accompany?.id)}
                          />
                          <input
                            type="text"
                            placeholder={t('First name')}
                            name="firstName"
                            value={accompany?.firstName}
                            css={!isFormValidRequiredFields && !accompany?.firstName?.trim() ? errorStyle : ''}
                            onChange={e => onChangeAccommodationInfoAccompanyName(e, accompany?.id)}
                          />
                        </div>
                        {!isFormValidRequiredFields && (!accompany?.lastName?.trim() || !accommodationInfo?.firstName?.trim()) ? (
                          <div css={errorMessage}>{t('Required field has not been entered')}</div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const form = css({
  backgroundColor: 'white',
  maxWidth: '874px',
  borderRadius: '10px',
  margin: 'auto',
  padding: '36px 53px',

  '.reservationLabel': {
    width: '100%',
    padding: '16px 0',

    p: {
      fontSize: '18px',
      textAlign: 'left',
      letterSpacing: '1.8px',
      color: '#272727',
      fontWeight: 'bold',
    },
  },
})

const reservationTelForm = css({
  marginTop: '16px',

  input: {
    width: '100%',
    height: '61.21px !important',
    border: '1px solid #cccccc',
    borderRadius: '40px !important',
    fontSize: '21px !important',
    lineHeight: '31px !important',
    padding: '14px 35px !important',
    textAlign: 'left',
    backgroundColor: 'white',
    backgroundPosition: '95% center !important',
    backgroundSize: '14px  !important',
  },

  'input::placeholder': {
    color: '#cccccc',
  },
  '.error': {
    borderColor: '#ff2700 !important',
  },
})
const errorStyle = css({
  borderColor: '#ff2700 !important',
})

const radioItemStyle = css({
  marginTop: '16px',
  display: 'flex',
  lineHeight: 'initial',
  label: {
    fontSize: '17px',
    lineHeight: '35px',
    fontWeight: 'normal',
  },
  '&:hover': { cursor: 'pointer' },
  img: {
    marginRight: 0,
    height: '35px',
    width: '35px',
  },
  ':first-of-type': { marginRight: 50 },
})

const nameForm = css({
  display: 'flex',
  marginTop: '16px',

  '.lastName': {
    marginRight: '20px',
  },

  input: {
    width: '100%',
    height: '61.21px',
    border: '1px solid #cccccc',
    borderRadius: '40px',
    fontSize: '21px',
    lineHeight: '31px',
    padding: '14px 35px',
    textAlign: 'left',
  },

  'input::placeholder': {
    color: '#cccccc',
  },
})

const assignSelectStyle = css({
  width: '100%',
  height: '61.21px',
  border: '1px solid #cccccc',
  color: '#272727',
  borderRadius: '40px',
  fontSize: '21px',
  padding: '14px 35px',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/arrow_down_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '95% center',
  backgroundSize: 14,
  backgroundColor: '#FFFFFF',
  display: 'block',
})

const errorMessage = css({
  fontSize: '14px',
  fontWeight: 'bold',
  marginTop: '16px',
  textAlign: 'left',
  color: '#FF2700',
})

const formDescriptionStyle = css({
  fontSize: 21,
  marginBottom: 24,
  lineHeight: '27px',
  letterSpacing: '1.8px',
  textAlign: 'center',
  fontWeight: 'bold',
  '@media(max-width: 1080px)': {
    fontSize: 21,
  },
})

const itemWrapperStyle = css({
  width: '100%',
  '@media(max-width: 1080px)': {
    padding: '0 0 38px',
  },
  '&:last-child': {
    padding: 0,
  },
  p: {
    color: '#272727',
    fontWeight: 'bold',
    fontSize: 18,
    '@media(max-width: 1080px)': {
      fontSize: 21,
      paddingBottom: 23,
    },
    paddingBottom: 12,
    lineHeight: '20px',
    letterSpacing: '1.4px',
  },
})

const imageItemWrapperStyle = css({
  display: 'flex',
})

const imageItemStyle = css({
  width: 256,
  height: 158,
  cursor: 'pointer',
  position: 'relative',
  fontSize: 18,
  fontWeight: 'bold',
  letterSpacing: '2.7px',
  lineHeight: '27px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:nth-of-type(1)': {
    margin: '0 20px 20px 0',
  },
  '> img': {
    width: 256,
    height: 158,
    borderRadius: 10,
  },
})

const imageItemTextStyle = css({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
  display: 'flex',
  fontSize: 12,
  color: '#ffffff',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  img: {
    width: 19,
    height: 'auto',
  },
  p: {
    color: '#ffffff',
  },
})

const scan = css({
  fontSize: '15px !important',
  '@media(max-width: 1080px)': {
    fontSize: '14px !important',
    paddingBottom: '0px !important',
  },
})

const accompanyPaxForm = css({
  marginTop: '16px',

  input: {
    width: '100%',
    height: '61.21px !important',
    border: '1px solid #cccccc',
    borderRadius: '40px !important',
    fontSize: '21px !important',
    lineHeight: '31px !important',
    padding: '14px 35px !important',
    textAlign: 'left',
    backgroundColor: 'white',
    backgroundPosition: '95% center !important',
    backgroundSize: '14px  !important',
  },

  'input::placeholder': {
    color: '#cccccc',
  },
  '.error': {
    borderColor: '#ff2700 !important',
  },
})

const accompanyNameForm = css({
  marginTop: 24,
})

const nameFormList = css({
  marginBottom: 30,
})

const paxSelectStyle = css({
  width: 159,
  height: 61,
  border: '1px solid #cccccc',
  color: '#272727',
  borderRadius: '40px',
  fontSize: '21px',
  padding: '14px 35px',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/arrow_down_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '108px center',
  backgroundSize: 14,
  backgroundColor: '#FFFFFF',
  display: 'block',
})

const checkBoxStyle = css({
  textAlign: 'left',
  height: '60px',
  padding: '24px 32px 0 0',
  display: 'flex',
  alignItems: 'center',
})

const inputCheckBoxStyle = css({
  width: 25,
  height: 25,
})

const labelStyle = css({
  fontSize: 21,
  textAlign: 'left',
  color: '#272727',
  marginLeft: '35px',
})
