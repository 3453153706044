import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import {
  barStyle,
  startBarStyle,
  betweenBarStyle,
  beginBarStyle,
  endBarStyle,
  dayUseBarStyle,
  barActiveStyle,
  nameStyle,
  barChangeStyle,
  startBarChangeStyle,
  betweenBarChangeStyle,
  beginBarChangeStyle,
  endBarChangeStyle,
  dayUseBarChangeStyle,
  endBarActiveStyle,
  beginBarActiveStyle,
  startBarActiveStyle,
  betweenBarActiveStyle,
  dayUseActiveBarStyle,
} from '@/components/pages/room-manager/_daily/_body/bar-layout'

import { clickShowDetail } from '@/components/pages/room-manager/_daily/_body/click-show-detail'
import { SerializedStyles } from '@emotion/core'

// models
import { StatusType } from '@/models/room-manager/response/guest-room-assign-status'
import { DateType } from '@/components/pages/room-manager/_daily/daily-props'

const DAILY_LIST_BLOCK_WIDTH = 111
// 表示開始日付以前（beginBarStyle）からの宿泊の場合、_beginDateと_assignCheckoutDaysの差分 + 0.5日分の表示になるのでnameWidthに加算する
const HALF_DAY_DIFF = 0.5
// 111 * 0.05：チェックアウト日のステータスバー（endBarStyle）のwidthが95%なので差分をnameWidthから差し引く値
const END_BAR_DIFF = 5.5
// 名前表示の余白部分を確保するためnameWidthから差し引く値
const MARGIN_VALUE = 15
// 表示開始日付以前（beginBarStyle）につける余白分を差し引く値
const BEGIN_PADDING_VALUE = 59.5

type DailyListBodyProps = {
  statusData: StatusType
  statusIndex: number
  date: DateType
  roomAssignActiveId: string
  roomNumber: string
  editGuestRooms: any
  setChangeUrl: React.Dispatch<React.SetStateAction<string>>
  setIsBar: React.Dispatch<React.SetStateAction<boolean>>
  beginDate: string
  endDate: string
  setIsClickStatusBar: React.Dispatch<React.SetStateAction<boolean>>
  clearRoomAssign: () => void
}

export const StatusBar: React.FC<DailyListBodyProps> = ({
  statusData,
  statusIndex,
  date,
  roomAssignActiveId,
  roomNumber,
  editGuestRooms,
  setChangeUrl,
  setIsBar,
  beginDate,
  endDate,
  setIsClickStatusBar,
  clearRoomAssign,
}) => {
  const { t } = useTranslation()
  const _assignCheckinDays = moment(statusData.assignCheckinDays)
  const _assignCheckoutDays = moment(statusData.assignCheckoutDays)
  const _days = moment(date.days)
  const _beginDate = moment(beginDate)
  const _endDate = moment(endDate)

  const nameWidth = (type: string) => {
    const diffAssignDays = _assignCheckoutDays.diff(_assignCheckinDays, 'day')
    const diffBeginDate = _assignCheckoutDays.diff(_beginDate, 'day') + HALF_DAY_DIFF

    const width = Math.min(diffAssignDays, diffBeginDate) * DAILY_LIST_BLOCK_WIDTH - MARGIN_VALUE - END_BAR_DIFF

    return type === 'beginBar' ? width - BEGIN_PADDING_VALUE : width
  }

  const onClickBar = e => {
    e.stopPropagation()
    // Deselect the currently selected bar if you click again
    const params = require('query-string').parse(window.location.search)
    if (statusData.reservationId === params.reservationId || statusData.guestRoomAssignId === params.guestRoomAssignId) {
      return clearRoomAssign()
    }

    clickShowDetail(statusData)
    setIsClickStatusBar(true)
    setChangeUrl(window.location.href)
    setIsBar(true)
  }

  const statusColor = () => {
    switch (statusData.status) {
      case 'reservation':
        return '#FFFCDD'
      case 'checkin':
        return '#FEF3F0'
      case 'stay':
        return '#EFF9FF'
      case 'checkout':
        return '#F6FDF2'
      case 'preparation':
        return '#F2F2F2'
    }
  }

  const nameColor = () => {
    switch (statusData.status) {
      case 'reservation':
        return '#F2A40B'
      case 'checkin':
        return '#F47110'
      case 'stay':
        return '#3E85CC'
      case 'checkout':
        return '#7DC931'
      case 'preparation':
        return '#676767'
    }
  }

  // Return color depending on assignment status
  const _statusColor = () => {
    // Returns ActiveStyle if the reservation ID or assignment ID (if preparing) matches the active ID
    if (
      roomAssignActiveId &&
      (statusData.reservationId === roomAssignActiveId ||
        (statusData.status === 'preparation' && statusData.guestRoomAssignId === roomAssignActiveId))
    ) {
      if (
        /**
         * When the check-in date (assignCheckinDays) in the assignment data and the changed check-in date (changeAssignCheckinDays) are different (when the check-in date is changed)
         * Or return an empty object if the room number has changed
         */
        statusData.assignCheckinDays !== statusData.changeAssignCheckinDays ||
        statusData.assignCheckoutDays !== statusData.changeAssignCheckoutDays ||
        !editGuestRooms.some(editGuestRoom => editGuestRoom.roomNumber === roomNumber)
      ) {
        return {}
      } else {
        // Returns the style if the assignment information has not changed
        return { background: nameColor() }
      }
    }

    // Otherwise return normal style
    return { background: statusColor(), color: nameColor() }
  }

  // Return Style depending on bar type and state
  const _barStyle = (type: string): SerializedStyles => {
    // Returns ActiveStyle if the reservation ID or assignment ID (if preparing) matches the active ID
    if (
      roomAssignActiveId &&
      (statusData.reservationId === roomAssignActiveId ||
        (statusData.status === 'preparation' && statusData.guestRoomAssignId === roomAssignActiveId))
    ) {
      // Returns ChangeStyle if editing
      if (
        /**
         * When the check-in date (assignCheckinDays) in the assignment data and the changed check-in date (changeAssignCheckinDays) are different (when the check-in date is changed)
         * Or if the room number is changed, change to each ChangeStyle (gray color)
         */
        statusData.assignCheckinDays !== statusData.changeAssignCheckinDays ||
        statusData.assignCheckoutDays !== statusData.changeAssignCheckoutDays ||
        !editGuestRooms.some(editGuestRoom => editGuestRoom.roomNumber === roomNumber)
      ) {
        if (type === 'endBar') return endBarChangeStyle
        if (type === 'beginBar') return beginBarChangeStyle
        if (type === 'startBar') return startBarChangeStyle
        if (type === 'betweenBar') return betweenBarChangeStyle
        if (type === 'dayUse') return dayUseBarChangeStyle
        return barChangeStyle
      } else {
        if (type === 'endBar') return endBarActiveStyle
        if (type === 'beginBar') return beginBarActiveStyle
        if (type === 'startBar') return startBarActiveStyle
        if (type === 'betweenBar') return betweenBarActiveStyle
        if (type === 'dayUse') return dayUseActiveBarStyle
        return barActiveStyle
      }
    }
    // Otherwise return normal bar
    if (type === 'endBar') return endBarStyle
    if (type === 'beginBar') return beginBarStyle
    if (type === 'startBar') return startBarStyle
    if (type === 'betweenBar') return betweenBarStyle
    if (type === 'dayUse') return dayUseBarStyle
    return barStyle
  }

  const _bar = (type: string) => {
    return (
      <div
        key={`StatusBarCompoment_bar${statusIndex}_${Math.random() * 1000000}`}
        css={_barStyle(type)}
        style={_statusColor()}
        onClick={e => onClickBar(e)}
      ></div>
    )
  }

  const _pinColor = () => {
    if (statusData.reservationId === roomAssignActiveId) return '#fff'

    switch (statusData.status) {
      case 'reservation':
        return '#f2a428'
      case 'checkin':
        return '#f47110'
      case 'stay':
        return '#3e85cc'
      case 'checkout':
        return '#7dc931'
      default:
        return '#fff'
    }
  }

  const _nameBar = (type: string) => {
    const name = statusData.userName ? statusData.userName : statusData.reservationGuestName ? statusData.reservationGuestName : ''

    return (
      <div key={`StatusBarCompoment_nameBar${statusIndex}`} css={_barStyle(type)} style={_statusColor()} onClick={e => onClickBar(e)}>
        {statusData.status !== 'preparation' ? (
          <>
            {
              // TODO: Delete reservationGuestName as soon as assign_guest_name in guest_room_assign table is modified
            }
            {!!statusData.isFixed && (
              <div css={{ marginRight: 4 }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                  <path
                    d="M9.258,2.293,8.5,3.049l.586.586L5.774,6.293,4.982,5.5l-.756.756L6.257,8.288,3,11.544v.749h.749L7.005,9.036l2.032,2.032.756-.756-.747-.747,2.622-3.35.576.576L13,6.035Z"
                    transform="translate(-3 -2.293)"
                    fill={_pinColor()}
                  />
                </svg>
              </div>
            )}
            <div css={[nameStyle, { flex: `0 0 ${nameWidth(type)}px` }]}>{type === 'dayUse' ? name.charAt(0) : name}</div>
          </>
        ) : (
          <div css={nameStyle}>{statusData.preparationMemo || t('In preparation')}</div>
        )}
      </div>
    )
  }

  return (
    <React.Fragment key={`StatusBarCompoment${statusIndex}`}>
      {statusData.assignCheckinDays !== statusData.assignCheckoutDays
        ? moment(statusData.assignCheckinDays).add(1, 'd').isSame(moment(_assignCheckoutDays))
          ? /****************
             * 1 night 2 days
             ***************/
            // If the check-in date is before the start date, display endBar on the start date Otherwise, display the bar on the check-in date
            _assignCheckinDays.isBefore(_beginDate) && _beginDate.isSame(_days, 'day')
            ? _bar('endBar')
            : _assignCheckinDays.isSame(_days, 'day') && _nameBar('bar')
          : /****************
             * 2 nights or more
             ***************/
            [
              // If the check-in date is before the start date, at the position of the start date
              _assignCheckinDays.isBefore(_beginDate) &&
                _beginDate.isSame(_days, 'day') &&
                // endBar if checkout date, beginBar if not checkout date
                (_beginDate.isSame(_assignCheckoutDays, 'day') ? _bar('endBar') : _nameBar('beginBar')),

              // startBar on check-in date
              _assignCheckinDays.isSame(_days, 'day') && _nameBar('startBar'),
              // Between is betweenBar(after check-in date && before check-out date)
              _assignCheckinDays.isBefore(_days, 'day') &&
                moment(statusData.assignCheckoutDays).add(-1, 'd').isAfter(_days, 'day') &&
                _bar('betweenBar'),
              // Checkout date is endBar
              statusData.assignCheckoutDays === date.days && _bar('endBar'),

              // If the check-out date is later than the end date and not the check-in date, display beginBar on the end date
              _assignCheckoutDays.isAfter(_endDate, 'day') &&
                !_assignCheckinDays.isSame(_days, 'day') &&
                _endDate.isSame(_days, 'day') &&
                _bar('beginBar'),
            ]
        : /****************
           * day use
           ***************/
          _assignCheckinDays.isSame(_days, 'day') && _nameBar('dayUse')}
    </React.Fragment>
  )
}
