import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import 'react-dates/initialize'
import { modalContainerStyle } from '@/components/pages/accommodation-management/list-layout'
import { get as transformHeaderGet } from 'lodash'
import { useErrorHandler } from '@/hooks/use-error-handler'

// components
import { Button } from '@/components/atoms/button'
import { CSVReader } from '@/components/organisms/csv-reader'

// contexts
import { AccountContext } from '@/contexts/account'

// libs
import { reservation } from '@/libs/reservation'

// apis
import {
  updateReservationCsv,
  fetchReservationStatus,
  fetchReservationTlApi,
  fetchReservationTemairazuApi,
  fetchReservationNeppanApi,
} from '@/apis/aipass'
import { initializeSelectBoxStyle } from '@/constants/form'

type ContainerProps = {
  closeCsvUploadModal: () => void
  setIsModalOpen: (isModalOpen: boolean) => void
  fetchAdminCheckinList: () => void
  refresh: () => void
  setIsLoading: (isLoading: boolean) => void
}

// CSV upload
export const CsvUploadModal: React.FC<ContainerProps> = ({
  closeCsvUploadModal,
  setIsModalOpen,
  refresh,
  setIsLoading,
  fetchAdminCheckinList,
}) => {
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const [isCsvUploaded, setIsCsvUploaded] = useState<boolean>(false)
  const [selectSiteController, setSelectSiteController] = useState('none')
  const [isReservationStatus, setIsReservationStatus] = useState<string>('')
  const { errorHandler } = useErrorHandler()

  const parseOptions = {
    header: true,
    skipEmptyLines: true,
    transformHeader: h => transformHeaderGet(reservation, h) || h,
  }

  const onFileLoad = async (data, file) => {
    try {
      closeCsvUploadModal()
      const fileName = file.name
      const reservationData = {
        reservationData: data.map(function (value) {
          // Convert country code and remove hyphens and spaces (Convert strings from + to hyphens or spaces to 0 and remove hyphens and spaces)
          const _formatTel = tel => {
            if (!tel) return ''
            if (/^[+＋]/.test(tel)) {
              const target = tel.search(/[\s/-]/i)
              return tel.replace(tel.slice(0, target), '0').replace(/[\s/-]/gi, '')
            } else {
              return tel.replace(/[\s/-]/gi, '')
            }
          }

          let loopLimiter = 100
          const rooms: Array<{ roomDate: number; roomType: string; roomCount: number; roomRate: number }> = []
          do {
            const roomTypeKey = Object.keys(value).find(x => x.match(/^室タイプ（.+）$/))
            if (!roomTypeKey || !value[roomTypeKey]) {
              break
            }
            const keyGroupIndex = roomTypeKey.replace('室タイプ', '')

            const isEnabledRoomRate =
              Number(value[`室料金${keyGroupIndex}`]) +
              Number(value[`料金（大人男）${keyGroupIndex}`]) +
              Number(value[`料金（大人女）${keyGroupIndex}`])
            if (isEnabledRoomRate) {
              rooms.push({
                roomDate: dayjs(value[`利用日${keyGroupIndex}`]).unix(),
                roomType: value[`室タイプ${keyGroupIndex}`],
                roomCount: Number(value[`室数${keyGroupIndex}`]),
                roomRate: Number(value[`室料金${keyGroupIndex}`]),
              })
            }
            delete value[`室タイプ${keyGroupIndex}`]
            loopLimiter -= 1
          } while (loopLimiter)

          return {
            dataClassification: value.DataClassification,
            salesOfficeCompanyCode: value.SalesOfficeCompanyCode,
            salesOfficeCompanyName: value.SalesOfficeCompanyName,
            channelCode: value.ChannelCode,
            travelAgencyBookingNumber: value.TravelAgencyBookingNumber,
            travelAgencyBookingDate: dayjs(value.TravelAgencyBookingDate).unix(),
            guestOrGroupNameKanjiName: value.GuestOrGroupNameKanjiName,
            guestOrGroupNameKanaName: value.GuestOrGroupNameKanaName,
            checkInDate: dayjs(`${value.CheckInDate}120000`).format('YYYY-MM-DD'),
            checkinTime: value.checkinTime,
            checkOutDate: dayjs(`${value.CheckOutDate}120000`).format('YYYY-MM-DD'),
            checkoutTime: value?.checkoutTime,
            packagePlanName: value.PackagePlanName,
            packagePlanCode: value.PackagePlanCode,
            specificMealCondition: value.SpecificMealCondition,
            totalAccommodationCharge: value.TotalAccommodationCharge,
            phoneNumber: _formatTel(value.PhoneNumber),
            email: value.Email,
            address: value.Address,
            userName: value.UserName,
            userKana: value.UserKana,
            userTel: _formatTel(value.UserTel),
            userMailAddr: value.UserMailAddr,
            userAddr: value.UserAddr,
            pointsDiscount: value.PointsDiscount,
            settlementDiv: value.SettlementDiv,
            otherServiceInformation: value.OtherServiceInformation,
            nights: value.Nights,
            totalRoomCount: value.TotalRoomCount,
            numberOfPeopleMale: value.NumberOfPeopleMale,
            numberOfPeopleFemale: value.NumberOfPeopleFemale,
            numberOfPeopleChildA: value.NumberOfPeopleChildA,
            numberOfPeopleChildB: value.NumberOfPeopleChildB,
            numberOfPeopleChildC: value.NumberOfPeopleChildC,
            numberOfPeopleChildD: value.NumberOfPeopleChildD,
            rooms,
            options: [
              {
                optionName: value.optionName1,
                optionRate: value.optionRate1,
                optionCount: value.optionCount1,
              },
              {
                optionName: value.optionName2,
                optionRate: value.optionRate2,
                optionCount: value.optionCount2,
              },
              {
                optionName: value.optionName3,
                optionRate: value.optionRate3,
                optionCount: value.optionCount3,
              },
              {
                optionName: value.optionName4,
                optionRate: value.optionRate4,
                optionCount: value.optionCount4,
              },
              {
                optionName: value.optionName5,
                optionRate: value.optionRate5,
                optionCount: value.optionCount5,
              },
            ],
          }
        }),
      }
      await updateReservationCsv(fileName, reservationData.reservationData)
      setIsCsvUploaded(true)
    } catch (error) {
      errorHandler(error)
    }
  }

  // TL1way/Temairazu/Neppan's reservation information acquisition API execution
  const _reservationStatus = async () => {
    setIsLoading(true)
    await fetchReservationStatus().then(res => {
      setIsReservationStatus(res?.reservationStatus)
    })
  }

  const _reservationApi = async () => {
    if (isReservationStatus === 'TL') {
      await fetchReservationTlApi()
        .then(() => {})
        .then(() => {
          fetchAdminCheckinList()
        })
        .then(() => {
          setIsLoading(false)
        })
    } else if (isReservationStatus === 'TM') {
      await fetchReservationTemairazuApi()
        .then(() => {})
        .then(() => {
          fetchAdminCheckinList()
        })
        .then(() => {
          setIsLoading(false)
        })
    } else if (isReservationStatus === 'NP') {
      await fetchReservationNeppanApi()
        .then(() => {})
        .then(() => {
          fetchAdminCheckinList()
        })
        .then(() => {
          setIsLoading(false)
        })
    } else {
      refresh()
    }
  }

  useEffect(() => {
    // Reacquire when CSV loading is complete
    if (isCsvUploaded && account) {
      refresh()
      setIsModalOpen(false)
    }
  }, [isCsvUploaded])

  useEffect(() => {
    // Reacquire when CSV loading is complete
    if (account) {
      _reservationStatus()
    }
  }, [account])

  useEffect(() => {
    if (isReservationStatus) {
      _reservationApi()
    }
  }, [isReservationStatus])

  // Determine by siteControllerChange when adding a site controller
  const siteControllerChange = e => {
    setSelectSiteController(e.target.value)
  }

  return (
    <div css={modalContainerStyle}>
      <div css={modalBodyContainerStyle}>
        <h3 css={modalHeaderStyle}>{t('CSV upload')}</h3>
        <div css={modalMainContainerStyle}>
          <div css={modalMainTopContainerStyle}>{t('Site controller')}</div>
          <select name="isPaid" defaultValue={String(t('Please select'))} onChange={siteControllerChange} css={siteControllerStyle}>
            <option value="none">{t('Please select')}</option>
            <option value="tl">{t('TL Lincoln')}</option>
          </select>
        </div>
        <div css={modalButtonSectionStyle}>
          <Button
            width={110}
            height={38}
            buttonType={3}
            marginRight={16}
            onClick={() => {
              setIsModalOpen(false)
            }}
          >
            {t('Cancel')}
          </Button>
          {selectSiteController === 'none' && (
            <Button width={110} height={40} buttonType={5} onClick={() => {}}>
              {t('Upload')}
            </Button>
          )}
          {selectSiteController === 'tl' && (
            <Button width={110} height={40} buttonType={1} onClick={() => {}}>
              <div onClick={() => {}}>
                <CSVReader
                  inputStyle={{ display: 'none', cursor: 'pointer' }}
                  label={t('Upload')}
                  parserOptions={parseOptions}
                  onFileLoaded={(data, file) => {
                    onFileLoad(data, file)
                  }}
                />
              </div>
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

// modal
const modalBodyContainerStyle = css({
  position: 'relative',
  top: '42%',
  left: '50%',
  width: 640,
  zIndex: 102,
  opacity: 1,
  background: '#FFF',
  borderRadius: 5,
  transform: 'translate(-50%, -50%)',
})

const modalHeaderStyle = css({
  height: 44,
  margin: '22px 32px',
  paddingTop: 22,
  fontWeight: 'bold',
  fontSize: 18,
  color: '#272727',
})

const modalMainContainerStyle = css({
  padding: '26px 32px',
  backgroundColor: '#F2F2F2',
})

const modalMainTopContainerStyle = css({
  display: 'flex',
  fontSize: 12,
  marginBottom: 12,
  fontWeight: 'bold',
  color: '#676767',
})

const modalButtonSectionStyle = css({
  display: 'flex',
  padding: '11px 32px 11px 32px',
  justifyContent: 'flex-end',
  boxShadow: '0px 0px 6px #0000001A',
})

const siteControllerStyle = css(initializeSelectBoxStyle, {
  width: '100%',
  height: 32,
  fontSize: 14,
  borderRadius: 19,
  padding: '0 24px 0 16px',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '95% center',
  backgroundSize: 10,
  display: 'block',
  marginBottom: 16,
  marginRight: 32,
  borderColor: '#CCCCCC',
})
