import React from 'react'
import { CSSObject, SerializedStyles, css } from '@emotion/core'
import { isEmpty } from 'lodash'

interface SearchConditionValuesProps {
  searchedConditions: Record<string, string>
}

export const useSearchedConditions = ({ searchedConditions }: SearchConditionValuesProps) => {
  const onlyHasValues = Object.entries(searchedConditions).filter(([_, value]) => !isEmpty(value))

  const SearchedConditionValues: React.FC<{ customCss?: CSSObject | SerializedStyles }> = ({ customCss }) => (
    <div css={[searchConditionStyle, customCss]}>
      {onlyHasValues?.map((value, index) => (
        <span key={index}>{`${value.join('：')}${index + 1 !== onlyHasValues.length ? '、' : ''}`}</span>
      ))}
    </div>
  )

  return { SearchedConditionValues }
}

export const searchConditionStyle = css({
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
  maxWidth: '755px',
  paddingRight: '20px',
  fontSize: 12,
  lineHeight: 1.5,
  fontWeight: 'bold',
  color: '#676767',
  letterSpacing: '0.6px',
  marginLeft: '8px',
})
