import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'
import { SelfCheckinHotelInfo } from '@/components/organisms/self-checkin/hotel-info'
import { Loading } from '@/components/molecules/self-checkin/loading'
import * as api from '@/apis/aipass'
import { SelfCheckinBasicInfoPluginType } from '@/models/self-checkin/results'
import { hasAnyReservationPlugin, PluginCode, checkActivePlugin } from '@/libs/plugins'

export const SelectLanguage: React.FC<{}> = () => {
  const { t, i18n } = useTranslation()
  const { search } = useLocation()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const params = new URLSearchParams(search)
  const hotelId = params.get('hotelId')
  type LanguageType = 'ja' | 'en' | 'zh' | 'ko'
  const [basicInfo, setBasicInfo] = useState<SelfCheckinBasicInfoPluginType['basicInfo']>()

  const fetchSelfCheckinSetting = async () => {
    const defaultSetting = {
      requiredJapaneseIdentify: true,
      requiredForeignIdentify: true,
      requiredAccompanyNameInput: false,
      requiredFuriganaInput: false,
      requiredBirthDateInput: false,
      requiredGenderInput: false,
      requiredTelephoneInput: false,
      requiredEmailInput: false,
      requiredAddressInput: false,
      isFrontDescGuidance: false,
      requiredIdentify: false,
    }
    return api.fetchSelfCheckinSetting(hotelId).then(res => {
      if (!res) {
        return defaultSetting
      }
      Object.keys(res).forEach(k => {
        if (Number.isInteger(res[k])) {
          res[k] = Boolean(res[k])
        }
      })
      return {
        ...defaultSetting,
        ...res,
        requiredJapaneseIdentify: res.requiredIdentify,
        requiredIdentify: false,
      }
    })
  }

  const fetchPaymentSetting = async (): Promise<string[]> => {
    return api.fetchPaymentSettingByHotelId(hotelId).then(res => {
      const selfCheckinPaymentSetting = res?.settings?.payment_time?.find(item => item.value === 'SELF_CHECKIN')
      if (!selfCheckinPaymentSetting?.is_enabled) {
        return []
      }
      const temp: any = []
      for (let index = 0; index < selfCheckinPaymentSetting.payment_method.length; index++) {
        const paymentMethod = selfCheckinPaymentSetting.payment_method[index]
        if (paymentMethod.is_enabled) {
          temp.push(paymentMethod.value)
        }
      }
      return temp
    })
  }

  const isGuidanceDisplay = async (plugins): Promise<boolean> => {
    const guides = await api.fetchGuide(hotelId).then(res => {
      return res?.customCheckin || []
    })
    return checkActivePlugin(plugins, PluginCode.CustomCheckin) && !!guides.length
  }

  const changeLanguage = async (language: LanguageType): Promise<void> => {
    try {
      i18n.changeLanguage(language)
      localStorage.setItem('lang', language)
      setIsLoading(true)

      const [{ results }, selfCheckinSetting, paymentSetting] = await Promise.all([
        api.fetchFacilityBasicInfoInSelfCheckin(hotelId),
        fetchSelfCheckinSetting(),
        fetchPaymentSetting(),
      ])
      const plugins = results.plugin as SelfCheckinBasicInfoPluginType['plugin']
      const _basicInfo = results.basicInfo[0] as SelfCheckinBasicInfoPluginType['basicInfo']

      const nextState = {
        hotelId,
        basicInfo: [_basicInfo],
        plugin: plugins,
        ...selfCheckinSetting,
        paymentSetting,
        isGuidanceDisplay: await isGuidanceDisplay(plugins),
      }
      if (hasAnyReservationPlugin(plugins)) {
        history.push({
          pathname: `/self-checkin/search-reservation`,
          state: nextState,
        })
      } else {
        history.push({
          pathname: `/self-checkin/accommodation-info`,
          state: {
            ...nextState,
            noneSelectReservation: true,
          },
        })
      }
    } catch (e) {
      console.error(e)
      setIsLoading(false)
    }
  }

  const browserBackBlockFunc = () => {
    history.go(1)
  }

  const reloadForVersionUp = () => {
    if (sessionStorage.getItem(api.CurrentVersionKey) !== sessionStorage.getItem(api.LatestVersionKey)) {
      sessionStorage.removeItem(api.CurrentVersionKey)
      sessionStorage.removeItem(api.LatestVersionKey)
      return window.location.reload()
    }
  }

  const backgroundStyle = useMemo(() => {
    if (basicInfo?.extImageId) {
      return css({
        background: `url(${basicInfo.extImageId}) center/cover no-repeat`,
        position: 'relative',
        '&::after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.36)',
          zIndex: 1,
        },
      })
    } else {
      return css({
        background: '#185AA5 0% 0% no-repeat padding-box',
      })
    }
  }, [basicInfo])

  useEffect(() => {
    if (!hotelId) {
      return
    }
    api.fetchFacilityBasicInfoInSelfCheckin(hotelId).then(({ results }) => {
      setBasicInfo(results.basicInfo[0])
      reloadForVersionUp()
    })
  }, [hotelId])

  useEffect(() => {
    window.history.pushState(null, '', window.location.href)
    window.addEventListener('popstate', browserBackBlockFunc)
    return () => {
      window.removeEventListener('popstate', browserBackBlockFunc)
    }
  }, [])

  useEffect(() => {
    const language = localStorage.getItem('lang')
    if (language) i18n.changeLanguage(language)
  }, [])

  if (!hotelId) {
    history.push({
      pathname: '/dashboard',
    })
    return <></>
  }

  return (
    <>
      <Loading isLoading={isLoading} />
      <div css={[containerStyle, { fontFamily: 'Noto Sans JP' }]}>
        <div css={[backgroundStyle, { minHeight: '100vh' }]}>
          <div css={pleaseSelectStyle}>
            <p>{t('Please select language')}</p>
          </div>
          <div css={innerStyle}>
            <div css={checkinMethodsStyle}>
              <div css={checkinMethodItemStyle} onClick={() => changeLanguage('ja')}>
                <p>日本語</p>
              </div>
              <div css={checkinMethodItemStyle} onClick={() => changeLanguage('en')}>
                <p>English</p>
              </div>
            </div>
            <div css={checkinMethodsStyle}>
              <div css={checkinMethodItemStyle} onClick={() => changeLanguage('zh')}>
                <p>中文</p>
              </div>
              <div css={checkinMethodItemStyle} onClick={() => changeLanguage('ko')}>
                <p>한국어</p>
              </div>
            </div>
          </div>
          <div css={poweredByContainer}>
            {basicInfo && (
              <SelfCheckinHotelInfo
                logoImage={basicInfo.logoImageId}
                telephone={basicInfo.telephone}
                frontOpenTime={basicInfo.frontOpenTime}
                frontCloseTime={basicInfo.frontCloseTime}
                usedIn="top"
                position="static"
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const containerStyle = css({
  width: '100%',
  minHeight: '100vh',
})

const innerStyle = css({
  width: 840,
  zIndex: 2,
  position: 'relative',
  margin: '0 auto',
})

const checkinMethodsStyle = css({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: 20,
})

const checkinMethodItemStyle = css({
  width: '31.742vw',
  height: 'calc( 100vh * 0.192 )',
  borderRadius: 10,
  border: '2px solid #fff',
  boxShadow: '0px 3px 10px #00000014',
  textAlign: 'center',
  padding: '56.5px 0',
  cursor: 'pointer',
  margin: '0 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  img: {
    width: 244,
  },
  p: {
    color: '#fff',
    fontSize: 24,
    letterSpacing: '2.4px',
    lineHeight: '42px',
    fontWeight: 'bold',
  },
})

const poweredByContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  width: '100%',
  height: 96,
  textAlign: 'center',
  position: 'fixed',
  bottom: 0,
  zIndex: 2,
  img: {
    width: 64,
    height: 64,
  },
})
const pleaseSelectStyle = css({
  color: '#FFF',
  position: 'relative',
  zIndex: 2,
  textAlign: 'center',
  fontSize: 21,
  fontWeight: 'bold',
  paddingTop: 'calc(100vh * 0.14)',
  paddingBottom: 'calc( 100vh * 0.05 )',
  p: {
    fontSize: 18,
    lineHeight: '30px',
    letterSpacing: '2.7px',
  },
})
