import React, { MouseEvent, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

export const useCopyText = () => {
  const { t } = useTranslation()
  const [copyPosition, setCopyPosition] = useState<{ top: number; left: number; height: number }>()

  const copyText = async (e: MouseEvent<HTMLSpanElement>, copyText?: string) => {
    const item = e.currentTarget
    const copiedText = item.getBoundingClientRect()
    setCopyPosition({ top: copiedText.top, left: copiedText.left, height: copiedText.height })

    await navigator.clipboard.writeText(copyText || item.innerText)

    setTimeout(() => {
      setCopyPosition(undefined)
    }, 500)
  }

  const CompleteToast: React.FC = () =>
    copyPosition ? (
      <span css={copyPopupStyle} style={{ top: copyPosition.top + copyPosition.height, left: copyPosition.left }}>
        {t('Copied.')}
      </span>
    ) : (
      <></>
    )
  return {
    copyText,
    CompleteToast,
  }
}

const copyPopupStyle = css({
  padding: 8,
  position: 'fixed',
  color: '#676767',
  background: '#fff',
  boxShadow: '0px 0px 10px #00000029',
  fontSize: 12,
  fontWeight: 'bold',
  zIndex: 100,
})
