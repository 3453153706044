import React, { useState, useEffect, useContext } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms/button'
import { RemoteLockModal } from '@/components/pages/setting/guest-room/key-number/remotelock-modal'
import { LoaderContextCreator } from '@/contexts/loader'
import { getSmartLockAccount, getStatusSelectMiwaLockPlugin, putStatusSelectMiwaLockPlugin } from '@/apis/aipass'
import { MiwaLockStatusSelect } from '@/constants/miwa-lock'
import { setHasMiwaLockPlugin } from '@/libs/plugins'
import { AccountContext } from '@/contexts/account'
import { RemoteLockClient, RemoteLockContextCreator } from '@/contexts/remote-lock'
import { AccountInfo, LockType } from '@/models/lock-setting'
import { initializeSelectBoxStyle } from '@/constants/form'

type Props = {
  selectRoomKey: LockType
  onChangeType: (v: LockType) => void
}

export const LinkSettingContainer: React.FC<Props> = ({ selectRoomKey, onChangeType }) => {
  const { t } = useTranslation()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const { plugins } = useContext<any>(AccountContext)
  const remoteLockClient = useContext<RemoteLockClient>(RemoteLockContextCreator())
  const hasMiwaLockPlugin = setHasMiwaLockPlugin(plugins)
  const [remoteLockModalOpen, setRemoteLockModalOpen] = useState<boolean>(false)
  const [statusSelectMiwaLock, setStatusSelectMiwaLock] = useState<number>(0)
  const [accountInfos, setAccountInfos] = useState<AccountInfo[]>([])

  const roomKeyChange = async (changeValue: LockType) => {
    if (changeValue === LockType.Manual && statusSelectMiwaLock === MiwaLockStatusSelect.OFF) {
      await changeStatusSelectMiwaLock(MiwaLockStatusSelect.ON)
    }
    onChangeType(changeValue)
  }

  const changeStatusSelectMiwaLock = async (status: number) => {
    setIsLoading(true)
    await putStatusSelectMiwaLockPlugin(status)
      .then(() => {
        setStatusSelectMiwaLock(status)
      })
      .catch(() => {
        window.alert(t('Could not update. Please try again.'))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getStatusSelectMiwaLock = async () => {
    try {
      setIsLoading(true)

      const { is_selected = MiwaLockStatusSelect.OFF } = (await getStatusSelectMiwaLockPlugin()) || {}

      setStatusSelectMiwaLock(is_selected)
    } catch (error) {
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  const pickSettingByType = (lockType: LockType) => accountInfos.find(i => i.lockType === lockType)

  useEffect(() => {
    remoteLockClient.initialize()
    getSmartLockAccount().then(setAccountInfos)
    if (hasMiwaLockPlugin) getStatusSelectMiwaLock()
  }, [plugins])

  return (
    <div css={settingRemoteLockStyle} id="settingRemoteLockStyle">
      <div css={settingRoomKeyTitleStyle}>{t('Cooperation method')}</div>
      <div css={selectRoomKeyButtonStyle}>
        <select name="isKey" value={selectRoomKey} onChange={e => roomKeyChange(e.target.value as LockType)} css={selectRoomKeyStyle}>
          {/* Because using translation, must pass value to option select */}
          <option value={LockType.Manual}>{t('Manual')}</option>
          <option value={LockType.RemoteLock} disabled={!remoteLockClient.isEnabled}>
            {t('Remote Lock')}
          </option>
          {hasMiwaLockPlugin && <option value={LockType.MiwaLock}>{t('MIWA Lock')}</option>}
          {pickSettingByType(LockType.SwitchBot)?.isEnabled && (
            <option value={LockType.SwitchBot} disabled={!pickSettingByType(LockType.SwitchBot)?.isConnected}>
              Switch Bot
            </option>
          )}
        </select>
      </div>
      {selectRoomKey === LockType.RemoteLock && (
        <Button
          width={remoteLockClient.isConnected ? 74 : 96}
          height={32}
          marginLeft={16}
          buttonType={remoteLockClient.isConnected ? 3 : 1}
          onClick={() => {
            remoteLockClient.isConnected ? setRemoteLockModalOpen(true) : remoteLockClient.openRemoteLockOAuthModal()
          }}
        >
          {remoteLockClient.isConnected ? t('Setting') : t('Alignment')}
        </Button>
      )}
      {remoteLockModalOpen && (
        <RemoteLockModal
          connected={remoteLockClient.isConnected}
          onDisconnect={async () => {
            setIsLoading(true)
            await remoteLockClient.disconnect()
            setIsLoading(false)
            setRemoteLockModalOpen(false)
          }}
          onSync={async () => {
            setIsLoading(true)
            await remoteLockClient.syncDevice()
            setIsLoading(false)
            setRemoteLockModalOpen(false)
          }}
          onCancel={() => setRemoteLockModalOpen(false)}
        />
      )}
    </div>
  )
}

const settingRemoteLockStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  color: '#676767',
  textAlign: 'left',
  marginLeft: 32,
  marginTop: 31,
  marginBottom: 26,
})

const settingRoomKeyTitleStyle = css({
  marginTop: 10,
})

const selectRoomKeyButtonStyle = css({
  marginLeft: 24,
})

const selectRoomKeyStyle = css(initializeSelectBoxStyle, {
  width: '100%',
  height: 32,
  fontSize: 14,
  borderRadius: 19,
  padding: '0 24px 0 16px',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '95% center',
  backgroundSize: 10,
  display: 'block',
  marginRight: 16.5,
  borderColor: '#CCCCCC',
})
