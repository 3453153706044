import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

type TabProps = {
  currentTab: 'marketSegment' | 'channelCode' | 'planName'
}

export const SettingReservationTab: React.FC<TabProps> = ({ currentTab }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  return (
    <div css={settingTabContainerStyle}>
      <div
        css={currentTab === 'channelCode' ? settingTabActiveStyle : settingTabStyle}
        onClick={() => history.push({ pathname: '/setting/reservation/channel-code', search: location.search })}
      >
        {t('Channel')}
      </div>
      <div
        css={currentTab === 'planName' ? settingTabActiveStyle : settingTabStyle}
        onClick={() => history.push({ pathname: '/setting/reservation/plan', search: location.search })}
      >
        {t('Plan name')}
      </div>
      <div
        css={currentTab === 'marketSegment' ? settingTabActiveStyle : settingTabStyle}
        onClick={() => history.push({ pathname: '/setting/reservation/market-segment', search: location.search })}
      >
        {t('Segment')}
      </div>
    </div>
  )
}
const centerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const settingTabContainerStyle = css({
  height: 45,
  display: 'flex',
})

const settingTabStyle = css(centerStyle, {
  width: 134,
  height: 45,
  borderRadius: '5px 5px 0 0',
  background: '#F5F5F5',
  boxShadow: '0px 0px 6px #0000001A',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#676767',
  '&:hover': {
    cursor: 'pointer',
    opacity: 0.8,
  },
})

const settingTabActiveStyle = css(centerStyle, {
  width: 134,
  height: 41,
  borderRadius: '5px 5px 0 0',
  background: '#fff',
  boxShadow: '0px 0px 6px #0000001A',
  position: 'relative',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#F2A40B',
  '&::after': {
    position: 'absolute',
    bottom: -4,
    left: 0,
    height: 6,
    width: '100%',
    content: '""',
    background: '#fff',
    zIndex: 3,
  },
})
