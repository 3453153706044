import { DragEvent, useState } from 'react'
import { css } from '@emotion/core'

type Props<T> = {
  list: Array<T & { id: string | number }>
  onChange: (newList: Array<T & { id: string | number }>) => void
  direction?: 'vertical' | 'horizontal'
  key?: string
}
export function useDraggableDom<T>({ list, onChange, direction = 'vertical', key = 'id' }: Props<T>) {
  const [draggingList, setDraggingList] = useState<(typeof list)[number] | undefined>()

  const isDropToAfter = (event): boolean => {
    const { top, bottom, left, right } = event.currentTarget.getBoundingClientRect()
    if (direction === 'vertical') {
      return Math.abs(top - event.clientY) > Math.abs(bottom - event.clientY)
    } else {
      return Math.abs(left - event.clientX) > Math.abs(right - event.clientX)
    }
  }
  const dragStart = (event: DragEvent<HTMLDivElement>) =>
    setDraggingList(list.find(item => item[key].toString() === event.currentTarget.id))
  const dragOver = (event: DragEvent<HTMLDivElement>) => event.preventDefault()
  const dragDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const dropOnListId = event.currentTarget.id

    const isDraggable = event.currentTarget.draggable
    if (!draggingList || !dropOnListId || !isDraggable || dropOnListId === draggingList[key].toString()) {
      return
    }

    const filteredDragList = list.filter(item => item[key] !== draggingList[key])
    const dropListIndex = filteredDragList.findIndex(item => item[key].toString() === dropOnListId)
    filteredDragList.splice(isDropToAfter(event) ? dropListIndex + 1 : dropListIndex, 0, draggingList)
    onChange([...filteredDragList])
  }
  return { dragStart, dragOver, dragDrop, isDropToAfter }
}

export const draggableIconStyle = css({
  '&[draggable="true"]': {
    cursor: 'pointer',
    position: 'relative',
    ':hover': {
      ':before': {
        content: '""',
        width: 30,
        height: 30,
        backgroundSize: 30,
        backgroundImage: `url(${require('@/static/images/icon_list.svg')})`,
        position: 'absolute',
        left: 16,
      },
    },
  },
})
