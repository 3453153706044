import React, { useState } from 'react'
import { useWindowSize } from 'react-use'
import { css } from '@emotion/core'
import { ErrorModal } from '@/components/molecules/qr-checkin/error-modal'
import { fetchCheckinReservationsAndSales, selfCheckinGuestRoomAutoAssign } from '@/apis/aipass'
import { decodeQr } from '@/libs/qr-decode'
import QrReader from 'react-qr-reader'
import { useHistory, useLocation } from 'react-router-dom'
import { setHasPaymentPlugin } from '@/libs/plugins'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { useTranslation } from 'react-i18next'

// utils
import { changeRequiredIdentify } from '@/utils/required-identify'

type Props = {
  _onClose: () => void
  _onScan: (data: any) => void
  isError: boolean
  _onError: () => void
}

export const SelfCheckinQrCamera: React.FC = () => {
  const { state, search } = useLocation<{
    basicInfo: any
    hotelId: string
    reservationSearchCondtion: {
      inputType: string
      phoneNumber: string
      reservationId: string
      firstName: string
      lastName: string
      firstNameFurigana: string
      lastNameFurigana: string
    }
    plugin: any
    requiredIdentify: any
    requiredJapaneseIdentify: boolean
    requiredForeignIdentify: boolean
    reservationInfo: any
    paymentInfo: any
    companionNumber: any
    from: any
    guides: any
    identificationBackImage: any
    identificationFrontImage: any
    imageType: any
    index: any
    representativePassportImage: any
    selectReservationType: any
    selectedReservations: any
    checkinMemo: any
    companionImageArray: any
    paymentMethod: any
    paymentSetting: any
    requiredAccompanyNameInput: boolean
  }>()
  const history = useHistory()
  const [isError, setIsError] = useState<boolean>(false)
  const [checkinId, setCheckinId] = useState<boolean>(false)
  const { t, i18n } = useTranslation()

  const _onClose = () => {
    if (state) {
      delete state.companionNumber
      delete state.from
      delete state.guides
      delete state.identificationBackImage
      delete state.identificationFrontImage
      delete state.imageType
      delete state.index
      delete state.representativePassportImage
      delete state.selectReservationType
      delete state.selectedReservations
      delete state.checkinMemo
      delete state.companionImageArray
      delete state.reservationInfo
      delete state.paymentMethod
    }

    history.push({
      pathname: `/self-checkin/search-reservation`,
      search,
      state,
    })
  }

  const checkWhiteSpace = (value: string) => {
    return value.includes(' ')
  }

  const parseReservationIntoInput = reservation => {
    const enteredAccompanies =
      reservation?.accompany.length > 0
        ? reservation?.accompany.map(value => {
            const lastName = value.name.slice(0, value.name.indexOf(' '))
            const firstName = value.name.slice(value.name.indexOf(' ') + 1, value.name.length)
            return { id: value.id, name: value.name, lastName: lastName, firstName: firstName }
          })
        : []
    const selectedPaxNum = (reservation?.accompany.length || 0) + 1
    const selectedHasContact = selectedPaxNum > 1 && selectedPaxNum.length - 1 !== enteredAccompanies

    return {
      nationality: reservation.nationality === 'JPN' || !reservation.nationality ? 'japanese' : 'nonJapanese',
      firstName: checkWhiteSpace(reservation.name) ? reservation.name.substring(reservation.name.indexOf(' ') + 1) : '',
      lastName: checkWhiteSpace(reservation.name) ? reservation.name.substring(0, reservation.name.indexOf(' ')) : reservation.name,
      firstNameFurigana: checkWhiteSpace(reservation.nameKana) ? reservation.nameKana.substring(reservation.nameKana.indexOf(' ') + 1) : '',
      lastNameFurigana: checkWhiteSpace(reservation.nameKana)
        ? reservation.nameKana.substring(0, reservation.nameKana.indexOf(' '))
        : reservation.nameKana,
      dateOfBirth: reservation.birthDate ?? '',
      gender: reservation.gender ?? 'M',
      phoneNumber: reservation.telephone && reservation.telephone !== '0' ? reservation.telephone : '',
      mail: reservation.email ?? '',
      occupation: reservation.occupation ?? '',
      postCode: reservation.postCode ?? '',
      company: reservation.company ?? '',
      otherOccupation: reservation.otherOccupation ?? '',
      address: reservation.address ?? '',
      checkinId: reservation.checkinId,
      checkinCode: reservation.checkinCode,
      accompany: enteredAccompanies,
      paxTotal: selectedPaxNum,
      hasContactForAllGuest: selectedPaxNum === 1 ? !state.requiredAccompanyNameInput : selectedHasContact,
    }
  }

  const _onScan = data => {
    if (data && !checkinId) {
      decodeQr(data)
        .then(async (res: any) => {
          try {
            setCheckinId(res.id)
            const response = await fetchCheckinReservationsAndSales(state.hotelId, [], res.id)
            if (response?.reservations[0]?.nationality) {
              const responseRequiredIdentify = changeRequiredIdentify(
                response?.reservations[0]?.nationality,
                state.requiredJapaneseIdentify,
                state.requiredForeignIdentify,
              )
              state.requiredIdentify = responseRequiredIdentify
            }

            const qrReservationIds = response.reservations.map(item => item.reservationId)
            selfCheckinGuestRoomAutoAssign({ hotelId: state.hotelId, reservationIds: qrReservationIds })
            for (let index = 0; index < response.reservations.length; index++) {
              const reservation = response.reservations[index]
              if (reservation.sales.length && setHasPaymentPlugin(state.plugin) && state.paymentSetting.length) {
                history.push({
                  pathname: '/self-checkin/qr-checkin-camera/payment',
                  search,
                  state: {
                    ...state,
                    reservationInfo: parseReservationIntoInput(reservation),
                    qrReservationIds,
                  },
                })
                return
              }
            }

            delete state.paymentInfo
            if (state?.requiredIdentify) {
              let uploadPath = '/self-checkin/qr-checkin-camera/upload-license'

              if (i18n.language !== 'ja') {
                uploadPath = `/self-checkin/qr-checkin-camera/upload-passport`
              }

              history.push({
                pathname: uploadPath,
                search,
                state: {
                  ...state,
                  reservationInfo: parseReservationIntoInput(response.reservations[0]),
                  qrReservationIds,
                },
              })
            } else {
              history.push({
                pathname: `/self-checkin/qr-checkin-camera/checkedin-confirm`,
                search,
                state: {
                  ...state,
                  reservationInfo: parseReservationIntoInput(response.reservations[0]),
                  qrReservationIds,
                },
              })
            }
          } catch (error) {
            alert(t('There is no reservation information'))
            setCheckinId(false)
            return
          }
        })
        .catch(e => {
          console.log(e)

          setIsError(true)
          return
        })
    }
    if (!data) {
      setIsError(false)
    }
  }

  const _onError = () => {
    setIsError(true)
  }

  return <UI _onClose={_onClose} _onScan={_onScan} isError={isError} _onError={_onError} />
}

export const UI: React.FC<Props> = ({ _onClose, _onScan, isError, _onError }) => {
  const windows = useWindowSize()
  const submit = () => {}
  const isDisabled = () => false
  const disable = false
  const { t } = useTranslation()
  return (
    <>
      {isError && <ErrorModal />}
      <div
        style={{
          height: windows.height,
        }}
        css={qrCameraWrapperStyle}
      >
        <QrReader showViewFinder={false} onScan={_onScan} onError={_onError} constraints={{ facingMode: 'user' }} />
        <p css={qrCameraTitleStyle}>{t('Please align QR code within the frame to scan')}</p>
        <div css={qrCameraMaskStyle}>
          <div css={maskTopStyle} />
          <div css={cameraWrapperStyle}>
            <div css={maskLeftStyle} />
            <div css={qrCameraStyle} />
            <div css={maskRightStyle} />
          </div>
          <div css={maskBottomStyle} />
        </div>
        <SelfCheckinFooter
          isDisabledButton={disable}
          isDisabled={isDisabled}
          goToPreviousPage={_onClose}
          goToNextPage={submit}
          isNext={'next'}
        />
      </div>
    </>
  )
}

const qrCameraWrapperStyle = css({
  background: '#F2F2F2',
  minWidth: '100%',
  textAlign: 'center',
  section: {
    height: '100%',
  },
})

const qrCameraMaskStyle = css({
  width: '100%',
  height: 'calc(100vh - 90px)',
  position: 'absolute',
  top: 0,
})

const cameraWrapperStyle = css({
  display: 'flex',
  position: 'relative',
})

const maskTopStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 'calc((100vh - 90px - 525px)/2)',
})

const maskLeftStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 525,
  width: 'calc((100% - 525px)/2)',
  left: 0,
})

const qrCameraStyle = css({
  width: '525px',
  height: '525px',
  background: 'transparent',
  border: '2px solid #FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const maskRightStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 525,
  width: 'calc((100% - 525px)/2)',
  position: 'absolute',
  right: 0,
})

const maskBottomStyle = css({
  background: 'rgba(0, 0, 0, 0.6)',
  height: 'calc((100vh - 90px - 525px)/2)',
})

const qrCameraTitleStyle = css({
  fontSize: 18,
  letterSpacing: '3.6px',
  fontWeight: 'bold',
  lineHeight: '28px',
  color: 'white',
  position: 'absolute',
  width: '100%',
  zIndex: 2,
  top: 'calc((100vh - 90px - 525px)/4)',
})
