import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// components
import { Button } from '@/components/atoms/button'

// models
// import { ReservationType } from '@/models/reservation'
import dayjs from 'dayjs'
import i18n from '@/i18n'
import { dividedRoomNumber } from '@/libs/divide-list'
import { ApprovedStatusType } from '@/models/reservation-approved-status'

type Props = {
  reservation: any
  reservationCheckinLink: (v: {}) => void
}

export const approvedStatus = [
  {
    value: ApprovedStatusType.Checkin,
    label: i18n.t('Checkin'),
  },
  {
    value: ApprovedStatusType.Stay,
    label: i18n.t('During your stay'),
  },
  {
    value: ApprovedStatusType.Checkout,
    label: i18n.t('Checkout'),
  },
]

export const ReservationList: React.FC<Props> = ({ reservation, reservationCheckinLink }) => {
  const { t } = useTranslation()
  return (
    <>
      <div css={reservationListContainer}>
        <div css={reservationListSubject}>
          <div css={{ color: '#272727' }}>{t('Reservation list')}</div>
          {/* <Button buttonType={10} width={116} height={24} marginLeft={0} onClick={() => {}} borderRadius={5} fontWeight={'normal'}>
            Bulk change
            <img css={arrowStyle} src={require('@/static/images/arrow_yellow.svg')} alt="" />
          </Button> */}
        </div>
        <div style={{ overflowX: 'auto', borderRadius: '0px 0px 10px 10px' }}>
          <table>
            <thead>
              <tr css={listTitle}>
                <th css={reservationIdStyle}>{t('Accommodation ID')}</th>
                <th css={reservationDateStyle}>{t('Date of stay')}</th>
                <th css={reservationUserStyle}>{t('Guest')}</th>
                <th css={roomNumberStyle}>{t('Room number')}</th>
                <th css={amountCancelledStyle}>{t('Amount to be collected')}</th>
                <th css={reservationStatusStyle}>{t('Status')}</th>
                <th css={updatedStyle}>{t('Update date')}</th>
              </tr>
            </thead>
            <tbody>
              <tr key={reservation && reservation.accommodationId ? reservation.accommodationId : ''} css={listStyle}>
                <td css={reservationIdStyle}>{reservation && reservation.accommodationId ? reservation.accommodationId : ''}</td>
                <td css={reservationDateStyle}>
                  {reservation &&
                    `${
                      dayjs(reservation.checkinDate).format(t('MM-DD')) ? dayjs(reservation.checkinDate).format(t('MM-DD')) : ''
                    } ${reservation.nights ?? 0}${t('Night')}`}
                </td>
                <td css={reservationUserStyle}>{reservation && reservation.guestName ? reservation.guestName : ''}</td>
                <td css={roomNumberStyle}>
                  <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {dividedRoomNumber(reservation && reservation.assignedRoom ? reservation.assignedRoom : '')}
                  </div>
                </td>
                <td css={[amountCancelledStyle, { color: reservation?.unpaid ? '#F2A40B' : '' }]}>
                  ¥{Number(reservation?.unpaid || 0).toLocaleString()}
                </td>
                <td css={reservationStatusStyle}>
                  <Button
                    buttonType={2}
                    width={139}
                    height={24}
                    fontSize={12}
                    marginLeft={0}
                    onClick={() => reservationCheckinLink(reservation)}
                    borderRadius={5}
                    fontWeight={'normal'}
                  >
                    {t('Check-in create')}
                  </Button>
                </td>
                <td css={updatedStyle}>
                  <p>
                    {t('Stay')}：{reservation?.statusStayAt ? dayjs(reservation.statusStayAt).format(t('MM-DD HH:mm')) : '-'}
                  </p>
                  <p>
                    {t('Out')}：{reservation?.statusCheckoutAt ? dayjs(reservation.statusCheckoutAt).format(t('MM-DD HH:mm')) : '-'}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

const reservationListContainer = css({
  backgroundColor: '#fff',
  border: '1px solid #CCCCCC',
  borderRadius: '10px',
})

const listTitle = css({
  minWidth: 'max-content',
  color: '#676767',
  fontWeight: 'bold',
  width: '100%',
  padding: '10px 40px',
  fontSize: 12,
  height: 36,
  'th, td': {
    paddingBlock: 10,
    paddingLeft: 32,
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    ':first-of-type': {
      '@media(max-width: 1080px)': {
        paddingLeft: 16,
      },
    },
    ':last-of-type': {
      paddingRight: 32,
      '@media(max-width: 1080px)': {
        paddingRight: 16,
      },
    },
  },
  th: {
    backgroundColor: '#f5f5f5',
  },
})

const listStyle = css(listTitle, {
  backgroundColor: '#fff',
  color: '#272727',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '24px',
  height: 56,
})

const reservationListSubject = css({
  fontSize: 14,
  fontWeight: 'bold',
  padding: '13px 34px 8px 32px',
  display: 'flex',
  justifyContent: 'space-between',
  '@media(max-width: 1080px)': {
    paddingInline: 16,
  },
})

const reservationIdStyle = css({
  width: '4%',
})

const reservationDateStyle = css({
  width: '12%',
})

const reservationUserStyle = css({
  width: '20%',
})

const roomNumberStyle = css({
  width: '12%',
  '@media(max-width: 1080px)': {
    width: '9%',
    div: {
      maxWidth: 90,
    },
  },
})

const reservationStatusStyle = css({
  width: '18%',
})

const amountCancelledStyle = css({
  width: '10%',
})

const updatedStyle = css({
  width: '20%',
  p: {
    lineHeight: 1.2,
  },
})
