import { env } from '@/libs/env'

import { HttpParams } from '@/apis/aipass'

const mockList = {
  // [`get ${env('BASE_API_V3')}/plugin`]: import('@/apis/mock/responses/get-plugin.json'),
  // [`get ${env('BASE_API_V3')}/admin-guest-room`]: import('@/apis/mock/responses/get-admin-guest-room.json'),
  // [`get ${env('BASE_API')}/remote-lock/client-info`]: import('@/apis/mock/responses/get-client-info.json'),
  // [`put ${env('BASE_API')}/remote-lock/plugin/toggle`]: true,
  // [`get ${env('BASE_API')}/remote-lock/devices`]: import('@/apis/mock/responses/get-remote-lock-devices.json'),
  // [`put ${env('BASE_API')}/remote-lock/devices/bulk-assign`]: true,
  // [`get ${env('BASE_API')}/remote-lock/account-info`]: import('@/apis/mock/responses/get-remote-lock-account-info.json'),
  // [`put ${env('BASE_API')}/remote-lock/account-info`]: import('@/apis/mock/responses/get-remote-lock-account-info.json'),
  // [`put ${env('BASE_API')}/remote-lock/devices/sync`]: true,
  // [`get ${env('BASE_API_V3')}/settings/coupons`]: require('@/apis/mock/responses/get-settings-coupons').default,
  // [`post ${env('BASE_API_V3')}/settings/coupons`]: true,
  // [`/put ${env('BASE_API_V3')}/settings/coupons/[A-Za-z0-9-]+$/`]: true,
  // [`/delete ${env('BASE_API_V3')}/settings/coupons/[A-Za-z0-9-]+$/`]: true,
  // [`get ${env('BASE_API_V3')}/used-coupons`]: require('@/apis/mock/responses/get-used-coupons').default,
  // [`get ${env('BASE_API_V3')}/admin-reservation-stock`]: require('@/apis/mock/responses/admin-reservation-stock').default,
  // [`post ${env('BASE_API_V3')}/sales-manager/report-csv`]: true,
  // [`get ${env('BASE_API')}/sales-manager/sales-subject-set`]: import('@/apis/mock/responses/get-sales-subject-set.json'),
  // [`get ${env('BASE_API')}/sales-manager/sales-account-subject-set`]: import('@/apis/mock/responses/sales-account-subject-set.json'),
  // [`put ${env('BASE_API')}/sales-manager/sales-subject-set`]: { data: { message: 'success' } },
  // [`post ${env('BASE_API')}/sales-manager/sales-subject-set`]: { data: { message: 'success' } },
  // [`delete ${env('BASE_API')}/sales-manager/sales-subject-set`]: { data: { message: 'success' } },
  // [`put ${env('BASE_API')}/sales-manager/sales-subject-set-list-csv`]: { data: { message: 'success' } },
  // [`put ${env('BASE_API_V3')}/room-manager/guest-room-fix`]: true,
  // [`put ${env('BASE_API_V3')}/room-manager/guest-room-auto-assign`]: true,
  // [`get ${env('BASE_API')}/auto-assignment-settings`]: import('@/apis/mock/responses/get-auto-assignment-settings.json'),
  // [`put ${env('BASE_API_V3')}/auto-assignment-settings`]: import('@/apis/mock/responses/get-auto-assignment-settings.json'),
  //   [`get ${env('BASE_API')}/employee-hotels`]: import('@/apis/mock/responses/get-employee-hotels.json'),
  //   [`patch ${env('BASE_API')}/employee-hotels/current`]: { data: { message: 'success' } },
  //   [`get ${env('BASE_API')}/staff-manager`]: import('@/apis/mock/responses/get-staff-manager.json'),
  //   [`post ${env('BASE_API')}/staff-manager`]: import('@/apis/mock/responses/get-staff-manager.json'),
  //   [`/put ${env('BASE_API')}/staff-manager/[A-Za-z0-9-]+$/`]: import('@/apis/mock/responses/get-staff-manager.json'),
  //   [`/delete ${env('BASE_API')}/staff-manager/[A-Za-z0-9-]+$/`]: { data: { message: 'success' } },
  //   [`get ${env('BASE_API')}/staff`]: import('@/apis/mock/responses/get-staff.json'),
  //   [`patch ${env('BASE_API')}/staff/login`]: { data: { message: 'success' } },
  //   [`patch ${env('BASE_API')}/staff/logout`]: { data: { message: 'success' } },
  //   [`get ${env('BASE_API_V3')}/operation-log`]: require('@/apis/mock/responses/get-operation-log').default,
  // [`get ${env('BASE_API_V3')}/employee-list`]: import('@/apis/mock/responses/get-employee-list.json'),
}

export const searchMockResult = (params: HttpParams): Promise<any> => {
  return new Promise(async resolve => {
    const key = Object.keys(mockList).find((key: string) => {
      const urlKey = `${params.method} ${params.url}`

      if (key.startsWith('/')) {
        const regexp = new RegExp(key.substring(1, key.length - 1))

        if (regexp.test(urlKey)) return urlKey
        else return
      }

      if (urlKey === key) return key
    })

    if (!key) return resolve(undefined)

    const result = await mockList[key]

    if (!result) return resolve(undefined)

    setTimeout(() => {
      console.log({ mock: { params, result } })
      resolve(result)
    }, 1500)
  })
}
