import { MemoWithCategory } from '@/models/reservation/reservation-note'

export type RoomType = {
  guestRoomId: string
  roomNumber: string
  floorNameJa: string
  floorNameEn: string
  cleaningStatus: CleaningStatusType
  roomUsageStatus: RoomUsageStatusType
  cleaningStaffId: string
  adminComment: string
  staffReport: string
  isStayOrScheduleOfStay: boolean
  isCleaningRequired: boolean
  cleanedAt: string | null
  checkList: Array<{ id: number; isChecked: boolean }>
  nextReservation: ReservationType | null
  photos: Array<{ path: string }>
}

export type ReservationType = {
  nights: number
  roomPax: {
    male: number
    female: number
    childA: number
    childB: number
    childC: number
    childD: number
    childE: number
    childF: number
    childOther: number
    total: number
  }
  roomCheckinAt: string
  roomCheckoutAt: string
  notes: MemoWithCategory[]
}

export const CleaningStatusType = {
  Unused: 0,
  NotCleaning: 1,
  Cleaned: 2,
  Inspected: 3,
  DoNotDisturb: 4,
  NotRequired: 5,
} as const
export type CleaningStatusType = (typeof CleaningStatusType)[keyof typeof CleaningStatusType]
export const CleaningStatusValues = {
  [CleaningStatusType.Unused]: '-',
  [CleaningStatusType.NotCleaning]: 'cleaningManager.Dirty',
  [CleaningStatusType.Cleaned]: 'cleaningManager.Clean',
  [CleaningStatusType.Inspected]: 'cleaningManager.Inspected',
  [CleaningStatusType.DoNotDisturb]: 'cleaningManager.Do not Disturb',
  [CleaningStatusType.NotRequired]: 'cleaningManager.No Cleaning',
}

export const RoomUsageStatusType = {
  Unused: 1,
  Stay: 2,
  Away: 3,
  CheckOutScheduled: 4,
  CheckedOut: 5,
} as const
export type RoomUsageStatusType = (typeof RoomUsageStatusType)[keyof typeof RoomUsageStatusType]
export const RoomUsageStatusValues = {
  [RoomUsageStatusType.Unused]: 'cleaningManager.Vacant',
  [RoomUsageStatusType.Stay]: 'cleaningManager.Occupied',
  [RoomUsageStatusType.Away]: 'cleaningManager.Out',
  [RoomUsageStatusType.CheckOutScheduled]: 'cleaningManager.Checkout Soon',
  [RoomUsageStatusType.CheckedOut]: 'cleaningManager.Done Checkout',
}

export type CheckListType = {
  id: number
  name: string
  displayOrder: number
}
export type CheckListFormType = {
  name: string
  displayOrder: number
}
type Assign = {
  dateOf: string
}
export type StaffType = {
  id: string
  name: string
  displayOrder: number
  assignments: Assign[]
  shifts: Assign[]
  hasShift: boolean
}
export type staffFormType = {
  name: string
  displayOrder: number
}
