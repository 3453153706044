import React from 'react'
import { SettingTab } from '../setting-tab'

interface MailDeliveryTabProps {
  currentTab: 'cleaningManager.CheckList' | 'cleaningManager.CleaningStaff'
}

export const CleaningTab: React.FC<MailDeliveryTabProps> = ({  currentTab }) => {
  return (
    <SettingTab
      currentTab={currentTab}
      tabs={[
        {
          name: 'cleaningManager.CleaningStaff',
          path: '/setting/cleaning-manager',
        },
        {
          name: 'cleaningManager.CheckList',
          path: '/setting/cleaning-manager/check-list',
        }
      ]}
    />
  )
}
