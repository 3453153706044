export const reservation = {
  通知種別: 'DataClassification',
  販売先コード: 'SalesOfficeCompanyCode',
  販売先名: 'SalesOfficeCompanyName',
  販売先販売店名: '',
  販売先販売店コード: 'ChannelCode',
  販売先販売店担当者名: '',
  販売先販売店担当者Email: '',
  販売先販売店電話番号: '',
  販売先営業所FAX番号: '',
  販売先販売店住所都道府県: '',
  販売先予約番号: 'TravelAgencyBookingNumber',
  予約受信日: 'TravelAgencyBookingDate',
  通知番号: '',
  '団体名または代表者氏名(半角)': 'GuestOrGroupNameKanaName',
  '団体名または代表者氏名 漢字': 'GuestOrGroupNameKanjiName',
  チェックイン日: 'CheckInDate',
  チェックイン時間: 'checkinTime',
  チェックアウト日: 'CheckOutDate',
  チェックアウト時刻: 'checkoutTime',
  泊数: 'Nights',
  入込方法: '',
  送客区分: '',
  利用客室合計数: 'TotalRoomCount',
  お客様総合計人数: 'NumberOfPeopleTotal',
  '大人人員(男性)合計': 'NumberOfPeopleMale',
  '大人人員(女性)合計': 'NumberOfPeopleFemale',
  子供A人数合計: 'NumberOfPeopleChildA',
  子供B人数合計: 'NumberOfPeopleChildB',
  子供C人数合計: 'NumberOfPeopleChildC',
  子供D人数合計: 'NumberOfPeopleChildD',
  添乗員人数合計: '',
  参加形態: '',
  プラン名: 'PackagePlanName',
  プランコード: 'PackagePlanCode',
  泊食条件: 'SpecificMealCondition',
  料金単価区分: '',
  税サ区分: '',
  '合計宿泊料金(総額)': 'TotalAccommodationCharge',
  合計その他料金: '',
  団体または代表者番号: 'PhoneNumber',
  団体または代表者Email: 'Email',
  団体または代表者郵便番号: '',
  団体または代表者住所: 'Address',
  大人人数: '',
  '予約者・会員名漢字': 'UserName',
  '予約者・会員名カタカナ': 'UserKana',
  '予約者・会員電話番号': 'UserTel',
  '予約者・会員Email': 'UserMailAddr',
  '予約者・会員郵便番号': '',
  '予約者・会員住所': 'UserAddr',
  '予約者・会員会社': '',
  '予約者・会員所属部署': '',
  '予約者・会員番号': '',
  'ポイント割引・補助金額': 'PointsDiscount',
  付与ポイント: '',
  充当ポイント: '',
  事前決済情報: 'SettlementDiv',
  オプション名1: 'optionName1',
  オプション単価1: 'optionRate1',
  オプション数1: 'optionCount1',
  オプション名2: 'optionName2',
  オプション単価2: 'optionRate2',
  オプション数2: 'optionCount2',
  オプション名3: 'optionName3',
  オプション単価3: 'optionRate3',
  オプション数3: 'optionCount3',
  オプション名4: 'optionName4',
  オプション単価4: 'optionRate4',
  オプション数4: 'optionCount4',
  オプション名5: 'optionName5',
  オプション単価5: 'optionRate5',
  オプション数5: 'optionCount5',
  備考: 'OtherServiceInformation',
  リンカーン販売先コード: '',
  '販売先コード（初期値）': '',
}
