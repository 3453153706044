import React, { useState } from 'react'
import { Collapse } from 'react-collapse'
import { css } from '@emotion/core'
import { CleaningStatusType, CleaningStatusValues, RoomType, RoomUsageStatusValues, StaffType } from '@/constants/cleaning-manager'
import { useTranslation } from 'react-i18next'

interface RoomListItemProps {
  floorNumber: string
  rooms: object
  staffList: StaffType[]
  selectedGuestRoomIds: string[]
  setSelectedGuestRoomIds: (arg0: string[]) => void
}

export const RoomListItem: React.FC<RoomListItemProps> = ({
  floorNumber,
  rooms,
  selectedGuestRoomIds,
  staffList,
  setSelectedGuestRoomIds,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation()

  const cleaningStatusColor = (room: RoomType) => {
    switch (room.cleaningStatus) {
      case CleaningStatusType.NotCleaning:
        if (room.isStayOrScheduleOfStay) {
          return room.isCleaningRequired ? '#F47110' : ''
        } else {
          return room.isCleaningRequired ? '#F2A40B' : ''
        }
      case CleaningStatusType.Cleaned:
        return '#3E85CC'
      case CleaningStatusType.Inspected:
        return '#7CC931'
      case CleaningStatusType.DoNotDisturb:
        return '#676767'
      default:
        return '#676767'
    }
  }

  const roomList = rooms[floorNumber]

  const toggleFloor = () => {
    setIsOpen(!isOpen)
    setSelectedGuestRoomIds([
      ...selectedGuestRoomIds,
      ...roomList.filter(({ cleaningStatus }) => cleaningStatus === CleaningStatusType.NotCleaning).map(room => room.guestRoomId),
    ])
  }

  return (
    <li>
      <div
        css={[
          {
            background: '#F5F5F5',
            display: 'flex',
            gap: 16,
            width: '100%',
            padding: '18px 27px',
            cursor: 'pointer',
            borderBottom: '1px solid #CCCCCC',
          },
        ]}
        onClick={() => toggleFloor()}
      >
        <input checked={isOpen} type="checkbox" css={checkboxStyle} />
        <span style={{ fontSize: 16, fontWeight: 'bold', color: '#676767' }}>{floorNumber}</span>
      </div>
      <Collapse isOpened={isOpen}>
        <ul>
          <li css={headStyle}>
            <span style={{ display: 'block', width: 36 }}></span>
            <span style={{ width: firstItemWidth }}>{t('Room number')}</span>
            <span style={{ width: secondItemWidth }}>{t('cleaningManager.Cleaning Status')}</span>
            <span style={{ width: thirdItemWidth }}>{t('cleaningManager.Room Status')}</span>
            <span>{t('cleaningManager.CleaningStaff')}</span>
          </li>
          {roomList.map(room => {
            const isSelected = selectedGuestRoomIds.includes(room.guestRoomId)
            return (
              <li
                key={room.guestRoomId}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 32,
                  padding: '18px 27px 18px 0',
                  cursor: 'pointer',
                  fontSize: 14,
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  let newSelectedGuestRoomIds: string[] = []
                  if (isSelected) {
                    newSelectedGuestRoomIds = selectedGuestRoomIds.filter((id: string) => id !== room.guestRoomId)
                  } else {
                    newSelectedGuestRoomIds = [...selectedGuestRoomIds, room.guestRoomId]
                  }
                  setSelectedGuestRoomIds(newSelectedGuestRoomIds)
                }}
              >
                <div css={listItemCheckStyle}>
                  <input
                    id={room.guestRoomId}
                    type="image"
                    alt="checkbox"
                    value={room.guestRoomId}
                    src={require(`@/static/images/check-box${isSelected ? '' : '_off'}.svg`)}
                  />
                </div>
                <span style={{ width: firstItemWidth }}>{room.roomNumber}</span>
                <span style={{ width: secondItemWidth, color: cleaningStatusColor(room) }}>
                  {t(CleaningStatusValues[room.cleaningStatus])}
                </span>
                <span style={{ width: thirdItemWidth }}>{t(RoomUsageStatusValues[room.roomUsageStatus])}</span>
                <span>{staffList.find(staff => staff.id === room.cleaningStaffId)?.name}</span>
              </li>
            )
          })}
        </ul>
      </Collapse>
    </li>
  )
}

const headStyle = css({
  display: 'flex',
  gap: 32,
  background: '#F5F5F5',
  fontSize: 12,
  paddingBlock: 11,
  fontWeight: 'bold',
  color: '#676767',
  borderBottom: '1px solid #CCCCCC',
})

const firstItemWidth = 53
const secondItemWidth = 93
const thirdItemWidth = 93

const listItemCheckStyle = css({
  width: 20,
  height: 20,
  paddingLeft: 27,
  marginRight: 11,
  img: {
    width: '100%',
    height: '100%',
  },
})

const checkboxStyle = css({
  appearance: 'none',
  outline: 'none',
  display: 'block',
  position: 'relative',
  width: 20,
  height: 20,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: '2px solid #CCCCCC',
    backgroundColor: '#fff',
    borderRadius: 3,
  },
  '&:checked:before': {
    backgroundColor: '#F2A40B',
    border: 'none',
  },
  '&::after': {
    content: '""',
    display: 'none',
    position: 'absolute',
    width: 10,
    height: 3,
    top: 8,
    left: 5,
    backgroundColor: '#fff',
  },
  '&:checked::after': {
    display: 'block',
  },
})
