import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import dayjs, { Dayjs } from 'dayjs'

// constants
import { wholeContainerStyle, mainColumnStyle, mainContainerStyle } from '@/constants/layout'

// components
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { LoadingFull } from '@/components/molecules/loading-full'
import { ListCleaningRoom } from '@/components/organisms/cleaning-manager/list-cleaning-room'
import { DayStats } from '@/components/organisms/cleaning-manager/day-stats'
import { GeneralNote } from '@/components/organisms/cleaning-manager/general-note'
import { fetchCleaningManagerSetting, fetchListRoom, fetchStaffByMonth } from '@/apis/aipass'
import { EditNoticeModal } from '@/components/organisms/cleaning-manager/edit-notice-modal'
import { CheckListType, StaffType } from '@/constants/cleaning-manager'
import RoomDetail from '@/components/organisms/cleaning-manager/list-cleaning-room/room-detail'
import { CleaningManagerContext } from '@/components/organisms/cleaning-manager/list-cleaning-room/room'
import { useAutoReload } from '@/hooks/use-auto-reload'
import { StaffStatsList } from '@/components/organisms/cleaning-manager/staff-stats-list'

export const CleaningManager = () => {
  const { t, i18n } = useTranslation()
  const { setReloadCallback } = useAutoReload()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [rooms, setRooms] = useState({})
  const [generalNoteContent, setGeneralNoteContent] = useState('')
  const [roomStats, setRoomStats] = useState({
    totalRoomDoNotDisturb: 0,
    totalRoomNotClean: 0,
    totalRoomNotCleanWithStay: 0,
    totalRoomNotCleanWithOut: 0,
    totalRoomCleaned: 0,
    totalRoomInspected: 0,
  })
  const collapsedStorageKey = 'cleaningManager.collapsedTracking'
  const [collapsedTracking, setCollapsedTracking] = useState(JSON.parse(localStorage.getItem(collapsedStorageKey) || '{}'))
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [isOpenRoomDetailModal, setIsOpenRoomDetailModal] = useState(false)
  const [selectedRoomId, setSelectedRoomId] = useState('')
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false)
  const [storedCheckList, setStoredCheckList] = useState<CheckListType[]>([])
  const [staffList, setStaffList] = useState<StaffType[]>([])
  const [displayCleaningStaffId, setDisplayCleaningStaffId] = useState('')

  const fetchCleaningData = (fetchDate: Dayjs) => {
    setIsLoading(true)
    fetchStaffByMonth(fetchDate.format('YYYY-MM-DD')).then(data => {
      setStaffList(data)
    })
    fetchListRoom(fetchDate.format('YYYY-MM-DD'))
      .then(data => {
        setRooms(data.allGuestRooms)
        setRoomStats({
          totalRoomDoNotDisturb: data.totalRoomDoNotDisturb,
          totalRoomNotClean: data.totalRoomNotClean,
          totalRoomNotCleanWithStay: data.totalRoomNotCleanWithStay,
          totalRoomNotCleanWithOut: data.totalRoomNotCleanWithOut,
          totalRoomCleaned: data.totalRoomCleaned,
          totalRoomInspected: data.totalRoomInspected,
        })

        setGeneralNoteContent(data.announce)
      })
      .catch(e => {})
      .finally(() => {
        setIsLoading(false)
      })
  }

  const initialComponent = async () => {
    const checkList = (await fetchCleaningManagerSetting()) || []
    setStoredCheckList(checkList)
  }

  useEffect(() => {
    // set dayjs reflecting language settings
    setSelectedDate(dayjs())
  }, [i18n.language])

  useEffect(() => {
    fetchCleaningData(selectedDate)
  }, [selectedDate])

  const reloadRoomList = (fetchDate?: Dayjs) => {
    setIsOpenRoomDetailModal(false)
    fetchCleaningData(fetchDate || selectedDate)
    initialComponent()
  }

  useEffect(() => {
    if (Object.keys(rooms).length === 0) {
      return
    }
    setCollapsedTracking(
      Object.keys(rooms).reduce(function (result, item) {
        result[item] = collapsedTracking[item] !== undefined ? collapsedTracking[item] : false
        return result
      }, {}),
    )
  }, [rooms])

  useEffect(() => {
    if (Object.keys(collapsedTracking).length === 0) {
      return
    }
    localStorage.setItem(collapsedStorageKey, JSON.stringify(collapsedTracking))
  }, [collapsedTracking])

  useEffect(() => {
    initialComponent()
    setReloadCallback(() => {
      // intervalプロセスからstateの参照ができないため、setterから現値を参照する
      let originSelectedDate
      setSelectedDate(d => {
        originSelectedDate = d
        return d
      })
      reloadRoomList(originSelectedDate)
    }, 60000)
  }, [])

  return (
    <>
      <CleaningManagerContext.Provider
        value={{
          staffList: staffList,
          checkList: storedCheckList,
          selectedDate,
          reloadRoomList,
          setIsLoading,
        }}
      >
        <div css={wholeContainerStyle}>
          <SideMenu />

          {isOpenRoomDetailModal && (
            <RoomDetail
              staffList={staffList}
              selectedDate={selectedDate}
              roomId={selectedRoomId}
              setIsOpenRoomDetailModal={setIsOpenRoomDetailModal}
              reloadRoomList={reloadRoomList}
            />
          )}

          {isOpenEditModal && (
            <EditNoticeModal
              selectedDate={selectedDate}
              adminComment={generalNoteContent}
              onSave={() => {
                setIsOpenEditModal(false)
                reloadRoomList()
              }}
              onClose={() => setIsOpenEditModal(false)}
            />
          )}
          <div className="mainColumn" css={mainColumnStyle}>
            <Header title={t('Cleaning management')} />
            <div css={mainContainerStyle}>
              <div css={headerCleaningRoomStyle}>
                <div css={headerLeftCleaningRoomStyle}>
                  <DayStats selectedDate={selectedDate} setSelectedDate={setSelectedDate} roomStats={roomStats} />
                </div>
                <div css={headerRightCleaningRoomStyle}>
                  <GeneralNote generalNoteContent={generalNoteContent} setIsOpenEditModal={setIsOpenEditModal} />
                </div>
              </div>
            </div>
            <StaffStatsList
              staffList={staffList}
              displayCleaningStaffId={displayCleaningStaffId}
              setDisplayCleaningStaffId={setDisplayCleaningStaffId}
              selectedDate={selectedDate}
            />
            <div css={secondMainContainerStyle}>
              <div css={cleaningMainStyle}>
                <div css={headerWrapperStyle}>
                  <ListCleaningRoom
                    collapsedTracking={collapsedTracking}
                    setCollapsedTracking={setCollapsedTracking}
                    rooms={rooms}
                    selectedCleaningStaffId={displayCleaningStaffId}
                    setIsOpenRoomDetailModal={setIsOpenRoomDetailModal}
                    setSelectedRoomId={setSelectedRoomId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingFull isLoading={isLoading} />
      </CleaningManagerContext.Provider>
    </>
  )
}

const headerLeftCleaningRoomStyle = css({
  width: '50%',
  background: '#fff',
  borderRadius: '5px',
  height: 152,
})
const headerRightCleaningRoomStyle = css({
  width: '50%',
  background: '#fff',
  borderRadius: '5px',
  minHeight: 152,
  marginLeft: '16px',
})

const headerCleaningRoomStyle = css({
  display: 'flex',
  alignItems: 'start',
  justifyContent: 'space-between',
})

const secondMainContainerStyle = css({
  marginTop: 16,
  marginBottom: 24,
  padding: '0 24px 0 24px',
})

const cleaningMainStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
  background: '#fff',
  justifyContent: 'space-between',
  borderRadius: '5px',
})

const headerWrapperStyle = css({
  width: '100%',
})
