import React from 'react'
import * as Sentry from '@sentry/browser'

import { Signin } from '@/components/pages/signin'
import { onlyForLocal } from '@/utils/check-environment'

interface Props {}

interface State {
  eventId?: string
  hasError: boolean
}

// Reference
// https://ja.reactjs.org/docs/error-boundaries.html
// https://kudolog.net/posts/func-error-boundary/
export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidMount() {
    const initSentry = (): void => {
      if (onlyForLocal) return

      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        // integrations: [new BrowserTracing()],
        environment: process.env.REACT_APP_ENV,
        release: 'aipass_hotel@' + process.env.REACT_APP_APP_TAG || 'unknown',
        tracesSampleRate: 0.1,
        integrations: [Sentry.replayIntegration()],
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1,
      })
    }
    initSentry()
  }

  componentDidCatch(error, errorInfo) {
    // Added sending errors to Sentry
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
    this.setState(state => ({ ...state, hasError: true }))
  }

  render() {
    if (this.state.hasError && (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'integ')) {
      return <Signin />
    }
    return this.props.children
  }
}
