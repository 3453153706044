import React, { useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms/button'
import { ModalHeader, ModalBody } from '@/components/atoms/modal'
import { ReservationUserEditForm, ReservationUserEditFormType } from './reservation-user-edit-form'
import { LoadingFull } from '@/components/molecules/loading-full'
import { Hint } from '@/components/atoms/hint'

type ReservationUserEditModalProps = {
  formValue: ReservationUserEditFormType
  isLoading: boolean
  onClickSave: (form: ReservationUserEditForm) => void
  onClickCancel: () => void
}

export const ReservationUserEditModal: React.FC<ReservationUserEditModalProps> = ({ formValue, isLoading, onClickSave, onClickCancel }) => {
  const { t } = useTranslation()
  const [form, setForm] = useState<ReservationUserEditFormType>({ ...formValue })
  const [errors, setErrors] = useState<(keyof ReservationUserEditFormType)[]>([])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    const name = event.target.name
    setForm({ ...form, [name]: value })
  }

  const handleClickSave = () => {
    const formModel = new ReservationUserEditForm({ ...form })
    const errors = formModel.validate()
    if (errors.length > 0) {
      setErrors(errors)
      return
    }
    setErrors([])
    onClickSave(formModel)
  }
  const handleClickCancel = () => {
    onClickCancel()
  }

  return (
    <React.Fragment>
      <div css={modalWrapperStyle}>
        <div css={modalContentStyle}>
          <ModalHeader>
            <div style={{ display: 'flex', gap: 15 }}>
              {t('Edit reservation/guest')}
              <Hint customCss={hintStyle}>
                <p className="hint-text">
                  {t('If the person making the reservation is the same as the guest, only the guest information needs to be entered.')}
                </p>
              </Hint>
            </div>
          </ModalHeader>
          <ModalBody>
            <div css={formContainerStyle}>
              <div css={sectionStyle}>
                <div css={sectionHeaderStyle}>{t('Reservation person')}</div>
                <div css={sectionBodyStyle}>
                  <div css={fieldBlockStyle}>
                    <div css={fieldTitleStyle}>{t('Full name')}</div>
                    <input
                      css={filedInputStyle}
                      placeholder={t('John Doe')}
                      name="userName"
                      value={form.userName}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  <div css={fieldBlockStyle}>
                    <div css={fieldTitleStyle}>{t('Furigana')}</div>
                    <input
                      css={filedInputStyle}
                      placeholder="ヤマダ タロウ"
                      name="userNameKana"
                      value={form.userNameKana}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  <div css={fieldBlockStyle}>
                    <div css={fieldTitleStyle}>{t('Phone number')}</div>
                    <input
                      css={errors.includes('userTel') ? filedInputErrorStyle : filedInputStyle}
                      name="userTel"
                      placeholder="08012345678"
                      value={form.userTel}
                      onChange={handleInputChange}
                    ></input>
                    {errors.includes('userTel') ? <div css={fieldErrorStyle}>{t('Please enter in single-byte number')}</div> : ''}
                  </div>
                  <div css={fieldBlockStyle}>
                    <div css={fieldTitleStyle}>{t('Email')}</div>
                    <input
                      css={filedInputStyle}
                      name="userMailAddr"
                      placeholder="yamada@aipass.jp"
                      value={form.userMailAddr}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  <div css={fieldBlockStyle}>
                    <div css={fieldTitleStyle}>{t('Address')}</div>
                    <input
                      css={filedInputStyle}
                      name="userAddr"
                      placeholder={t('1-1 Chiyoda Chiyoda-ku')}
                      value={form.userAddr}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                </div>
              </div>
              <hr css={lineStyle} />
              <div css={sectionStyle}>
                <div css={sectionHeaderStyle}>{t('Guests')}</div>
                <div css={sectionBodyStyle}>
                  <div css={fieldBlockStyle}>
                    <div css={fieldTitleStyle}>{t('Full name')}</div>
                    <input
                      css={filedInputStyle}
                      placeholder={t('John Doe')}
                      name="guestName"
                      value={form.guestName}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  <div css={fieldBlockStyle}>
                    <div css={fieldTitleStyle}>{t('Furigana')}</div>
                    <input
                      css={filedInputStyle}
                      placeholder="ヤマダ タロウ"
                      name="guestNameKana"
                      value={form.guestNameKana}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  <div css={fieldBlockStyle}>
                    <div css={fieldTitleStyle}>{t('Phone number')}</div>
                    <input
                      css={errors.includes('guestTel') ? filedInputErrorStyle : filedInputStyle}
                      placeholder="08012345678"
                      name="guestTel"
                      value={form.guestTel}
                      onChange={handleInputChange}
                    ></input>
                    {errors.includes('guestTel') ? <div css={fieldErrorStyle}>{t('Please enter in single-byte number')}</div> : ''}
                  </div>
                  <div css={fieldBlockStyle}>
                    <div css={fieldTitleStyle}>{t('Email address')}</div>
                    <input
                      css={filedInputStyle}
                      placeholder="yamada@aipass.jp"
                      name="guestMailAddr"
                      value={form.guestMailAddr}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                  <div css={fieldBlockStyle}>
                    <div css={fieldTitleStyle}>{t('Address')}</div>
                    <input
                      css={filedInputStyle}
                      placeholder={t('1-1 Chiyoda Chiyoda-ku')}
                      name="guestAddr"
                      value={form.guestAddr}
                      onChange={handleInputChange}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <div css={buttomButtonStyle}>
            <Button width={110} height={38} buttonType={3} marginRight={16} onClick={handleClickCancel}>
              {t('Cancel')}
            </Button>
            <Button width={110} height={38} buttonType={1} onClick={handleClickSave}>
              {t('Save')}
            </Button>
          </div>
          <LoadingFull isLoading={isLoading} />
        </div>
      </div>
    </React.Fragment>
  )
}

const modalWrapperStyle = css({
  width: '100vw',
  height: '100vh',
  background: 'rgba(39, 39, 39, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 103,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'scroll',
})

const modalContentStyle = css({
  width: 600,
  maxHeight: 650,
  background: '#fff',
  borderRadius: 5,
  marginBottom: 40,
})

const hintStyle = css({
  '.help-icon': { width: 18, height: 18 },
  '.help-popup': { left: 26, top: 0, width: 400 },
  '.hint-text': {
    color: '#676767',
    fontSize: 12,
    lineHeight: 1.75,
    padding: '12px 16px',
  },
})

const formContainerStyle = css({
  width: '100%',
  backgroundColor: '#FFF',
  border: '1px solid #CCCCCC',
  borderRadius: '5px',
  color: '#676767',
  padding: '32px',
})
const sectionStyle = css({
  display: 'flex',
})
const sectionHeaderStyle = css({
  fontWeight: 'bold',
  fontSize: 14,
  width: '150px',
})
const sectionBodyStyle = css({
  flexGrow: 1,
})
const lineStyle = css({
  marginTop: '30px',
  marginBottom: '30px',
})
const fieldBlockStyle = css({
  marginBottom: '20px',
  '&:last-child': {
    marginBottom: '0',
  },
})
const fieldTitleStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  marginBottom: '10px',
})
const filedInputStyle = css({
  width: '100%',
  height: 32,
  padding: '8px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  textAlign: 'left',
  backgroundSize: 10,
  '::placeholder': {
    color: '#CCCCCC',
  },
})

const filedInputErrorStyle = css(filedInputStyle, {
  border: '1px solid #ff5500',
})

const fieldErrorStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  marginTop: '10px',
  marginBottom: '10px',
  color: '#ff5500',
})

const buttomButtonStyle = css({
  display: 'flex',
  padding: '11px 32px',
  justifyContent: 'flex-end',
  backgroundColor: '#FFFFFF',
  borderRadius: '0px 0px 5px 5px',
})
