import React, { useState, Dispatch, SetStateAction, useContext, useMemo } from 'react'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import { ReadyState } from 'react-use-websocket'
import { useTranslation } from 'react-i18next'

// components
import { Button } from '@/components/atoms'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'

// contexts
import { MiwaAppWebsocketContext } from '@/contexts/miwa-app-websocket'

// libs
import { times } from '@/libs/times'

// models
import { TMiwaLockStatus, TMiwaAppWebsocketContext, TMiwaState } from '@/models/miwa-lock'

// constants
import { MiwaLockStatus } from '@/constants/miwa-lock'

// extends dayjs
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { initializeSelectBoxStyle } from '@/constants/form'
dayjs.extend(isSameOrBefore)

type TKeyCardReleaseModalProps = {
  miwaState: TMiwaState
  setMiwaState: Dispatch<SetStateAction<TMiwaState>>
  onClickModalCancel: () => void
  onClickModalSave: (type: TMiwaLockStatus) => void
}

const MINIMUM_ROOM_CARD = 1
const MAXIMUM_ROOM_CARD = 99

export const KeyCardReleaseModal: React.FC<TKeyCardReleaseModalProps> = ({
  miwaState,
  setMiwaState,
  onClickModalCancel,
  onClickModalSave,
}) => {
  const { t } = useTranslation()

  const [numberCards, setNumberCards] = useState('')
  const { checkinTime, checkinDate, checkoutTime, checkoutDate, numberOfCards } = miwaState
  const { readyState } = useContext<TMiwaAppWebsocketContext>(MiwaAppWebsocketContext)

  const [isDisabledButton, setIsDisableButton] = useState(false)

  const onChangeRoomCard = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result = e.target.value.replace(/[^0-9]/g, '').replace(/^0(\d)/, '$1')
    if (Number(result) <= MAXIMUM_ROOM_CARD) {
      setNumberCards(result)
      setMiwaState({ ...miwaState, numberOfCards: Number(result) })
    }
  }

  const onClickReleaseKeyCard = (type: TMiwaLockStatus) => {
    if (!_validate()) return

    onClickModalSave(type)
  }

  const _validate = (): boolean => {
    const errorMessage = t('Checkout time before checkin time')
    // validate checkinTime & checkoutTime empty
    if (!miwaState.checkinTime || !miwaState.checkoutTime) {
      window.alert(errorMessage)
      return false
    }
    // validate checkoutTime greater than checkinTime
    if (checkinTime && checkoutTime && dayjs(`${checkoutDate} ${checkoutTime}`).isSameOrBefore(`${checkinDate} ${checkinTime}`)) {
      window.alert(errorMessage)
      return false
    }

    return true
  }

  useMemo(() => {
    if (!numberOfCards || numberOfCards < MINIMUM_ROOM_CARD || numberOfCards > MAXIMUM_ROOM_CARD || readyState !== ReadyState.OPEN) {
      setIsDisableButton(true)
    } else {
      setIsDisableButton(false)
    }
  }, [numberOfCards, readyState])

  return (
    <Modal customCss={{ width: 680, height: 'unset', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <ModalHeader>{t('Issue card')}</ModalHeader>
      <ModalBody>
        <div css={bodyWrapperStyle}>
          <div>
            <div css={keyCardReleaseTimeDate}>
              <div css={keyCardReleaseTimeDateWrapper}>
                <div css={keyCardReleaseTime}>{miwaState.checkinDate ? dayjs(miwaState.checkinDate).format(t('MM-DD')) : '-'}</div>
                {miwaState.issueType === MiwaLockStatus.ISSUE ? (
                  <div css={keyCardReleaseCheckinTime}>
                    <select
                      name="checkin_time"
                      css={keyCardReleaseCheckinTimeSelect}
                      value={checkinTime ?? ''}
                      onChange={e => setMiwaState({ ...miwaState, checkinTime: e.target.value })}
                    >
                      {times.map((item, index) => (
                        <option key={index} value={item['value']}>
                          {item['name'] === '指定なし' ? '-' : item['name']}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div>{miwaState.checkinTime}</div>
                )}
              </div>
              <div css={keyCardReleaseTimeSpace}>~</div>
              <div css={keyCardReleaseTimeDateWrapper}>
                <div css={keyCardReleaseTime}>{miwaState.checkoutDate ? dayjs(miwaState.checkoutDate).format(t('MM-DD')) : '-'}</div>
                {miwaState.issueType === MiwaLockStatus.ISSUE ? (
                  <div css={keyCardReleaseCheckinTime}>
                    <select
                      name="checkout_time"
                      css={keyCardReleaseCheckinTimeSelect}
                      value={checkoutTime ?? ''}
                      onChange={e => setMiwaState({ ...miwaState, checkoutTime: e.target.value })}
                    >
                      {times.map((item, index) => (
                        <option key={index} value={item['value']}>
                          {item['name'] === '指定なし' ? '-' : item['name']}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div>{miwaState.checkoutTime}</div>
                )}
              </div>
            </div>
            <div css={keyCardReleaseType}>
              <span>
                {miwaState.typeNameJa ?? '-'}／{miwaState.roomNumber ?? '-'}
              </span>
            </div>
          </div>
          <div>
            <div>
              <label htmlFor="roomCard" css={keyCardReleaseInputLabel}>
                {t('Number card')}
              </label>
              <input
                type="text"
                name="roomCard"
                placeholder="0"
                minLength={MINIMUM_ROOM_CARD}
                maxLength={MAXIMUM_ROOM_CARD}
                css={keyCardReleaseInput}
                value={numberCards}
                onChange={onChangeRoomCard}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div css={footerButtonWrapperStyle}>
          <div css={footerButtonCancelStyle}>
            <Button width={110} height={38} buttonType={3} onClick={onClickModalCancel}>
              {t('Cancel')}
            </Button>
          </div>

          <div css={footerButtonSaveStyle}>
            {miwaState.issueType === MiwaLockStatus.ISSUE ? (
              <Button
                width={109}
                height={38}
                buttonType={isDisabledButton ? 5 : 1}
                onClick={() => onClickReleaseKeyCard(MiwaLockStatus.ISSUE)}
                isDisabled={isDisabledButton}
              >
                {t('Issue new')}
              </Button>
            ) : (
              <>
                <Button
                  width={93}
                  height={38}
                  buttonType={isDisabledButton ? 5 : 3}
                  marginRight={10}
                  onClick={() => onClickReleaseKeyCard(MiwaLockStatus.REISSUE)}
                  isDisabled={isDisabledButton}
                >
                  {t('Issue again')}
                </Button>

                <Button
                  width={109}
                  height={38}
                  buttonType={isDisabledButton ? 5 : 1}
                  onClick={() => onClickReleaseKeyCard(MiwaLockStatus.ADDNEWISSUE)}
                  isDisabled={isDisabledButton}
                >
                  {t('Additional Issue')}
                </Button>
              </>
            )}
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

const bodyWrapperStyle = css({
  width: '100%',
  background: '#F2F2F2',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const keyCardReleaseTimeDate = css({
  display: 'flex',
  alignItems: 'center',
  color: '#272727',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.7px',
})

const keyCardReleaseTimeDateWrapper = css({
  display: 'flex',
  alignItems: 'center',
})

const keyCardReleaseTime = css({
  fontWeight: 500,
})

const keyCardReleaseCheckinTime = css({
  marginLeft: 6,
})

const keyCardReleaseCheckinTimeSelect = css(initializeSelectBoxStyle, {
  color: '#272727',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.7px',
  width: 60,
  backgroundPosition: 48,
  backgroundColor: 'transparent',
  backgroundImage: `url(${require('@/static/images/arrow_gray.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 8,
  marginBottom: 0,
  height: 32,
  border: 'none',
  textAlign: 'left',
})

const keyCardReleaseTimeSpace = css({
  fontWeight: 500,
  display: 'inline-block',
  margin: '0 16px',
})

const keyCardReleaseType = css({
  color: '#272727',
  fontSize: '19px',
  fontWeight: 'bold',
  lineHeight: '51px',
  letterSpacing: '0.95px',
})

const keyCardReleaseInputLabel = css({
  color: '#676767',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '21px',
  letterSpacing: '0.6px',
  marginRight: '16px',
})

const keyCardReleaseInput = css({
  color: '#272727',
  fontsize: '14px',
  letterSpacing: '0.7px',
  lineHeight: '20px',
  '&::placeholder': {
    color: '#ccc',
  },
  padding: '9px 16px',
  width: '64px',
  height: '32px',
  border: '1px solid #CCCCCC',
  borderRadius: '16px',
})

const footerButtonWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: 'white',
})

const footerButtonCancelStyle = css({})

const footerButtonSaveStyle = css({
  display: 'flex',
  alignItems: 'center',
})
