export type CustomerTagType = {
  id: string
  customerTagMasterId: string
  tag: CustomerTag
}
export type CustomerTag = {
  customerTagMasterId: string
  name: string
  bgColor: string
  borderColor: string
  fontColor: string
  colorName: string
}
export type CustomerTagMasterType = {
  id: string
  isCurrentHotelTag?: boolean
  tag: CustomerTag
}

export type CustomerTagInfoType = {
  id: string
  tags: CustomerTagMasterType[]
}

export const ColorLabelType = {
  Black: 'black',
  Gray: 'gray',
  Brawn: 'brawn',
  Orange: 'orange',
  Yellow: 'yellow',
  Green: 'green',
  Blue: 'blue',
  Purple: 'purple',
  Pink: 'pink',
  Red: 'red',
} as const
export type ColorLabelType = (typeof ColorLabelType)[keyof typeof ColorLabelType]
export interface Color {
  bgColor: string
  fontColor: string
  borderColor: string
}

export const tagColors: Record<ColorLabelType, Color> = {
  black: {
    borderColor: '#B9B4B4',
    bgColor: '#E3E3E3',
    fontColor: '#555555',
  },
  gray: {
    borderColor: '#CCCCCC',
    bgColor: '#F2F2F2',
    fontColor: '#676767',
  },
  brawn: {
    borderColor: '#D5C1B2',
    bgColor: '#F8ECE4',
    fontColor: '#9A7255',
  },
  orange: {
    borderColor: '#F5C974',
    bgColor: '#FFF3DB',
    fontColor: '#F0851A',
  },
  yellow: {
    borderColor: '#EAE5A3',
    bgColor: '#FEFCE0',
    fontColor: '#F7B500',
  },
  green: {
    borderColor: '#C0E5AC',
    bgColor: '#F6FDF2',
    fontColor: '#5AC931',
  },
  blue: {
    borderColor: '#A8D4EF',
    bgColor: '#EFF9FF',
    fontColor: '#3E85CC',
  },
  purple: {
    borderColor: '#E5C8F5',
    bgColor: '#F8EBFF',
    fontColor: '#BA79E3',
  },
  pink: {
    borderColor: '#F8C4E1',
    bgColor: '#FFEFF8',
    fontColor: '#EF6AB6',
  },
  red: {
    borderColor: '#F4CCC2',
    bgColor: '#FDEDED',
    fontColor: '#F44D10',
  },
}
export interface TagStyle {
  bgColor: string
  fontColor: string
  name: string
}
