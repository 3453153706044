import React, { useContext, useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { CleaningManagerContext } from '../../cleaning-manager/list-cleaning-room/room'
import { CleaningListSp } from '../../cleaning-manager/list-cleaning-room/sp'
import { HeaderSp } from './header-sp'
import { CalendarWithArrow } from '@/components/organisms/calendar-with-arrow'
import { useHistory } from 'react-router-dom'

const queryString = require('query-string')

interface IndexSpProps {
  isLoading: boolean
  rooms: object
  generalNoteContent: string
  setSelectedDate: (arg0: Dayjs) => void
}

export const IndexSp: React.FC<IndexSpProps> = ({ setSelectedDate, generalNoteContent, rooms, isLoading }) => {
  const { t } = useTranslation()

  const { selectedDate } = useContext(CleaningManagerContext)

  const history = useHistory()
  const { staffId, tabIndex, dateOf } = queryString.parse(history.location.search)

  const onTabChange = (tabIndex: number, e: Event) => {
    e.preventDefault()
    history.push(`/cleaning/?tabIndex=${tabIndex}&dateOf=${selectedDate.format('YYYY-MM-DD')}&staffId=${staffId ?? ''}`)
  }

  const dateChange = (date: Dayjs) => {
    history.push(`/cleaning/?tabIndex=1&dateOf=${date.format('YYYY-MM-DD')}`)
  }

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!selectedDate.isSame(dayjs(dateOf), 'day')) {
      setSelectedDate(dayjs(dateOf))
    }
  }, [dateOf, isLoading])

  return (
    <div css={blockStyle}>
      <HeaderSp title={t('Cleaning management')} />
      <CalendarWithArrow
        customCss={calendarWrapperStyle}
        overWriteDateFieldStyle={overWriteDateFieldStyle}
        dateOf={selectedDate}
        setDate={dateChange}
        displayFormat={t('YYYY-MM-DD')}
        unit="day"
      />

      <div style={{ marginTop: 24 }}>
        <Tabs defaultIndex={Number(tabIndex ?? 0)} onSelect={(index, _, e) => onTabChange(index, e)}>
          <TabList>
            <Tab>{t('cleaningManager.Notice')}</Tab>
            <Tab>{t('GuestRoomAssign')}</Tab>
          </TabList>

          <TabPanel>
            <p style={{ padding: 24 }}>{generalNoteContent}</p>
          </TabPanel>
          <TabPanel>
            {!isLoading && <CleaningListSp tabIndex={tabIndex} rooms={rooms} selectedDate={selectedDate} staffId={staffId ?? ''} />}
          </TabPanel>
        </Tabs>
      </div>
    </div>
  )
}

const blockStyle = css({
  '.react-tabs__tab-list': {
    display: 'flex',
    border: 'none',
  },
  '.react-tabs__tab': {
    width: '50%',
    border: 'none',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#AFAFAF',
    paddingBottom: 14,
    '&--selected': {
      color: '#F2A40B',
      borderBottom: '2px solid #F2A40B',
    },
  },
})

const calendarWrapperStyle = css({
  marginTop: 26,
  paddingInline: 32,
  justifyContent: 'space-between',
})

const overWriteDateFieldStyle = css({
  'div.SingleDatePicker': {
    '.SingleDatePickerInput': {
      width: 148,
      border: 'none',
      backgroundColor: 'transparent',
      'div.SingleDatePicker_picker': {
        zIndex: 100,
      },
      '.SingleDatePickerInput_calendarIcon': { display: 'none' },
      'div.DateInput': {
        width: '100%',
        'input.DateInput_input': {
          padding: '5px 0',
          lineHeight: '24px',
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.65)',
          letterSpacing: '0.8px',
        },
      },
    },
  },
})
