import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import moment from 'moment'
import dayjs from 'dayjs'

// model
import { GuestRoomAssignResponseType } from '@/models/room-manager/response/guest-room-assign'
import { initializeSelectBoxStyle } from '@/constants/form'

type roomDateSelectProps = {
  room: GuestRoomAssignResponseType['rooms'][0]
  roomIndex: number
  reservationCheckinDate: moment.Moment
  reservationCheckoutDate: moment.Moment
  onEditRoomStayPeriod: (selectDate: 'checkin' | 'checkout', value: string, selectIndex: number) => void
  getStayPeriodDates: (startDate: any, endDate: any) => string[]
  getRoomDateSelectLabelColor: () => '#F2F2F2' | '#FFFCDD' | '#FEF3F0' | '#EFF9FF' | '#F6FDF2' | '#F5F5F5'
  onDeleteRoom: (selectIndex: number) => void
  assignPanelEditMode: boolean
  disabled?: boolean
}

export const RoomDateSelect: React.FC<roomDateSelectProps> = ({
  room,
  roomIndex,
  reservationCheckinDate,
  reservationCheckoutDate,
  onEditRoomStayPeriod,
  getStayPeriodDates,
  getRoomDateSelectLabelColor,
  onDeleteRoom,
  assignPanelEditMode,
  disabled,
}) => {
  const { t } = useTranslation()
  const assignTypeDatesWrapperStyle = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: assignPanelEditMode ? '' : '7px',
    paddingBottom: assignPanelEditMode ? '' : '7px',
    paddingLeft: 12,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottom: '1px solid #cccccc',
    img: { cursor: 'pointer' },
  })

  const assignTypeDateSelectWrapperStyle = css({
    display: 'flex',
    alignItems: 'center',
  })

  const assignTypeDateSelectStyle = css(initializeSelectBoxStyle, {
    fontSize: 11,
    width: assignPanelEditMode ? 65 : 52,
    backgroundColor: getRoomDateSelectLabelColor(),
    border: 'none',
    backgroundImage: assignPanelEditMode ? `url(${require('@/static/images/arrow_down_gray.svg')})` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    backgroundSize: 8,
  })
  const [stayPeriodDates, setStayPeriodDates] = useState<string[]>([])
  /**
   * TODO:
   * Warning: Use the `defaultValue` or `value` props on <select> instead of setting `selected` on <option>.
   * Since the above error occurs, I want to change the implementation to not use <option> selected
   */

  useEffect(() => {
    setStayPeriodDates(getStayPeriodDates(reservationCheckinDate, reservationCheckoutDate))
  }, [reservationCheckinDate, reservationCheckoutDate])

  return (
    <div css={assignTypeDatesWrapperStyle} style={{ background: getRoomDateSelectLabelColor() }}>
      <div css={assignTypeDateSelectWrapperStyle}>
        <select
          name="assignCheckinDate"
          value={
            room.assignCheckinDate
              ? dayjs(room.assignCheckinDate).format('MM-DD-YYYY')
              : reservationCheckinDate
                ? moment(reservationCheckinDate).format('MM-DD-YYYY')
                : undefined
          }
          onChange={e => {
            onEditRoomStayPeriod('checkin', e.target.value, roomIndex)
          }}
          disabled={disabled}
          css={[assignTypeDateSelectStyle, { cursor: disabled ? 'default' : 'pointer' }]}
        >
          {!!stayPeriodDates.length &&
            stayPeriodDates.map((date, index) => {
              return (
                <option key={`assignCheckinDate${index}`} value={date}>
                  {moment(date, 'MM-DD-YYYY').format(t('MM-DD'))}
                </option>
              )
            })}
        </select>
        <span style={{ fontSize: 8, margin: `0 ${assignPanelEditMode ? 2 : 8}px` }}>~</span>
        <select
          name="assignCheckoutDate"
          value={
            room.assignCheckoutDate
              ? dayjs(room.assignCheckoutDate).format('MM-DD-YYYY')
              : reservationCheckoutDate
                ? moment(reservationCheckoutDate).format('MM-DD-YYYY')
                : undefined
          }
          onChange={e => {
            onEditRoomStayPeriod('checkout', e.target.value, roomIndex)
          }}
          disabled={disabled}
          css={[assignTypeDateSelectStyle, { cursor: disabled ? 'default' : 'pointer' }]}
        >
          {!!stayPeriodDates.length &&
            stayPeriodDates.map((date, index) => {
              return (
                <option key={`assignCheckinDate${index}`} value={date}>
                  {moment(date, 'MM-DD-YYYY').format(t('MM-DD'))}
                </option>
              )
            })}
        </select>
      </div>
      {assignPanelEditMode && <img src={require('@/static/images/delete_yellow.svg')} onClick={() => onDeleteRoom(roomIndex)} />}
    </div>
  )
}
