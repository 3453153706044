import React from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// components
import {
  salesMainContainerStyle,
  containerTitleStyle,
  containerItemStyle,
  separateStyle,
  contentsWrapperStyle,
  itemStyle,
  itemMoneyStyle,
  itemContentStyle,
  contentsMoneyStyle,
  totalContentsMoneyStyle,
  totalWrapperStyle,
  totalContentsTitleStyle,
} from '@/components/pages/sales-manager/sales/_detail/sales'
import dayjs from 'dayjs'

type PaymentProps = {
  salesTotalDetail: {
    paymentDetail: Array<{
      name: string
      price: number
      byAccountReceivable: Array<{
        name: string
        price: number
      }>
    }>
    confirmedLastTime: string
    confirmedThisTime: string
    totalPayment: number
  }
}

export const Payment: React.FC<PaymentProps> = ({ salesTotalDetail }) => {
  const { t } = useTranslation()

  return (
    <div css={paymentMainContainerStyle}>
      <div css={containerTitleStyle}>
        {t('Deposit')}
        <p>
          {salesTotalDetail?.confirmedLastTime &&
            `${t('Period')}：${dayjs(salesTotalDetail.confirmedLastTime).format(t('YYYY-M-D HH:mm:ss'))} ~ ${
              salesTotalDetail?.confirmedThisTime ? dayjs(salesTotalDetail?.confirmedThisTime).format(t('YYYY-M-D HH:mm:ss')) : '現在'
            }`}
        </p>
      </div>
      <div css={paymentContainerItemStyle}>
        <div css={itemStyle}>{t('Method of payment')}</div>
        <div css={itemMoneyStyle}>{t('Amount of money')}</div>
      </div>
      <ul>
        {salesTotalDetail?.paymentDetail &&
          salesTotalDetail?.paymentDetail.map((detail, index) => (
            <>
              <li css={paymentContentsWrapperStyle} key={index}>
                <p css={itemContentStyle}>{detail.name}</p>
                <p css={contentsMoneyStyle}>¥{detail.price < 0 && detail.name === '-' ? 0 : Number(detail.price).toLocaleString()}</p>
              </li>
              {detail.byAccountReceivable.map(accReceiv => (
                <li css={paymentContentsWrapperStyle} key={`${index}-${accReceiv.name}`} className="account-receivable">
                  <p css={itemContentStyle}>{accReceiv.name}</p>
                  <p css={contentsMoneyStyle}>¥{Number(accReceiv.price).toLocaleString()}</p>
                </li>
              ))}
            </>
          ))}
        <li css={separateStyle} />
      </ul>
      <div css={paymentTotalWrapperStyle}>
        <p css={totalContentsTitleStyle}>{t('Total')}</p>
        <p css={totalContentsMoneyStyle}>¥{salesTotalDetail?.totalPayment ? (+salesTotalDetail?.totalPayment).toLocaleString() : 0}</p>
      </div>
    </div>
  )
}

const paymentMainContainerStyle = css(salesMainContainerStyle, {
  marginRight: 0,
})

export const paymentContainerItemStyle = css(containerItemStyle, {
  display: 'flex',
  justifyContent: 'space-between',
})

export const paymentContentsWrapperStyle = css(contentsWrapperStyle, {
  display: 'flex',
  justifyContent: 'space-between',
  width: 'auto',
  height: 'auto',
  minHeight: 32,
  '&.account-receivable': {
    marginLeft: 25,
    '> p': { color: '#676767 !important' },
  },
})

const paymentTotalWrapperStyle = css(totalWrapperStyle, {
  marginBottom: 24,
})
