import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms/button'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { css } from '@emotion/core'
import { InputField } from '@/components/molecules/input-field'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useForm, Controller } from 'react-hook-form'
import { StaffType, staffFormType } from '@/constants/cleaning-manager'
import { createCleaningStaff, updateCleaningStaff } from '@/apis/aipass'
import { RHFInputField } from '@/components/molecules/RHFInput-field'

type Props = {
  editItem?: StaffType
  nextOrder: number
  onSave: () => void
  onClose: () => void
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const StaffModal = ({ editItem, nextOrder, onSave, onClose, setLoading }: Props) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useForm<staffFormType>({
    defaultValues: {
      name: editItem?.name || '',
      displayOrder: editItem?.displayOrder || nextOrder,
    },
  })
  const save = async (formValues: staffFormType) => {
    if (isSubmitting) return
    try {
      setLoading(true)
      if (editItem) {
        await updateCleaningStaff(formValues, editItem.id)
      } else {
        await createCleaningStaff(formValues)
      }
      onSave()
      onClose()
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal customCss={modalStyle}>
      <ModalHeader customCss={modalHeaderStyle}>
        {t(editItem ? 'cleaningManager.EditCheckList' : 'cleaningManager.AddCheckList')}
      </ModalHeader>
      <ModalBody customCss={modalBodyStyle}>
        <div css={bodyContentStyle}>
          <div css={inputAreaStyle}>
            <div>
              <div css={inputTitleTextStyle}>
                {t('Display order')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <RHFInputField
                required
                type="number"
                placeholder="00000"
                error={errors.displayOrder?.message}
                rules={{ required: true }}
                name="displayOrder"
                control={control}
              />
            </div>
          </div>

          <div css={inputAreaStyle}>
            <div>
              <div css={inputTitleTextStyle}>
                {t('Full name')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                name="name"
                control={control}
                rules={{ required: true, maxLength: 5 }}
                render={({ field: { onChange, value, name } }) => (
                  <InputField
                    marginBottom={0}
                    placeholder={`${t('Full name')}（${t('maxLength', { max: 5 })}）`}
                    value={value}
                    name={name}
                    handleChangeData={onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter customCss={modalFooterStyle}>
        <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(save)} isDisabled={!isValid}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = css({
  top: '5%',
  left: 'calc(50% - 300px)',
  minWidth: 600,
  width: 600,
  height: 'auto',
})

const modalHeaderStyle = css({
  height: '60px',
  boxShadow: 'none',
  color: '#272727',
})

const modalBodyStyle = css({
  padding: 32,
})

const modalFooterStyle = css({
  height: '60px',
  display: 'flex',
})

const bodyContentStyle = css({
  padding: '32px',
  backgroundColor: '#FFFFFF',
  border: '1px solid #CCCCCC',
  borderRadius: '5px',
  width: '100%',
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const inputAreaStyle = css({
  paddingTop: 24,
  display: 'flex',
  '> div': {
    width: '100%',
  },
  ':first-of-type': {
    paddingTop: 0,
  },
})
