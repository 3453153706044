import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'react-dates/initialize'
import { modalContainerStyle } from '@/components/pages/accommodation-management/list-layout'
import { Button, ButtonType } from '@/components/atoms/button'
import { DateRangeFixedCalender } from '@/components/molecules/date-range-fixed-calender'
import * as api from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useForm, useWatch } from 'react-hook-form'
import { LoaderContextCreator } from '@/contexts/loader'

type ContainerProps = {
  setIsModalOpen: (isModalOpen: boolean) => void
}

export type FormType = {
  beginDate: string
  endDate: string
}

export const ReservationCreateModal: React.FC<ContainerProps> = ({ setIsModalOpen }) => {
  const history = useHistory()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { control, handleSubmit, setValue } = useForm<FormType>({
    defaultValues: {
      beginDate: dayjs().format('YYYY-MM-DD'),
      endDate: '',
    },
  })
  const watchBeginDate = useWatch({ control, name: 'beginDate' })
  const watchEndDate = useWatch({ control, name: 'endDate' })

  const createReservation = async (formValue: FormType) => {
    try {
      setIsLoading(true)
      const res = await api.createReservation({ checkinDate: formValue.beginDate, checkoutDate: formValue.endDate })
      history.push({ pathname: `/reservation/${res.id}`, search: `?tabType=reservation&reservationId=${res.reservationId}` })
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const changeRangeDate = (beginDate: string, endDate: string) => {
    setValue('beginDate', beginDate)
    setValue('endDate', endDate)
  }

  const isValid = useMemo(() => watchBeginDate && watchEndDate, [watchBeginDate, watchEndDate])

  return (
    <div css={modalContainerStyle}>
      <div css={modalBodyContainerStyle}>
        <h3 css={modalHeaderStyle}>{t('New reservation')}</h3>
        <div css={modalMainContainerStyle}>
          <div>
            <div css={fieldTittleStyle}>
              {t('Date of stay')}
              <div>※</div>
            </div>
            <DateRangeFixedCalender beginDate={watchBeginDate} endDate={watchEndDate} changeRangeDate={changeRangeDate} />
          </div>
        </div>
        <div css={modalButtonSectionStyle}>
          <Button
            width={110}
            height={38}
            buttonType={3}
            marginRight={16}
            onClick={() => {
              setIsModalOpen(false)
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            width={110}
            height={40}
            buttonType={isValid ? ButtonType.Primary : ButtonType.PrimaryDisabled}
            isDisabled={!isValid}
            onClick={handleSubmit(createReservation)}
          >
            {t('Create')}
          </Button>
        </div>
      </div>
    </div>
  )
}

// モーダル
const modalBodyContainerStyle = css({
  position: 'relative',
  top: '42%',
  left: '50%',
  width: 570,
  zIndex: 102,
  opacity: 1,
  background: '#FFF',
  borderRadius: 5,
  transform: 'translate(-50%, -50%)',
})

const modalHeaderStyle = css({
  height: 40,
  margin: '22px 32px',
  fontWeight: 'bold',
  fontSize: 18,
  color: '#272727',
  paddingTop: 22,
})

const modalMainContainerStyle = css({
  padding: 32,
  backgroundColor: '#F2F2F2',
  display: 'flex',
  '> div': { margin: 'auto' },
})

const fieldTittleStyle = css({
  fontSize: 14,
  fontWeight: 'bold',
  color: '#676767',
  marginBottom: 12,
  display: 'flex',
  '> div': {
    fontSize: 8,
    paddingLeft: 4.75,
  },
})

const modalButtonSectionStyle = css({
  display: 'flex',
  padding: '11px 32px 11px 32px',
  justifyContent: 'flex-end',
  boxShadow: '0px 0px 6px #0000001A',
})
