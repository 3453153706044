import React, { useContext, useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from 'react-use'
import { Link } from 'react-router-dom'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

// contexts
import { AccountContext } from '@/contexts/account'

import {
  mainContainerHeaderStyle,
  headerBarLeftWrapperStyle,
  wholeContainerStyle,
  mainColumnStyle,
  listContainerGrayStyle,
  mainContainerStyle,
  tableHeaderThStyle,
  tableBodyTdStyleRead,
  tableBodyTdStyleUnread,
  tableBodyTdStyle,
} from '@/constants/layout'

// apis
import * as api from '@/apis/aipass'

// components
import { ReloadButton } from '@/components/atoms/reload-button'
import { LoadingFull } from '@/components/molecules/loading-full'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { useForm } from 'react-hook-form'
import { Button } from '@/components/atoms/button'
import { ReservationHeaderButtonStyle, companionStyle } from '@/components/pages/accommodation-management/list-layout'
import { useSearchedConditions } from '@/hooks/use-search-condition'

// models
import { CustomerType, SearchCustomerListParams } from '@/models/customer-manager/customer'

// libs
import { setHasRepeatPlugin } from '@/libs/plugins'
import { CustomerTag } from '@/components/atoms/customer/customer-tag'
import { Hint } from '@/components/atoms/hint'
import { SearchListModal } from '@/components/organisms/customer-manager/search-list-modal'
import { fetchCustomerTagMasters, fetchFacilityBasicInfo } from '@/apis/aipass'
import { CustomerTagMasterType } from '@/models/customer-manager/customer-tag'
import { HotelBasicInfo } from '@/models/hotel/basic-info'
const queryString = require('query-string')

const LIMIT = 30
const MAX_DOWNLOAD_LIMIT = 1000

type tildeValueType = string | number | undefined | null
const tildeJoin = ({ from, to }: { from: tildeValueType; to: tildeValueType }) => {
  if (!from && !to) return ''

  if (from && !to) {
    return `${from} ~`
  }

  if (!from && to) {
    return `~ ${to}`
  }

  return `${from} ~ ${to}`
}

export const CustomerList: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { account, plugins } = useContext<any>(AccountContext)
  const location = useLocation()
  const windows = useWindowSize()
  const history = useHistory()
  const searchParams: SearchCustomerListParams = queryString.parse(location.search)

  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)
  const [customers, setCustomers] = useState<CustomerType[]>([])
  const hasRepeatPlugin = setHasRepeatPlugin(plugins)
  const [page, setPage] = useState<number>(searchParams.page || 1)
  const [listTotal, setListTotal] = useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [customerTagMasters, setCustomerTagMasters] = useState<CustomerTagMasterType[]>([])
  const [basicInfo, setBasicInfo] = useState<HotelBasicInfo>()

  const useFormReturn = useForm<SearchCustomerListParams>({
    defaultValues: {
      name: searchParams.name || '',
      nameKana: searchParams.nameKana || '',
      nationality: searchParams.nationality || '',
      gender: searchParams.gender || '',
      fromAge: searchParams.fromAge || null,
      toAge: searchParams.toAge || null,
      birthMonth: searchParams.birthMonth || null,
      phoneNumber: searchParams.phoneNumber || '',
      searchEmail: searchParams.searchEmail || '',
      fromStayCount: searchParams.fromStayCount || null,
      toStayCount: searchParams.toStayCount || null,
      searchHotelId: searchParams.searchHotelId || account.hotel.id,
      customerTagIds: searchParams.customerTagIds ? [searchParams.customerTagIds].flat() : undefined,
      fromCheckinDate: searchParams.fromCheckinDate ? searchParams.fromCheckinDate : dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
      toCheckinDate: searchParams.toCheckinDate ? searchParams.toCheckinDate : dayjs().format('YYYY-MM-DD'),
      fromTotalSales: searchParams.fromTotalSales || null,
      toTotalSales: searchParams.toTotalSales || null,
      fromReviewPoint: searchParams.fromReviewPoint || null,
      toReviewPoint: searchParams.toReviewPoint || null,
    },
  })
  const { getValues } = useFormReturn
  const formValues = getValues()

  const doSearchCustomers = async () => {
    setIsLoading(true)
    const formValues = getValues()
    const formattedBeginDate = formValues.fromCheckinDate ? `${formValues.fromCheckinDate}` : ''
    const formattedEndDate = formValues.toCheckinDate ? `${formValues.toCheckinDate}` : ''
    setIsOpen(false)
    await api
      .fetchCustomers({ ...formValues, page: Number(page), limit: LIMIT })
      .then(res => {
        setCustomers([]) // To reset the table position to the top
        setCustomers(res?.hotelCuicinUsers)
        setListTotal(res?.total)
        history.replace({
          pathname: '',
          search: queryString.stringify({
            ...formValues,
            fromCheckinDate: formattedBeginDate,
            toCheckinDate: formattedEndDate,
            page: Number(page),
          }),
        })
      })
      .finally(() => setIsLoading(false))
  }

  const downloadAccommodationManagementCsv = async () => {
    const formValues = getValues()
    if (listTotal >= MAX_DOWNLOAD_LIMIT) {
      alert(
        t('You cannot create more than {{num}} items Please press the download button after narrowing down the number of items', {
          num: MAX_DOWNLOAD_LIMIT,
        }),
      )
      return
    }
    setIsLoading(true)
    await api.downloadCustomerListCsv(formValues).then(() => {
      history.push({
        pathname: '/customer/list/csv-download-history',
        search: location.search,
      })
      setIsLoading(false)
    })
  }

  const _fetchCustomerTagMasters = async () => {
    await fetchCustomerTagMasters().then(res => res?.length && setCustomerTagMasters(res))
  }

  const _fetchFacilityBasicInfo = async () => {
    await fetchFacilityBasicInfo()
      .then(res => {
        if (res !== undefined) {
          setBasicInfo(res?.basicInfo)
        }
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const searchedConditions = {
    [t('Date of stay')]: tildeJoin({
      from: formValues.fromCheckinDate ? dayjs(formValues.fromCheckinDate).format(t('YYYY-MM-DD')) : undefined,
      to: formValues.toCheckinDate ? dayjs(formValues.toCheckinDate).format(t('YYYY-MM-DD')) : undefined,
    }),
    [t('Full name')]: formValues.name || '',
    [t('Furigana')]: formValues.nameKana || '',
    [t('Country of Citizenship')]: formValues.nationality || '',
    [t('Gender')]: formValues.gender || '',
    [t('Age years')]: tildeJoin({ from: formValues.fromAge, to: formValues.toAge }),
    [t('Birthday month')]: formValues.birthMonth ? `${formValues.birthMonth}月` : '',
    [t('Phone number')]: formValues.phoneNumber || '',
    [t('Email')]: formValues.searchEmail || '',
    [t('Name of facility')]: formValues.searchHotelId ? basicInfo?.hotelName ?? '' : t('All'),
    [t('Number of nights stayed')]: tildeJoin({ from: formValues.fromStayCount, to: formValues.toStayCount }),
    [t('CustomerTag')]:
      formValues.customerTagIds?.map(tagId => customerTagMasters.find(tag => tag.id === tagId)?.tag.name).join(', ') || '',
    [t('Total amount used')]: tildeJoin({ from: formValues.fromTotalSales, to: formValues.toTotalSales }),
    [t('Evaluation')]: tildeJoin({ from: formValues.fromReviewPoint, to: formValues.toReviewPoint }),
  }
  const { SearchedConditionValues } = useSearchedConditions({ searchedConditions })

  useEffect(() => {
    doSearchCustomers()
  }, [page])

  useEffect(() => {
    _fetchCustomerTagMasters()
    _fetchFacilityBasicInfo()
  }, [])

  return (
    <>
      <div css={listContainerGrayStyle}>
        <div css={wholeContainerStyle}>
          <SideMenu />
          <div css={mainColumnStyle}>
            <Header title={t('Customer management')} />
            <div css={[mainContainerStyle, { position: 'relative' }]}>
              <div css={{ borderRadius: 5, boxShadow: '0px 0px 10px #00000029', overflow: 'hidden' }}>
                <div css={[mainContainerHeaderStyle, { minWidth: 'auto' }]}>
                  <div css={headerBarLeftWrapperStyle}>
                    <Button
                      buttonType={4}
                      width={i18n.language === 'en' ? 118 : 90}
                      height={32}
                      fontSize={12}
                      marginLeft={8}
                      marginRight={8}
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {t('Detailed search')}
                    </Button>
                    <ReloadButton onClick={doSearchCustomers} />
                    {!isOpen && <SearchedConditionValues customCss={{ maxWidth: 840 }} />}
                  </div>
                  <div css={headerBarLeftWrapperStyle}>
                    <div css={dropDownButtonStyle}>
                      <Button
                        buttonType={4}
                        width={100}
                        height={32}
                        fontSize={12}
                        paddingLeft={16}
                        paddingRight={16}
                        icon={require('@/static/images/csv.svg')}
                        onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                      >
                        {t('CSV')}
                        <img src={require('@/static/images/arrow_gray.svg')} css={dropDownIcon} />
                      </Button>
                      {isDropDownOpen && (
                        <div css={dropDownStyle}>
                          <ul>
                            <li css={dropDownContentStyle} onClick={downloadAccommodationManagementCsv}>
                              <p css={dropDownTitleStyle}>{t('Customer export')}</p>
                            </li>
                            <li
                              css={dropDownContentStyle}
                              onClick={() => history.push({ pathname: '/customer/list/csv-download-history', search: location.search })}
                            >
                              <p css={dropDownTitleStyle}>{t('Csv download history')}</p>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <AccommodationPaging page={Number(page)} setPage={setPage} total={listTotal || 0} limit={LIMIT} />
                    <div css={ReservationHeaderButtonStyle}></div>
                  </div>
                </div>
                <div css={{ overflow: 'auto', maxHeight: windows.height - 168 }}>
                  <table css={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th css={[tableHeaderThStyle, { paddingLeft: 32, width: 45 }]}></th>
                        <th css={[tableHeaderThStyle, { width: 200 }]}>{t('Full name')}</th>
                        <th css={[tableHeaderThStyle, { width: 200 }]}>{t('Phone number')}</th>
                        <th css={[tableHeaderThStyle, { width: 200 }]}>{t('CustomerTag')}</th>
                        <th css={[tableHeaderThStyle, { width: 80 }]}>{t('Number of nights stayed')}</th>
                        <th css={[tableHeaderThStyle, { width: 200 }]}>{t('Total amount used')}</th>
                        <th css={[tableHeaderThStyle, { width: 200 }]}>
                          <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                            {t('Evaluation')}
                            <Hint customCss={hintStyle}>
                              <div>{t('The average of past survey results is shown')}</div>
                            </Hint>
                          </div>
                        </th>
                        <th css={[tableHeaderThStyle, { width: 200 }]}>{t('Last night of stay')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customers &&
                        customers.map((v: CustomerType, index) => {
                          if (!v) return null

                          return (
                            <Link
                              key={index}
                              css={[
                                v.customerRead ? tableBodyTdStyleRead : tableBodyTdStyleUnread,
                                { display: 'table-row', ':hover': { color: '#000' } },
                                { color: '#000', ':hover': { color: '#000' } },
                              ]}
                              to={`/customer/${v.id}?${queryString.stringify(searchParams)}`}
                            >
                              <td css={[customerTdStyle, { paddingLeft: 32 }]}>
                                <img src={v.picture ? v.picture : require('@/static/images/user.svg')} css={pictureStyle} />
                              </td>
                              <td css={customerTdStyle}>
                                <div css={nameWrapperStyle}>
                                  <ruby>{v?.nameKana === null || v?.nameKana === '' ? '-' : v.nameKana}</ruby>
                                  <div css={dataNameStyle}>{v?.name === null || v?.name === '' ? '-' : v.name}</div>
                                </div>
                              </td>
                              <td css={customerTdStyle}>{v.telephone ? v.telephone : '-'}</td>
                              <td css={customerTdStyle}>
                                <div css={tagsStyle}>
                                  {v.customerTags.map(tag => (
                                    <CustomerTag name={tag.name} bgColor={tag.bgColor} fontColor={tag.fontColor} />
                                  ))}
                                </div>
                              </td>
                              <td css={customerTdStyle}>{hasRepeatPlugin ? <span>{v.stayCount ? `${v.stayCount}回` : '-'}</span> : '-'}</td>
                              <td css={customerTdStyle}>
                                <div css={companionStyle}>{v.totalSalesPrice ? `¥${v.totalSalesPrice.toLocaleString()}` : '-'}</div>
                              </td>
                              <td css={customerTdStyle}>
                                <div css={companionStyle}>{v.averageReviewPoint ?? '-'}</div>
                              </td>
                              <td css={[customerTdStyle, { lineHeight: 1.1 }]}>
                                {v.checkinDate ? (
                                  <>
                                    <span css={reservationDateStyle}>{dayjs(v.checkinDate).format(t('YYYY'))}</span>
                                    {dayjs(v.checkinDate).format(t('MM-DD'))}
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </Link>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              {isOpen && (
                <SearchListModal
                  useFormReturn={useFormReturn}
                  doSearchCustomers={doSearchCustomers}
                  basicInfo={basicInfo}
                  customerTagMasters={customerTagMasters}
                />
              )}
            </div>
          </div>
          <LoadingFull isLoading={isLoading} />
        </div>
      </div>
    </>
  )
}

const pictureStyle = css({
  width: 40,
  height: 40,
  borderRadius: '50%',
})

const nameWrapperStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  ruby: {
    color: '#676767',
    fontSize: 12,
    marginBottom: 6,
  },
  marginRight: 23,
})

const dataNameStyle = css({
  width: 180,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const tagsStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 8,
  maxHeight: 56,
  overflowY: 'hidden',
})

const hintStyle = css({
  '.help-icon': {
    width: 18,
    height: 18,
  },
  '.help-popup': {
    top: 30,
    left: 0,
    transform: 'translateX(-50%)',
    width: 263,
    padding: 16,
    fontSize: 12,
    whiteSpace: 'pre-wrap',
    lineHeight: 1.5,
  },
})

const reservationDateStyle = css({
  '@media(max-width: 1080px)': {
    display: 'block',
  },
})

const dropDownButtonStyle = css({
  position: 'relative',
})

const dropDownIcon = css({
  marginLeft: 8,
})

const dropDownStyle = css({
  width: 153,
  zIndex: 50,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 33,
  right: 10,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownContentStyle = css({
  display: 'flex',
  height: 34,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  fontSize: 14,
  color: '#272727',
})

const customerTdStyle = css(tableBodyTdStyle, {
  padding: '12px 8px',
})
