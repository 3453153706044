import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { AssetSpaceDetailFormValue } from '@/components/organisms/settings/guest-app/space-detail-modal'
import { InputField } from '@/components/molecules/input-field'

type Props = {
  control: Control<AssetSpaceDetailFormValue, any>
  errors: FieldErrors<AssetSpaceDetailFormValue>
}

export const InputChargePrice: React.FC<Props> = ({ control, errors }) => {
  const { t } = useTranslation()

  return (
    <div css={priceAreaStyle}>
      <div className="left-panel">
        <div css={inputTitleTextStyle}>{t('Amount')}</div>
        <div css={inputAreaStyle}>
          <Controller
            name="chargePrice.unitPrice"
            control={control}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <div style={{ marginRight: 8, width: 106 }}>
                <InputField
                  marginBottom={12}
                  placeholder="00000"
                  type="number"
                  value={value}
                  name={name}
                  handleChangeData={onChange}
                  error={error?.message}
                />
              </div>
            )}
          />
          <div className="unit">/{t('Hours')}</div>
        </div>
        <div className="annotation">{t('*If not divisible, round up the decimal point')}</div>
      </div>
      <div className="right-panel">
        <div css={inputTitleTextStyle}>{t('Maximum amount')}</div>
        <div css={inputAreaStyle}>
          <Controller
            name="chargePrice.maxPrice"
            control={control}
            render={({ field: { onChange, value, name }, fieldState: { error } }) => (
              <div style={{ marginRight: 8, width: 106 }}>
                <InputField
                  marginBottom={12}
                  placeholder="00000"
                  type="number"
                  value={value}
                  name={name}
                  handleChangeData={onChange}
                  error={error?.message}
                />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  )
}

const priceAreaStyle = css({
  display: 'flex',
  paddingTop: 24,
  marginTop: 24,
  borderTop: '1px solid #f2f2f2',
  '.left-panel': {
    width: 186,
    borderRight: 'none',
    paddingRight: 0,
    '.unit': {
      fontSize: 12,
      letterSpacing: 1.2,
      lineHeight: '32px',
    },
    '.annotation': { fontSize: 10 },
  },
  '.right-panel': {
    width: '50%',
  },
})

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const inputAreaStyle = css({
  display: 'flex',
})
