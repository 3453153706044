import React, { useState, useEffect, useContext, Dispatch } from 'react'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import dayjs, { Dayjs } from 'dayjs'

import { sectionHeaderStyle, sectionStyle } from '@/components/pages/dashboard/_index/style'

// contexts
import { AccountContext } from '@/contexts/account'

// apis
import { fetchReservationStatus } from '@/apis/aipass'

// components
import { LoadingFull } from '@/components/molecules/loading-full'
import { DateField } from '@/components/molecules/date-field'
import moment from 'moment'

type dailyTotal = {
  reservationTotal: number
  checkinTotal: number
  stayTotal: number
  checkoutTotal: number
}

interface BusinessStatusProps {
  date: Dayjs
  setDate: Dispatch<React.SetStateAction<Dayjs>>
  dailyTotal?: dailyTotal
}

export const BusinessStatus: React.FC<BusinessStatusProps> = ({ date, setDate, dailyTotal }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)
  const [isReservationStatus, setIsReservationStatus] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // status
  const _reservationStatus = async () => {
    setIsLoading(true)
    await fetchReservationStatus().then(res => {
      // [get] Get TL or not
      setIsReservationStatus(res?.reservationStatus)
      setIsLoading(false)
    })
  }

  const clickToReservation = async () => {
    setIsLoading(true)
    const searchParams = `?tabType=reservation&beginDate=${date.format('YYYY-MM-DD')}&endDate=${date.format('YYYY-MM-DD')}&searchBeginDate=${date.format(
      'YYYY-MM-DD',
    )}&searchEndDate=${date.format('YYYY-MM-DD')}`

    if (isReservationStatus === 'TL') {
      history.push({
        pathname: '/accommodation-management',
        search: searchParams,
      })
    } else if (isReservationStatus === 'TM') {
      setIsLoading(true)
      history.push({
        pathname: '/accommodation-management',
        search: searchParams,
      })
    } else if (isReservationStatus === 'NP') {
      setIsLoading(true)
      history.push({
        pathname: '/accommodation-management',
        search: searchParams,
      })
    } else {
      setIsLoading(false)
      //  飛ぶだけ
      history.push({
        pathname: '/accommodation-management',
        search: searchParams,
      })
    }
  }

  useEffect(() => {
    if (account && account?.hotel) {
      // Get the status as soon as the page opens
      _reservationStatus()
    }
  }, [account])

  return (
    <div css={sectionStyle}>
      <div css={sectionHeaderStyle}>
        <img
          style={{ cursor: 'pointer' }}
          src={require('@/static/images/arrow_left_gray.svg')}
          onClick={() => {
            setDate(date.add(-1, 'd'))
          }}
        />
        <DateField
          customCss={overWriteDateFieldStyle}
          value={moment(date.format('YYYY-MM-DD'))}
          onChange={date => date && setDate(dayjs(date.format('YYYY-MM-DD')))}
          displayFormat={t('YYYY-MM-DD')}
        />
        <img
          style={{ cursor: 'pointer' }}
          src={require('@/static/images/arrow_right_gray.svg')}
          onClick={() => {
            setDate(date.add(1, 'd'))
          }}
        />
      </div>
      <div css={changePageWrapperStyle}>
        <div css={itemBlockStyle} onClick={() => clickToReservation()}>
          <div css={iconAndTitleStyle}>
            <img src={require('@/static/images/dashboard/reservation.svg')} />
            <div css={titleStyle}>{t('Reservation')}</div>
          </div>
          <div css={linkStyle}>{dailyTotal?.reservationTotal}</div>
        </div>
        <div
          css={itemBlockStyle}
          onClick={() =>
            history.push({
              pathname: '/accommodation-management',
              search: `?tabType=checkin&beginDate=${date.format('YYYY-MM-DD')}&endDate=${date.add(1, 'day').format('YYYY-MM-DD')}&searchBeginDate=${date.format('YYYY-MM-DD')}&searchEndDate=${date.format('YYYY-MM-DD')}`,
            })
          }
        >
          <div css={iconAndTitleStyle}>
            <img src={require('@/static/images/dashboard/checkin.svg')} />
            <div css={titleStyle}>{t('Checkin')}</div>
          </div>
          <div css={linkStyle}>{dailyTotal?.checkinTotal}</div>
        </div>
        <div css={itemBlockStyle} onClick={() => history.push({ pathname: '/accommodation-management', search: `?tabType=stay` })}>
          <div css={iconAndTitleStyle}>
            <img src={require('@/static/images/dashboard/stay.svg')} />
            <div css={titleStyle}>{t('Stay')}</div>
          </div>
          <div css={linkStyle}>{dailyTotal?.stayTotal}</div>
        </div>
        <div
          css={itemBlockStyle}
          onClick={() =>
            history.push({
              pathname: '/accommodation-management',
              search: `?tabType=checkout&beginDate=${date.format('YYYY-MM-DD')}&endDate=${date.format('YYYY-MM-DD')}&searchBeginDate=${date.format('YYYY-MM-DD')}&searchEndDate=${date.format('YYYY-MM-DD')}`,
            })
          }
        >
          <div css={iconAndTitleStyle}>
            <img src={require('@/static/images/dashboard/checkout.svg')} />
            <div css={titleStyle}>{t('Checkout')}</div>
          </div>
          <div css={linkStyle}>{dailyTotal?.checkoutTotal}</div>
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const dateStyle = css({
  color: '#272727',
})

const dayStyle = css({
  fontSize: 12,
  color: '#676767',
  marginLeft: 18,
})

const changePageWrapperStyle = css({
  display: 'flex',
  height: 89,
})

const itemBlockStyle = css({
  borderTop: '1px solid #F2F2F2',
  borderRight: '1px solid #F2F2F2',
  width: '100%',
  padding: '16px 24px 16px 16px',
  cursor: 'pointer',
})

const iconAndTitleStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
})

const linkStyle = css({
  fontSize: 20,
  fontWeight: 'bold',
  textAlign: 'right',
  marginTop: 11,
  color: '#272727',
})

const titleStyle = css({
  marginLeft: 12,
  fontWeight: 'bold',
})

const overWriteDateFieldStyle = css({
  'div.SingleDatePicker': {
    '.SingleDatePickerInput': {
      width: '142px',
      border: 'none',
      'div.SingleDatePicker_picker': {
        zIndex: 100,
      },
      '.SingleDatePickerInput_calendarIcon': { display: 'none' },
      'div.DateInput': {
        width: '100%',
        'input.DateInput_input': {
          padding: '5px 0',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: 'bold',
          color: 'rgba(0, 0, 0, 0.65)',
          letterSpacing: '0.8px',
        },
      },
    },
  },
})
