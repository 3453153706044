import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button, ButtonType } from '@/components/atoms/button'
import { InputField } from '@/components/molecules/input-field'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { ChildrenClass, SalesSubjectTaxPriceType, SalesSubjectTaxType } from '@/models/sales-manager/sales'
import { LoaderContextCreator } from '@/contexts/loader'
import * as api from '@/apis/aipass'
import { InputSalesSubject } from '@/components/molecules/settings/asset/space/input-sales-subject'
import { ToggleButton } from '@/components/molecules/settings/toggle-button'
import { Checkbox } from '@/components/atoms/checkbox'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { SubjectTaxPriceRangeField } from './subject-tax-price-range-field'

type Props = {
  detail: SalesSubjectTaxType | null
  onCancel: () => void
  onSave: () => void
}

export type FormValue = {
  name: string
  childrenClass: ChildrenClass[]
  isIncludeDayUse: boolean
  salesDepartmentId: string
  salesSubjectMasterId: string
  priceType: SalesSubjectTaxPriceType
  roomChargeRate: number | undefined
  roomChargeRange: Array<{
    upperPrice: number | undefined
    lowerPrice: number | undefined
    taxPrice: number | undefined
  }>
}

export const SubjectTaxDetailModal: React.FC<Props> = ({ detail, onCancel, onSave: parentSave }) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { setIsLoading } = useContext(LoaderContextCreator())

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm<FormValue>({
    mode: 'all',
    defaultValues: {
      name: detail?.name || '',
      childrenClass: detail?.childrenClass || Object.values(ChildrenClass),
      isIncludeDayUse: detail?.isIncludeDayUse || false,
      salesDepartmentId: detail?.salesSubject.salesDepartmentId || undefined,
      salesSubjectMasterId: detail?.salesSubject.salesSubjectMasterId || undefined,
      priceType: detail?.priceType || SalesSubjectTaxPriceType.FixAmount,
      roomChargeRate: detail?.priceType === SalesSubjectTaxPriceType.FixRate ? detail.roomChargeRate : undefined,
      roomChargeRange:
        detail?.priceType === SalesSubjectTaxPriceType.FixAmount
          ? detail.roomChargeRange
          : [
              {
                upperPrice: undefined,
                lowerPrice: undefined,
                taxPrice: undefined,
              },
            ],
    },
  })
  const watchPriceType = useWatch({ control, name: 'priceType' })

  const onSave = async (value: FormValue) => {
    try {
      setIsLoading(true)
      if (detail) {
        await api.updateSalesSubSubjectTax(detail.id, value)
      } else {
        await api.createSalesSubSubjectTax(value)
      }
      parentSave()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Modal customCss={modalStyle}>
        <ModalHeader>{t(detail ? 'saleSubjectTax.EditTax' : 'saleSubjectTax.AddTax')}</ModalHeader>
        <ModalBody>
          <div css={modalBodyStyle}>
            <div>
              <div css={inputTextStyle}>
                {t('saleSubjectTax.Name')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="name"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <InputField
                    value={value}
                    handleChangeData={onChange}
                    error={error?.message}
                    placeholder={t('saleSubjectTax.Tax')}
                    marginBottom={24}
                  />
                )}
              />
            </div>

            <div style={{ marginBottom: 24, position: 'relative' }}>
              <div css={inputTextStyle}>{t('saleSubjectTax.TargetChildClassification')}</div>
              <Controller
                control={control}
                name="childrenClass"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '12px 32px' }}>
                      {Object.values(ChildrenClass).map(classVal => (
                        <Checkbox
                          key={classVal}
                          value={value.includes(classVal)}
                          onChange={v => onChange(v ? [...value, classVal] : value.filter(val => val !== classVal))}
                          label={t(`ChildrenClass.${classVal}`)}
                        />
                      ))}
                    </div>
                  </>
                )}
              />
            </div>

            <div style={{ marginBottom: 24 }}>
              <div css={inputTextStyle}>
                {t('saleSubjectTax.IncludesDayUse')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                name="isIncludeDayUse"
                render={({ field: { onChange, value } }) => (
                  <ToggleButton
                    customCss={css({ '.label': { width: 81 } })}
                    value={value}
                    onChange={onChange}
                    label={{ on: t('saleSubjectTax.Include'), off: t('saleSubjectTax.Exclude') }}
                  />
                )}
              />
            </div>

            <div style={{ marginBottom: 16 }}>
              <div css={inputTextStyle}>
                {t('Amount')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <Controller
                control={control}
                rules={{ required: t('Required field has not been entered') }}
                name="priceType"
                render={({ field: { onChange, value } }) => (
                  <RadioGroupField
                    onChange={onChange}
                    value={value}
                    items={[
                      { value: SalesSubjectTaxPriceType.FixAmount, label: t('saleSubjectTax.FixAmount') },
                      { value: SalesSubjectTaxPriceType.FixRate, label: t('saleSubjectTax.FixRate') },
                    ]}
                    groupCss={css({ gap: 24 })}
                    style={{ radioSize: 18, labelMargin: 5 }}
                  />
                )}
              />
            </div>

            <div style={{ marginBottom: 24 }}>
              {watchPriceType === SalesSubjectTaxPriceType.FixAmount ? (
                <SubjectTaxPriceRangeField control={control} errors={errors} />
              ) : (
                <div css={roomChargeRateFieldStyle}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>{t('saleSubjectTax.RoomChargeOf')}</div>
                    <Controller
                      control={control}
                      rules={{
                        required: t('Required field has not been entered'),
                        pattern: { value: /^\d+$/, message: t('Please enter in single-byte number') },
                      }}
                      name={`roomChargeRate`}
                      render={({ field: { onChange, value } }) => (
                        <InputField
                          type="text"
                          value={value}
                          handleChangeData={onChange}
                          placeholder="10"
                          marginBottom={0}
                          width="50px"
                          fieldWidth="unset"
                          error={errors?.roomChargeRate ? ' ' : ''}
                        />
                      )}
                    />
                    <div>%</div>
                  </div>
                  {errors?.roomChargeRate && <div css={errorMessageStyle}>{errors.roomChargeRate.message}</div>}
                </div>
              )}
            </div>

            <div>
              <div css={inputTextStyle}>
                {t('saleSubjectTax.SubjectAuto')}
                <div css={requireLabelTextStyle}>※</div>
              </div>
              <InputSalesSubject control={control} detail={detail} setValue={setValue} disableLabel required />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
            {t('Cancel')}
          </Button>
          <Button
            buttonType={isValid ? ButtonType.Primary : ButtonType.PrimaryDisabled}
            height="38px"
            width="110px"
            onClick={handleSubmit(onSave)}
          >
            {t('Save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const modalStyle = {
  minHeight: 534,
  maxHeight: 534,
  width: 662,
  minWidth: 662,
  left: 'calc((100% - 662px) / 2)',
}

const modalBodyStyle = css({
  padding: 32,
  border: '1px solid #ddd',
  borderRadius: 8,
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
})

const inputTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
})

const requireLabelTextStyle = css({
  fontSize: 8,
  fontWeight: 'bold',
  letterSpacing: '0.4px',
  color: '#676767',
  paddingLeft: 8,
})

const errorMessageStyle = {
  fontSize: 10,
  color: 'rgba(255,0,0,0.5)',
  marginTop: 4,
}

const roomChargeRateFieldStyle = css({
  border: '1px solid #CCCCCC',
  borderRadius: 10,
  padding: '12px 24px',
  '> div': {
    '> div:nth-of-type(2)': { paddingLeft: 16, paddingRight: 8 },
    '> div': {
      fontSize: 12,
      fontWeight: 'bold',
      letterSpacing: 1.2,
      color: '#676767',
    },
  },
})
