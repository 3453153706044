import React from 'react'
import { css, CSSObject } from '@emotion/core'

import { GuestRoomAssignResponseType } from '@/models/guest-room/guest-room-assign'
import { initializeSelectBoxStyle } from '@/constants/form'

type RoomNumberSelectProps = {
  room: GuestRoomAssignResponseType['rooms'][0]
  roomIndex: number
  guestRoomNumberList: (roomInfo: GuestRoomAssignResponseType['rooms'][0]) => {
    name: string
    value: string
  }[]
  onEditRoomNumber: (e: React.ChangeEvent<HTMLSelectElement>, guestRoomId: string, selectIndex: number) => void
  isEdit: boolean
  cssStyles?: CSSObject
}

export const RoomNumberSelect: React.FC<RoomNumberSelectProps> = ({
  room,
  roomIndex,
  guestRoomNumberList,
  onEditRoomNumber,
  isEdit,
  cssStyles,
}) => {
  const addRoomSelectboxStyle = css(initializeSelectBoxStyle, {
    color: '#272727',
    padding: '0 10px',
    height: 32,
    width: 190,
    border: '1px solid #CCCCCC',
    borderRadius: 17,
    marginRight: 16,
    backgroundImage: isEdit ? `url(${require('@/static/images/select.svg')})` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% center',
    backgroundSize: 10,
    maxWidth: `calc(100% - 16px)`,
  })

  const addRoomNumberSelectboxStyle = css(addRoomSelectboxStyle, {
    marginRight: 0,
  })

  return (
    <select
      name={`roomNumber[${roomIndex}]`}
      css={[addRoomNumberSelectboxStyle, cssStyles]}
      value={room && room.guestRoomId !== null ? room.guestRoomId : ''}
      onChange={e => onEditRoomNumber(e, e.target.value, roomIndex)}
    >
      {guestRoomNumberList(room).map(roomNumberItem => (
        <option key={roomNumberItem['guestRoomId']} value={roomNumberItem.value}>
          {roomNumberItem.name}
        </option>
      ))}
    </select>
  )
}
