import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CleaningStatusType, RoomType, RoomUsageStatusType, StaffType, CheckListType } from '@/constants/cleaning-manager'
import {
  fetchCleaningManagerSetting,
  fetchCleaningStaff,
  fetchRoomDetail,
  putCleaningRoomCheckList,
  updateGuestRoomStatusForCleaningManager,
  UpdateGuestRoomStatusForCleaningManagerRequestType,
} from '@/apis/aipass'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import { css } from '@emotion/core'
import { errorHandler } from '@/libs/errors'
import { HeaderSp } from '@/components/organisms/cleaner/sp/header-sp'
import { ReportForm } from '@/components/organisms/cleaner/sp/report-form'
import { LoadingFull } from '@/components/molecules/loading-full'
import { Share } from '@/components/organisms/cleaner/sp/share'
const queryString = require('query-string')

export const CleaningManagerDetail: React.FC = () => {
  const [isUpdated, setIsUpdated] = useState(false)
  const [room, setRoom] = useState<RoomType>()
  const [isLoading, setIsLoading] = useState(false)
  const [staffList, setStaffList] = useState<StaffType[]>([])
  const [checkList, setCheckList] = useState<CheckListType[]>([])
  const [checkedIdList, setCheckedIdList] = useState<number[]>([])

  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const params = useParams<{ roomId: string | undefined }>()
  const { roomId } = params
  const selectedDate = queryParams.dateOf ? dayjs(queryParams.dateOf) : dayjs()

  const { t } = useTranslation()

  const useFormReturn = useForm<Required<UpdateGuestRoomStatusForCleaningManagerRequestType>>({
    defaultValues: {
      guestRoomId: roomId,
      dateOf: queryParams?.dateOf || '',
      staffReport: '',
      cleaningStatus: CleaningStatusType.Unused,
      roomUsageStatus: RoomUsageStatusType.Unused,
      images: [],
    },
  })
  const { getValues, reset, setValue } = useFormReturn

  const fetchCheckList = async () => {
    setIsLoading(true)
    const checkList = (await fetchCleaningManagerSetting()) || []
    setCheckList(checkList)
    setIsLoading(false)
  }

  const fetchStaffs = async () => {
    setIsLoading(true)
    const staffs = await fetchCleaningStaff().then(res => res || [])
    setStaffList(staffs)
    setIsLoading(false)
  }

  const createBackToUrl = () => {
    return `/cleaning?tabIndex=${queryParams.tabIndex}&dateOf=${selectedDate.format('YYYY-MM-DD')}&staffId=${queryParams.staffId}`
  }

  const renderCleaningStatusButton = () => {
    switch (room?.cleaningStatus) {
      case CleaningStatusType.NotCleaning:
      case CleaningStatusType.Unused:
        return (
          <div css={buttonStyle} style={{ background: '#3E85CC' }} onClick={() => changeCleaningStatus(CleaningStatusType.Cleaned)}>
            {t('cleaningManager.Changed to cleaned')}
          </div>
        )
      case CleaningStatusType.Cleaned:
        return (
          <div css={buttonStyle} style={{ background: '#7DC931' }} onClick={() => changeCleaningStatus(CleaningStatusType.Inspected)}>
            {t('cleaningManager.Changed to inspected')}
          </div>
        )
      default:
        return <></>
    }
  }

  const clearAllCheckList = async () => {
    if (!room) {
      return
    }

    checkedIdList.forEach(async id => {
      await putCleaningRoomCheckList({
        guestRoomId: room.guestRoomId,
        dateOf: selectedDate.format('YYYY-MM-DD'),
        checkListId: id,
        isChecked: false,
      })
    })
    setCheckedIdList([])
  }

  const changeCleaningStatus = async (newStatus: CleaningStatusType) => {
    if (!room) {
      return
    }

    const values = getValues()

    try {
      setIsLoading(true)
      setIsUpdated(true)
      setValue('cleaningStatus', newStatus)
      if (newStatus === CleaningStatusType.NotCleaning) {
        clearAllCheckList()
      }
      await updateGuestRoomStatusForCleaningManager({
        guestRoomId: room.guestRoomId,
        dateOf: selectedDate,
        staffReport: values.staffReport,
        cleaningStatus: newStatus,
        cleaningStaffId: values.cleaningStaffId,
        roomUsageStatus: values.roomUsageStatus,
        images: values.images,
      })

      history.push(createBackToUrl())
    } catch (error) {
      errorHandler(error)
    }
  }

  const changeRoomUsageStatus = async (newStatus: RoomUsageStatusType) => {
    if (!room) {
      return
    }

    setValue('roomUsageStatus', newStatus)

    await updateGuestRoomStatusForCleaningManager({
      guestRoomId: room.guestRoomId,
      dateOf: selectedDate,
      staffReport: undefined,
      cleaningStatus: undefined,
      cleaningStaffId: undefined,
      roomUsageStatus: newStatus,
    })
  }

  const save = async () => {
    if (!room) {
      return
    }

    const values = getValues()
    await updateGuestRoomStatusForCleaningManager({
      guestRoomId: room.guestRoomId,
      dateOf: selectedDate,
      staffReport: values.staffReport,
      cleaningStatus: values.cleaningStatus,
      cleaningStaffId: values.cleaningStaffId,
      roomUsageStatus: values.roomUsageStatus,
      images: values.images,
    })

    history.block(() => {})
    history.push(createBackToUrl())
  }

  const saveCheckList = async (checkListId: number, isChecked: boolean) => {
    if (!room) {
      return
    }

    let newCheckedIdList = isChecked ? [...checkedIdList, checkListId] : checkedIdList.filter(i => i !== checkListId)

    setCheckedIdList(newCheckedIdList)
    putCleaningRoomCheckList({
      guestRoomId: room.guestRoomId,
      dateOf: selectedDate.format('YYYY-MM-DD'),
      checkListId: checkListId,
      isChecked: isChecked,
    })
  }

  useEffect(() => {
    if (!selectedDate) {
      return
    }

    setIsLoading(true)
    fetchRoomDetail(roomId, selectedDate.format('YYYY-MM-DD'))
      .then(data => {
        setRoom(data)
        setCheckedIdList(data.checkList.filter(({ isChecked }) => isChecked).map(({ id }) => id))
      })
      .catch(e => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    fetchCheckList()
  }, [])

  useEffect(() => {
    fetchStaffs()
  }, [])

  useEffect(() => {
    if (!room) {
      return
    }
    reset({
      ...getValues(),
      staffReport: room.staffReport,
      cleaningStaffId: room.cleaningStaffId,
      cleaningStatus: room.cleaningStatus,
      roomUsageStatus: room.roomUsageStatus,
      images: room.photos,
    })
  }, [room])

  return (
    <>
      {isLoading ? (
        <LoadingFull isLoading={isLoading} />
      ) : (
        <div>
          <HeaderSp title={t('Cleaning management')} />
          <div css={roomNumberHeaderStyle}>
            <p className="room-number">{room?.roomNumber}</p>
            <div css={selectStyle}>
              <select
                onChange={e => {
                  changeCleaningStatus(Number(e.target.value) as CleaningStatusType)
                }}
                value={getValues().cleaningStatus}
              >
                <option value={CleaningStatusType.NotCleaning}>{t('cleaningManager.Dirty')}</option>
                <option value={CleaningStatusType.Cleaned}>{t('cleaningManager.Clean')}</option>
                <option value={CleaningStatusType.Inspected}>{t('cleaningManager.Inspected')}</option>
                <option value={CleaningStatusType.DoNotDisturb}>{t('cleaningManager.Do not Disturb')}</option>
                <option value={CleaningStatusType.NotRequired}>{t('cleaningManager.No Cleaning')}</option>
                <option value={CleaningStatusType.Unused}>-</option>
              </select>

              <select
                className="room-usage"
                onChange={e => {
                  changeRoomUsageStatus(Number(e.target.value) as RoomUsageStatusType)
                }}
                value={getValues().roomUsageStatus}
              >
                <option value={RoomUsageStatusType.Unused}>{t('cleaningManager.Vacant')}</option>
                <option value={RoomUsageStatusType.Stay}>{t('cleaningManager.Occupied')}</option>
                <option value={RoomUsageStatusType.Away}>{t('cleaningManager.Out')}</option>
                <option value={RoomUsageStatusType.CheckOutScheduled}>{t('cleaningManager.Checkout Soon')}</option>
                <option value={RoomUsageStatusType.CheckedOut}>{t('cleaningManager.Done Checkout')}</option>
              </select>
            </div>
          </div>

          {renderCleaningStatusButton()}

          <div css={tabBlockStyle}>
            <Tabs>
              <TabList>
                <Tab>{t('Share')}</Tab>
                <Tab>{t('Reporting')}</Tab>
              </TabList>

              <TabPanel>{room && <Share isUpdated={isUpdated} useFormReturn={useFormReturn} onSave={save} room={room} />}</TabPanel>
              <TabPanel>
                {room && (
                  <ReportForm
                    isUpdated={isUpdated}
                    cleaningStatus={room.cleaningStatus}
                    checkedIdList={checkedIdList}
                    saveCheckList={saveCheckList}
                    onSave={save}
                    useFormReturn={useFormReturn}
                    checkList={checkList}
                    staffList={staffList}
                  />
                )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      )}
    </>
  )
}

const roomNumberHeaderStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 24,
  borderBottom: '1px solid #F2F2F2',
  '.room-number': {
    color: '#272727',
    fontWeight: 'bold',
    fontSize: 18,
  },
})

const selectStyle = css({
  display: 'flex',
  select: {
    width: 'max-content',
    height: 18,
    color: '#676767',
    border: 'none',
    background: 'transparent',
    appearance: 'none',
    backgroundImage: `url(${require('@/static/images/arrow_gray.svg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '90% center',
    backgroundColor: '#FFFFFF',
    display: 'block',
    fontSize: '14px',
    fontWeight: 'bold',
    '&.room-usage': {
      marginLeft: 16,
      paddingLeft: 16,
      borderLeft: '1px solid #CCCCCC',
      fontSize: 12,
    },
  },
})

const tabBlockStyle = css({
  marginTop: 24,
  '.react-tabs__tab-list': {
    display: 'flex',
    border: 'none',
  },
  '.react-tabs__tab': {
    width: '50%',
    border: 'none',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#AFAFAF',
    paddingBottom: 14,
    '&--selected': {
      color: '#F2A40B',
      borderBottom: '2px solid #F2A40B',
    },
  },
})

const buttonStyle = css({
  margin: 24,
  marginBottom: 0,
  paddingBlock: 12,
  borderRadius: 6,
  textAlign: 'center',
  color: '#fff',
})
