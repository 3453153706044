import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import useBrowserContextCommunication from 'react-window-communication-hook'

// components
import { Notice } from '@/components/organisms/notice'
import { LanguageSelect } from '@/components/molecules'
import { HotelSwitcher } from './account/hotel-switcher'
import { StaffSwitcher } from './account/staff-switcher'

// contexts
import { AccountContext } from '@/contexts/account'

// api
import { CurrentVersionKey, LatestVersionKey, fetchFacilityBasicInfo } from '@/apis/aipass'
import { signout } from '@/apis/auth0'

// libs
import { env } from '@/libs/env'
import { hasManagerRole } from '@/libs/account'
import { useGuestAppUrl } from '@/hooks/use-guest-app-url'
import { setHasSelfCheckinPlugin } from '@/libs/plugins'

type HeaderProps = {
  title: string
  afterTitle?: React.ReactChild
  isPublicPageLink?: boolean
  isBack?: boolean
  goBack?: () => void
}

export const Header: React.FC<HeaderProps> = ({ title, afterTitle, isPublicPageLink, isBack, goBack }) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false)
  const [basicInfo, setBasicInfo] = useState<any>()

  const history = useHistory()
  const { t } = useTranslation()
  const { account, plugins } = useContext<any>(AccountContext)
  const [communicationState, postMessage] = useBrowserContextCommunication('')
  const aipassZendeskUrl = env('AIPASS_ZENDESK_URL')
  const hasSelfCheckinPlugin = setHasSelfCheckinPlugin(plugins)

  const { homePageUrl } = useGuestAppUrl()

  useEffect(() => {
    // Log out the management screen of all tabs when logging out (to avoid duplicate logins)
    if (communicationState.lastMessage === 'logout') {
      signout()
    }
  }, [postMessage])

  useEffect(() => {
    if (sessionStorage.getItem(CurrentVersionKey) !== sessionStorage.getItem(LatestVersionKey)) {
      sessionStorage.removeItem(CurrentVersionKey)
      sessionStorage.removeItem(LatestVersionKey)
      window.location.reload()
    }

    if (localStorage.getItem('idToken')) {
      _fetchFacilityBasicInfo()
    } else {
      history.replace({
        pathname: '/',
      })
    }
  }, [])

  const _fetchFacilityBasicInfo = async () => {
    await fetchFacilityBasicInfo()
      .then(res => {
        if (res !== undefined) {
          setBasicInfo(res?.basicInfo)
        }
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const _logout = async () => {
    postMessage('logout')
    await signout()
  }

  return (
    <div css={headerContainerStyle}>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        {isBack && <img alt="arrow_left" css={arrowStyle} src={require('@/static/images/arrow_left.svg')} onClick={goBack} />}
        <h2 css={isBack ? mainTitleLeftArrowStyle : mainTitleStyle}>{title}</h2>
        {afterTitle}
      </div>
      {account && isPublicPageLink && (
        <>
          <a
            css={[publicPageStyle, hasSelfCheckinPlugin ? { borderRight: '1px solid #ccc', paddingRight: 16 } : undefined]}
            href={homePageUrl}
            target="hotel-guide"
          >
            <p css={isPublicPageLinkStyle}>{t('Dedicated App for Guests')}</p>
          </a>
          {hasSelfCheckinPlugin && (
            <a css={[publicPageStyle, { paddingLeft: 16 }]} href={`/self-checkin?hotelId=${account?.hotel?.id}`} target="_blank">
              <p css={isPublicPageLinkStyle}>{t('Self check-in')}</p>
            </a>
          )}
        </>
      )}
      <div css={hearerRightPartStyle}>
        {process.env.REACT_APP_ENV !== 'production' && <LanguageSelect />}
        <div css={{ marginLeft: 30, cursor: 'pointer', img: { width: 18 } }} onClick={() => window.location.reload()}>
          <img src={require('@/static/images/reload.svg')} height="20" />
        </div>
        <div css={noticeLogoWrapperStyle}>
          {false && <img src={require('@/static/images/header/news.svg')} />}
          <>{basicInfo && <Notice />}</>
        </div>
        <StaffSwitcher customCss={{ marginLeft: 16 }} />
        {account && (
          <div css={dropDownContainerStyle}>
            <div
              onClick={() => {
                setIsDropDownOpen(!isDropDownOpen)
              }}
              css={{ display: 'flex', alignItems: 'center' }}
            >
              {basicInfo && (
                <>
                  <img
                    src={basicInfo?.logoImageId ? basicInfo?.logoImageId : require('@/static/images/header/aipassLogo.svg')}
                    css={hotelLogoStyle}
                  />
                  <div css={{ fontSize: 12, color: '#676767', fontWeight: 'bold', marginRight: '4px' }}>{basicInfo?.hotelName}</div>
                </>
              )}
              <img src={require('@/static/images/header/button_arrow_down.svg')} css={dropButtonStyle} />
            </div>
            {isDropDownOpen && (
              <div css={dropDownStyle}>
                <ul css={dropDownBodyStyle}>
                  <a href={homePageUrl} target="hotel-guide" css={dropDownAtagStyle}>
                    <li css={dropDownHeaderStyle}>{basicInfo?.hotelName}</li>
                  </a>
                  {hasManagerRole(account) && (
                    <li css={dropDownContentStyle}>
                      <a
                        href="/setting/account"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <img src={require('@/static/images/header/setting.svg')} />
                        <p css={dropDownTitleStyle}>{t('Setting')}</p>
                      </a>
                    </li>
                  )}
                  {hasManagerRole(account) && <HotelSwitcher />}
                  <a href={`${aipassZendeskUrl}/hc/ja`} target="_brank" css={dropDownAtagStyle}>
                    <li css={dropDownContentStyle}>
                      <img src={require('@/static/images/header/help.svg')} />
                      <p css={dropDownTitleStyle}>{t('Help')}</p>
                    </li>
                  </a>
                  <li onClick={() => _logout()} css={dropDownContentStyle}>
                    <img src={require('@/static/images/header/icon_logout.svg')} />
                    <p css={dropDownTitleStyle}>{t('Logout')}</p>
                  </li>
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
const headerContainerStyle = css({
  height: 55,
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.10)',
  width: '100%',
  background: '#fff',
  display: 'flex',
  position: 'sticky',
  top: 0,
  zIndex: 100,
})

const hearerRightPartStyle = css({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
})

const mainTitleStyle = css({
  padding: '18px 24px',
  fontSize: 18,
  fontWeight: 'bold',
  color: '#272727',
})

const mainTitleLeftArrowStyle = css(mainTitleStyle, {
  padding: '18px 24px 18px 0',
})

const arrowStyle = css({
  cursor: 'pointer',
})

const publicPageStyle = css({
  display: 'flex',
  margin: 'auto 0',
})

const isPublicPageLinkStyle = css({
  fontSize: '12px',
  color: '#F2A40B',
  letterSpacing: '1.2px',
})

const tabOpenImageStyle = css({
  width: '16px',
  height: '13px',
  margin: 'auto 4px',
})

const noticeLogoWrapperStyle = css({
  margin: '10px 0 10px 16px',
})

//ドロップダウン
const dropDownContainerStyle = css({
  cursor: 'pointer',
  position: 'relative',
  zIndex: 1,
})

const hotelLogoStyle = css({
  width: 34,
  height: 34,
  border: '1px solid #CCCCCC',
  borderRadius: '50%',
  margin: '8px 8px 8px 16px',
})

const dropButtonStyle = css({
  marginRight: 32,
  margin: '16px 32px 16px 0px',
})

const dropDownStyle = css({
  width: 180,
  zIndex: 100,
  backgroundColor: '#FFF',
  position: 'absolute',
  top: 45,
  right: 32,
  boxShadow: '0px 3px 10px #0000003d',
  borderRadius: 5,
})

const dropDownBodyStyle = css({})

const dropDownHeaderStyle = css({
  fontSize: 12,
  padding: 16,
  borderBottom: '1px solid #f2f2f2',
  '&:hover': {
    color: '#f2a40b',
  },
})

const dropDownContentStyle = css({
  display: 'flex',
  height: 44,
  listStyle: 'none',
  alignItems: 'center',
  padding: '0 16px',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const dropDownTitleStyle = css({
  paddingLeft: 12,
  fontSize: 12,
  fontWeight: 'bold',
  color: '#676767',
})

const dropDownAtagStyle = css({
  '&:link': {
    color: 'black',
  },
  '&:visited': {
    color: 'black',
  },
})
