import React from 'react'
import { StaffType } from '@/constants/cleaning-manager'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { css } from '@emotion/core'

interface StaffStatsListProps {
  selectedDate: dayjs.Dayjs
  staffList: StaffType[]
  displayCleaningStaffId: string
  setDisplayCleaningStaffId: React.Dispatch<string>
}

export const StaffStatsList: React.FC<StaffStatsListProps> = ({
  selectedDate,
  staffList,
  setDisplayCleaningStaffId,
  displayCleaningStaffId,
}) => {
  const { t } = useTranslation()
  return (
    <div css={staffListStyle}>
      <div className="inner">
        <div className="item" onClick={() => setDisplayCleaningStaffId('')}>
          <p>{t('All')}</p>
          <p>
            {staffList.reduce((acc, staff) => {
              acc += staff.assignments.filter(({ dateOf }) => dateOf === selectedDate.format('YYYY-MM-DD')).length
              return acc
            }, 0)}
          </p>
        </div>
        {staffList.map(staff => {
          const isSelected = displayCleaningStaffId === staff.id
          return (
            <div
              key={staff.id}
              className={`item ${isSelected ? 'selected' : ''}`}
              style={{ opacity: staff.hasShift ? 1 : 0.5 }}
              onClick={() => setDisplayCleaningStaffId(staff.id)}
            >
              <p className="name">{staff.name}</p>
              <p className="count">{staff.assignments.filter(({ dateOf }) => dateOf === selectedDate.format('YYYY-MM-DD')).length}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const staffListStyle = css({
  marginBlock: 16,
  fontSize: 12,
  paddingInline: 24,
  cursor: 'pointer',
  '.inner': {
    display: 'flex',
    background: '#fff',
    fontWeight: 'bold',
  },
  '.item': {
    width: 81,
    padding: '8px 0',
    borderRight: '1px solid #F2F2F2',
    '&.selected': {
      color: '#F2A40B',
    },
  },
  p: {
    textAlign: 'center',
    ':first-of-type': {
      marginBottom: 6,
    },
  },
})
