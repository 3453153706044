import { useContext, useMemo } from 'react'
import { AccountContext } from '@/contexts/account'
import { PluginCode, checkActivePlugin } from '@/libs/plugins'
import { env } from '@/libs/env'

export const useGuestAppUrl = (state?: { hotelId: string; plugin: any }) => {
  const { account, plugins } = useContext<any>(AccountContext)

  const hotelId = useMemo(() => {
    return (account && account.hotel.id) || state?.hotelId
  }, [account])

  const guestAppUrl = useMemo(() => {
    return checkActivePlugin(plugins || state?.plugin, PluginCode.GuestApp) ? env('GUEST_APP_URL') : env('ID_AIPASS_URL')
  }, [plugins])

  const homePageUrl = useMemo(() => {
    return checkActivePlugin(plugins, PluginCode.GuestApp)
      ? `${guestAppUrl}?hotelId=${hotelId}`
      : `${guestAppUrl}/hotel-guide?hotelId=${hotelId}`
  }, [guestAppUrl])

  const mypageUrl = (checkinId: string, checkinCode: string) => {
    return checkActivePlugin(plugins, PluginCode.GuestApp)
      ? `${guestAppUrl}/mypage/checkin-history/${checkinId}?hotelId=${hotelId}&checkinCode=${checkinCode}`
      : `${guestAppUrl}/mypage/checkin-history/contents?hotelId=${hotelId}&checkinId=${checkinId}&checkinCode=${checkinCode}`
  }

  return {
    guestAppUrl,
    homePageUrl,
    mypageUrl,
  }
}
