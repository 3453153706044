import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import useBrowserContextCommunication from 'react-window-communication-hook'
import { useTranslation } from 'react-i18next'
import { StaffSwitcher } from '../account/staff-switcher'
import { signout } from '@/apis/auth0'
import { Link } from 'react-router-dom'

type HeaderProps = {
  title: string
  reload?: () => void
}

export const Header: React.FC<HeaderProps> = ({ title, reload }) => {
  const [communicationState, postMessage] = useBrowserContextCommunication('')
  const { t, i18n } = useTranslation()

  useEffect(() => {
    // Log out the management screen of all tabs when logging out (to avoid duplicate logins)
    if (communicationState.lastMessage === 'logout') {
      signout()
    }
  }, [postMessage])

  const logout = async () => {
    postMessage('logout')
  }

  return (
    <div css={headerContainerStyle}>
      <div css={hearerLeftPartStyle}>
        <div css={sideMenu}>
          <img src={require('@/static/images/mobile_logo.svg')} width={45} />
          <img src={require(`@/static/images/self-checkin-payment/arrow.svg`)} css={languageArrowImageStyle} alt="down-arrow" />

          <div className="list">
            <div className="list__container">
              <div>
                <Link to={'/cleaning/'}>
                  <img src={require(`@/static/images/cleaning_manager/icon_menu_black.svg`)} width={16} />
                  <p style={{ paddingLeft: 8 }}>{t('Cleaning status')}</p>
                </Link>
              </div>

              <div>
                <Link to={'/cleaning/schedule'}>
                  <img src={require(`@/static/images/calendar_icon.svg`)} width={16} />
                  <p style={{ paddingLeft: 8 }}>{t('Schedule')}</p>
                </Link>
              </div>

              <div className="item__language">
                <img src={require(`@/static/images/header/language.svg`)} width={16} />
                <p>Language</p>
                <img src={require('@/static/images/arrow_right.svg')} height={30} style={{ position: 'absolute', right: 8 }} />

                <div className="language-list">
                  <div className="list__container">
                    <div onClick={() => i18n.changeLanguage('ja')} style={{ color: i18n.language === 'ja' ? '#F2A40B' : '#676767' }}>
                      日本語
                    </div>
                    <div onClick={() => i18n.changeLanguage('en')} style={{ color: i18n.language === 'en' ? '#F2A40B' : '#676767' }}>
                      English
                    </div>
                  </div>
                </div>
              </div>

              <div onClick={() => logout()}>
                <img src={require(`@/static/images/header/icon_logout.svg`)} width={16} />
                <p>{t('Logout')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div css={hearerCenterPartStyle}>
        <h2 css={mainTitleStyle}>{title}</h2>
      </div>

      <div css={hearerRightPartStyle}>
        <StaffSwitcher customCss={{ marginLeft: 32 }} />
        <div css={noticeLogoWrapperStyle} onClick={() => reload && reload()}>
          <img src={require('@/static/images/reload.svg')} height="20" />
        </div>
      </div>
    </div>
  )
}
const headerContainerStyle = css({
  height: 55,
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.10)',
  width: '100%',
  background: '#fff',
  display: 'flex',
  position: 'sticky',
  top: 0,
  zIndex: 100,
})

const hearerLeftPartStyle = css({
  display: 'flex',
  justifyContent: 'start',
  paddingLeft: '24px',
  width: '20%',
  maxWidth: '240px',
  alignItems: 'center',
})
const hearerCenterPartStyle = css({
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
})
const hearerRightPartStyle = css({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  paddingRight: '24px',
})

const mainTitleStyle = css({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 'bold',
  letterSpacing: '1.6px',
  padding: '18px 24px',
  color: '#272727',
})

const noticeLogoWrapperStyle = css({
  margin: '10px 15px 10px 24px',
  paddingLeft: '30px',
  borderLeft: 'solid lightgray',
  alignSelf: 'center',
})

const languageArrowImageStyle = css({
  height: 18,
})

const sideMenu = css({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  '.list': {
    position: 'absolute',
    width: 180,
    top: 51,
    visibility: 'hidden',
    opacity: 0,
    transition: '0.3s',
    boxShadow: '0px 3px 10px #0000003D',
    '.list__container': {
      backgroundColor: 'white',
    },
  },
  '.language-list': {
    position: 'absolute',
    width: 180,
    left: 188,
    visibility: 'hidden',
    opacity: 0,
    transition: '0.3s',
    top: 0,
    boxShadow: '0px 3px 10px #0000003D',
    '.list__container': {
      backgroundColor: 'white',
    },
  },
  '.list__container': {
    '.item__language': {
      cursor: 'auto',
      ':hover': {
        '.language-list': {
          visibility: 'visible',
          opacity: 1,
          transition: '0s',
        },
      },
    },
  },
  '.list__container > div': {
    paddingLeft: 16,
    height: 41,
    borderBottom: '1px solid #F2F2F2',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: '0.7px',
    color: '#676767',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    ':last-child': {
      borderBottom: 'none',
    },
    '> p': {
      paddingLeft: 8,
    },
  },
  a: {
    display: 'flex',
    color: '#676767',
    ':hover': {
      color: '#F2A40B',
    },
  },
  ':hover': {
    '.list': {
      // display: 'block',
      visibility: 'visible',
      opacity: 1,
      transition: '0s',
    },
  },
})
