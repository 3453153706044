import React from 'react'
import { css } from '@emotion/core'

// model
import { GuestRoomAssignResponseType } from '@/models/room-manager/response/guest-room-assign'
import { initializeSelectBoxStyle } from '@/constants/form'

type roomTypeSelectProps = {
  room: GuestRoomAssignResponseType['rooms'][0]
  roomIndex: number
  onEditRoomType: (e: React.ChangeEvent<HTMLSelectElement>, selectRoomType: string, selectIndex: number) => void
  guestRoomTypeList: (roomInfo: GuestRoomAssignResponseType['rooms'][0]) => { id: number; name: string | null; value: string | null }[]
  assignPanelEditMode: boolean
  disabled?: boolean
}

export const RoomTypeSelect: React.FC<roomTypeSelectProps> = ({
  room,
  roomIndex,
  onEditRoomType,
  guestRoomTypeList,
  assignPanelEditMode,
  disabled,
}) => {
  const selectContainerStyle = css({
    display: 'flex',
  })

  const assignSelectStyle = css(initializeSelectBoxStyle, {
    width: '100%',
    height: 32,
    color: '#272727',
    border: '1px solid #CCCCCC',
    borderRadius: 19,
    padding: '0 24px 0 16px',
    backgroundImage: assignPanelEditMode ? `url(${require('@/static/images/arrow_down_yellow.svg')})` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '95% center',
    backgroundSize: 10,
    display: 'block',
    marginBottom: 12,
  })

  return (
    <div css={selectContainerStyle}>
      <select
        name={`roomType[${roomIndex}]`}
        css={[assignSelectStyle, disabled ? { backgroundColor: '#F5F5F5' } : {}]}
        value={room && room['typeNameJa'] ? room['typeNameJa'] : '選択してください。'}
        onChange={e => onEditRoomType(e, e.target.value, roomIndex)}
        disabled={disabled}
      >
        {guestRoomTypeList(room).map((roomTypeInfo, roomTypeIndex) => (
          <option key={roomTypeIndex} value={roomTypeInfo['value'] ?? ''}>
            {roomTypeInfo['name'] ?? ''}
          </option>
        ))}
      </select>
    </div>
  )
}
