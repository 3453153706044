import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import parse from 'html-react-parser'
import 'dayjs/locale/ja'
import { v4 as uuidv4 } from 'uuid'

// components
import { SelfCheckinHeader, FooterAgreement } from '@/components/molecules'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { Loading } from '@/components/molecules/self-checkin/loading'

// models
import { PaymentInfo } from './payment-info'
import {
  fetchReservationsAndSales,
  selfCheckinApprovedReservations,
  selfCheckinForReservations,
  selfCheckinHandlePayment,
  selfCheckinLinkReservations,
  selfCheckinUploadIdentificationImages,
  sendMailSelfCheckin,
} from '@/apis/aipass'
import { CREDIT_CARD } from '@/models/self-checkin/payment-method'
import { ApprovedStatus } from '@/models/reservation-approved-status'
import dayjs from 'dayjs'
import { OccupationType, isEmployee } from '@/models/self-checkin/accommodation-info'
import { getTranslateOccupation } from '@/libs/self-checkin'
import { useGuestAppUrl } from '@/hooks/use-guest-app-url'
import { useCardToken } from '@/hooks/use-card-token'
import { useAssignKey } from '@/hooks/use-assign-key'
import { RequireText } from '@/components/atoms/require-text'
import { SelfCheckinCompleteState } from '../complete'
import { serialNumberStorageKey } from '@/models/self-checkin/card-reader'

export const Index: React.FC<{}> = () => {
  // FIXME: It doesn't have to be State because it has unused parts
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isDeliveryConfirm, setIsDeliveryConfirm] = useState<boolean>(true)
  const { search, state } = useLocation<{
    from: string
    accommodationInfo: any
    identificationFrontImage: string
    identificationBackImage: string
    representativePassportImage: string
    companionImageArray: {
      id: number
      companionPassportImage: string
    }[]
    checkinMemo: string
    guides: any
    hotelId: string
    imageType: 'front' | 'back' | 'representativePassport' | 'companionPassport'
    paymentInfo: any
    plugin: any
    selectedReservations: any
    singleSelectedResesvation: any
    requiredIdentify: any
    edit: any
    paymentSetting: any
    basicInfo: any
    companionNumber: any
    isGuidanceDisplay: boolean
    requiredAccompanyNameInput: boolean
    requiredFuriganaInput: boolean
    requiredBirthDateInput: boolean
    requiredGenderInput: boolean
    requiredTelephoneInput: boolean
    requiredEmailInput: boolean
    requiredAddressInput: boolean
    isFrontDescGuidance: boolean
  }>()
  const { mypageUrl, homePageUrl } = useGuestAppUrl(state)
  const history = useHistory()
  const { fetchRoomKey } = useAssignKey()
  const { t, i18n } = useTranslation()
  const { makeCardToken } = useCardToken()
  const lang = i18n.language

  const accompanyData = () => {
    if (state?.accommodationInfo?.accompany?.length > 0) {
      return state.accommodationInfo.accompany.filter(accompany => accompany?.name.trim())
    }
    return []
  }
  const onClickCheckin = async () => {
    if (isLoading) return
    setIsLoading(true)
    const reservationIds = state.selectedReservations || []

    const reservationSaleIds: any = []
    const reservationsPaymentDetail: any = []
    await fetchReservationsAndSales(state.hotelId, reservationIds).then(res => {
      for (let index = 0; index < res?.reservations?.length; index++) {
        const reservation = res.reservations[index]
        reservationsPaymentDetail.push({
          reservationNumber: index + 1,
          useAmount: reservation.totalPrice,
          paymentAmount: reservation.totalDiscount,
          internalDiscount: reservation.totalDiscount,
        })
        reservation.sales.map(item => {
          reservationSaleIds.push(item?.id)
        })
        reservation.salesDiscounts.map(item => {
          reservationSaleIds.push(item?.id)
        })
      }
    })

    let paymentResult: any = undefined
    if (state?.paymentInfo?.paymentMethod === CREDIT_CARD) {
      try {
        const cardToken = await makeCardToken(state.hotelId, {
          cardNumber: state.paymentInfo.cardInfo.cardNumber.replaceAll(' ', ''),
          cardExpire: `${state.paymentInfo.cardInfo.expiredMonth}/${state.paymentInfo.cardInfo.expiredYear}`,
          securityCode: state.paymentInfo.cardInfo.cvc,
          cardHolder: state.paymentInfo.cardInfo.holderName,
        })
        paymentResult = await selfCheckinHandlePayment(state.hotelId, reservationIds, cardToken)
      } catch (error) {
        console.warn(error)
        alert(t('Your payment was declined by your card issuer Please connect your card issuer'))
        editPaymentInfo()
        return
      }
    }

    const now = dayjs().unix()
    const checkinData = {
      checkinId: uuidv4(),
      reservationIds,
      checkin: {
        hotelId: state.hotelId,
        approvedStatus: 0,
        accompany: accompanyData(),
        guest: {
          name: `${state.accommodationInfo.lastName} ${state.accommodationInfo.firstName}`,
          nameKana:
            state.accommodationInfo.nationality === 'japanese' && lang === 'ja'
              ? `${state.accommodationInfo.lastNameFurigana} ${state.accommodationInfo.firstNameFurigana}`
              : '',
          birthDate: state.accommodationInfo.dateOfBirth,
          nationality: state.accommodationInfo.nationality == 'japanese' ? 'JPN' : 'NJP',
          gender: state.accommodationInfo.gender,
          postalCode: state.accommodationInfo.nationality == 'japanese' ? state.accommodationInfo.postCode : '',
          address: state.accommodationInfo.address,
          telephone: state.accommodationInfo.phoneNumber,
          email: state.accommodationInfo.mail,
          occupation: state.accommodationInfo.occupation,
          otherOccupation: _isOtherOccupation() ? state.accommodationInfo.otherOccupation : null,
          company: isEmployee(state?.accommodationInfo?.occupation) ? state.accommodationInfo.company : '',
          totalMember: null,
          picture: null,
          credential: null,
        },
        checkinTime: now,
        userCheckinTime: now,
        nextPlaceToStay: '自宅',
        previousPlaceToStay: '自宅',
        jpnGtdStatus: 0,
      },
    }

    const checkinResponseInfo = await selfCheckinForReservations(checkinData)

    let identificationImageData: any
    switch (state.from) {
      case 'upload-license':
        identificationImageData = {
          hotelId: state.hotelId,
          identification: {
            checkinId: checkinData.checkinId,
            images: [
              { isDriversLicense: 1, imageBase64: state.identificationFrontImage, name: '免許証表面' },
              { isDriversLicense: 1, imageBase64: state.identificationBackImage, name: '免許証裏面' },
            ],
          },
        }
        await selfCheckinUploadIdentificationImages(identificationImageData)
        break
      case 'upload-passport':
        identificationImageData = {
          hotelId: state.hotelId,
          identification: {
            checkinId: checkinData.checkinId,
            images: [{ isDriversLicense: 1, imageBase64: state.representativePassportImage, name: '代表者パスポート' }],
          },
        }

        for (let index = 0; index < state.companionNumber; index++) {
          const image = state.companionImageArray.find(img => img.id === index)
          if (image) {
            identificationImageData.identification.images.push({
              isDriversLicense: 1,
              imageBase64: image?.companionPassportImage,
              name: '同行者パスポート',
            })
          }
        }
        await selfCheckinUploadIdentificationImages(identificationImageData)
        break

      default:
        break
    }

    const linkData = {
      checkinId: checkinData.checkinId,
      hotelId: state.hotelId,
      reservationIds,
    }

    await selfCheckinLinkReservations(linkData)

    const approvedData = {
      approvedStatus: ApprovedStatus.Stay,
      hotelId: state.hotelId,
      reservationIds,
      checkinId: checkinData.checkinId,
      checkinMemo: state.checkinMemo ? state.checkinMemo : '',
      deliveryAgreementStatus: isDeliveryConfirm ? 'deliverable' : 'nonDeliverable',
    }
    await selfCheckinApprovedReservations(approvedData)

    const roomInformations = await fetchRoomKey({
      hotelId: state.hotelId,
      checkinId: checkinData.checkinId,
      reservationIds,
    })

    const emailData = {
      checkinId: checkinData.checkinId,
      is_enabled_smk: roomInformations.length ? 1 : 0,
      is_cc_payment: 0,
      email: checkinData.checkin.guest.email,
      name: checkinData.checkin.guest.name,
      hotelName: state.basicInfo[0]?.hotelName,
      checkinDateTime: dayjs().format('YYYY-MM-DD HH:mm'),
      checkinCode: checkinResponseInfo.checkinCode,
      mypageUrl: mypageUrl(checkinData.checkinId, checkinResponseInfo.checkinCode),
      facilityGuideUrl: homePageUrl,
      hotelPhoneNumber: state.basicInfo[0]?.telephone,
      roomInformations,
      _cp: 'confirm',
    }

    if (paymentResult) {
      const reception = paymentResult
        ? {
            number: paymentResult.processResult.orderId,
            date: dayjs().format('YYYY-MM-DD HH:mm'),
            paymentMethod: paymentResult.processResult.cardBrand,
            paymentAmount: paymentResult.processResult.amount,
          }
        : {}

      Object.assign(emailData, {
        hotelId: state.hotelId,
        is_cc_payment: 1,
        reception,
        reservations: reservationsPaymentDetail,
        saleIds: reservationSaleIds,
      })
    }

    if (emailData.email) await sendMailSelfCheckin(emailData)

    const nextState: SelfCheckinCompleteState = {
      hotelId: state.hotelId,
      checkinId: checkinData.checkinId,
      basicInfo: state.basicInfo[0],
      isFrontDescGuidance: state.isFrontDescGuidance,
      roomInformation: roomInformations,
      payment: {
        paymentMethod: state.paymentInfo?.paymentMethod,
        totalAmount: state.paymentInfo?.totalAmount || 0,
        paymentAmount: paymentResult?.processResult?.amount || 0,
        totalAmountAt10Per: paymentResult?.processResult?.totalAmountAt10Per || 0,
        totalAmountAt8Per: paymentResult?.processResult?.totalAmountAt8Per || 0,
        totalTaxAt10Per: paymentResult?.processResult?.totalTaxAt10Per || 0,
        totalTaxAt8Per: paymentResult?.processResult?.totalTaxAt8Per || 0,
        receiptName: undefined,
      },
    }
    const pathname = localStorage.getItem(serialNumberStorageKey) ? '/self-checkin/issue-room-key' : '/self-checkin/complete'
    history.push({
      pathname,
      search,
      state: nextState,
    })
  }

  const goEditPage = () => {
    history.push({
      pathname: '/self-checkin/info/edit',
      search,
      state,
    })
  }

  const getBackPageUrl = async (): Promise<string> => {
    const isSelectJaLanguage = i18n.language === 'ja'
    if (state.requiredIdentify) {
      return `/self-checkin/identity-verify/${isSelectJaLanguage ? 'upload-license' : 'upload-passport'}`
    }

    if (state.paymentInfo) {
      return '/self-checkin/payment'
    }

    if (!state.isGuidanceDisplay) {
      return '/self-checkin/accommodation-info'
    }

    return '/self-checkin/checkin-customize'
  }

  const goBack = async () => {
    delete state.edit
    const backPageUrl = await getBackPageUrl()
    history.push({
      pathname: backPageUrl,
      search,
      state,
    })
  }

  const editPaymentInfo = () => {
    history.push({
      pathname: `/self-checkin/payment`,
      search,
      state,
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [search])

  const getTitle = guide => {
    if (lang === 'ja') {
      return parse(guide.jpTitle)
    }
    if (lang === 'en') {
      return parse(guide.enTitle)
    }
    if (lang === 'ko') {
      return parse(guide.koTitle)
    }
    if (lang === 'zh') {
      return parse(guide.zhTitle)
    }
  }

  const _isOtherOccupation = (): boolean => {
    if (state?.accommodationInfo?.occupation === OccupationType.Other) {
      return true
    } else {
      return false
    }
  }

  if (!state?.hotelId) {
    history.replace({ pathname: '/dashboard' })
    return <></>
  }

  return (
    <>
      <Loading isLoading={isLoading} />
      <div css={containerStyle}>
        <SelfCheckinHeader goToPreviousPage={goBack} title={t('Please confirm the contents')} />
        <div css={mainStyle}>
          <div css={containerWrapperStyle}>
            <div css={innerStyle}>
              <div css={descriptionStyle}>
                <p>{t('Representative information')}</p>
                <div css={editButtonStyle} onClick={() => goEditPage()}>
                  <img src={require('@/static/images/edit.svg')} />
                </div>
              </div>
              <div css={itemWrapperStyle}>
                {state?.from === 'upload-license' && (
                  <>
                    <div css={requiredLabelWrapperStyle}>
                      <p css={itemLabelStyle}>{t('Identification')}</p>
                      <RequireText customCss={requiredSymbolStyle} />
                    </div>
                    <div css={identificationWrapperStyle}>
                      <img src={state?.identificationFrontImage} />
                      <img src={state?.identificationBackImage} />
                    </div>
                  </>
                )}
                {state?.from === 'upload-passport' && (
                  <>
                    <div css={requiredLabelWrapperStyle}>
                      <p css={itemLabelStyle}>{t('Identification')}</p>
                      <RequireText customCss={requiredSymbolStyle} />
                    </div>
                    <div css={identificationWrapperStyle}>
                      <img src={state?.representativePassportImage} />
                    </div>
                  </>
                )}
              </div>
              <>
                <div css={itemWrapperStyle}>
                  <div css={requiredLabelWrapperStyle}>
                    <p css={itemLabelStyle}>{t('Nationality')}</p>
                    <RequireText customCss={requiredSymbolStyle} />
                  </div>
                  <p css={itemTextStyle}>{state?.accommodationInfo?.nationality === 'japanese' ? t('Japanese') : t('Non-Japanese')}</p>
                </div>
                <div css={itemWrapperStyle}>
                  <div css={requiredLabelWrapperStyle}>
                    <p css={itemLabelStyle}>{t('Full name')}</p>
                    <RequireText customCss={requiredSymbolStyle} />
                  </div>
                  <p css={itemTextStyle}>
                    {state?.accommodationInfo?.lastName} {state?.accommodationInfo?.firstName}
                  </p>
                </div>
                {state?.accommodationInfo?.nationality === 'japanese' && lang === 'ja' && (
                  <div css={itemWrapperStyle}>
                    <div css={requiredLabelWrapperStyle}>
                      <p css={itemLabelStyle}>{t('Furigana')}</p>
                      {state.requiredFuriganaInput && <RequireText customCss={requiredSymbolStyle} />}
                    </div>
                    <p css={itemTextStyle}>
                      {state?.accommodationInfo?.lastNameFurigana} {state?.accommodationInfo?.firstNameFurigana}
                    </p>
                  </div>
                )}
                <div css={itemWrapperStyle}>
                  <div css={requiredLabelWrapperStyle}>
                    <p css={itemLabelStyle}>{t('Date of birth')}</p>
                    {state.requiredBirthDateInput && <RequireText customCss={requiredSymbolStyle} />}
                  </div>
                  <p css={itemTextStyle}>{state?.accommodationInfo?.dateOfBirth}</p>
                </div>
              </>
              <div css={itemWrapperStyle}>
                <div css={requiredLabelWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Gender')}</p>
                  {state.requiredGenderInput && <RequireText customCss={requiredSymbolStyle} />}
                </div>
                <p css={itemTextStyle}>{state?.accommodationInfo?.gender === 'F' ? t('Female') : t('Male')}</p>
              </div>
              <div css={itemWrapperStyle}>
                <div css={requiredLabelWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Phone number')}</p>
                  {state.requiredTelephoneInput && <RequireText customCss={requiredSymbolStyle} />}
                </div>
                <p css={itemTextStyle}>{state?.accommodationInfo?.phoneNumber}</p>
              </div>
              <div css={itemWrapperStyle}>
                <div css={requiredLabelWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Email')}</p>
                  {state.requiredEmailInput && <RequireText customCss={requiredSymbolStyle} />}
                </div>
                <p css={itemTextStyle}>{state?.accommodationInfo?.mail || '-'}</p>
              </div>
              {!_isOtherOccupation() && (
                <div css={itemWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Occupation')}</p>
                  <p css={itemTextStyle}>
                    {state?.accommodationInfo?.occupation ? t(`${getTranslateOccupation(state?.accommodationInfo?.occupation)}`) : '-'}
                  </p>
                </div>
              )}
              {isEmployee(state?.accommodationInfo?.occupation) && (
                <div css={itemWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Company')}</p>
                  <p css={itemTextStyle}>{state?.accommodationInfo?.company ? state?.accommodationInfo?.company : '-'}</p>
                </div>
              )}
              {_isOtherOccupation() && (
                <div css={itemWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Occupation')}</p>
                  <p css={itemTextStyle}>{state?.accommodationInfo?.otherOccupation ? state?.accommodationInfo?.otherOccupation : '-'}</p>
                </div>
              )}
              {state?.accommodationInfo?.nationality === 'japanese' && (
                <>
                  <div css={itemWrapperStyle}>
                    <p css={itemLabelStyle}>{t('Post code')}</p>
                    <p css={itemTextStyle}>{state?.accommodationInfo?.postCode || '-'}</p>
                  </div>
                </>
              )}
              <div css={itemWrapperStyle}>
                <div css={requiredLabelWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Address')}</p>
                  {state.requiredAddressInput && <RequireText customCss={requiredSymbolStyle} />}
                </div>
                <p css={itemTextStyle}>{state?.accommodationInfo?.address}</p>
              </div>
              <div css={itemWrapperStyle} style={{ borderTop: '1px solid #F2F2F2', paddingTop: 32 }}>
                <div css={requiredLabelWrapperStyle}>
                  <p css={itemLabelStyle}>{t('Number of guests')}</p>
                  {state.requiredAccompanyNameInput && <RequireText customCss={requiredSymbolStyle} />}
                </div>
                <p css={itemTextStyle}>
                  {state?.accommodationInfo?.paxTotal}
                  {t(state?.accommodationInfo?.paxTotal1 > 1 ? 'Persons' : 'Person')}
                </p>
              </div>

              {!!accompanyData().length && (
                <div css={itemWrapperStyle}>
                  <div css={requiredLabelWrapperStyle}>
                    <p css={itemLabelStyle}>{t('Accompany name')}</p>
                    {state.requiredAccompanyNameInput && <RequireText customCss={requiredSymbolStyle} />}
                  </div>
                  {accompanyData().map((accompany, i) => {
                    return (
                      <p css={itemTextStyle} key={i}>
                        {accompany?.lastName} {accompany?.firstName}
                      </p>
                    )
                  })}
                </div>
              )}
            </div>
            {state?.guides && !!state?.guides?.length && (
              <div css={innerStyle}>
                <div css={descriptionStyle}>
                  <p>{t('Guidance')}</p>
                  <div css={editButtonStyle} onClick={() => goEditPage()}>
                    <img src={require('@/static/images/edit.svg')} />
                  </div>
                </div>
                {state?.guides?.map(guide => {
                  return (
                    guide.inputType !== 'none' && (
                      <div key={guide.customCheckinId} css={itemWrapperStyle}>
                        <p css={itemLabelStyle}>{getTitle(guide)}</p>
                        <p css={itemTextStyle}>{guide.selectedAnswer}</p>
                      </div>
                    )
                  )
                })}
              </div>
            )}
            {!!state?.paymentSetting?.length && state?.paymentInfo && (
              <div>
                <PaymentInfo paymentInfo={state.paymentInfo} editPaymentInfo={editPaymentInfo} />
              </div>
            )}

            <div
              style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center', gap: 16 }}
              onClick={() => setIsDeliveryConfirm(!isDeliveryConfirm)}
            >
              <input
                type="image"
                alt="checkbox"
                css={inputCheckboxStyle}
                src={require(`@/static/images/check-box${isDeliveryConfirm ? '' : '_off'}.svg`)}
              />
              <p>{t('Receive campaign information and special offers by e-mail')}</p>
            </div>
            <FooterAgreement />
          </div>
          <SelfCheckinFooter
            isDisabled={() => {
              return false
            }}
            isDisabledButton={true}
            goToNextPage={onClickCheckin}
            isNext={'submit'}
          />
        </div>
      </div>
    </>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
    color: '#272727',
  },
})

const mainStyle = css({
  padding: '14px 0 32px ',
})

const innerStyle = css({
  maxWidth: 874,
  backgroundColor: '#fff',
  margin: '0 auto 32px',
  padding: '46px 32px 32px 32px',
  borderRadius: 5,
})

const descriptionStyle = css({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  paddingBottom: 24,
  p: {
    fontSize: 21,
    lineHeight: '27px',
    letterSpacing: '1.8px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
})

const editButtonStyle = css({
  cursor: 'pointer',
  position: 'absolute',
  right: 0,
  width: 40,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  backgroundColor: '#F2F2F2',
  img: {
    width: 30,
  },
  '@media(max-width: 1080px)': {
    width: 46,
    height: 46,
    img: {
      width: 33,
    },
  },
})

const itemWrapperStyle = css({
  width: '100%',
  padding: '0 0 28px',
  '&:last-child': {
    padding: 0,
  },
})

const itemLabelStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  lineHeight: '27px',
  marginBottom: 7,
  letterSpacing: '1.8px',
  '-webkit-font-smoothing': 'antialiased',
})

const itemTextStyle = css({
  color: '#272727',
  fontSize: 18,
  lineHeight: '27px',
  letterSpacing: '1.8px',
})

const identificationWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-start',
  img: {
    width: 256,
    height: 158,
    borderRadius: 10,
    '&:nth-of-type(1)': {
      margin: '0 20px 0 0',
    },
  },
})

const containerWrapperStyle = css({
  margin: '93px 0',
})

const requiredSymbolStyle = css({
  marginLeft: 4,
  marginBottom: 12,
  color: '#272727',
  fontSize: 14,
  fontWeight: 'bold',
  letterSpacing: '1.4px',
  '@media(max-width: 1080px)': {
    fontSize: 21,
  },
})
const requiredLabelWrapperStyle = css({
  display: 'flex',
})

const inputCheckboxStyle = css({
  width: 25,
  height: 25,
})
