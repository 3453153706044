import React, { useEffect } from 'react'
import { css } from '@emotion/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { UpdateGuestRoomStatusForCleaningManagerRequestType } from '@/apis/aipass'
import { RoomType, ReservationType } from '@/constants/cleaning-manager'
import dayjs from 'dayjs'
import { hasCleaningMemo, isCleaningMemo } from '@/models/reservation/reservation-note'

interface ShareProps {
  onSave: () => void
  useFormReturn: UseFormReturn<Required<UpdateGuestRoomStatusForCleaningManagerRequestType>>
  isUpdated: boolean
  room: RoomType
}
const queryString = require('query-string')

export const Share: React.FC<ShareProps> = ({ onSave, useFormReturn, room, isUpdated }) => {
  const history = useHistory()
  const { t } = useTranslation()

  const {
    formState: { isDirty },
  } = useFormReturn

  const queryParams = queryString.parse(location.search)
  const selectedDate = queryParams.dateOf ? dayjs(queryParams.dateOf) : dayjs()

  const computedChildPaxText = (roomPax: ReservationType['roomPax']): string => {
    return (
      [
        roomPax.childA ? t('cleaningManager.ChildA', { pax: roomPax.childA }) : undefined,
        roomPax.childB ? t('cleaningManager.ChildB', { pax: roomPax.childB }) : undefined,
        roomPax.childC ? t('cleaningManager.ChildC', { pax: roomPax.childC }) : undefined,
        roomPax.childD ? t('cleaningManager.ChildD', { pax: roomPax.childD }) : undefined,
        roomPax.childE ? t('cleaningManager.ChildE', { pax: roomPax.childE }) : undefined,
        roomPax.childF ? t('cleaningManager.ChildF', { pax: roomPax.childF }) : undefined,
        roomPax.childOther ? t('cleaningManager.ChildOther', { pax: roomPax.childOther }) : undefined,
      ]
        .filter(t => !!t)
        .join(t('cleaningManager.PaxSeparate')) || '-'
    )
  }

  const computedAdultPaxText = (roomPax: ReservationType['roomPax']): string => {
    return (
      [
        roomPax.male ? t('cleaningManager.Male', { pax: roomPax.male }) : undefined,
        roomPax.female ? t('cleaningManager.Female', { pax: roomPax.female }) : undefined,
      ]
        .filter(t => !!t)
        .join(t('cleaningManager.PaxSeparate')) || '-'
    )
  }

  useEffect(() => {
    if (isUpdated || !isDirty) {
      return
    }

    history.block(() => {
      onSave()
      return false
    })
  }, [history])

  const roomCheckinAt = room?.nextReservation?.roomCheckinAt ? dayjs(room?.nextReservation?.roomCheckinAt) : null

  return (
    <div style={{ padding: '32px 24px' }}>
      <div style={{ marginBottom: 24 }}>
        <div css={[inlineContent, shareContentStyle]}>
          <p className="title">{t('cleaningManager.Cleaning Instructions')}</p>
          {(room?.adminComment ?? '').length > 0 && (
            <img src={require('@/static/images/cleaning_manager/icon_instruction.svg')} alt="" style={{ paddingLeft: '4px' }} />
          )}
        </div>
        <p css={adminCommentTextStyle}>{room?.adminComment || '-'}</p>
      </div>

      <div css={[shareContentStyle, { paddingBottom: 18 }]}>
        <p className="title">{t('cleaningManager.Guest Information')}</p>
      </div>
      <div css={inlineContent} style={{ alignItems: 'flex-start' }}>
        <img src={require('@/static/images/cleaning_manager/icon_date.svg')} alt="" />
        <div css={reservationDetailStyle}>
          <div className="title" style={{ paddingBottom: '4px' }}>
            {roomCheckinAt
              ? `${roomCheckinAt.format(t('cleaningManager.MM-DD(ddd)'))} ${t('cleaningManager.Night', {
                  nights: room?.nextReservation?.nights,
                })} ${` - ${selectedDate.startOf('day').diff(roomCheckinAt.startOf('day'), 'day') + 1}泊目`}`
              : '-'}
          </div>
          <div style={{ fontSize: 12, color: '#676767' }}>
            {roomCheckinAt ? `${t('cleaningManager.Estimated Time of Arrival')} : ${roomCheckinAt.format('HH:mm')}` : '-'}
          </div>
        </div>
      </div>

      <div css={inlineContent} style={{ alignItems: 'flex-start', paddingTop: 16 }}>
        <img src={require('@/static/images/reservation-note/icon_cleaning.svg')} alt="" />
        <div css={reservationDetailStyle}>
          <div css={shareContentStyle}>
            <p className="title">{hasCleaningMemo(room?.nextReservation?.notes) ? t('cleaningManager.Cleaning Remarks') : '-'}</p>
            {hasCleaningMemo(room?.nextReservation?.notes) && (
              <img src={require('@/static/images/cleaning_manager/icon_instruction.svg')} alt="" style={{ paddingLeft: '4px' }} />
            )}
          </div>
          <p style={{ lineHeight: '1.5', fontSize: 14 }}>
            {room?.nextReservation?.notes.find(({ category }) => isCleaningMemo(category))?.memo ?? '-'}
          </p>
        </div>
      </div>

      <div css={inlineContent} style={{ alignItems: 'flex-start', paddingTop: 16 }}>
        <img src={require('@/static/images/cleaning_manager/icon_people.svg')} alt="" />
        <div css={reservationDetailStyle}>
          <div css={shareContentStyle} style={{ paddingBottom: '4px', lineHeight: 1 }}>
            <p className="title">
              {room?.nextReservation?.roomPax ? t('cleaningManager.Persons', { pax: room?.nextReservation?.roomPax.total }) : '-'}
            </p>
          </div>
          <div>{room?.nextReservation?.roomPax ? `${t('Adult')} : ${computedAdultPaxText(room.nextReservation.roomPax)}` : '-'}</div>
          <div>{room?.nextReservation?.roomPax ? `${t('Child')} : ${computedChildPaxText(room.nextReservation.roomPax)}` : '-'}</div>
        </div>
      </div>
    </div>
  )
}

const inlineContent = css({
  display: 'flex',
  alignItems: 'center',
})

const shareContentStyle = css({
  '.title': {
    fontSize: 14,
    fontWeight: 'bold',
  },
})

const reservationDetailStyle = css({
  paddingLeft: '16px',
})

const adminCommentTextStyle = css({
  whiteSpace: 'pre-line',
  fontSize: 14,
  lineHeight: '22px',
  letterSpacing: '0.7px',
  color: '#272727',
})
