import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import useBrowserContextCommunication from 'react-window-communication-hook'
import { signin, qrSignin, parseToken } from '@/apis/auth0'
import { AuthContext } from '@/contexts/auth'
import { Button, ButtonType } from '@/components/atoms/button'
import { SigninHeader } from '@/components/pages/signin/signin-header'
import { SigninContainer } from '@/components/pages/signin/signin-container'
import { SigninError } from '@/components/pages/signin/signin-error'
import { Copyright } from '@/components/molecules/copyright'
import { env } from '@/libs/env'
import { useHistory } from 'react-router-dom'

export const Signin: React.FC<{}> = () => {
  const { auth, setAuth } = useContext<any>(AuthContext)
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isFormError, setIsFormError] = useState<boolean>(false)
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [communicationState, postMessage] = useBrowserContextCommunication('')
  const { t } = useTranslation()
  const idAipassUrl = env('ID_AIPASS_URL')
  const operatingCompanyUrl = env('OPERATING_COMPANY_URL')

  const _signIn = async () => {
    setIsBusy(true)
    try {
      if (window.location.pathname === '/qr-checkin') {
        await qrSignin(email, password)
      } else {
        await signin(email, password)
      }
    } catch (error) {
      setIsFormError(true)
    } finally {
      setIsBusy(false)
    }
  }

  const _parseToken = () => {
    parseToken()
      .then(result => {
        setAuth(result)
        history.replace({ pathname: window.location.pathname })
      })
      .catch(error => setIsFormError(true))
      .finally()
  }

  const isValidEmail = useMemo(() => isFormError && (email === '' || !email.match(/[\w\-.]+@[\w\-.]+\.[a-zA-Z]+/)), [isFormError, email])
  const isValidPassword = useMemo(() => isFormError && password === '', [isFormError, password])

  useEffect(() => {
    // Reload the management screen of all tabs when logging in (to avoid duplicate logins)
    if (communicationState.lastMessage === 'login') {
      window.location.reload()
    }
  }, [postMessage])

  useEffect(() => {
    const hash = window.location.hash
    if (auth || !hash) return
    _parseToken()
  }, [window, auth])

  return (
    <>
      {!auth && (
        <div css={signinContainerStyle}>
          <div css={signinFormContainerStyle}>
            <SigninHeader />
            <SigninContainer
              email={email}
              password={password}
              emailOnChange={setEmail}
              passwordOnChange={setPassword}
              validEmail={isValidEmail}
              validPassword={isValidPassword}
            />
            {isFormError ? <SigninError /> : null}
            <Button
              buttonType={isBusy ? ButtonType.NonStyle : ButtonType.Primary}
              width={275}
              height={38}
              marginTop={22}
              marginLeft={'auto'}
              marginRight={'auto'}
              onClick={() => _signIn()}
            >
              {t('Login')}
            </Button>
          </div>
          <div css={{ marginBottom: 32 }}>
            <div css={signinLinkContainerStyle}>
              <a href={operatingCompanyUrl} css={signinLinkStyle} target="_blank" rel="noreferrer noreferrer">
                {t('Operating company')}
              </a>
              <a href={`${idAipassUrl}/about-terms-of-use-hotel`} css={signinLinkStyle} target="_blank" rel="noreferrer noreferrer">
                {t('Terms of service')}
              </a>
              <a href={`${idAipassUrl}/about-privacy-policy`} css={lastLinkStyle} target="_blank" rel="noreferrer noreferrer">
                {t('Privacy policy')}
              </a>
            </div>
            <Copyright style={{ position: 'relative', marginTop: 0, bottom: 0 }} />
          </div>
        </div>
      )}
    </>
  )
}

const signinContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
  backgroundImage: `url(${require('@/static/images/icon/background-pc.png')})`,
  backgroundSize: '100% 100%',
  minHeight: '100vh',
})

const signinFormContainerStyle = css({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: 32,
})

const signinLinkContainerStyle = css({
  marginTop: 32,
  marginBottom: 32,
})

const signinLinkStyle = css({
  fontSize: 12,
  letterSpacing: 2.4,
  color: '#FF5500',
  marginRight: 24,
  '&:hover': {
    opacity: 0.8,
  },
})

const lastLinkStyle = css(signinLinkStyle, {
  marginRight: 0,
})
