import React, { useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

import { nationality } from '@/libs/nationality'
import { Link } from 'react-router-dom'
import { checkinType } from '@/models/checkin'
import { customerOccupation } from '@/libs/occupations'

type PersonInfoProps = {
  checkin: checkinType
  blockTitle?: string
  onClickAddGuestInfo?: () => void
}

export const PersonInfo: React.FC<PersonInfoProps> = ({ checkin, blockTitle, onClickAddGuestInfo = () => {} }) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  let displayAddress = ''
  if (checkin.guest.address) {
    displayAddress = checkin.guest.address
  } else {
    displayAddress = '-'
  }

  const agreementText = () => {
    if (checkin?.deliveryAgreementStatus === 'unconfirmed') {
      return t('Unconfirmed')
    }

    if (checkin?.deliveryAgreementStatus === 'deliverable') {
      return t('Deliverable')
    }

    if (checkin?.deliveryAgreementStatus === 'nonDeliverable') {
      return t('Non deliverable')
    }

    return '-'
  }

  return (
    <div>
      <div
        css={{
          padding: '0 32px',
          borderBottom: '1px solid #f5f5f5',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 48,
          '@media(max-width: 1080px)': {
            paddingLeft: 16,
          },
        }}
      >
        <div css={{ fontSize: 16, fontWeight: 'bold', color: '#272727' }}>{blockTitle || t('Check-in information')}</div>
        <div css={{ display: 'flex' }}>
          <Link to={{ pathname: '/tablet-register', search: `?checkinId=${checkin.id}` }} target="_blank">
            <img src={require('@/static/images/edit_window.svg')} />
          </Link>
          <div css={[editableIcon, { marginLeft: 16, cursor: 'pointer' }]} onClick={onClickAddGuestInfo}></div>
        </div>
      </div>
      <div css={personInfoWrapper}>
        <>
          <div css={topListSectionStyle}>
            <div css={userBasicInfo}>
              <img css={userImageStyle} src={checkin.guest.picture ? checkin.guest.picture : require('@/static/images/user.svg')} />
              <div css={exceptIconStyle}>
                <p css={kanaStyle}>{checkin.guest.nameKana ? checkin.guest.nameKana : '-'}</p>
                <p css={userNameStyle}>{checkin.guest.name ? checkin.guest.name : '-'}</p>
                <ul css={userDetailsWrapperStyle}>
                  <li>{checkin.guest.gender ? (checkin.guest.gender === 'M' ? t('Male') : t('Female')) : '-'} /</li>
                  <li>ID : {checkin.checkinCode}</li>
                </ul>
              </div>
            </div>
            {!!checkin.guest.name && (
              <div css={itemWrapperStyle}>
                <img src={require('@/static/images/birthday.svg')} />
                <p>{checkin.guest.birthDate ? dayjs(checkin.guest.birthDate).format('YYYY/MM/DD') : '-'}</p>
              </div>
            )}
            <div css={itemWrapperStyle}>
              <img src={require('@/static/images/nationality.svg')} />
              <p>{checkin.guest.nationality ? (checkin.guest.nationality === 'JPN' ? t('Japanese') : t('Non-Japanese')) : '-'} /</p>
              <p>
                {checkin.guest.nationality && nationality[checkin.guest.nationality]?.ja ? nationality[checkin.guest.nationality]?.ja : '-'}
              </p>
            </div>
            <div css={itemWrapperStyle}>
              <img src={require('@/static/images/access.svg')} />
              <p>{displayAddress}</p>
            </div>
          </div>
        </>
        <div css={topListSectionMiddleStyle}>
          <div css={itemWrapperStyle}>
            <img src={require('@/static/images/phone.svg')} />
            <p>{checkin.guest.telephone?.replace(/(\d{3})(\d{3})(\d{4})/, '$1$2$3')}</p>
          </div>
          <div css={itemWrapperStyle}>
            <img src={require('@/static/images/mail.svg')} />
            <p>{checkin.guest.email ? checkin.guest.email : '-'}</p>
          </div>
          <div css={itemWrapperStyle}>
            <img src={require('@/static/images/business.svg')} />
            <p>{customerOccupation(checkin.guest)}</p>
          </div>
          <div css={itemWrapperStyle}>
            <img src={require('@/static/images/identification.svg')} />
            <p css={identificationStyle} onClick={onClickAddGuestInfo}>
              {t('Identification card')}
            </p>
          </div>
        </div>
        <div css={topListSectionEndStyle}>
          <div css={itemWrapperStyle}>
            <img src={require('@/static/images/date.svg')} />
            <p>{checkin.checkinTime ? dayjs(checkin.checkinTime).format('YYYY/MM/DD HH:mm') : '-'}</p>
          </div>
          <div css={itemWrapperStyle}>
            <img src={require('@/static/images/numberofpeople.svg')} />
            <div css={accompanyStyle}>
              <p css={accompanyNumberStyle}>
                {checkin.accompanyList.length + 1}
                {t('Name')}
                <span onClick={() => setIsOpen(!isOpen)}>
                  {!isOpen ? (
                    <img src={require('@/static/images/arrow_down_gray.svg')} />
                  ) : (
                    <img src={require('@/static/images/arrow_up_gray.svg')} />
                  )}
                </span>
              </p>
            </div>
          </div>
          {isOpen &&
            (checkin.accompanyList.length === 0 ? (
              <div css={accompanyListStyle}>{checkin.guest.name}</div>
            ) : (
              <div css={accompanyListStyle}>{checkin.guest.name + ',' + checkin.accompanyList.map(v => v.name).join(',')}</div>
            ))}
          <div css={itemWrapperStyle}>
            <img src={require('@/static/images/previous-night.svg')} />
            <p>
              {t('Accommodation before and after')} : {checkin.previousPlaceToStay ? checkin.previousPlaceToStay : '-'} /{' '}
              {checkin.nextPlaceToStay ? checkin.nextPlaceToStay : '-'}
            </p>
          </div>
          <div css={itemWrapperStyle}>
            <img src={require('@/static/images/delivery.svg')} />
            <p>{agreementText()}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const editableIcon = css({
  textAlign: 'right',
  display: 'block',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundColor: '#FFF',
  borderRadius: '50px',
  width: '30px',
  height: '30px',
  backgroundImage: `url(${require('@/static/images/edit.svg')})`,
  border: 'solid 1px #cccccc',
})

const textStyle = css({
  color: '#272727',
})

const topListSectionStyle = css({
  backgroundColor: '#FFF',
  margin: '24px 0',
  padding: '0 32px',
  borderRight: '1px solid #F2F2F2',
  flex: 1,
  '@media(max-width: 1080px)': {
    padding: '0 16px',
  },
})

const topListSectionMiddleStyle = css({
  backgroundColor: '#FFF',
  margin: '24px 0',
  padding: '0 32px',
  borderRight: '1px solid #F2F2F2',
  flex: 1,
  '@media(max-width: 1080px)': {
    padding: '0 16px',
  },
})

const topListSectionEndStyle = css({
  backgroundColor: '#FFF',
  margin: '24px 0',
  padding: '0 32px',
  flex: 1,
  overflow: 'hidden scroll',
  '@media(max-width: 1080px)': {
    padding: '0 16px',
  },
})

const itemWrapperStyle = css(textStyle, {
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: 12,
  img: {
    marginRight: 16,
    width: 18,
  },
  p: {
    fontSize: 14,
    lineHeight: '20px',
  },
})

const userImageStyle = css({
  margin: '0 24px 0 0',
  display: 'block',
  width: 58,
  height: 58,
  borderRadius: '50%',
})

const userNameStyle = css({
  padding: '8px 0',
  textAlign: 'left',
  color: '#272727',
  fontWeight: 'bold',
  fontSize: 18,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const userDetailsWrapperStyle = css({
  display: 'flex',
  justifyContent: 'center',
  fontSize: 12,
  color: '#676767',
  'justify-content': 'flex-start',
})

const userBasicInfo = css({
  display: 'flex',
  paddingBottom: 20,
})

const exceptIconStyle = css({
  width: '100%',
})

const kanaStyle = css({
  fontSize: 12,
})

const personInfoWrapper = css({
  display: 'flex',
  width: '100%',
  'div:not:last-child': {
    borderLeft: '1px solid #F2F2F2',
  },
})

const identificationStyle = css({
  textDecoration: 'underline',
  ':hover': {
    cursor: 'pointer',
  },
})

const accompanyNumberStyle = css({
  display: 'flex',
  img: {
    marginLeft: 5,
    width: 10,
  },
})

const accompanyStyle = css({
  display: 'flex',
  flexDirection: 'column',
})

const accompanyListStyle = css({
  marginTop: -10,
  marginBottom: 12,
  marginLeft: 34,
  fontSize: 14,
  color: '#676767',
})
