import React, { useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'

import { UsageDetails } from '@/components/organisms/sales-manager/_sales/usage-details'
import { Discount } from '@/components/organisms/sales-manager/_sales/discount'
import { Adjustment } from '@/components/organisms/sales-manager/_sales/adjustment'
import { fetchSmaregiTransactionDetail } from '@/apis/aipass'
import { TransactionType } from '@/models/smaregi/transaction'
import { LoadingContent } from '@/components/molecules/loading-content'

type Props = {
  transactionId: string
  onClose: (isUpdated: boolean) => void
}

export const TransactionDetailModal: React.FC<Props> = ({ transactionId, onClose }) => {
  const { t } = useTranslation()
  const [transaction, setTransaction] = useState<TransactionType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const initializeFetching = () => {
    if (!transactionId) {
      return
    }
    setIsLoading(true)
    fetchSmaregiTransactionDetail(transactionId)
      .then((transaction: TransactionType) => {
        setTransaction(transaction)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onAdjustSales = () => {
    initializeFetching()
  }

  useEffect(() => {
    initializeFetching()
  }, [transactionId])

  return (
    <Modal customCss={modalStyle}>
      <LoadingContent isLoading={isLoading} />

      <ModalHeader customCss={modalHeaderStyle}>
        <div>{t('Transaction')}</div>
        <div css={subTitleStyle}>ID : {transactionId}</div>
      </ModalHeader>
      <ModalBody customCss={modalBodyStyle}>
        <div>
          <UsageDetails transaction={transaction} />
        </div>
        <div css={bodyRowStyle}>
          <Discount transaction={transaction} />
          <Adjustment transaction={transaction} onAdjustSales={onAdjustSales} setIsLoading={setIsLoading} onClose={() => onClose(true)} />
        </div>
      </ModalBody>
      <ModalFooter customCss={modalFooterStyle}>
        <Button buttonType={3} height="36px" width="110px" marginRight={16} onClick={() => onClose(false)}>
          {t('Back')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = css({
  top: '5%',
  left: '5%',
  right: '5%',
  height: 'auto',
  maxHeight: '90%',
  width: '90%',
})

const bodyRowStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 16,
  '> div': {
    marginRight: 16,
  },
  '> div:last-child': {
    marginRight: 0,
  },
})

const modalHeaderStyle = css({
  textAlign: 'center',
  color: '#272727',
  display: 'flex',
  justifyContent: 'space-between',
  height: 60,
})

const modalBodyStyle = css({
  padding: '20px 32px 16px 32px',
  height: 'calc(100% - 2px)',
})

const modalFooterStyle = css({
  height: '60px',
  display: 'flex',
  justifyContent: 'end',
})

const subTitleStyle = css({
  fontSize: 14,
  color: '#676767',
  letterSpacing: 1.4,
})
