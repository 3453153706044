import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

// apis
import {
  fetchSalesPayment,
  adjustSales,
  fetchSalesSubject,
  fetchSalesDiscount,
  fetchReservationDiscount,
  fetchSalesReservation,
  fetchSalesAccountsReceivable,
} from '@/apis/aipass'

//components
import { sectionStyle } from '@/components/pages/dashboard/_index/style'
import { UsageDetails } from '@/components/organisms/customer/sales-manager/_sales/usage-details'
import { Discount } from '@/components/organisms/reservation/_sales/discount'
import { Adjustment } from '@/components/organisms/customer/sales-manager/_sales/adjustment'
import { LoadingFull } from '@/components/molecules/loading-full'

// models
import {
  DiscountSettingType,
  EditDiscountType,
  EditSalesType,
  PaymentSettingType,
  SalesDiscountType,
  SalesType,
  SubjectSettingType,
} from '@/models/sales'
import { ReservationType } from '@/models/reservation'
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'

// libs
import { reduceTaxAmount } from '@/libs/calculator/tax'

type SalesProps = {
  reservationId: string
  customerId: string
  customerLink?: any
  customerName: string
  isEdit: boolean
  isEditReservationStatus: boolean
  setHasConfirmedSales: (v: boolean) => void
  setHasConfirmedDiscount: (v: boolean) => void
  currentTabReservationId: string
  reservation: ReservationType
  onUpdateSales: () => void
  setIsReceiptModalOpen: (v: boolean) => void
  setIsSplitReceiptModalOpen: (v: boolean) => void
  onChangeUsageDetails?: (value: any) => void
  onChangeDiscounts?: (value: any) => void
}

export const Sales: React.FC<SalesProps> = ({
  reservationId,
  customerId,
  customerName,
  customerLink,
  isEdit,
  isEditReservationStatus,
  setHasConfirmedSales,
  setHasConfirmedDiscount,
  currentTabReservationId,
  reservation,
  onUpdateSales,
  setIsReceiptModalOpen,
  setIsSplitReceiptModalOpen,
  onChangeUsageDetails,
  onChangeDiscounts,
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Usage details
  const [usageDetails, setUsageDetails] = useState<SalesType[]>([])
  const [editUsageDetails, setEditUsageDetails] = useState<EditSalesType[]>([])
  // Original usage data (for comparison when updating)
  const [originUsageDetails, setOriginUsageDetails] = useState<SalesType[]>([])

  // Discount item
  const [discounts, setDiscounts] = useState<SalesDiscountType[]>([])
  const [editDiscounts, setEditDiscounts] = useState<EditDiscountType[]>([])
  // Original discount item data (for comparison when updating)
  const [originDiscounts, setOriginDiscounts] = useState<SalesDiscountType[]>([])

  const [confirmedDay, setConfirmedDay] = useState<string>('')
  const [useCheckinDay, setUserCheckinDay] = useState<string>('')

  const [salesSubjects, setSalesSubjects] = useState<SubjectSettingType[]>([])
  const [salesPayments, setSalesPayments] = useState<PaymentSettingType[]>([])
  const [salesDiscounts, setSalesDiscounts] = useState<DiscountSettingType[]>([])

  // Only payment methods included in the subject (eligible payment)
  const [salesDiscountPayments, setSalesDiscountPayments] = useState<PaymentSettingType[]>([])

  // total sales
  const [totalSalesPrice, setTotalSalesPrice] = useState<number>(0)
  // consumption tax
  const [totalTax, setTotalTax] = useState<number>(0)
  // Receipt total
  const [totalSalesPaymentPrice, setTotalSalesPaymentPrice] = useState<number>(0)
  // accounts receivable
  const [totalUnpaid, setTotalUnpaid] = useState<number>(0)
  // Total by eligible payment included in the discount
  const [totalList, setTotalList] = useState<{ id: string; name: string; totalDiscount: string; companyId: any }[]>([])
  // Total deposits by payment method (amount including discounts)
  const [totalSalesPayments, setTotalSalesPayments] = useState<{ id: string; name: string; totalDiscount: number; companyId: any }[]>([])
  // discount
  const [totalSalesDiscountPrice, setTotalSalesDiscountPrice] = useState<number>(0)
  // Initial value of accounts receivable
  const initialAccountsReceivableSettings: AccountsReceivableSettingType[] = [
    {
      closingDate: 0,
      channelCode: '',
      channelName: '',
      code: '',
      id: '',
      name: '',
    },
  ]
  //Accounts receivable
  const [accountsReceivableSettings, setAccountsReceivableSettings] =
    useState<AccountsReceivableSettingType[]>(initialAccountsReceivableSettings)

  const [salesAdvanceCompanyIds, setSalesAdvanceCompanyIds] = useState<any[]>([])

  const [salesDiscountPaymentsGroupBySalesDate, setSalesDiscountPaymentsGroupBySalesDate] = useState<any[]>([])

  // Total Deposits by Payment Method Add together the deposit amounts for matching payment methods in usageDetails
  const groupBySalesPayments = usageDetails?.reduce(
    (result: { id: string; name: string; totalDiscount: number; companyId: any }[], current) => {
      const element = result.find(value => value.id === current.salesPaymentId && value.companyId == current.companyId)
      if (element) {
        // At some point (manipulating initial data)
        element.totalDiscount += Number(current.salesPaymentPrice)
      } else {
        // When there is none (create new initial data)
        result.push({
          id: current.salesPaymentId,
          name: current.salesPaymentName,
          totalDiscount: Number(current.salesPaymentPrice),
          companyId: current.companyId,
        })
      }
      return result
    },
    [],
  )

  // line item
  const _fetchSalesReservation = async () => {
    await fetchSalesReservation(reservationId)
      .then(res => {
        res?.sales.map(sales => {
          sales.reservationId = reservationId
        })
        setUsageDetails(res?.sales)
        setOriginUsageDetails(res?.sales.map(value => ({ ...value })))
        setConfirmedDay(res?.confirmedDay)
        setUserCheckinDay(dayjs(res?.userCheckinDay).format('YYYY-MM-DD'))
        // Does it contain a usage statement with a confirmed date?
        setHasConfirmedSales(res?.sales.some(value => value.salesDate <= res?.confirmedDay))
        const companyIds = res?.sales
          .filter(item => item.companyId)
          .map(item => item.companyId)
          .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), [])
        setSalesAdvanceCompanyIds(companyIds)
        setSalesDiscountPaymentsGroupBySalesDate(res?.salesGroupBySalesDate)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  // 割引
  const _fetchReservationDiscount = async () => {
    await fetchReservationDiscount(reservationId)
      .then(res => {
        setTotalList(res?.totalList)
        setDiscounts(res?.sales)
        setOriginDiscounts(res?.sales.map(value => ({ ...value })))
        // Contains discounts with confirmed dates
        setHasConfirmedDiscount(res?.sales.some(value => value.salesDate <= res?.confirmedDay))
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  // Subject setting
  const _fetchSalesSubject = async () => {
    await fetchSalesSubject()
      .then(res => {
        setSalesSubjects(res?.salesSubjects || [])
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  // method of payment
  const _fetchSalesPayment = async () => {
    await fetchSalesPayment()
      .then(res => {
        if (!res?.salesPayment) {
          console.warn('salesPayment is empty')

          setSalesPayments([])
          setSalesDiscountPayments([])
          return
        }

        setSalesPayments(res?.salesPayment)
        // Payment eligible for discount defaults to "-"
        setSalesDiscountPayments([res?.salesPayment[0]])
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  // Discount item setting
  const _fetchSalesDiscount = async () => {
    await fetchSalesDiscount()
      .then(res => {
        setSalesDiscounts(res?.salesDiscount)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
  }

  const onAdjustSales = () => {
    _fetchSalesReservation()
  }

  useEffect(() => {
    _fetchSalesSubject()
    _fetchSalesPayment()
    _fetchSalesDiscount()
    _fetchSalesAccountsReceivable()
  }, [])

  const _fetchSalesAccountsReceivable = async () => {
    setIsLoading(true)
    await fetchSalesAccountsReceivable()
      .then(res => {
        setAccountsReceivableSettings(res?.accountsReceivables)
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })
    setIsLoading(false)
  }

  // When a statement's payment method is updated, update the eligible payment
  useLayoutEffect(() => {
    const newSalesDiscountPayments: PaymentSettingType[] = [salesDiscountPayments[0]]
    salesPayments?.filter(salesPayment => {
      totalSalesPayments.filter(totalSalesPayment => {
        if (totalSalesPayment.id === salesPayment.id) {
          newSalesDiscountPayments.push(salesPayment)
        }
      })
    })
    // Delete if duplicate
    let deleteDuplication: PaymentSettingType[] = []
    if (newSalesDiscountPayments[0] !== undefined) {
      deleteDuplication = newSalesDiscountPayments?.filter((element, index, self) => self.findIndex(e => e.id === element.id) === index)
    } else {
      deleteDuplication = newSalesDiscountPayments
    }
    setSalesDiscountPayments(deleteDuplication)
    const discountCompanyIds = totalSalesPayments.filter(v => v.companyId).map(v => v.companyId)
    setSalesAdvanceCompanyIds(
      [...(salesAdvanceCompanyIds || []), ...(discountCompanyIds || [])].filter((value, index, self) => self.indexOf(value) === index),
    )
  }, [totalSalesPayments, salesPayments])

  useEffect(() => {
    if (reservationId) {
      _fetchSalesReservation()
    }
  }, [isEdit, reservationId, isEditReservationStatus])

  useEffect(() => {
    if (reservationId) {
      _fetchReservationDiscount()
    }
  }, [usageDetails, reservationId])

  useEffect(() => {
    if (usageDetails) {
      // total sales
      const totalPrice = usageDetails?.reduce((p, usageDetail) => p + Number(usageDetail.salesPrice), 0)
      setTotalSalesPrice(totalPrice)

      // consumption tax
      setTotalTax(reduceTaxAmount(usageDetails))
      // discount
      const totalDiscount = totalList?.reduce((p, totalList) => p + Number(totalList.totalDiscount), 0)
      setTotalSalesDiscountPrice(totalDiscount)

      // Total deposits by payment method minus the discount amount (totalDeposits = total discounts (-totalDiscount) + total deposits (totalDiscount))
      // Replace discount totals with negative notation
      const minusTotalList: { id: string; name: string; totalDiscount: number; companyId: any }[] = totalList?.map(v => {
        return { id: v.id, name: v.name, totalDiscount: Number('-' + v.totalDiscount), companyId: v.companyId }
      })

      const sumArrayElements = groupBySalesPayments.concat(minusTotalList)

      // Add the total discount (replace with negative notation) and the total amount by payment method
      const totalDeposits: { id: string; name: string; totalDiscount: number; companyId: any }[] = sumArrayElements?.reduce(
        (result: { id: string; name: string; totalDiscount: number; companyId: any }[], current) => {
          const element = result.find(value => value?.id === current?.id && value?.companyId == current?.companyId)
          if (element) {
            // At some point (manipulating initial data)
            element.totalDiscount += current.totalDiscount
            // 0 if negative
            if (element.totalDiscount < 0) {
              element.totalDiscount = 0
            }
          } else if (current) {
            // When there is none (create new initial data)
            result.push({
              id: current.id,
              name: current.name,
              totalDiscount: current.totalDiscount < 0 ? 0 : current.totalDiscount,
              companyId: current.companyId,
            })
          }
          return result
        },
        [],
      )

      setTotalSalesPayments(totalDeposits)

      // Sum of total deposits by payment method minus discounts
      const totalAmount = totalDeposits?.reduce((p, totalDeposit) => p + Number(totalDeposit.totalDiscount), 0)
      // Total deposits (sum of total deposits by payment method minus discounts + discounts)
      const totalSalesPaymentPrice = totalAmount + totalDiscount
      setTotalSalesPaymentPrice(totalSalesPaymentPrice)

      // accounts receivable
      setTotalUnpaid(usageDetails.reduce((p, usageDetail) => p + Number(usageDetail.unpaid), 0))

      // Accounts Receivable minus Discount Accounts Receivable = Total Sales - Total Receipts
      setTotalUnpaid(totalPrice - totalSalesPaymentPrice)
    }
  }, [totalList, editUsageDetails, confirmedDay])

  // Get the array in which the elements with the same subjectCode are deleted for subject display in salesSubjects
  const salesSubjectsWithoutSameSubjectCode = (salesSubjects || []).reduce(
    (prev: SubjectSettingType[] | [], current: SubjectSettingType) => {
      if (prev[prev.length - 1]?.subjectCode === current?.subjectCode && current?.subSubjectCode !== '-') {
        return prev
      } else if (Array.isArray(prev) && prev.length === 0) {
        return [current]
      } else {
        return [...prev, current]
      }
    },
    [],
  )

  useEffect(() => {
    onChangeDiscounts && onChangeDiscounts(discounts)
  }, [discounts])

  useEffect(() => {
    onChangeUsageDetails && onChangeUsageDetails(usageDetails)
  }, [usageDetails])

  return (
    <div css={mixedSectionStyle}>
      <UsageDetails
        salesPayments={salesPayments}
        salesSubjects={salesSubjects}
        totalUnpaid={totalUnpaid}
        setIsLoading={setIsLoading}
        originUsageDetails={originUsageDetails}
        usageDetails={usageDetails}
        setUsageDetails={setUsageDetails}
        editUsageDetails={editUsageDetails}
        setEditUsageDetails={setEditUsageDetails}
        confirmedDay={confirmedDay}
        useCheckinDay={useCheckinDay}
        fetchSales={_fetchSalesReservation}
        reservations={[reservation]}
        accountsReceivableSettings={accountsReceivableSettings}
        salesSubjectsWithoutSameSubjectCode={salesSubjectsWithoutSameSubjectCode}
        currentTabReservationId={currentTabReservationId}
        onUpdateUsageDetails={onUpdateSales}
        receiptProps={{ customerId, customerName, customerLink, setIsReceiptModalOpen, setIsSplitReceiptModalOpen }}
      />
      <div css={sectionBottomStyle}>
        <Discount
          reservationId={reservationId}
          salesDiscounts={salesDiscounts}
          salesDiscountPayments={salesDiscountPayments}
          setIsLoading={setIsLoading}
          discounts={discounts}
          originDiscounts={originDiscounts}
          setDiscounts={setDiscounts}
          editDiscounts={editDiscounts}
          setEditDiscounts={setEditDiscounts}
          confirmedDay={confirmedDay}
          useCheckinDay={useCheckinDay}
          accountsReceivableSettings={accountsReceivableSettings}
          reservation={reservation}
          fetchReservationDiscount={_fetchReservationDiscount}
          salesAdvanceCompanyIds={salesAdvanceCompanyIds}
          salesDiscountPaymentsGroupBySalesDate={salesDiscountPaymentsGroupBySalesDate}
          onUpdateDiscount={onUpdateSales}
        />
        <Adjustment
          totalSalesPrice={totalSalesPrice}
          totalTax={totalTax}
          totalSalesPaymentPrice={totalSalesPaymentPrice}
          totalSalesDiscountPrice={totalSalesDiscountPrice}
          totalUnpaid={totalUnpaid}
          onAdjustSales={onAdjustSales}
          totalSalesPayments={totalSalesPayments}
          currentTabReservationId={currentTabReservationId}
          setIsLoading={setIsLoading}
          onSendPos={onAdjustSales}
        />
      </div>
      <LoadingFull isLoading={isLoading} />
    </div>
  )
}

const sectionBottomStyle = css({
  margin: '0 32px',
  display: 'flex',
  justifyContent: 'space-between',
  '@media(max-width: 1080px)': {
    marginInline: 16,
  },
})

const mixedSectionStyle = css(sectionStyle, {
  paddingBottom: 33,
})
