import React, { useState } from 'react'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import 'react-dates/initialize'

import {
  mainContainerHeaderStyle,
  headerBarLeftWrapperStyle,
  tableHeaderThStyle,
  tableBodyTdStyle,
  tableBodyTdStyleRead,
  tableBodyTdStyleUnread,
} from '@/constants/layout'

import {
  guestNameUnreadEnStyle,
  guestNameUnreadJaStyle,
  guestNameReadEnStyle,
  guestNameReadJaStyle,
  guestNameStyle,
  guestNameKanaStyle,
  companionStyle,
  reservationRoomNumbersStyle,
  selfCheckinTimeStyle,
  lowScoreStyle,
  middleScoreStyle,
  HighScoreStyle,
  attentionLogoStyle,
  reloadWrapperStyle,
} from '@/components/pages/accommodation-management/list-layout'

// components
import { Button } from '@/components/atoms/button'
import { ReloadButton } from '@/components/atoms/reload-button'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { DetailedReserchPanel } from '@/components/pages/accommodation-management/_checkout/detailed-reserch-panel'
import { getSearchedConditions } from '@/components/pages/accommodation-management/search-condition'

// libs
import { dividedRoomNumber, dividedTypeName } from '@/libs/divide-list'
import { useSearchedConditions } from '@/hooks/use-search-condition'

type ContainerProps = {
  reservationList: any
  page: number
  setPage: (v: number) => void
  refresh: () => void
  listTotal: number
  limit: number
  pagingIsDisabled: boolean
  onClickReserch: any
  onClickClearReserch: any
  detailedReserch: any
  setDetailedReserch: any
}

export const CheckoutList: React.FC<ContainerProps> = ({
  reservationList,
  page,
  setPage,
  refresh,
  listTotal,
  limit,
  pagingIsDisabled,
  onClickReserch,
  onClickClearReserch,
  detailedReserch,
  setDetailedReserch,
}) => {
  const windows = useWindowSize()
  const { t, i18n } = useTranslation()
  const [isDropDownReserchPanelOpen, setIsDropDownReserchPanelOpen] = useState<boolean>(false)

  const searchedConditions = getSearchedConditions()
  const { SearchedConditionValues } = useSearchedConditions({ searchedConditions })

  return (
    <>
      <div css={[mainContainerHeaderStyle, { minWidth: 'auto' }]}>
        <div css={headerBarLeftWrapperStyle}>
          <div>
            <Button
              buttonType={4}
              width={i18n.language === 'en' ? 118 : 90}
              height={32}
              fontSize={12}
              onClick={() => setIsDropDownReserchPanelOpen(!isDropDownReserchPanelOpen)}
            >
              {t('Detailed search')}
            </Button>
            {isDropDownReserchPanelOpen && (
              <DetailedReserchPanel
                onClickReserch={onClickReserch}
                onClickClearReserch={onClickClearReserch}
                setIsDropDownReserchPanelOpen={() => setIsDropDownReserchPanelOpen(!isDropDownReserchPanelOpen)}
                detailedReserch={detailedReserch}
                setDetailedReserch={setDetailedReserch}
              />
            )}
          </div>
          <div css={reloadWrapperStyle}>
            <ReloadButton onClick={() => refresh()} />
          </div>

          <SearchedConditionValues />
        </div>
        <div css={buttonWrapperStyle}>
          <AccommodationPaging page={page} setPage={setPage} total={listTotal} limit={limit} isDisabled={pagingIsDisabled} />
        </div>
      </div>
      <div css={{ overflow: 'auto', height: windows.height - 218 }}>
        <table css={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th css={[tableHeaderThStyle, { paddingLeft: 32 }]}>{t('Guest')}</th>
              <th css={tableHeaderThStyle}>{t('Reservation ID')}</th>
              <th css={tableHeaderThStyle}>{t('Room type')}</th>
              <th css={tableHeaderThStyle}>{t('Room number')}</th>
              <th css={tableHeaderThStyle}>{t('Evaluation')}</th>
              <th css={tableHeaderThStyle}>{t('Check-out date')}</th>
            </tr>
          </thead>
          <tbody>
            {reservationList &&
              reservationList.map((v: any, index) => {
                return (
                  <Link
                    to={{
                      pathname: `/checkout/${v.checkinId}`,
                      search: `${location.search}&reservationId=${v.reservationId}`,
                      state: { fromAccommodationManagement: true },
                    }}
                    key={index}
                    css={[
                      v.checkoutRead ? tableBodyTdStyleRead : tableBodyTdStyleUnread,
                      { display: 'table-row', ':hover': { color: '#000' } },
                    ]}
                  >
                    <td css={[tableBodyTdStyle, { paddingLeft: 32 }]}>
                      <div
                        css={() => {
                          if (!v.checkoutRead) {
                            return i18n.language === 'en' ? guestNameUnreadEnStyle : guestNameUnreadJaStyle
                          } else {
                            return i18n.language === 'en' ? guestNameReadEnStyle : guestNameReadJaStyle
                          }
                        }}
                      >
                        <div css={guestNameKanaStyle}>{v.guestNameKana ? v.guestNameKana : '-'}</div>
                        <div css={guestNameStyle}>{v.guestName ? v.guestName : '-'}</div>
                      </div>
                    </td>
                    <td css={reservationIdTdStyle}>
                      <div css={companionStyle}>{v.reservationId ? v.reservationId : '-'}</div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 200 }]}>
                      <div css={reservationRoomNumbersStyle}>{dividedTypeName(v.rooms)}</div>
                    </td>
                    <td css={[tableBodyTdStyle, { maxWidth: 130 }]}>
                      <div css={[reservationRoomNumbersStyle, { width: '100%' }]}>{dividedRoomNumber(v.rooms)}</div>
                    </td>
                    <td css={[tableBodyTdStyle]}>
                      <div css={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          css={[
                            (() => {
                              if (Number(reservationList[index].point) <= 6 || reservationList[index].point === null) {
                                return lowScoreStyle
                              }
                              if (Number(reservationList[index].point) <= 8) {
                                return middleScoreStyle
                              }
                              if (Number(reservationList[index].point) <= 10) {
                                return HighScoreStyle
                              }
                            })(),
                            { justifyContent: 'start' },
                          ]}
                        >
                          {reservationList[index].point !== null ? reservationList[index].point : '-'}
                        </div>
                        {(() => {
                          if (reservationList[index].point <= 4 && reservationList[index].point !== null) {
                            return <img src={require('@/static/images/evaluation.svg')} css={attentionLogoStyle} />
                          }
                        })()}
                      </div>
                    </td>
                    <td css={[tableBodyTdStyle]}>
                      <div css={selfCheckinTimeStyle}>{v.checkoutDate ? dayjs(v.checkoutDate).format(t('YYYY-MM-DD')) : '-'}</div>
                    </td>
                  </Link>
                )
              })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const buttonWrapperStyle = css({
  display: 'flex',
})

const reservationIdTdStyle = css(tableBodyTdStyle, {
  maxWidth: 180,
  '@media(max-width: 1080px)': {
    maxWidth: 140,
  },
})
