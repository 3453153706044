import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { deleteKeywordGroupSetting, getKeywordGroupSetting, sortKeywordGroupSetting } from '@/apis/aipass'
import { SettingReservationTab } from '@/components/pages/setting/reservation/tab'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { ContainerHeader } from '@/components/molecules/settings/container-header'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { TableBody, TableHeader, TableRow } from '@/components/atoms/settings/table'
import { css } from '@emotion/core'
import { Hint } from '@/components/atoms/hint'
import { EditFooter } from '@/components/organisms/edit-footer'
import { KeywordGroup } from '@/models/reservation/keyword-group'
import { AddPlanGroupModal } from '@/components/organisms/settings/add-plan-group-modal'
import { LoaderContextCreator } from '@/contexts/loader'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useDraggableDom } from '@/components/molecules/settings/use-draggable-dom'

export const SettingReservationPlan: React.FC = () => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const [showDetail, setShowDetail] = useState<KeywordGroup | null>()
  const [keywordGroups, setKeywordGroups] = useState<KeywordGroup[]>([])
  const [initKeywordGroups, setInitKeywordGroups] = useState<KeywordGroup[]>([])
  const [deleteTargetIds, setDeleteTargetIds] = useState<string[]>([])
  const [showFooter, setShowFooter] = useState<boolean>(false)

  const changeDisplayOrder = newList => {
    setKeywordGroups(newList)
    setShowFooter(true)
  }
  const { dragStart, dragOver, dragDrop } = useDraggableDom({ list: keywordGroups, onChange: changeDisplayOrder })

  const doUpdateList = async () => {
    try {
      setIsLoading(true)
      if (deleteTargetIds.length) {
        await deleteKeywordGroupSetting(deleteTargetIds)
      }
      await sortKeywordGroupSetting(keywordGroups.map(group => group.id))
      doReload()
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }
  const doUpdateCancelList = () => {
    setDeleteTargetIds([])
    setKeywordGroups(initKeywordGroups)
    setShowFooter(false)
  }
  const doReload = () => {
    getKeywordGroupSetting().then(res => {
      setDeleteTargetIds([])
      setShowFooter(false)
      setKeywordGroups(res)
      setInitKeywordGroups(res)
    })
  }

  useEffect(() => {
    doReload()
  }, [])

  const filteredKeywordGroups = useMemo(() => {
    return keywordGroups.filter(group => !deleteTargetIds.includes(group.id))
  }, [deleteTargetIds, keywordGroups])

  const footerContent = useMemo(
    () => (showFooter ? <EditFooter onSave={doUpdateList} onCancel={doUpdateCancelList} /> : undefined),
    [showFooter],
  )

  return (
    <>
      <SettingsLayout loading={false} footerContent={footerContent}>
        <TabContainer tabComponent={<SettingReservationTab currentTab="planName" />} isShowFooter={showFooter}>
          <>
            <ContainerHeader
              onClickAddButton={() => setShowDetail(null)}
              style={{
                flexDirection: 'row-reverse',
                padding: '0 16px 0 32px',
              }}
            />
            <TableHeader style={{ zIndex: 10 }}>
              <div css={groupNameColStyle}>{t('keyword group')}</div>
              <div css={priorityColStyle}>
                {t('Priority')}
                <Hint>
                  <div>{t('For keyword groups that contain multiple keywords, the highest priority setting is applied.')}</div>
                  <div>
                    <p>{`${t('Setting example')} )`}</p>
                    <p>{`・${t('keyword group')} : 夕食`}</p>
                    <p>{`・${t('Priority')}1 : 伊勢海老 - 伊勢海老をA匹準備`}</p>
                    <p>{`・${t('Priority')}2 : 2食 - 基本メニュー`}</p>
                  </div>
                  <div>
                    <p>{t('For reservations with the plan name “{{plan}}”,', { plan: '伊勢海老2食つきプラン' })}</p>
                    <p>{t('“{{note}}” set as Priority 1 will be applied.', { note: '伊勢海老をA匹準備' })}</p>
                  </div>
                </Hint>
              </div>
              <div css={keywordColStyle}>{t('keyword')}</div>
              <div css={memoColStyle}>{t('Notes (automatic creation)')}</div>
              <div css={deleteColStyle}></div>
            </TableHeader>
            <TableBody>
              {filteredKeywordGroups.map(keywordGroup => (
                <div key={keywordGroup.id}>
                  <TableRow
                    id={keywordGroup.id}
                    draggable={true}
                    onDragStart={dragStart}
                    onDragOver={dragOver}
                    onDrop={dragDrop}
                    css={{ '> div': { fontWeight: 500 }, padding: '16px 0' }}
                    onClick={() => setShowDetail(keywordGroup)}
                  >
                    <div css={titleColStyle}>{keywordGroup.name}</div>
                    <div css={deleteColStyle}>
                      <DeleteIcon onClick={() => setDeleteTargetIds([...deleteTargetIds, keywordGroup.id])} />
                    </div>
                  </TableRow>
                  {keywordGroup.keywords.map(keyword => (
                    <TableRow css={tableRowStyle} key={`${keywordGroup.id}-${keyword.priority}`}>
                      <div css={groupNameColStyle}></div>
                      <div css={priorityColStyle}>{keyword.priority}</div>
                      <div css={keywordColStyle}>{keyword.keyword}</div>
                      <div css={memoColStyle}>
                        <div>
                          <img src={require('@/static/images/reservation-note/icon_front.svg')} width={18} height={18} />
                          <p>{keyword.frontNote || '-'}</p>
                        </div>
                        <div>
                          <img src={require('@/static/images/reservation-note/icon_cleaning.svg')} width={18} height={18} />
                          <p>{keyword.cleaningNote || '-'}</p>
                        </div>
                        <div>
                          <img src={require('@/static/images/reservation-note/icon_meal.svg')} width={18} height={18} />
                          <p>{keyword.mealNote || '-'}</p>
                        </div>
                      </div>
                      <div css={deleteColStyle}></div>
                    </TableRow>
                  ))}
                </div>
              ))}
            </TableBody>
          </>
        </TabContainer>
      </SettingsLayout>
      {showDetail !== undefined && (
        <AddPlanGroupModal
          detail={showDetail}
          onClose={() => {
            setShowDetail(undefined)
          }}
          doReload={doReload}
        />
      )}
    </>
  )
}
const tableRowStyle = css({
  '> div': { fontWeight: 500, lineHeight: '18px' },
  backgroundColor: '#F5F5F5',
  cursor: 'auto',
  padding: '16px 0',
  alignItems: 'start',
  minHeight: 'auto',
})
const groupNameColStyle = css({ marginLeft: 62, width: 150, marginRight: 32 })
const titleColStyle = css({ marginLeft: 62, flexGrow: 1 })
const priorityColStyle = css({
  width: 80,
  marginRight: 32,
  display: 'flex',
  alignItems: 'center',
  '.help-icon': { paddingLeft: 8 },
  '.help-popup': {
    left: 8 + 14,
    top: 0,
    width: 400,
    padding: 16,
    letterSpacing: 1.2,
    lineHeight: '21px',
    fontSize: 12,
    fontWeight: 'bold',
    '>div': {
      paddingTop: 16,
      '&:first-of-type': { paddingTop: 0 },
    },
  },
})
const keywordColStyle = css({ width: 150, marginRight: 32 })
const memoColStyle = css({
  flexGrow: 1,
  overflowWrap: 'anywhere',
  width: 'min-content',
  marginRight: 32,
  display: 'flex',
  '> div': {
    width: '33%',
    display: 'flex',
    alignItems: 'start',
    '> p': { paddingLeft: 16 },
  },
})
const deleteColStyle = css({ width: 30, margin: '0 16px' })
