import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { css } from '@emotion/core'
import 'react-dates/initialize'
import moment from 'moment'
import { LocationDescriptor } from 'history'
import uuid from 'react-uuid'

// apis
import { putRoomManagerPluginGuestRoomAssign, deleteSalesAdvancePayment, guestRoomFix } from '@/apis/aipass'

// models
import { DailyProps } from '@/components/pages/room-manager/_daily/daily-props'
import { ApprovedStatus } from '@/models/reservation-approved-status'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { AdminReservationResponseType } from '@/models/reservation/admin-reservation'
import { GuestRoomAssignResponseType } from '@/models/room-manager/response/guest-room-assign'

// hooks
import { useHotelGuestRooms } from '@/hooks/use-hotel-guest-rooms'
import { useErrorHandler } from '@/hooks/use-error-handler'

// component
import { RoomNumberSelect } from '@/components/pages/room-manager/_room-manager/room-number-select'
import { RoomTypeSelect } from '@/components/pages/room-manager/_room-manager/room-type-select'
import { DatePicker } from '@/components/pages/room-manager/_room-manager/date-picker'
import { Button } from '@/components/atoms/button'

// validation
import { validUpdateGuestRoom } from '@/components/organisms/customer/_guest-room/valid-update-guest-room'
import { RoomDateSelect } from './room-date-select'
import { useAssignConfirmDialog } from '@/components/organisms/room-manager/use-assign-confirm-dialog'
import { AxiosError } from 'axios'
import { DuplicateAssignmentConfirmDialog } from '@/components/organisms/room-manager/assign-panel/duplicate-assignment-confirm-dialog'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'

type AssignPanelProps = Pick<
  DailyProps,
  | 'reservation'
  | 'reCreateAssignPanelDetail'
  | 'reservationCheckinDate'
  | 'setReservationCheckinDate'
  | 'reservationCheckoutDate'
  | 'setReservationCheckoutDate'
  | 'setPreparationMemo'
  | 'preparationMemo'
  | 'editGuestRooms'
  | 'setEditGuestRooms'
  | 'guestRooms'
  | 'setRoomDatesChangedGuestRooms'
  | 'setIsLoading'
  | 'changeUrl'
  | 'setRoomAssignActiveId'
  | 'setRoomManagerTopSelectDate'
  | 'hasTlTwoWayPlugin'
  | 'hasRemoteLockPlugin'
  | 'setIsBar'
  | 'preparation'
  | 'setPreparation'
  | 'order'
  | 'changePreparation'
  | 'assignPanelEditMode'
  | 'setAssignPanelEditMode'
  | 'fetchSelectableGuestRoom'
  | 'fetchGuestRoomAssignStatus'
  | 'initializeGuestRoomsStatuses'
  | 'deletedPreparationRooms'
  | 'setDeletedPreparationRooms'
  | 'calendarScrollRoomTypePosition'
  | 'clearRoomAssign'
>

export const AssignPanel: React.FC<AssignPanelProps> = ({
  reservation,
  reCreateAssignPanelDetail,
  reservationCheckinDate,
  setReservationCheckinDate,
  setPreparationMemo,
  preparationMemo,
  reservationCheckoutDate,
  setReservationCheckoutDate,
  editGuestRooms,
  setEditGuestRooms,
  guestRooms,
  setRoomDatesChangedGuestRooms,
  setIsLoading,
  changeUrl,
  setRoomAssignActiveId,
  setRoomManagerTopSelectDate,
  setIsBar,
  preparation,
  setPreparation,
  order,
  changePreparation,
  assignPanelEditMode,
  setAssignPanelEditMode,
  fetchSelectableGuestRoom,
  fetchGuestRoomAssignStatus,
  initializeGuestRoomsStatuses,
  deletedPreparationRooms,
  setDeletedPreparationRooms,
  calendarScrollRoomTypePosition,
  clearRoomAssign,
}) => {
  const { errorHandler, isOutOfStockError } = useErrorHandler()
  const { t } = useTranslation()
  const params = require('query-string').parse(window.location.search)
  const reservationId: string | null = params.reservationId
  const guestRoomAssignId: string | null = params.guestRoomAssignId

  // Confirm duplicate day use assign
  const [openDuplicationConfirm, setOpenDuplicateConfirm] = useState(false)
  // Hotel room list
  const [hotelGuestRooms, handleFetchGuestRoomForHotel] = useHotelGuestRooms()
  // For unassigned reserved rooms
  const [reservationRooms, setReservationRooms] = useState<typeof editGuestRooms>([])
  const [roomsInPreparationWereUpdated, setRoomsInPreparationWereUpdated] = useState<boolean>(false)
  // Assignment Room Removed
  const [deletedAssignRooms, setDeletedAssignRooms] = useState<GuestRoomAssignResponseType['rooms']>([])
  // Current room assignment status: undefined if remote lock setting information is invalid
  // const [userRoomAssign, setUserRoomAssign] = useState<userRoomAssignType[]>()
  const canBeFixed =
    editGuestRooms?.length && editGuestRooms.every(i => !!i.roomNumber && i.roomNumber !== '指定なし') && !assignPanelEditMode
  const [isFixed, setIsFixed] = useState<boolean>(reservation?.isFixed || false)

  const { AssignConfirmDialog, openConfirmDialog } = useAssignConfirmDialog()
  /**
   * Get a list of rooms for the entire hotel
   */
  useEffect(() => {
    // Get currently available rooms
    handleFetchGuestRoomForHotel()
  }, [])

  /**
   * Add assistance when moving a reserved room to room management
   */
  useEffect(() => {
    if (reservation?.reservationRoom?.length) {
      // Check if there are any reserved rooms that are not currently assigned
      const notAssignedReservationRoom = reservation.reservationRoom.filter(room => !room.guestRoomAssignId)
      // If not, we will assist you in booking a room
      if (notAssignedReservationRoom.length) {
        addReservationRoomType(reservation.reservationRoom)
      } else {
        // If there is no target reserved room, delete all reserved rooms
        setReservationRooms([])
      }
    }

    setIsFixed(reservation?.isFixed || false)
    setRoomsInPreparationWereUpdated(false)
  }, [reservation])

  /**
   * Processing handling to scroll the calendar position in the reserved room
   */
  useEffect(() => {
    if (guestRooms && reservationRooms.length) {
      // [Specifications] RoomTypeName is always one because there are no multiple room types for a reserved room.
      const reservationRoomTypeNames = reservationRooms.map(reservationRoom => reservationRoom.typeNameJa)
      const [roomTypeName] = reservationRoomTypeNames.filter((v, i, a) => a.indexOf(v) === i)
      if (roomTypeName && editGuestRooms.length) {
        calendarScrollRoomTypePosition(roomTypeName)
      }
    }
  }, [editGuestRooms])

  /**
   * Action to take when tab is clicked
   * (when URL is changed)
   */
  useEffect(() => {
    if (!changeUrl) {
      return
    }

    const newReservationId: string | null = params.reservationId
    // Reacquisition of data when the URL changes
    if (reservationId) {
      if (newReservationId && newReservationId === reservationId) {
        reCreateAssignPanelDetail(newReservationId)
      } else {
        reCreateAssignPanelDetail(reservationId)
      }
      // Restore Not Ready status
      setPreparation(false)
    } else {
      if (newReservationId) {
        reCreateAssignPanelDetail(newReservationId)
        // Restore Not Ready status
        setPreparation(false)
      }
    }
  }, [changeUrl])

  /**
   * ================================================================
   * Assigned room-related processing
   * ================================================================
   */

  /**
   * Processing to be added when a reserved room is moved to the room management
   */
  const addReservationRoomType = (rooms: AdminReservationResponseType['reservations']['reservationRoom']) => {
    // put together reserved rooms
    const reservationRooms: typeof editGuestRooms = []
    // If there are no rooms currently assigned
    for (let i = 0; i < rooms.length; i++) {
      for (let v = 0; v < rooms[i].currentRoomNum; v++) {
        reservationRooms.push({
          typeNameJa: rooms[i].typeNameJa,
          typeNameEn: rooms[i].typeNameEn,
          roomTypeId: rooms[i].roomTypeId,
          guestRoomId: null,
          roomNumber: null,
        })
      }
    }
    reservationRooms.map(reservationRoom => {
      editGuestRooms.push({
        roomTypeId: reservationRoom.roomTypeId,
        typeNameJa: reservationRoom.typeNameJa,
        typeNameEn: reservationRoom.typeNameEn,
        roomNumber: null,
        guestRoomId: null,
      })
    })

    setReservationRooms(reservationRooms)
    const _editGuestRooms = editGuestRooms.filter(editGuestRoom => editGuestRoom.typeNameJa !== null)
    setEditGuestRooms(_editGuestRooms)
    setAssignPanelEditMode(true)
  }

  /**
   * Processing when changing the room type of each room
   */
  const onEditRoomType = (e: React.ChangeEvent<HTMLSelectElement>, selectRoomType: string, selectIndex: number) => {
    // If no room type is selected, stop processing
    if (!selectRoomType) {
      setIsLoading(false)
      alert(t('Please select a room type'))
      e.preventDefault()
      return
    }

    setAssignPanelEditMode(true)
    // Create a new array to update the state
    const _editGuestRooms = [...editGuestRooms]

    hotelGuestRooms.forEach(room => {
      if (room['typeNameJa'] === selectRoomType) {
        const { roomTypeId, typeNameJa, typeNameEn } = room
        _editGuestRooms[selectIndex] = {
          ..._editGuestRooms[selectIndex],
          roomTypeId,
          typeNameJa,
          typeNameEn,
          roomNumber: null,
          guestRoomId: null,
        }
      }
    })
    setEditGuestRooms(_editGuestRooms)
  }

  /**
   * Processing when changing the room number of each room
   */
  const onEditRoomNumber = (e: React.ChangeEvent<HTMLSelectElement>, selectGuestRoomId: string, selectIndex: number) => {
    // If no room number is selected, stop processing
    if (!selectGuestRoomId) {
      setIsLoading(false)
      alert(t('Please select a room number'))
      e.preventDefault()
      return
    }

    setAssignPanelEditMode(true)
    // Create a new array to update the state
    const _editGuestRooms = [...editGuestRooms]
    _editGuestRooms[selectIndex].guestRoomId = selectGuestRoomId
    _editGuestRooms[selectIndex].roomNumber = roomNumberByGuestRoomId(selectGuestRoomId)

    setEditGuestRooms(_editGuestRooms)
    // Update guestRooms
    fetchGuestRoomAssignStatus()
  }

  /**
   * Processing when the room assignment period is changed
   */
  const onEditRoomStayPeriod = (selectDate: 'checkin' | 'checkout', dateValue: string, selectIndex: number) => {
    if (!guestRooms) {
      return
    }
    if (!canChangedEditRooms(selectIndex)) {
      alert(t('Dates cannot be changed for this room Please complete the assignment'))
      return
    }

    setAssignPanelEditMode(true)
    // Create a new array to update the state
    const _editGuestRooms = [...editGuestRooms]

    /**
     * dateValue is sent in YY month DD day format, so convert it to YYYY-MM-DD HH:mm:ss for registration
     */
    const month = dateValue.substring(0, 2)
    const day = dateValue.substring(3, 5)
    const year = dateValue.substring(6, 10)

    /**
     * update changeAssignCheckinDate and changeAssignCheckoutDate in statuses for target room in guestRooms
     * Also update changeAssignCheckinDays and changeAssignCheckoutDays
     * Insert new even if there is no value (not assigned)
     */
    if (selectDate === 'checkin') {
      const checkinDate = moment(`${Number(year)}-${Number(month)}-${Number(day)} 12:00:00`).utcOffset(9)

      Object.keys(guestRooms).forEach(roomTypeName => {
        if (_editGuestRooms[selectIndex].typeNameJa !== roomTypeName) {
          return
        }
        Object.keys(guestRooms[roomTypeName]).forEach(roomNumber => {
          if (_editGuestRooms[selectIndex].roomNumber !== roomNumber) {
            return
          }
          if (guestRooms[roomTypeName][roomNumber].statuses.length > 1) {
            guestRooms[roomTypeName][roomNumber].statuses.forEach(status => {
              if (
                Object.prototype.hasOwnProperty.call(status, 'changeAssignCheckinDate') &&
                // To change only the target tag, update only those whose check-in and check-out are the same as before the change
                moment(_editGuestRooms[selectIndex].assignCheckinDate).format('YYYY-MM-DD') === status.changeAssignCheckinDays &&
                moment(_editGuestRooms[selectIndex].assignCheckoutDate).format('YYYY-MM-DD') === status.changeAssignCheckoutDays
              ) {
                status.changeAssignCheckinDate = moment(checkinDate).format('YYYY-MM-DD HH:mm:ss')
                status.changeAssignCheckinDays = moment(checkinDate).format('YYYY-MM-DD')
              }
            })
          } else {
            guestRooms[roomTypeName][roomNumber].statuses.forEach(status => {
              status.assignCheckinDate = moment(checkinDate).format('YYYY-MM-DD HH:mm:ss')
              status.assignCheckinDays = moment(checkinDate).format('YYYY-MM-DD')
              status.changeAssignCheckinDate = moment(checkinDate).format('YYYY-MM-DD HH:mm:ss')
              status.changeAssignCheckinDays = moment(checkinDate).format('YYYY-MM-DD')
            })
          }
        })
      })

      // update check-in date in editGuestRooms
      _editGuestRooms[selectIndex].assignCheckinDate = moment(checkinDate).format('YYYY-MM-DD HH:mm:ss')
      // Also update available rooms
      fetchSelectableGuestRoom(moment(checkinDate), moment(_editGuestRooms[selectIndex].assignCheckoutDate!), _editGuestRooms, selectIndex)
    }

    if (selectDate === 'checkout') {
      const checkoutDate = new Date(Number(year), Number(month) - 1, Number(day), 12, 0, 0)

      Object.keys(guestRooms).forEach(roomTypeName => {
        if (_editGuestRooms[selectIndex].typeNameJa !== roomTypeName) {
          return
        }
        Object.keys(guestRooms[roomTypeName]).forEach(roomNumber => {
          if (_editGuestRooms[selectIndex].roomNumber !== roomNumber) {
            return
          }
          if (guestRooms[roomTypeName][roomNumber].statuses.length > 1) {
            guestRooms[roomTypeName][roomNumber].statuses.forEach(status => {
              if (
                Object.prototype.hasOwnProperty.call(status, 'changeAssignCheckoutDate') &&
                // To change only the target tag, update only those whose check-in and check-out are the same as before the change
                moment(_editGuestRooms[selectIndex].assignCheckinDate).format('YYYY-MM-DD') === status.changeAssignCheckinDays &&
                moment(_editGuestRooms[selectIndex].assignCheckoutDate).format('YYYY-MM-DD') === status.changeAssignCheckoutDays
              ) {
                status.changeAssignCheckoutDate = moment(checkoutDate).format('YYYY-MM-DD HH:mm:ss')
                status.changeAssignCheckoutDays = moment(checkoutDate).format('YYYY-MM-DD')
              }
            })
          } else {
            guestRooms[roomTypeName][roomNumber].statuses.forEach(status => {
              status.assignCheckoutDate = moment(checkoutDate).format('YYYY-MM-DD HH:mm:ss')
              status.assignCheckoutDays = moment(checkoutDate).format('YYYY-MM-DD')
              status.changeAssignCheckoutDate = moment(checkoutDate).format('YYYY-MM-DD HH:mm:ss')
              status.changeAssignCheckoutDays = moment(checkoutDate).format('YYYY-MM-DD')
            })
          }
        })
      })
      // update checkout date in editGuestRooms
      _editGuestRooms[selectIndex].assignCheckoutDate = moment(checkoutDate).format('YYYY-MM-DD HH:mm:ss')
      // Also update available rooms
      fetchSelectableGuestRoom(moment(_editGuestRooms[selectIndex].assignCheckinDate!), moment(checkoutDate), _editGuestRooms, selectIndex)
    }

    setRoomDatesChangedGuestRooms(guestRooms)
    setEditGuestRooms(_editGuestRooms)
    // Update guestRooms
    fetchGuestRoomAssignStatus(order, guestRooms)
  }

  /**
   * List Generation: List of currently selectable room types
   */
  const guestRoomTypeList = (roomInfo: GuestRoomAssignResponseType['rooms'][0]) => {
    const list: { id: number; name: string | null; value: string | null }[] = [
      {
        id: 1,
        name: '部屋タイプを選択',
        value: '',
      },
    ]

    if (roomInfo.selectableRooms) {
      // Delete the selected room from the list once to avoid duplication of rooms.
      const rooms = roomInfo.selectableRooms.filter(room => Number(roomInfo.roomTypeId) !== Number(room.roomTypeId))

      rooms.forEach(data => {
        list.push({
          id: Number(data.roomTypeId) + 1,
          name: data['typeNameJa'],
          value: data['typeNameJa'],
        })
      })

      // If the selected room type is not in the selectable array, add the selected room type to the hotel-wide list of rooms
      if (
        roomInfo &&
        roomInfo.roomTypeId &&
        hotelGuestRooms &&
        hotelGuestRooms.findIndex(room => room.roomTypeId === roomInfo.roomTypeId) !== -1
      ) {
        list.push({
          id: Number(roomInfo.roomTypeId) + 1,
          name: roomInfo['typeNameJa'],
          value: roomInfo['typeNameJa'],
        })
      }

      // Sort by ascending roomTypeId (id = 1 is 'select room type')
      list.sort((a, b) => {
        return a.id - b.id
      })
    }

    return list
  }

  /**
   * List generation: List of currently selectable room numbers
   */
  const guestRoomNumberList = (roomInfo: GuestRoomAssignResponseType['rooms'][0]) => {
    const list = [
      {
        name: '部屋番号を選択',
        value: '',
        guestRoomId: '',
      },
    ]

    if (roomInfo.selectableRooms) {
      // If room type is selected
      if (roomInfo && roomInfo.typeNameJa) {
        roomInfo.selectableRooms.forEach(data => {
          if (data.rooms && data.rooms.length && data.typeNameJa === roomInfo.typeNameJa) {
            data.rooms.forEach(room => {
              list.push({
                name: room.roomNumber,
                value: room.guestRoomId,
                guestRoomId: room.guestRoomId,
              })
            })
          }
        })
        if (roomInfo.roomNumber && roomInfo.guestRoomId) {
          let isDuplicate = false
          list.filter(v => {
            if (v.value === roomInfo.guestRoomId) isDuplicate = true
          })
          // Add if not duplicate
          if (!isDuplicate) {
            list.push({
              name: roomInfo.roomNumber,
              value: roomInfo.guestRoomId,
              guestRoomId: roomInfo.guestRoomId,
            })
          }
        }
        // Sort Ascending
        list.sort((a, b) => Number(a.name) - Number(b.name))
      } else {
        // Show all available room numbers if no room type is selected
        const allNumber: { guestRoomId: string; number: string }[] = []
        roomInfo.selectableRooms.forEach(data => {
          if (data.rooms && data.rooms.length) {
            data.rooms.map(room => {
              allNumber.push({
                guestRoomId: room['guestRoomId'],
                number: room['number'],
              })
            })
          }
        })
        allNumber?.forEach(guestRoom => {
          list.push({
            name: guestRoom['number'],
            value: guestRoom['number'],
            guestRoomId: guestRoom['guestRoomId'],
          })
        })
      }
    }

    return list
  }

  /**
   * Get the target room number from guestRoomId
   */
  const roomNumberByGuestRoomId = (guestRoomId: string) => {
    let roomNumber = ''
    hotelGuestRooms.forEach(hotelGuestRoom => {
      if (hotelGuestRoom.roomNumber) {
        Object.keys(hotelGuestRoom.roomNumber).forEach(key => {
          hotelGuestRoom.roomNumber[key].forEach(room => {
            if (room.guestRoomId === guestRoomId) {
              roomNumber = room.roomNumber
            }
          })
        })
      }
    })
    return roomNumber
  }

  /**
   * Deletion process for each room
   */
  const onDeleteRoom = (selectIndex: number) => {
    if (!editGuestRooms?.[selectIndex] || !guestRooms) {
      return
    }

    if (!canChangedEditRooms(selectIndex)) {
      alert(t('Cannot delete Please complete the assignment'))
      return
    }

    // Delete the assigned room if there is an assigned ID
    if (editGuestRooms[selectIndex].guestRoomAssignId) {
      // If there is a guestRoomAssignId, it will be deleted in the backend, so create data for it
      const deleteGuestRoom = { ...editGuestRooms[selectIndex] }
      deleteGuestRoom.guestRoomId = null
      deleteGuestRoom.typeNameJa = null
      deleteGuestRoom.assignCheckinDate = null
      deleteGuestRoom.assignCheckoutDate = null

      // In case of deletion in preparation
      if (preparation) {
        setDeletedPreparationRooms([...deletedPreparationRooms, deleteGuestRoom])
      } else {
        // In the case of deleting an assigned room
        setDeletedAssignRooms([...deletedAssignRooms, deleteGuestRoom])
      }
    }

    // If no room type or room number is selected, remove it from the array and exit
    if (!editGuestRooms[selectIndex].typeNameJa || !editGuestRooms[selectIndex].roomNumber) {
      editGuestRooms.splice(selectIndex, 1)
      setEditGuestRooms([...editGuestRooms])
      return
    }

    Object.keys(guestRooms).forEach(roomTypeName => {
      if (editGuestRooms[selectIndex].typeNameJa !== roomTypeName) {
        return
      }
      Object.keys(guestRooms[roomTypeName]).forEach(roomNumber => {
        if (editGuestRooms[selectIndex].roomNumber !== roomNumber) {
          return
        }
        guestRooms[roomTypeName][roomNumber].statuses.forEach((status, index) => {
          if (
            // Remove statuses that meet the conditions from statuses
            moment(editGuestRooms[selectIndex].assignCheckinDate).format('YYYY-MM-DD') === status.changeAssignCheckinDays &&
            moment(editGuestRooms[selectIndex].assignCheckoutDate).format('YYYY-MM-DD') === status.changeAssignCheckoutDays
          ) {
            guestRooms[roomTypeName][roomNumber].statuses.splice(index, 1)
          }
        })
      })
    })

    // remove from editGuestRooms array
    editGuestRooms.splice(selectIndex, 1)
    setAssignPanelEditMode(true)
    setRoomDatesChangedGuestRooms(guestRooms)
    setEditGuestRooms([...editGuestRooms])
  }

  /**
   * ================================================================
   * Button-related processing
   * ================================================================
   */

  /**
   * Add Room Button Handling
   */
  const onFieldPush = () => {
    if (isFixed) return alert(t('Registered in a fixed room. If you want to change it, please uncheck the fixed room.'))

    const _editGuestRooms = [...editGuestRooms]

    _editGuestRooms.push({
      roomTypeId: null,
      typeNameJa: null,
      typeNameEn: null,
      guestRoomId: null,
      roomNumber: null,
      assignCheckinDate: moment(reservationCheckinDate).format('YYYY-MM-DD HH:mm:ss'),
      assignCheckoutDate: moment(reservationCheckoutDate).format('YYYY-MM-DD HH:mm:ss'),
      editingId: `${uuid()}`,
    })
    const addedIndex = _editGuestRooms.length - 1
    setEditGuestRooms(_editGuestRooms)
    setAssignPanelEditMode(true)
    fetchSelectableGuestRoom(reservationCheckinDate, reservationCheckoutDate, _editGuestRooms, addedIndex)
  }

  /**
   * Transition process to detail screen
   * Page transition according to status
   */
  const makeReservationDetailLocation = (): LocationDescriptor<unknown> => {
    if (!reservation) return '#'

    const search = `?reservationId=${reservationId}`

    // Booking details
    if (reservation.approvedStatus === ApprovedStatusType.Reservation) {
      return { pathname: `/reservation/${reservation.id}`, search }
    }
    // check-in
    if (reservation.approvedStatus === ApprovedStatusType.Checkin) {
      return { pathname: `/checkin/${reservation.checkinId}`, search }
    }
    // During your stay
    if (reservation.approvedStatus === ApprovedStatusType.Stay) {
      return { pathname: `/stay/${reservation.checkinId}`, search }
    }
    // check out
    if (reservation.approvedStatus === ApprovedStatusType.Checkout) {
      return { pathname: `/checkout/${reservation.checkinId}`, search }
    }
    return '#'
  }

  /**
   * New reservation creation and room assignment
   */
  const assignRoomNoReservation = async () => {
    try {
      const { isCancel, reservationId } = await doGuestRoomAssign({
        checkinDate: reservationCheckinDate,
        checkoutDate: reservationCheckoutDate,
        editGuestRooms,
        roomsInPreparation: roomsInPreparationWereUpdated ? undefined : deletedPreparationRooms,
      })
      if (isCancel) {
        return
      }

      const url = new URL(window.location.href)
      if (reservationId) {
        setRoomAssignActiveId(reservationId)

        url.searchParams.set('reservationId', reservationId)
        url.searchParams.delete('guestRoomAssignId')
      } else {
        url.searchParams.delete('reservationId')
        setRoomManagerTopSelectDate(moment(reservationCheckinDate))
      }
      setDeletedAssignRooms([])
      setDeletedPreparationRooms([])
      // Update URL
      window.history.replaceState('', '', url.search)
    } catch (error) {
      if ((error as AxiosError).response?.data?.roomsInPreparationWereUpdated) {
        setRoomsInPreparationWereUpdated(true)
        setRoomManagerTopSelectDate(moment(reservationCheckinDate))
        setDeletedPreparationRooms([])
      }
      throw error
    }
  }

  /**
   * Room assignment from reservation details
   */
  const assignReservation = async reservation => {
    const { isCancel } = await doGuestRoomAssign({
      checkinDate: reservationCheckinDate,
      checkoutDate: reservationCheckoutDate,
      editGuestRooms,
      reservationTel: reservation.guestTel ?? '',
      reservationName: reservation.guestName ?? '',
      reservationId: reservation.reservationId,
    })
    if (isCancel) {
      return
    }
    setDeletedAssignRooms([])
    // Updated information in Assignments panel
    await reCreateAssignPanelDetail(reservation.reservationId)
    // Judgment when the accommodation date has changed
    const isChangedCheckinDate = reservation.checkinDate !== moment(reservationCheckinDate).format('YYYY-MM-DD HH:mm:ss')
    if (isChangedCheckinDate) {
      // Clear Advance Receipt Flag
      deleteSalesAdvancePayment(reservation.reservationId).catch(() => {
        console.log(t('Communication failed'))
      })
    }
  }

  /**
   * Assignment in preparation
   */
  const assignPreparation = async () => {
    // Add if you are preparing to be deleted
    if (deletedPreparationRooms.length) {
      editGuestRooms.push(...deletedPreparationRooms)
    }
    const { isCancel } = await doGuestRoomAssign({
      checkinDate: reservationCheckinDate,
      checkoutDate: reservationCheckoutDate,
      editGuestRooms: [],
      roomsInPreparation: editGuestRooms.map(room => ({ ...room, preparationMemo })),
    })
    if (isCancel) {
      return
    }

    setRoomAssignActiveId('')
    // Each data initialization
    const url = new URL(window.location.href)
    const urlGuestRoomAssignId = url.searchParams.get('guestRoomAssignId')
    if (urlGuestRoomAssignId) {
      url.searchParams.delete('guestRoomAssignId')
      window.history.pushState('', '', url.href)
    }
    // roomManagerTopSelectDate is the key for inventory acquisition, so update it
    setRoomManagerTopSelectDate(moment(reservationCheckinDate))
    setPreparation(false)
    setEditGuestRooms([])
    setDeletedAssignRooms([])
    setDeletedPreparationRooms([])
  }

  const doGuestRoomAssign = async (inputAssign: {
    checkinDate: moment.Moment
    checkoutDate: moment.Moment
    editGuestRooms: GuestRoomAssignResponseType['rooms']
    reservationTel?: string
    reservationName?: string
    reservationId?: string
    roomsInPreparation?: GuestRoomAssignResponseType['rooms']
    excludeStockCheck?: boolean
  }): Promise<{ reservationId?: string | undefined; isCancel?: boolean }> => {
    try {
      await putRoomManagerPluginGuestRoomAssign({
        ...inputAssign,
        reservationTel: inputAssign.reservationTel || '',
        reservationName: inputAssign.reservationName || '',
        validationOnly: true,
      })
    } catch (error) {
      if (!isOutOfStockError(error as Error)) {
        throw error
      }
      setIsLoading(false)
      if (!(await openConfirmDialog())) {
        return { isCancel: true }
      }
    }

    setIsLoading(true)
    const res = await putRoomManagerPluginGuestRoomAssign({
      ...inputAssign,
      reservationTel: inputAssign.reservationTel || '',
      reservationName: inputAssign.reservationName || '',
      excludeStockCheck: true,
    })

    // Initialize guestRooms
    initializeGuestRoomsStatuses(true)
    setAssignPanelEditMode(false)
    return res
  }

  const _guestRoomAssign = () => {
    if (!guestRooms) {
      return
    }

    const editRoomsOfAssignedAndDuplicate = editGuestRooms
      .filter(
        editGuestRoom =>
          editGuestRoom.assignCheckinDate &&
          editGuestRoom.assignCheckoutDate &&
          dayjs(editGuestRoom.assignCheckinDate).isSame(dayjs(editGuestRoom.assignCheckoutDate), 'day'),
      )
      .filter(editGuestRoomForDayUse => {
        const someGuestRoomsByRoomType = editGuestRoomForDayUse.typeNameJa && guestRooms[editGuestRoomForDayUse.typeNameJa]
        const someGuestRooms =
          someGuestRoomsByRoomType && editGuestRoomForDayUse.roomNumber && someGuestRoomsByRoomType[editGuestRoomForDayUse.roomNumber]

        if (!someGuestRooms) {
          return false
        }

        const otherAssignedGuestRooms = someGuestRooms.statuses.filter(
          guestRoom => !isEmpty(guestRoom.status) && guestRoom.guestRoomAssignId !== editGuestRoomForDayUse.guestRoomAssignId,
        )
        return otherAssignedGuestRooms.some(
          otherAssignedGuestRoom =>
            dayjs(editGuestRoomForDayUse.assignCheckinDate!).isSame(dayjs(otherAssignedGuestRoom.assignCheckinDays), 'day') &&
            dayjs(editGuestRoomForDayUse.assignCheckoutDate!).isSame(dayjs(otherAssignedGuestRoom.assignCheckoutDays), 'day'),
        )
      })

    const hasAssign = editRoomsOfAssignedAndDuplicate.length

    if (hasAssign) {
      setOpenDuplicateConfirm(true)
    } else {
      guestRoomAssign(changeButtonType())
    }
  }

  /**
   * Handle save button
   * (Room assignment processing)
   */
  const guestRoomAssign = async (buttonType: number) => {
    try {
      const _editGuestRooms = [...editGuestRooms]
      const validated = validUpdateGuestRoom(editGuestRooms)
      setIsLoading(true)

      if (buttonType !== 2 || !validated) {
        setIsLoading(false)
        return
      }

      if (preparation) {
        const hasUnspecifiedRoom = _editGuestRooms.some(room => !room.roomNumber)

        if (hasUnspecifiedRoom) return alert(t('If you are preparing, please specify the room number.'))
      }

      // If there are rooms in deletedAssignRooms, add them to editGuestRooms
      if (deletedAssignRooms.length) {
        // HACK: Just in case, convert for deletion (if deletion processing is done properly, processing may not be necessary)
        deletedAssignRooms.forEach((room, index) => {
          if (room.guestRoomAssignId) {
            deletedAssignRooms[index].guestRoomId = null
            deletedAssignRooms[index].typeNameJa = null
            deletedAssignRooms[index].assignCheckinDate = null
            deletedAssignRooms[index].assignCheckoutDate = null
          }
        })
        editGuestRooms.push(...deletedAssignRooms)
      }

      if (preparation) {
        await assignPreparation()
      } else if (reservationId && reservation) {
        await assignReservation(reservation)
      } else {
        await assignRoomNoReservation()
      }
    } catch (error) {
      errorHandler(error)
      console.warn(error)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Switch the button display according to the situation
   * (return button type)
   */
  const changeButtonType = () => {
    // Deactivate the button if the required items of accommodation date and room are not entered
    let type: number = 5
    let isValid = false
    editGuestRooms.map(room => {
      if (room.roomNumber === '部屋番号を選択' || typeof room.roomNumber !== 'string') {
        isValid = true
      }
    })
    if (reservationCheckinDate && reservationCheckoutDate && isValid === false) {
      type = 2
    }
    return type
  }

  /**
   * ================================================================
   * helper
   * ================================================================
   */

  /**
   * Get selectable length of stay in array
   */
  const getStayPeriodDates = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return []
    }

    let dates: Date[] = []
    //to avoid modifying the original date
    const theDate = new Date(startDate)
    const theEndDate = new Date(endDate)
    while (theDate < theEndDate) {
      dates = [...dates, new Date(theDate)]
      theDate.setDate(theDate.getDate() + 1)
    }
    dates = [...dates, endDate]
    const formatDates = dates.map(date => {
      return moment(date).format('MM-DD-YYYY')
    })

    // delete duplicates and return
    return formatDates.filter(function (val, i, self) {
      return i === self.indexOf(val)
    })
  }

  /**
   * Determine the color of the assignment period label for each room
   */
  const getRoomDateSelectLabelColor = () => {
    if (isFixed) {
      return '#F5F5F5'
    }
    if (preparation) {
      return '#F2F2F2'
    }

    if (reservation) {
      switch (String(reservation.approvedStatus)) {
        case ApprovedStatus.Reservation:
          return '#FFFCDD'
        case ApprovedStatus.Checkin:
          return '#FEF3F0'
        case ApprovedStatus.Stay:
          return '#EFF9FF'
        case ApprovedStatus.Checkout:
          return '#F6FDF2'
        default:
          return '#FFFCDD'
      }
    } else {
      return '#FFFCDD'
    }
  }

  /**
   * Determining if a room can be edited
   */
  const canChangedEditRooms = (roomSelectIndex: number) => {
    if (reservationRooms.length) {
      const reservationRoomTypeNames = reservationRooms.map(reservationRoom => reservationRoom.typeNameJa)
      const [roomTypeName] = reservationRoomTypeNames.filter((v, i, a) => a.indexOf(v) === i)
      const targetRooms = editGuestRooms.filter(editGuestRoom => editGuestRoom.typeNameJa === roomTypeName)
      if (
        reservationRoomTypeNames.length >= targetRooms.length &&
        !editGuestRooms[roomSelectIndex].guestRoomAssignId &&
        editGuestRooms[roomSelectIndex].typeNameJa === roomTypeName
      ) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  const fixedRooms = async () => {
    try {
      if (!reservation) return

      setIsLoading(true)

      await guestRoomFix({ reservationId: reservation?.reservationId, isFixed: !isFixed })

      setIsFixed(!isFixed)

      await reCreateAssignPanelDetail(reservation?.reservationId)
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div css={assignContainerStyle}>
        {/* <div css={assignTitleStyle}>{t('Room assignment')}</div> */}
        <div>
          <div css={assignDateContainerStyle}>
            <div css={assignDateWrapperStyle}>
              <div css={{ margin: '0 6px', fontSize: 12, fontWeight: 'bold' }}>IN</div>
              <div css={[assignDateStyle, assignDateStartStyle]} onClick={() => setIsBar(false)}>
                <DatePicker
                  date={reservationCheckinDate}
                  setDate={setReservationCheckinDate}
                  type={'checkin'}
                  setRoomManagerTopSelectDate={setRoomManagerTopSelectDate}
                  fetchSelectableGuestRoom={fetchSelectableGuestRoom}
                  otherDate={reservationCheckoutDate}
                  setOtherDate={setReservationCheckoutDate}
                  setAssignPanelEditMode={setAssignPanelEditMode}
                  disabled={isFixed}
                />
              </div>
              <div css={{ margin: '0 6px', fontSize: 12, fontWeight: 'bold' }}>OUT</div>
              <div css={[assignDateStyle, assignDateEndStyle]}>
                <DatePicker
                  date={reservationCheckoutDate}
                  setDate={setReservationCheckoutDate}
                  type={'checkout'}
                  setRoomManagerTopSelectDate={setRoomManagerTopSelectDate}
                  fetchSelectableGuestRoom={fetchSelectableGuestRoom}
                  otherDate={reservationCheckinDate}
                  setAssignPanelEditMode={setAssignPanelEditMode}
                  disabled={isFixed}
                />
              </div>
            </div>
          </div>
          <Link css={assignReservationUserContainerStyle} to={makeReservationDetailLocation()}>
            <div css={assignReservationUserDetailStyle}>
              <div style={{ fontSize: 12, marginBottom: 8 }}>
                {reservation && reservation.accommodationId ? reservation.accommodationId : '-'}
              </div>
              <div style={{ fontSize: 16 }}>{reservation && reservation.guestName ? reservation.guestName : '-'}</div>
            </div>
            <div style={{ margin: 'auto 0' }}>
              <img src={require('@/static/images/room_manager_arrow.svg')} />
            </div>
          </Link>

          <div css={assignTypeTopStyle}>
            {!reservationId && (
              <>
                <div css={prepareCheckStyle} onClick={() => changePreparation(!preparation)}>
                  <img src={require(`@/static/images/room/check-box${preparation ? '_yellow_on' : '_off'}.svg`)} />
                  <p css={{ fontWeight: 'bold' }}>{t('In preparation')}</p>
                </div>
                {preparation && (
                  <>
                    <textarea
                      value={preparationMemo}
                      placeholder={t('Enter memo')}
                      onChange={e => {
                        if (!assignPanelEditMode) {
                          setAssignPanelEditMode(true)
                        }
                        setPreparationMemo(e.currentTarget.value)
                      }}
                    />
                    <div className="divider" />
                  </>
                )}
              </>
            )}
            {!!editGuestRooms?.length && !!reservation?.reservationId && (
              <div css={[prepareCheckStyle, { cursor: canBeFixed ? 'pointer' : 'default' }]} onClick={() => canBeFixed && fixedRooms()}>
                <img
                  src={require(`@/static/images/room/check-box${isFixed ? '_yellow_on' : '_off'}.svg`)}
                  css={{ backgroundColor: !canBeFixed ? '#f2f2f2' : undefined }}
                />
                <p css={{ color: !canBeFixed ? '#ccc !important' : undefined, fontWeight: 'bold' }}>{t('Fixed room')}</p>
              </div>
            )}
          </div>
        </div>
        <div css={assignRoomContainerStyle}>
          {editGuestRooms.length !== 0 &&
            editGuestRooms.map((room, index) => {
              return (
                <div key={`room${index}`} css={[assignTypeWrapperStyle, isFixed ? { backgroundColor: '#F5F5F5' } : {}]}>
                  <RoomDateSelect
                    room={room}
                    roomIndex={index}
                    reservationCheckinDate={reservationCheckinDate}
                    reservationCheckoutDate={reservationCheckoutDate}
                    onEditRoomStayPeriod={onEditRoomStayPeriod}
                    getStayPeriodDates={getStayPeriodDates}
                    getRoomDateSelectLabelColor={getRoomDateSelectLabelColor}
                    onDeleteRoom={onDeleteRoom}
                    assignPanelEditMode={true}
                    disabled={isFixed}
                  />
                  <div css={assignTypeSelectWrapperStyle}>
                    <RoomTypeSelect
                      room={room}
                      roomIndex={index}
                      onEditRoomType={onEditRoomType}
                      guestRoomTypeList={guestRoomTypeList}
                      assignPanelEditMode={assignPanelEditMode}
                      disabled={isFixed}
                    />
                    <RoomNumberSelect
                      room={room}
                      roomIndex={index}
                      onEditRoomNumber={onEditRoomNumber}
                      guestRoomNumberList={guestRoomNumberList}
                      assignPanelEditMode={assignPanelEditMode}
                      editGuestRooms={editGuestRooms}
                      disabled={isFixed}
                    />
                  </div>
                </div>
              )
            })}
          <div css={assignAddStyle} onClick={() => onFieldPush()}>
            <img src={require('@/static/images/plus_yellow.svg')} />
            <p>{t('Add room')}</p>
          </div>
        </div>
        <div css={assignFooterStyle}>
          {(editGuestRooms.length !== 0 || reservationId || guestRoomAssignId) && (
            <div css={assignButtonContainerStyle}>
              {assignPanelEditMode ? (
                <>
                  <Button width={95} height={28} buttonType={3} fontSize={12} onClick={clearRoomAssign}>
                    {t('Cancel')}
                  </Button>
                  <Button width={95} height={28} buttonType={changeButtonType()} fontSize={12} onClick={() => _guestRoomAssign()}>
                    {t('Save')}
                  </Button>
                </>
              ) : (
                <>
                  {!guestRoomAssignId && (
                    <Link css={assignDetailLinkStyle} to={makeReservationDetailLocation()}>
                      <Button width={'100%'} height={28} buttonType={3} fontSize={12}>
                        {t('To details')}
                      </Button>
                    </Link>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <AssignConfirmDialog />
      <DuplicateAssignmentConfirmDialog
        isShowDialog={openDuplicationConfirm}
        onCancel={() => setOpenDuplicateConfirm(false)}
        onAssign={() => {
          guestRoomAssign(changeButtonType())
          setOpenDuplicateConfirm(false)
        }}
      />
    </>
  )
}

const centerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

/* assign */
const assignContainerStyle = css({
  width: '100%',
  height: '100%',
  //   width: '24%',
  //   height: '92.6vh',
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
  //   paddingLeft: 23,
  // overflowY: 'auto',
})

const assignReservationUserContainerStyle = css({
  cursor: 'pointer',
  background: '#FAFAFA',
  padding: '16px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const assignReservationUserDetailStyle = css({
  color: '#F2A40B',
})

const assignDateContainerStyle = css({
  background: '#fff',
  borderBottom: '1px solid #F2F2F2',
  padding: '0 16px',
  boxShadow: '-3px 0 3px -3px #0000001A',
  minHeight: 90,
  height: 90,
  display: 'flex',
  alignItems: 'center',
})

const assignDateWrapperStyle = css({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
})

const assignDateStartStyle = css({
  '.SingleDatePicker_picker.SingleDatePicker_picker__directionLeft': {
    top: '32px !important',
    left: '-136px !important',
    zIndex: 11,
  },
})

const assignDateEndStyle = css({
  '.SingleDatePicker_picker.SingleDatePicker_picker__directionLeft': {
    top: '32px !important',
    left: '-223px !important',
    zIndex: 11,
  },
})

const assignDateStyle = css({
  '$:last-child': {
    marginRight: 0,
  },
  p: {
    fontSize: 12,
    letterSpacing: 0.6,
    marginBottom: 8,
    fontWeight: 'bold',
  },
  input: {
    width: 80,
    height: 32,
    borderRadius: 19,
    border: '1px solid #CCCCCC',
    paddingLeft: 16,
  },
  '.DateInput': {
    width: 80,
  },
  'div.SingleDatePicker': {
    '.SingleDatePickerInput': {
      width: 80,
      border: 'none',
      'button.SingleDatePickerInput_calendarIcon': {
        display: 'none',
      },
      'div.DateInput': {
        'input.DateInput_input': {
          paddingLeft: 0,
        },
      },
    },
  },
})

const assignRoomContainerStyle = css({
  borderBottom: '1px solid #F2F2F2',
  padding: '0 16px 16px',
  backgroundColor: '#fff',
  boxShadow: '-3px 0 3px -3px #0000001A',
  flex: 1,
  overflowY: 'auto',
})

const assignTypeWrapperStyle = css({
  marginBottom: 8,
  border: '1px solid #cccccc',
  borderRadius: 5,
  opacity: 0.8,
})

const assignTypeSelectWrapperStyle = css({
  padding: 12,
})

const assignTypeTopStyle = css(centerStyle, {
  backgroundColor: '#fff',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  padding: '12px 16px 16px',
  p: {
    fontSize: 12,
    letterSpacing: 0.6,
    fontWeight: 'bold',
    color: '#676767',
  },
  textarea: {
    marginTop: 9,
    width: '100%',
    minHeight: 56,
    border: '1px solid #ccc',
    borderRadius: 10,
    lineHeight: '18px',
    padding: 12,
    resize: 'none',
    '&::placeholder': {
      color: '#CCCCCC',
    },
  },
  '.divider': {
    width: '150%',
    height: 1,
    margin: '16px -16px 0',
    backgroundColor: '#F2F2F2',
  },
})

const prepareCheckStyle = css({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  img: {
    marginRight: 8,
  },
  p: {
    fontSize: 12,
    letterSpacing: 0.6,
    color: '#676767',
  },
})

const assignAddStyle = css({
  cursor: 'pointer',
  display: 'flex',
  height: 40,
  backgroundColor: '#FAFAFA',
  border: '1px dashed #CCCCCC',
  alignItems: 'center',
  justifyContent: 'center',
  p: {
    fontSize: 12,
    letterSpacing: 1.2,
    color: '#F2A40B',
  },
  img: {
    marginRight: 11,
  },
})

const assignFooterStyle = css({
  marginTop: 'auto',
  backgroundColor: '#fff',
  boxShadow: '-3px 0 3px -3px #0000001A',
})

const assignButtonContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 12%',
  borderTop: '1px solid #F2F2F2',
})

const assignDetailLinkStyle = css({
  width: '100%',
})
