import React from 'react'
import { SerializedStyles, css } from '@emotion/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Lottie from 'react-lottie'

const animationData = require('@/static/images/json/loading.json')

type Props = {
  buttonType: number
  width?: number | string
  height?: number | string
  fontSize?: number
  fontWeight?: 'normal' | 'bold'
  borderRadius?: number
  marginTop?: number
  marginLeft?: number | 'auto'
  marginRight?: number | 'auto'
  marginBottom?: number
  paddingLeft?: number
  paddingRight?: number
  icon?: string
  loading?: boolean
  onClick?: (e?) => void
  onMouseOver?: () => void
  onMouseOut?: () => void
  isDisabled?: boolean
  type?: 'button' | 'submit' | 'reset' | undefined
  customCss?: SerializedStyles
}

export const ButtonType = {
  Primary: 1,
  Secondary: 3,
  PrimaryDisabled: 5,
  SecondaryDisabled: 12,
  NonStyle: 4,
  Simple: 8,
} as const
export type ButtonType = (typeof ButtonType)[keyof typeof ButtonType]

export const Button: React.FC<Props> = ({
  buttonType = 1,
  width = 0,
  height = 0,
  fontSize = 14,
  fontWeight = 'bold',
  borderRadius = 24,
  marginTop = 0,
  marginLeft = 0,
  marginRight = 0,
  marginBottom = 0,
  paddingLeft = 0,
  paddingRight = 0,
  icon,
  onClick,
  onMouseOver,
  onMouseOut,
  children,
  loading,
  isDisabled = false,
  type = 'button',
  customCss = css(),
}) => {
  const buttonStyle = () => {
    if (buttonType === ButtonType.Primary) return buttonType1Style
    if (buttonType === 2) return buttonType2Style
    if (buttonType === ButtonType.Secondary) return buttonType3Style
    if (buttonType === ButtonType.NonStyle) return buttonType4Style
    if (buttonType === ButtonType.PrimaryDisabled) return buttonType5Style
    if (buttonType === 6) return buttonType6Style
    if (buttonType === 7) return buttonType7Style
    if (buttonType === 8) return buttonType8Style
    if (buttonType === 9) return buttonType9Style
    if (buttonType === 10) return buttonType10Style
    if (buttonType === 11) return buttonType11Style
    if (buttonType === ButtonType.SecondaryDisabled) return buttonType12Style
  }
  const matches = useMediaQuery('(max-width:1080px)')

  return (
    <button
      onClick={!isDisabled ? onClick : undefined}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      css={[
        buttonStyle(),
        css({
          width,
          height,
          fontSize,
          fontWeight,
          borderRadius,
          marginTop,
          marginLeft,
          marginRight,
          marginBottom,
          paddingLeft,
          paddingRight,
        }),
        customCss,
      ]}
      type={type}
    >
      {loading ? (
        <Lottie width="30%" options={{ animationData: animationData, autoplay: true, loop: true }} />
      ) : (
        <>
          {icon && <img src={icon} css={iconStyle} />}
          {children}
        </>
      )}
    </button>
  )
}

const iconStyle = css({
  width: 14,
  marginRight: 7,
})

const buttonStyle = css({
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 24,
  color: '#fff',
})

const buttonType1Style = css(buttonStyle, {
  cursor: 'pointer',
  background: '#F2A40B',
  '&:hover': {
    background: '#D68E0F',
  },
})

const buttonType2Style = css(buttonStyle, {
  background: '#F2A40B',
  '&:hover': {
    background: '#D68E0F',
  },
})

const buttonType3Style = css(buttonStyle, {
  cursor: 'pointer',
  color: '#f2a40b',
  border: '1px solid #f2a40b',
  background: '#FFF',
  '&:hover': {
    color: '#FFF',
    border: '#F2A40B',
    background: '#F2A40B',
  },
})

const buttonType4Style = css(buttonType1Style, {
  color: '#676767',
  border: '1px solid #cccccc',
  background: '#FFF',
  '&:hover': {
    background: '#FFF',
    opacity: 0.7,
  },
})

const buttonType5Style = css(buttonStyle, {
  cursor: 'not-allowed',
  background: '#CCCCCC',
  color: '#FFF',
  border: 'none',
})

const buttonType6Style = css(buttonStyle, {
  cursor: 'pointer',
  background: '#CCCCCC',
  color: '#FFF',
  border: '1px solid #cccccc',
})

const buttonType7Style = css(buttonStyle, {
  cursor: 'pointer',
  color: '#f2a40b',
  border: '1px solid #cccccc',
  '&:hover': {
    background: '#f2f2f2',
  },
})

const buttonType8Style = css(buttonStyle, {
  cursor: 'pointer',
  color: '#f2a40b',
  border: '1px solid #CCCCCC',
  background: '#FFF',
  letterSpacing: 1.8,
  '&:hover': {
    background: '#F2F2F2',
  },
})

const buttonType9Style = css(buttonStyle, {
  cursor: 'pointer',
  color: '#fff',
  border: '1px solid #ff5500',
  background: '#F47110',
  '&:hover': {
    color: '#FFF',
    border: '#f28737',
    background: '#f28737',
  },
})

const buttonType10Style = css(buttonStyle, {
  cursor: 'pointer',
  color: '#E7A73C',
  border: '1px solid #E7A73C',
  background: '#FFF',
  '&:hover': {
    color: '#FFF',
    border: 'linear-gradient(to right, #FF7733, #FF2700)',
    background: 'linear-gradient(to right, #FF7733, #FF2700)',
  },
})

const buttonType11Style = css(buttonStyle, {
  color: '#ccc',
  border: '1px solid #cccccc',
  background: '#FFF',
})

const tabletStyle = css({
  height: '48px !important',
})

const buttonType12Style = css(buttonStyle, {
  position: 'absolute',
  top: '65%',
  left: '50%',
  transform: 'translate(-50%, -65%)',
  zIndex: 100,
  cursor: 'pointer',
  background: '#F2A40B',
  '&:hover': {
    background: '#D68E0F',
  },
})
