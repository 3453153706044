import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

type Props = {
  errorMessage?: string
  value: string
  onChange: (value: string) => void
} & React.InputHTMLAttributes<HTMLInputElement>

export const CardNumberField: React.FC<Props> = ({ errorMessage, onChange, value, ...props }) => {
  const { t } = useTranslation()
  const [isVisibility, setIsVisibility] = React.useState<boolean>(false)
  const [numberPeace, setNumberPeace] = React.useState<string[]>((value.match(/.{1,4}/g) || ['']).concat(['', '', '', '']).slice(0, 4))
  const [currentSelectPeace, setCurrentSelectPeace] = React.useState<number>(0)
  const [inputtedSection, setInputtedSection] = React.useState<number>(0)
  const numberPeaceRef: Array<React.RefObject<HTMLInputElement>> = []
  numberPeaceRef[0] = React.useRef<HTMLInputElement>(null)
  numberPeaceRef[1] = React.useRef<HTMLInputElement>(null)
  numberPeaceRef[2] = React.useRef<HTMLInputElement>(null)
  numberPeaceRef[3] = React.useRef<HTMLInputElement>(null)

  const formatCreditCardNumber = (data: string): string => {
    return data.replace(/[^0-9]/g, '')
  }

  const hookFocusKey = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    const currentSelection = numberPeaceRef[index].current?.selectionStart || 0
    if (numberPeaceRef[index - 1] && currentSelection === 0) {
      if (e.key === 'Backspace') {
        numberPeaceRef[index - 1].current!.focus()
      }
      if (e.key === 'ArrowLeft') {
        numberPeaceRef[index - 1].current!.focus()
        e.preventDefault()
      }
    }
    if (numberPeaceRef[index + 1] && currentSelection === 4) {
      if (e.key === 'ArrowRight') {
        numberPeaceRef[index + 1].current!.focus()
        numberPeaceRef[index + 1].current!.selectionStart = 0
        numberPeaceRef[index + 1].current!.selectionEnd = 0
        e.preventDefault()
      }
    }
  }

  const focusPeace = (index: number) => {
    setCurrentSelectPeace(index)
    if (firstEmptyPeace < index) {
      numberPeaceRef[firstEmptyPeace].current?.focus()
    }
  }

  const inputPeace = (index: number, value: string) => {
    setInputtedSection(numberPeaceRef[index].current!.selectionStart || 0)
    numberPeace[index] = formatCreditCardNumber(value)
    setNumberPeace((numberPeace.join('').match(/.{1,4}/g) || ['']).concat(['', '', '', '']).slice(0, 4))
  }

  const firstEmptyPeace = React.useMemo(() => {
    const emptyIndex = numberPeace.findIndex(peace => peace.length !== 4)
    return emptyIndex === -1 ? 3 : emptyIndex
  }, [numberPeace])

  React.useEffect(() => {
    // console.log(numberPeace)
    onChange(numberPeace.join(''))
    if (inputtedSection === 4 && numberPeaceRef[currentSelectPeace + 1]) {
      numberPeaceRef[currentSelectPeace + 1].current!.focus()
      numberPeaceRef[currentSelectPeace + 1].current!.selectionStart = 0
      numberPeaceRef[currentSelectPeace + 1].current!.selectionEnd = 0
    } else {
      numberPeaceRef[currentSelectPeace].current!.selectionStart = inputtedSection
      numberPeaceRef[currentSelectPeace].current!.selectionEnd = inputtedSection
    }
  }, [numberPeace])

  return (
    <>
      <div>
        <div
          css={inputStyle}
          style={errorMessage ? { borderColor: '#e30000' } : {}}
          onClick={() => {
            numberPeaceRef[3].current!.focus()
            numberPeaceRef[3].current!.selectionStart = 4
            numberPeaceRef[3].current!.selectionEnd = 4
          }}
        >
          <input
            {...props}
            type={isVisibility ? 'tel' : 'password'}
            inputMode="tel"
            style={{ width: value.length === 0 || isVisibility ? '4em' : '5.5em' }}
            ref={numberPeaceRef[0]}
            placeholder={value.length === 0 ? '1234' : ''}
            onKeyDown={e => hookFocusKey(0, e)}
            onFocus={e => focusPeace(0)}
            onChange={e => inputPeace(0, e.target.value)}
            onClick={e => e.stopPropagation()}
            value={numberPeace[0]}
            autoComplete="off"
          />
          <input
            {...props}
            type={isVisibility ? 'tel' : 'password'}
            inputMode="tel"
            style={{ width: value.length === 0 || isVisibility ? '4em' : '5.5em' }}
            ref={numberPeaceRef[1]}
            placeholder={value.length === 0 ? '1234' : ''}
            onKeyDown={e => hookFocusKey(1, e)}
            onFocus={e => focusPeace(1)}
            onChange={e => inputPeace(1, e.target.value)}
            onClick={e => e.stopPropagation()}
            value={numberPeace[1]}
            autoComplete="off"
          />
          <input
            {...props}
            type={isVisibility ? 'tel' : 'password'}
            inputMode="tel"
            style={{ width: value.length === 0 || isVisibility ? '4em' : '5.5em' }}
            ref={numberPeaceRef[2]}
            placeholder={value.length === 0 ? '1234' : ''}
            onKeyDown={e => hookFocusKey(2, e)}
            onFocus={e => focusPeace(2)}
            onChange={e => inputPeace(2, e.target.value)}
            onClick={e => e.stopPropagation()}
            value={numberPeace[2]}
            autoComplete="off"
          />
          <input
            {...props}
            type={isVisibility ? 'tel' : 'password'}
            inputMode="tel"
            style={{ width: value.length === 0 || isVisibility ? '4em' : '5.5em' }}
            ref={numberPeaceRef[3]}
            placeholder={value.length === 0 ? '1234' : ''}
            onKeyDown={e => hookFocusKey(3, e)}
            onFocus={e => focusPeace(3)}
            onChange={e => inputPeace(3, e.target.value)}
            onClick={e => e.stopPropagation()}
            value={numberPeace[3]}
            autoComplete="off"
          />
          <div
            css={suffixIconStyle}
            onClick={e => {
              setIsVisibility(!isVisibility)
              e.stopPropagation()
            }}
          >
            {isVisibility ? (
              <img src={require('@/static/images/icon_eye_off.svg')}></img>
            ) : (
              <img src={require('@/static/images/icon_eye_on.svg')}></img>
            )}
          </div>
        </div>
      </div>
      {errorMessage && <p css={errorStyle}>{t(errorMessage)}</p>}
    </>
  )
}

const suffixIconStyle = css({
  position: 'absolute',
  cursor: 'pointer',
  padding: 5,
  top: 'calc((60px - 30px) / 2)',
  right: 30,
  img: {
    width: 20,
    height: 20,
  },
})

const inputStyle = css({
  border: '1px solid #CCCCCC',
  borderRadius: '40px',
  fontSize: '21px',
  height: '61px',
  margin: '0',
  lineHeight: '1',
  padding: '0 20px',
  overflow: 'hidden',
  position: 'relative',
  '> input': {
    height: '61px',
    border: 'none',
    '&::placeholder': {
      color: '#CCCCCC',
      fontWeight: 'normal',
    },
  },
})

const errorStyle = css({
  color: '#e30000',
  marginTop: 10,
})
