import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { AssetType, FormatType, SpaceChargePriceType, SpaceDetailType } from '@/models/guest-app/asset'
import { ContainerHeader } from '@/components/molecules/settings/container-header'
import { ContainerSidePanelBody } from '@/components/molecules/settings/container-side-panel-body'
import { AssetDetailContext } from '@/components/pages/setting/guest-app/asset-detail'
import { deleteAssetDetail, updateAsset, updateAssetDetail, updateAssetDetailDisplayOrder } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { useDraggableDom } from '@/components/molecules/settings/use-draggable-dom'
import { GuestAppTableHeader, GuestAppTableBody, GuestAppTableRow } from '@/components/atoms/settings/guest-app-table'
import { SpaceDetailModal } from './space-detail-modal'

type Props = {
  asset: AssetType & { details: SpaceDetailType[] }
}

export const SpaceAssetList: React.FC<Props> = ({ asset }) => {
  const { t } = useTranslation()
  const [formValue, setFormValue] = useState<{ title: string; details: SpaceDetailType[] }>({
    title: asset.title,
    details: asset.details,
  })
  const [deleteIds, setDeleteIds] = useState<string[]>([])
  const [showDetailProp, setShowDetailProp] = useState<SpaceDetailType | undefined | null>(undefined)
  const { doReload, setIsLoading, setIsEditing, listenDoSave, listenDoCancel } = useContext(AssetDetailContext)
  const { errorHandler } = useErrorHandler()
  const addDeleteTarget = (id: string) => {
    setFormValue({ ...asset, details: formValue.details.filter(detail => detail.id !== id) })
    setDeleteIds([...deleteIds, id])
    setIsEditing(true)
  }

  const changeDisplayOrder = (newList: SpaceDetailType[]) => {
    setFormValue({ ...asset, details: newList })
    setIsEditing(true)
  }
  const { dragStart, dragOver, dragDrop } = useDraggableDom({ list: formValue.details, onChange: changeDisplayOrder })

  const saveFormValue = async () => {
    try {
      setIsLoading(true)
      if (deleteIds.length) {
        await deleteAssetDetail(asset.id, deleteIds)
        setDeleteIds([])
      }
      if (formValue.details.length) {
        await updateAssetDetailDisplayOrder(
          asset.id,
          formValue.details.map(detail => detail.id),
        )
      }
      await updateAsset(asset.id, { title: formValue.title })
      doReload()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const doToggleFavorite = async (assetId: string, detail: SpaceDetailType) => {
    try {
      setIsLoading(true)
      await updateAssetDetail(assetId, detail.id, FormatType.Space, { isFavorite: !detail.isFavorite })
      doReload()
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const computeChargePriceText = (price: SpaceChargePriceType): string => {
    if (!price.unitPrice) {
      if (price.maxPrice) {
        return `¥${price.maxPrice.toLocaleString()}`
      }
      return '-'
    }
    const priceText = `¥${price.unitPrice.toLocaleString()}/時間`
    if (!price.maxPrice) {
      return priceText
    }
    return `${priceText}、最大¥${price.maxPrice.toLocaleString()}`
  }

  useEffect(() => {
    if (listenDoSave) saveFormValue()
  }, [listenDoSave])

  useEffect(() => {
    if (listenDoCancel) {
      setDeleteIds([])
      doReload()
    }
  }, [listenDoCancel])

  useEffect(() => {
    if (!asset) {
      return
    }
    setFormValue({
      title: asset.title,
      details: asset.details,
    })
  }, [asset])

  return (
    <>
      <ContainerHeader
        goBack
        title={formValue.title}
        onChangeTitle={(newTitle: string) => {
          setFormValue({ ...formValue, title: newTitle })
          setIsEditing(true)
        }}
        onClickAddButton={() => setShowDetailProp(null)}
      />
      <ContainerSidePanelBody menus={[{ title: t('List'), path: '', isActive: true }]}>
        <>
          <GuestAppTableHeader>
            <div css={favoriteColStyle}></div>
            <div css={thumbnailColStyle}></div>
            <div css={titleColStyle}>{t('Title')}</div>
            <div css={salesHoursColStyle}>{t('Sales hours')}</div>
            <div css={salesPriceColStyle}>{t('Sales price')}</div>
            <div css={stockColStyle}>{t('Stock')}</div>
            <div css={deleteColStyle}></div>
          </GuestAppTableHeader>
          <GuestAppTableBody>
            {formValue.details.map(detail => (
              <GuestAppTableRow
                id={detail.id}
                key={detail.id}
                onClick={() => setShowDetailProp(detail)}
                draggable="true"
                onDragStart={dragStart}
                onDragOver={dragOver}
                onDrop={dragDrop}
              >
                <div
                  css={favoriteColStyle}
                  onClick={e => {
                    e.stopPropagation()
                    doToggleFavorite(asset.id, detail)
                  }}
                >
                  {detail.isFavorite ? (
                    <img src={require('@/static/images/icon_star_f.svg')}></img>
                  ) : (
                    <img src={require('@/static/images/icon_star_n.svg')}></img>
                  )}
                </div>
                <div css={thumbnailColStyle}>
                  {!!detail.images.length ? (
                    <img src={detail.images[0]}></img>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#f2f2f2',
                        color: '#bfbfbf',
                        fontSize: 10,
                        height: 38,
                        borderRadius: 5,
                      }}
                    >
                      No image
                    </div>
                  )}
                </div>
                <div css={titleColStyle}>{detail.title}</div>
                <div css={salesHoursColStyle}>
                  {detail.salesHour.start}~{detail.salesHour.end}
                </div>
                <div css={salesPriceColStyle}>{detail.spacePrice ? computeChargePriceText(detail.spacePrice.chargePrice) : '-'}</div>
                <div css={stockColStyle}>{detail.stock}</div>
                <div css={deleteColStyle}>
                  <DeleteIcon onClick={() => addDeleteTarget(detail.id)} />
                </div>
              </GuestAppTableRow>
            ))}
          </GuestAppTableBody>
        </>
      </ContainerSidePanelBody>
      {showDetailProp !== undefined && (
        <SpaceDetailModal
          asset={asset}
          detail={showDetailProp}
          onCancel={() => setShowDetailProp(undefined)}
          onSave={() => setShowDetailProp(undefined)}
        />
      )}
    </>
  )
}

const favoriteColStyle = css({
  marginLeft: 62,
  width: 18,
})
const thumbnailColStyle = css({
  marginLeft: 16,
  marginRight: 24,
  width: 'calc(38px * 16 / 9)',
  '> img': {
    height: 38,
    borderRadius: 5,
  },
})
const titleColStyle = css({
  flexGrow: 1,
  overflowWrap: 'anywhere',
  width: 'min-content',
})
const salesHoursColStyle = css({
  width: 139,
})
const salesPriceColStyle = css({
  width: 242,
})
const stockColStyle = css({
  width: 51,
})
const deleteColStyle = css({
  width: 30,
  margin: '0 16px',
})
