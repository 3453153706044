export type AccommodationInput = {
  isJapanese?: NationalityType
  nationality?: string
  firstName: string
  lastName: string
  firstNameFurigana: string
  lastNameFurigana: string
  dateOfBirth: string
  gender: GenderType
  phoneNumber: string
  mail: string
  occupation?: string
  otherOccupation?: string
  company?: string
  postCode?: string
  address: string
  imageUrlFront?: string
  imageUrlBack?: string
  paxTotal?: number
  accompany?: any[]
  hasContactForAllGuest: boolean
}

export const NationalityType = {
  Jp: 'japanese',
  Njp: 'nonJapanese',
} as const
export type NationalityType = (typeof NationalityType)[keyof typeof NationalityType]

export const GenderType = {
  Male: 'M',
  Female: 'F',
} as const
export type GenderType = (typeof GenderType)[keyof typeof GenderType]

export const OccupationType = {
  Employee: '会社員',
  SelfEmployee: '自営業',
  Student: '学生',
  NoOccupation: 'なし',
  Other: 'その他',
} as const
export type OccupationType = (typeof OccupationType)[keyof typeof OccupationType]

export const occupationList = [
  { name: 'SelectOptions.Occupation.Employee', value: '会社員' },
  { name: 'SelectOptions.Occupation.SelfEmployed', value: '自営業' },
  { name: 'SelectOptions.Occupation.Student', value: '学生' },
  { name: 'SelectOptions.Occupation.NoOccupation', value: 'なし' },
  { name: 'SelectOptions.Occupation.Other', value: 'その他' },
]

export const isEmployee = (occupation: string | undefined) => {
  return occupation === OccupationType.Employee || occupation === OccupationType.SelfEmployee
}
