import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

//components
import { DatePicker } from '@/components/organisms/customer/sales-manager/date-picker'

// constants
import { deleteStyle } from '@/constants/layout'

// models
import { DiscountSettingType, EditDiscountType, PaymentSettingType } from '@/models/sales'
import { ReservationType } from '@/models/reservation'
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'
import { Cascader, Select } from 'antd'
import { getRoomCascadeOptionsByReservations, findDefaultRoomCascadeOption, roomCascadeOptionsLabelRender } from '@/utils/reservations'
import { initializeSelectBoxStyle } from '@/constants/form'

type DiscountSubjectProps = {
  editDiscount: EditDiscountType
  salesDiscounts: DiscountSettingType[]
  salesDiscountPayments: PaymentSettingType[]
  changeDate: (date, id) => void
  changeState: (e, id) => void
  deleteDiscountSubject: (id) => void
  index: number
  confirmedDay: string
  changeSalesPrice: (salesPrice, index) => void
  reservations?: ReservationType[]
  reservation?: ReservationType
  accountsReceivableSettings: AccountsReceivableSettingType[]
  currentTabReservationId: string
  advanceCompany?: any
  salesDiscountPaymentsGroupBySalesDate?: any
}

export const DiscountSubject: React.FC<DiscountSubjectProps> = ({
  editDiscount,
  salesDiscounts,
  salesDiscountPayments,
  changeDate,
  changeState,
  deleteDiscountSubject,
  index,
  confirmedDay,
  changeSalesPrice,
  reservations,
  reservation,
  currentTabReservationId,
  accountsReceivableSettings,
  advanceCompany,
  salesDiscountPaymentsGroupBySalesDate,
}) => {
  const { t } = useTranslation()
  const [salesPaymentOptions, setSalesPaymentOptions] = useState<any[]>([{ value: '', label: '' }])

  useEffect(() => {
    const newAdvanceCompany = advanceCompany?.paymentCompanies
      ?.filter(item => salesDiscountPaymentsGroupBySalesDate[editDiscount.salesDate]?.some(v => v.company_id === item?.id && v.is_paid))
      .map(item => ({ value: item?.id, label: item?.name }))

    const salesPaymentOptionsArr = salesDiscountPayments.map(item => {
      if (
        Object.keys(advanceCompany?.paymentMethod).length &&
        item?.id === advanceCompany?.paymentMethod?.id &&
        salesDiscountPaymentsGroupBySalesDate[editDiscount.salesDate]?.some(v => v.sales_payment_id === item?.id && v.is_paid)
      ) {
        return { value: item?.id, label: item?.name, children: newAdvanceCompany }
      } else if (item?.name === '-') {
        return { value: item?.id, label: item?.name }
      } else if (salesDiscountPaymentsGroupBySalesDate[editDiscount.salesDate]?.some(v => v.sales_payment_id === item?.id && v.is_paid)) {
        return { value: item?.id, label: item?.name }
      } else {
        return null
      }
    })

    setSalesPaymentOptions(salesPaymentOptionsArr.filter(v => !!v))
  }, [editDiscount.salesDate, salesDiscountPayments])

  const displayRender = label => label[label.length - 1]

  // Autofill
  const clickForm = (salesDiscounts: DiscountSettingType[]) => {
    salesDiscounts?.map((salesDiscount: DiscountSettingType) => {
      if (editDiscount.salesDiscountId === salesDiscount.id) {
        changeSalesPrice(salesDiscount.price, index)
      }
    })
  }

  const roomsOptions = getRoomCascadeOptionsByReservations({ reservation, reservations })

  // FIXME
  // eslint-disable-next-line no-prototype-builtins
  if (!editDiscount.hasOwnProperty('salesDate')) return null
  return (
    <tr>
      <td>
        <DatePicker
          date={editDiscount.salesDate ? moment(editDiscount.salesDate) : null}
          setDate={async date => {
            await changeDate(date, index)
            if (
              !salesDiscountPaymentsGroupBySalesDate[editDiscount.salesDate]?.some(
                v => v.sales_payment_id === editDiscount.salesPaymentId && v.is_paid,
              )
            ) {
              const eventChangeSalesPaymentId = {
                target: { value: salesPaymentOptions[0].value, name: 'salesPaymentId' },
              }

              const eventChangeCompanyId = {
                target: { value: '', name: 'companyId' },
              }

              await changeState(eventChangeSalesPaymentId, index)
              await changeState(eventChangeCompanyId, index)
            } else {
              if (
                advanceCompany?.paymentMethod?.id === editDiscount.salesPaymentId &&
                !salesDiscountPaymentsGroupBySalesDate[editDiscount.salesDate]?.some(
                  v => String(v.company_id ?? '') === editDiscount.companyId && v.is_paid,
                )
              ) {
                const eventChangeSalesPaymentId = {
                  target: { value: salesPaymentOptions[0].value, name: 'salesPaymentId' },
                }

                const eventChangeCompanyId = {
                  target: { value: '', name: 'companyId' },
                }

                await changeState(eventChangeSalesPaymentId, index)
                await changeState(eventChangeCompanyId, index)
              }
            }
          }}
          confirmedDay={confirmedDay}
        />
      </td>
      <td>
        <select
          name="salesDiscountId"
          css={largeSelectBoxStyle}
          value={editDiscount.salesDiscountId}
          onChange={e => {
            changeState(e, index)
            clickForm(salesDiscounts)
          }}
        >
          <option selected={editDiscount.salesDiscountId === ''} value="">
            {t('Please select')}
          </option>
          {salesDiscounts?.map((salesDiscount: DiscountSettingType, index) => {
            return (
              <option key={index} selected={editDiscount.salesDiscountId === salesDiscount.id} value={salesDiscount.id}>
                {salesDiscount.name}
              </option>
            )
          })}
        </select>
      </td>
      <td>
        <Cascader
          options={roomsOptions}
          expandTrigger="hover"
          className="cascader-customize"
          defaultValue={findDefaultRoomCascadeOption({
            reservations: reservations ? reservations : [reservation],
            reservationId: currentTabReservationId,
            roomsOptions,
          })}
          displayRender={roomCascadeOptionsLabelRender}
          popupClassName="popup-cascader-customize"
          onChange={async value => {
            changeState(
              {
                target: { value: value[0], name: 'reservationId' },
              },
              index,
            )
            changeState(
              {
                target: { value: value[1], name: 'guestRoomAssignId' },
              },
              index,
            )
          }}
          allowClear={false}
          suffixIcon={<span></span>}
          changeOnSelect
        />
      </td>

      <td>
        <Cascader
          options={salesPaymentOptions}
          expandTrigger="hover"
          className="cascader-customize"
          value={
            editDiscount.companyId
              ? [editDiscount.salesPaymentId, editDiscount.companyId]
              : editDiscount.salesPaymentId
                ? [editDiscount.salesPaymentId]
                : [salesPaymentOptions[0]?.value ?? '']
          }
          displayRender={displayRender}
          popupClassName="popup-cascader-customize"
          onChange={async value => {
            const eventChangeSalesPaymentId = {
              target: { value: value[0], name: 'salesPaymentId' },
            }

            const eventChangeCompanyId = {
              target: { value: value[1] ? value[1] : '', name: 'companyId' },
            }
            await changeState(eventChangeSalesPaymentId, index)
            changeState(eventChangeCompanyId, index)
          }}
          allowClear={false}
          suffixIcon={<span></span>}
        />
      </td>
      <td>
        <input
          name="salesSubjectPrice"
          type="number"
          value={editDiscount.salesSubjectPrice}
          css={unitPriceStyle}
          onChange={e => changeState(e, index)}
          placeholder="0000"
          className="unitPriceStyle"
        />
      </td>
      <td>
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={true}
          suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
          onChange={value => {
            changeState(
              {
                target: { value, name: 'salesAccountsReceivableMasterId' },
              },
              index,
            )
          }}
          value={editDiscount.salesAccountsReceivableMasterId || ''}
        >
          <Select.Option value="">-</Select.Option>
          {accountsReceivableSettings.map((accountsReceivable, index) => (
            <Select.Option key={index} value={accountsReceivable.id}>
              {accountsReceivable.name}
            </Select.Option>
          ))}
        </Select>
      </td>
      <td>
        <div css={deleteStyle} className="delete-none-0" onClick={() => deleteDiscountSubject(index)}>
          <img src={require('@/static/images/delete_yellow.svg')} />
        </div>
      </td>
    </tr>
  )
}

const selectBoxStyle = css(initializeSelectBoxStyle, {
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  display: 'block',
  paddingLeft: 14,
  paddingRight: 14,
  marginRight: 10,
})

const middleInputStyle = css(selectBoxStyle, {
  marginRight: '2.5%',
  width: '8.5%',
  backgroundImage: 'none',
  '::placeholder': {
    color: '#ccc',
  },
})

export const unitPriceStyle = css(middleInputStyle, {
  marginLeft: 12,
})

const largeSelectBoxStyle = css(selectBoxStyle, {
  marginRight: '1.5%',
  width: '90%',
  backgroundPosition: '95% center',
})

export const xlargeSelectBoxStyle = css(selectBoxStyle, {
  width: 'calc(20%)',
  margin: '0 2% 0 1.5%',
  backgroundPosition: '95% center',
})
