import React, { useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { SINGLE_CHOICE, MULTIPLE_CHOICE } from '@/constants/type-select-reservations'
import { SelfCheckinHeader } from '@/components/molecules/self-checkin/header'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'
import { ReservationDetail } from './reservation-detail'
import { ErrorNotice } from './error-notice'
import { useHistory, useLocation } from 'react-router-dom'
import { fetchCheckinReservationsAndSales, selfCheckinGuestRoomAutoAssign } from '@/apis/aipass'
import { setHasPaymentPlugin } from '@/libs/plugins'
import { changeRequiredIdentify } from '@/utils/required-identify'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { SearchReservationTypeWhenCheckedIn, SearchReservationTypeWhenNonCheckIn } from '@/models/reservation'

export const SelectReservation: React.FC<{}> = () => {
  const [selectReservationType, setSelectReservationType] = useState<number>(MULTIPLE_CHOICE)
  const [checkedList, setCheckedList] = useState<string[]>([])
  const [checkedSingleResvertion, setCheckedSingleResvertion] = useState<string>('')
  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false)
  const { state, search } = useLocation<{
    basicInfo: any
    hotelId: string
    plugin: any
    selectedReservations: any
    singleSelectedResesvation: any
    from: any
    paymentInfo: any
    selectReservationType: any
    requiredIdentify: any
    requiredJapaneseIdentify: boolean
    requiredForeignIdentify: boolean
    requiredAccompanyNameInput: boolean
    accommodationInfo: any
    paymentSetting: any
    notCheckinReservations: SearchReservationTypeWhenNonCheckIn[] | undefined
    preCheckinReservations: SearchReservationTypeWhenCheckedIn[] | undefined
  }>()

  const history = useHistory()

  const { t, i18n } = useTranslation()

  const isDisabled = (): boolean => {
    const checkDisabled =
      Boolean(
        (selectReservationType === MULTIPLE_CHOICE && checkedList.length === 0) ||
          (selectReservationType === SINGLE_CHOICE && !checkedSingleResvertion),
      ) || isDisabledSubmit
    return checkDisabled
  }

  const checkWhiteSpace = (value: string) => {
    return value.includes(' ')
  }

  const parseReservationIntoInput = reservation => {
    const enteredAccompanies =
      reservation?.accompany.length > 0
        ? reservation?.accompany.map(value => {
            const lastName = value.name.slice(0, value.name.indexOf(' '))
            const firstName = value.name.slice(value.name.indexOf(' ') + 1, value.name.length)
            return { id: value.id, name: value.name, lastName: lastName, firstName: firstName }
          })
        : []
    const selectedPaxNum = (reservation?.accompany.length || 0) + 1
    const selectedHasContact = selectedPaxNum > 1 && selectedPaxNum.length - 1 !== enteredAccompanies

    return {
      nationality: reservation.nationality === 'JPN' || !reservation.nationality ? 'japanese' : 'nonJapanese',
      firstName: checkWhiteSpace(reservation.name) ? reservation.name.substring(reservation.name.indexOf(' ') + 1) : '',
      lastName: checkWhiteSpace(reservation.name) ? reservation.name.substring(0, reservation.name.indexOf(' ')) : reservation.name,
      firstNameFurigana: checkWhiteSpace(reservation.nameKana) ? reservation.nameKana.substring(reservation.nameKana.indexOf(' ') + 1) : '',
      lastNameFurigana: checkWhiteSpace(reservation.nameKana)
        ? reservation.nameKana.substring(0, reservation.nameKana.indexOf(' '))
        : reservation.nameKana,
      dateOfBirth: reservation.birthDate ?? '',
      gender: reservation.gender ?? 'M',
      phoneNumber: reservation.telephone && reservation.telephone !== '0' ? reservation.telephone : '',
      mail: reservation.email ?? '',
      occupation: reservation.occupation ?? '',
      otherOccupation: reservation.otherOccupation ?? '',
      postCode: reservation.postCode ?? '',
      company: reservation.company ?? '',
      address: reservation.address ?? '',
      checkinId: reservation.checkinId,
      checkinCode: reservation.checkinCode,
      accompany: enteredAccompanies,
      paxTotal: selectedPaxNum,
      hasContactForAllGuest: selectedPaxNum === 1 ? !state.requiredAccompanyNameInput : selectedHasContact,
    }
  }

  const isCheckedListReservation = item => checkedList.includes(item)

  const handleCheckListResvartion = (e, id) => {
    e.preventDefault()
    let updatedListReservations = [...checkedList]
    if (!isCheckedListReservation(id)) {
      updatedListReservations = [...checkedList, id]
    } else {
      updatedListReservations.splice(checkedList.indexOf(id), 1)
    }
    setCheckedList(updatedListReservations)
  }

  const goBack = (): void => {
    delete state.selectedReservations
    delete state.singleSelectedResesvation
    history.push({
      pathname: '/self-checkin/search-reservation',
      search,
      state,
    })
  }

  const submit = async () => {
    setIsDisabledSubmit(true)
    if (selectReservationType === MULTIPLE_CHOICE) {
      delete state.singleSelectedResesvation
      selfCheckinGuestRoomAutoAssign({ hotelId: state.hotelId, reservationIds: checkedList })
      history.push({
        pathname: '/self-checkin/accommodation-info',
        search,
        state: {
          ...state,
          selectedReservations: checkedList,
          selectedReservationDetails: state.notCheckinReservations?.filter(r => checkedList.includes(r.reservationId)),
          selectReservationType,
          paxTotal: state.notCheckinReservations?.filter(r => checkedList.includes(r.reservationId))[0].paxTotal,
        },
      })
    }

    if (selectReservationType === SINGLE_CHOICE) {
      delete state.selectedReservations
      const checkinIdReservations = state.preCheckinReservations?.filter(r => checkedSingleResvertion === r.checkinId)
      const reservationIds = checkinIdReservations?.map(r => r.reservationId) as string[]
      selfCheckinGuestRoomAutoAssign({ hotelId: state.hotelId, reservationIds })
      fetchCheckinReservationsAndSales(state.hotelId, reservationIds).then(async res => {
        if (!res) return

        if (res?.reservations[0]?.nationality) {
          const responseRequiredIdentify = changeRequiredIdentify(
            res?.reservations[0]?.nationality,
            state.requiredJapaneseIdentify,
            state.requiredForeignIdentify,
          )
          state.requiredIdentify = responseRequiredIdentify
        }

        if (setHasPaymentPlugin(state.plugin) && state.paymentSetting.length) {
          if (res?.totalAmount > 0) {
            history.push({
              pathname: '/self-checkin/payment',
              search,
              state: {
                ...state,
                singleSelectedResesvation: reservationIds,
                reservationInfo: parseReservationIntoInput(res.reservations[0]),
                selectReservationType,
              },
            })
            return
          }
        }

        delete state.paymentInfo
        let nextPath = '/self-checkin/checkedin-confirm'
        if (state?.requiredIdentify) {
          nextPath = `/self-checkin/identity-verify/${i18n.language !== 'ja' ? 'upload-passport' : 'upload-license'}`
        }
        history.push({
          pathname: nextPath,
          search,
          state: {
            ...state,
            singleSelectedResesvation: reservationIds,
            reservationInfo: parseReservationIntoInput(res.reservations[0]),
            selectReservationType,
          },
        })
      })
    }
  }

  const handleChangeSelectReservationType = e => {
    delete state.paymentInfo
    delete state.from
    setSelectReservationType(parseInt(e.target.value))
  }

  const handleCheckSingleReservation = id => {
    setCheckedSingleResvertion(id)
  }

  const computedViewReservations = (): SearchReservationTypeWhenNonCheckIn[] | SearchReservationTypeWhenCheckedIn[][] | undefined => {
    return selectReservationType === MULTIPLE_CHOICE ? state.notCheckinReservations : preCheckinReservationsByCheckin
  }
  const preCheckinReservationsByCheckin = useMemo(() => {
    if (!state.preCheckinReservations?.length) {
      return []
    }
    const checkinGroup: { [checkinId: string]: SearchReservationTypeWhenCheckedIn[] } = {}
    state.preCheckinReservations.forEach(r => {
      if (!checkinGroup[r.checkinId]) {
        checkinGroup[r.checkinId] = []
      }
      checkinGroup[r.checkinId].push(r)
    })
    return Object.values(checkinGroup)
  }, [state])

  useEffect(() => {
    if (!state) {
      history.replace({ pathname: '/dashboard' })
      return
    }
    delete state.accommodationInfo

    if (state.selectReservationType) {
      setSelectReservationType(state.selectReservationType)
    } else if (!state.notCheckinReservations?.length) {
      setSelectReservationType(SINGLE_CHOICE)
    }

    if (state.selectedReservations) {
      setCheckedList(state.selectedReservations)
    } else {
      setCheckedList(state.notCheckinReservations?.map(r => r.reservationId) || [])
    }

    if (state.singleSelectedResesvation) {
      const selectedReservationByCheckin = state.preCheckinReservations?.find(cr => cr.reservationId === state.singleSelectedResesvation[0])
      if (selectedReservationByCheckin) {
        setCheckedSingleResvertion(selectedReservationByCheckin.checkinId)
      }
    } else if (state.preCheckinReservations) {
      setCheckedSingleResvertion(state.preCheckinReservations[0].checkinId)
    }
  }, [state])

  return (
    <div css={containerStyle}>
      <SelfCheckinHeader goToPreviousPage={goBack} title={t('Select reservation')} />
      <div css={mainStyle}>
        <p className="subtitle">{t('Please select the reservation')}</p>
        <div css={selectReservationRadio}>
          <RadioGroupField
            value={selectReservationType}
            items={[
              {
                value: MULTIPLE_CHOICE,
                label: t('Reservations without advance check-in'),
              },
              {
                value: SINGLE_CHOICE,
                label: t('Reservations with advance check-in'),
              },
            ]}
            style={{ radioSize: 35, direction: 'column' }}
            itemWrapperCss={selectReservationItem}
            itemCss={radioButtonStyle}
            onChange={handleChangeSelectReservationType}
          />
        </div>

        <div css={wrapperStyle}>
          {computedViewReservations()?.length ? (
            computedViewReservations()!.map((reservation, index) => (
              <div css={reservationWrapperStyle} key={index}>
                <ReservationDetail
                  reservation={reservation}
                  selectType={selectReservationType}
                  handleCheckListResvartion={handleCheckListResvartion}
                  isCheckedListReservation={isCheckedListReservation}
                  checkedSingleResvertion={checkedSingleResvertion}
                  handleCheckSingleReservation={handleCheckSingleReservation}
                />
              </div>
            ))
          ) : (
            <div css={errorWrapperStyle}>
              <ErrorNotice />
            </div>
          )}
        </div>
      </div>
      <SelfCheckinFooter isDisabled={isDisabled} goToNextPage={submit} isNext={'next'} />
    </div>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
    color: '#272727',
  },
})

const mainStyle = css({
  padding: '102px 0 117px ',
  minHeight: '100vh',

  '.subtitle': {
    textAlign: 'center',
    lineHeight: '31px',
    letterSpacing: '3.15px',
    fontSize: '21px',
    fontWeight: 'bold',
    marginBottom: '24px',
  },
})
const selectReservationRadio = css({
  marginTop: '24px',
  marginBottom: '32px',
  width: '100%',
})

const selectReservationItem = css({
  display: 'flex',
  height: '59px',
  backgroundColor: '#fff',
  '&:first-of-type': {
    borderBottom: '1px solid #f2f2f2',
  },
})

const radioButtonStyle = css({
  width: '100%',
  margin: '0 auto',
  maxWidth: 872,
  label: {
    color: '#272727',
    fontWeight: 'bold',
    fontSize: '17px',
    letterSpacing: '1.7px',
  },
})

const reservationWrapperStyle = css({
  maxWidth: 872,
  margin: '32px auto 0',
  border: '1px solid #ccc',
  borderRadius: '5px',
})

const wrapperStyle = css({
  padding: '0 77px 0 75px',
})
const errorWrapperStyle = css({
  maxWidth: 872,
  margin: '32px auto 0',
  border: '1px solid #ccc',
  borderRadius: '5px',
})
