import React from 'react'
import dayjs from 'dayjs'
import { DateField } from '../molecules/date-field'
import { SerializedStyles } from '@emotion/core'

interface CalendarWithArrowProps {
  dateOf: dayjs.Dayjs
  setDate: (arg0: dayjs.Dayjs) => void
  unit: 'month' | 'day'
  overWriteDateFieldStyle?: SerializedStyles
  displayFormat?: string
  customCss?: SerializedStyles
}

export const CalendarWithArrow: React.FC<CalendarWithArrowProps> = ({
  dateOf,
  setDate,
  unit = 'day',
  overWriteDateFieldStyle,
  displayFormat,
  customCss,
}) => {
  return (
    <div css={[{ display: 'flex', alignItems: 'center', justifyContent: 'center' }, customCss]}>
      <img
        style={{ cursor: 'pointer' }}
        src={require('@/static/images/arrow_left_gray.svg')}
        onClick={() => {
          setDate(dateOf.subtract(1, unit))
        }}
      />
      <DateField
        customCss={overWriteDateFieldStyle}
        value={dateOf.format('YYYY-MM-DD')}
        displayFormat={displayFormat}
        onChange={date => date && setDate(dayjs(date.format('YYYY-MM-DD')))}
      />
      <img
        style={{ cursor: 'pointer' }}
        src={require('@/static/images/arrow_right_gray.svg')}
        onClick={() => {
          setDate(dateOf.add(1, unit))
        }}
      />
    </div>
  )
}
