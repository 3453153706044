import React, { useContext, useEffect, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import 'dayjs/locale/ja'
import * as api from '@/apis/aipass'
import { Button } from '@/components/atoms/button'
import { LoaderContextCreator } from '@/contexts/loader'
import { CustomerType } from '@/models/customer-manager/customer'
import { ReservationNoteModal } from './reservation-note-modal'
import { ReservationMemos, ReservationNote, ReservationNoteCategory } from '@/models/reservation/reservation-note'
import { Hint } from '@/components/atoms/hint'
import { CategoryLabel } from '@/components/atoms/reservation-note/category-label'

type MemoDetailProps = {
  reservationId: string
  checkoutDate: string
  customer?: CustomerType
  onSavedData?: () => void
}

export const ReservationDetailMemo: React.FC<MemoDetailProps> = ({ reservationId, onSavedData, customer, checkoutDate }) => {
  const { t } = useTranslation()
  const [canEditMemo, setCanEditMemo] = useState<boolean>(false)
  const [inputNotes, setInputNotes] = useState<ReservationMemos | null>(null)
  const [pastNotes, setPastNotes] = useState<ReservationNote[]>([])
  const [showModal, setShowModal] = useState(false)
  const { setIsLoading } = useContext(LoaderContextCreator())

  const setMemos = (response: ReservationNote[]) => {
    const memos = response.reduce((acc, r) => {
      acc[r.category] = r.memo
      return acc
    }, {} as ReservationMemos)

    setInputNotes(memos)
  }

  const fetchReservationPastNote = async () => {
    if (!customer) {
      return
    }

    await api
      .fetchReservationPastNote({ customerId: customer.id, checkoutDate })
      .then(res => res?.length && setPastNotes(res.filter(({ memo }) => memo)))
  }

  const fetchReservationNote = async () => {
    if (!reservationId) {
      return
    }
    setCanEditMemo(false)
    await api.fetchReservationNote(reservationId).then(res => (res.length > 0 ? setMemos(res) : setInputNotes(null)))
  }

  const saveNote = async () => {
    setIsLoading(true)
    await api.updateReservationNote(reservationId, [
      { memo: inputNotes?.front, category: ReservationNoteCategory.Front },
      { memo: inputNotes?.cleaning, category: ReservationNoteCategory.Cleaning },
      { memo: inputNotes?.meal, category: ReservationNoteCategory.Meal },
    ])
    setCanEditMemo(false)
    if (onSavedData) onSavedData()
    setIsLoading(false)
  }

  const onCopyPastNote = (notes: ReservationMemos) => {
    setInputNotes(notes)
    setCanEditMemo(true)
    setShowModal(false)
  }

  const onEditMemo = (v: React.ChangeEvent<HTMLTextAreaElement>, category: ReservationNoteCategory) => {
    setInputNotes({ ...inputNotes, [category]: v.target.value })
    if (!canEditMemo) {
      setCanEditMemo(true)
    }
  }

  useEffect(() => {
    fetchReservationNote()
  }, [reservationId])

  useEffect(() => {
    fetchReservationPastNote()
  }, [customer])

  return (
    <>
      <div css={mixedSectionStyle}>
        <div css={memoBlockStyle}>
          <div className="text-box">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <label>
                <CategoryLabel category={ReservationNoteCategory.Front} />
              </label>
              <Button
                isDisabled={pastNotes.length === 0}
                buttonType={pastNotes.length > 0 ? 1 : 5}
                width={120}
                height={32}
                fontSize={12}
                fontWeight={'bold'}
                onClick={() => setShowModal(true)}
              >
                {t('Update reservation notes')}
              </Button>
            </div>
            <textarea
              style={{ marginTop: 9, minHeight: '172px' }}
              value={inputNotes?.front}
              placeholder={t('Enter memo')}
              onChange={v => onEditMemo(v, ReservationNoteCategory.Front)}
            />
          </div>
          <div className="text-box">
            <label>
              <CategoryLabel category={ReservationNoteCategory.Cleaning} />
              <Hint customCss={cleaningHintStyle}>
                <div className="help-inner">
                  <p>メモ更新時に、清掃管理の「清掃指示」に反映されます。予約に紐づく全部屋が対象となります。</p>
                  <p>(例) 以下の予約の場合 宿泊日 : 10月10日 1泊 部屋 : 101,201 → 10月10日の101,201の清掃指示に表示されます。</p>
                  <img src={require('@/static/images/reservation-note/help_cleaning.jpg')} alt="" />
                </div>
              </Hint>
            </label>
            <textarea
              style={{ marginTop: 12, minHeight: '81px' }}
              value={inputNotes?.cleaning}
              placeholder={t('Enter memo')}
              onChange={v => onEditMemo(v, ReservationNoteCategory.Cleaning)}
            />
          </div>
          <div className="text-box">
            <label>
              <CategoryLabel category={ReservationNoteCategory.Meal} />
            </label>
            <textarea
              style={{ marginTop: 12, minHeight: '81px' }}
              value={inputNotes?.meal}
              placeholder={t('Enter memo')}
              onChange={v => onEditMemo(v, ReservationNoteCategory.Meal)}
            />
          </div>
        </div>
        {canEditMemo && (
          <div css={addButtonWrapperStyle}>
            <Button
              buttonType={3}
              width={100}
              height={32}
              fontSize={12}
              marginRight={16}
              fontWeight={'bold'}
              onClick={fetchReservationNote}
            >
              {t('Cancel')}
            </Button>
            <Button buttonType={1} width={100} height={32} fontSize={12} fontWeight={'bold'} onClick={saveNote}>
              {t('Save')}
            </Button>
          </div>
        )}
      </div>
      {showModal && customer && (
        <ReservationNoteModal
          inputNotes={inputNotes}
          onCopyPastNote={onCopyPastNote}
          pastNotes={pastNotes}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

const mixedSectionStyle = css({
  width: '100%',
  height: '100%',
  minHeight: 440,
  border: '1px solid #CCCCCC',
  backgroundColor: '#FFF',
  borderRadius: '10px',
  paddingBottom: 16,
})

const memoBlockStyle = css({
  padding: '8px 16px 0',
  margin: '0 auto',
  width: '100%',
  '.text-box': {
    '&:first-of-type': {
      minHeight: 214,
    },
    '&:nth-child(2), &:last-of-type': {
      marginTop: 12,
      minHeight: 112,
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    fontSize: 14,
    fontWeight: 'bold',
  },
  textarea: {
    width: '100%',
    height: '100%',
    marginTop: 15,
    border: '1px solid #ccc',
    display: 'block',
    padding: 12,
    fontSize: 14,
    borderRadius: 10,
    lineHeight: '18px',
    '&::placeholder': {
      color: '#CCCCCC',
    },
  },
})

const addButtonWrapperStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px 12px 0 0',
})

const cleaningHintStyle = css({
  '.help-icon': {
    width: 18,
    height: 18,
  },
  '.help-popup': {
    top: 0,
    left: 26,
  },
  '.help-inner': {
    width: 300,
    padding: 16,
    textAlign: 'center',
    p: {
      lineHeight: 1.5,
      fontSize: 12,
      textAlign: 'left',
      '+ p': {
        marginTop: 20,
        marginBottom: 16,
      },
    },
    img: {
      width: 250,
    },
  },
})
