import React, { useContext } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { translateObject } from '@/libs/text'

// models
import {
  HotelNoticeStatusType,
  HotelNoticeType,
  MessageParameterForGuestRoomAssignDiffReservation,
  MessageParameterForSmaregi,
} from '@/models/hotel-notice'

// api
import { readAdminNotice } from '@/apis/aipass'
import { AccountContext } from '@/contexts/account'
import { PluginCode, checkActivePlugin } from '@/libs/plugins'

type Props = {
  notices: HotelNoticeType[]
  onClose: () => void
}

export const NoticeModal: React.FC<Props> = ({ notices, onClose }) => {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { plugins } = useContext<any>(AccountContext)
  const guestAppPluginActive = checkActivePlugin(plugins, PluginCode.GuestApp)

  const formatDate = (notificationAt: string) => {
    const today = dayjs()
    const notificationDay = dayjs(notificationAt)
    const isNoticeSameDay = today.diff(notificationDay, 'day') === 0
    return notificationDay.format(isNoticeSameDay ? t('NoticeView.HH:mm') : t('NoticeView.MM-DD'))
  }

  const redirectToDetail = (notice: HotelNoticeType) => {
    if (notice.type === HotelNoticeStatusType.Smaregi) {
      const params = notice.messageParameter as MessageParameterForSmaregi
      return history.push({
        pathname: '/sales-manager/detail',
        search: new URLSearchParams({
          id: params.salesManagerDailyId,
          mode: 'daily',
          transactionId: params.transactionId,
        }).toString(),
      })
    }
    if (notice.type === HotelNoticeStatusType.deviceError) {
      return history.push({ pathname: `/request-manager` })
    }
    if (notice.type === HotelNoticeStatusType.subjectAlertForRequest) {
      const pathname = guestAppPluginActive ? '/setting/guest-app/asset' : '/setting/mobile-request'
      return history.push({ pathname })
    }
    if (notice.type === HotelNoticeStatusType.subjectAlertForSpace) {
      return history.push({ pathname: `/setting/guest-app/asset` })
    }
    if (notice.type === HotelNoticeStatusType.subjectAlertForTax) {
      return history.push({ pathname: '/setting/sales-manager/tax' })
    }
    if (notice.type === HotelNoticeStatusType.subjectAlertForSubjectSet) {
      return history.push({ pathname: `/setting/sales-manager/subject-set` })
    }
    if (notice.type === HotelNoticeStatusType.guestRoomAssignDiffReservation) {
      const params = notice.messageParameter as MessageParameterForGuestRoomAssignDiffReservation
      return history.push({
        pathname: '/room-manager/daily',
        search: new URLSearchParams({
          reservationId: params.reservationId,
        }).toString(),
      })
    }
  }

  return (
    <>
      <div css={backgroundWrapperStyle} onClick={onClose}></div>
      <div css={modalContainerStyle}>
        <div css={modalHeaderStyle}>{t('News')}</div>
        <ul css={listWrapperStyle}>
          {notices.length !== 0 &&
            notices.map(notice => {
              return (
                <li
                  key={notice.id}
                  css={notice.noticeRead ? readStyle : unreadStyle}
                  onClick={() => {
                    readAdminNotice(notice.id)
                    redirectToDetail(notice)
                    onClose()
                  }}
                >
                  <div>
                    <p css={listTextStyle}>{t(translateObject(notice, 'message', i18n.language), notice.messageParameter)}</p>
                    <p css={dateStyle}>{formatDate(notice.createdAt)}</p>
                  </div>
                </li>
              )
            })}
        </ul>
      </div>
    </>
  )
}

const backgroundWrapperStyle = css({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  top: 0,
  left: 0,
  zIndex: 100000,
})

const modalContainerStyle = css({
  width: 427,
  height: 466,
  position: 'absolute',
  top: '45px',
  right: '30px',
  background: '#fff',
  boxShadow: '0px 3px 10px #0000003D',
  borderRadius: '5px',
  zIndex: 100001,
})

const modalHeaderStyle = css({
  width: '100%',
  height: 50,
  paddingLeft: '24px',
  color: '$text-color-27',
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: '50px',
  letterSpacing: '0.8px',
  borderBottom: 'solid 1px #f2f2f2',
})

const listWrapperStyle = css({
  width: '100%',
  height: 416,
  position: 'relative',
  overflowY: 'auto',
})

const readStyle = css({
  height: 82,
  paddingLeft: '24px',
  paddingRight: '24px',
  display: 'flex',
  justifyContent: 'spaceBetween',
  alignItems: 'center',
  borderTop: '1px solid #F2F2F2',
  cursor: 'pointer',
  background: '#f5f5f5',
  '&:hover': {
    background: '#EDEDED',
  },
})

const unreadStyle = css({
  height: 82,
  paddingLeft: '24px',
  paddingRight: '24px',
  display: 'flex',
  justifyContent: 'spaceBetween',
  alignItems: 'center',
  borderTop: '1px solid #F2F2F2',
  cursor: 'pointer',
  '&:hover': {
    background: '#f5f5f5',
  },
})

const listTextStyle = css({
  marginBottom: '8px',
  color: '#272727',
  fontSize: '12px',
  letterSpacing: '0.6px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '2',
  lineHeight: 1.5,
  fontWeight: 'bold',
})

const dateStyle = css({
  lineHeight: '16px',
  color: '#a3a3a3',
  fontSize: '12px',
  letterSpacing: '0.6px',
})
