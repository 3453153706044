import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { css } from '@emotion/core'
import { getSelfCheckinCardReader, postSelfCheckinCardReaderWithCheckin, selfCheckinFetchGuestRoomAssign } from '@/apis/aipass'
import { Trans, useTranslation } from 'react-i18next'
import { SelfCheckinHeader } from '@/components/molecules'
import { RoomToIssueKeyModal } from '@/components/organisms/self-checkin/room-to-issue-key-modal'
import Lottie from 'react-lottie'
import { CardReaderCommand, SelfCheckinCardReaderType, serialNumberStorageKey } from '@/models/self-checkin/card-reader'
import dayjs from 'dayjs'
import { useErrorHandler } from '@/hooks/use-error-handler'

export type SelfCheckinIssueRoomKeyState = {
  hotelId: string
  checkinId: string
}

type RoomInfoType = {
  roomNumber: string
  pax: {
    male: number
    female: number
  }
  assignCheckinDate: string
  assignCheckoutDate: string
}

export const SelfCheckinIssueRoomKey: React.FC<{}> = () => {
  const history = useHistory()
  const { errorHandler } = useErrorHandler()
  const { t } = useTranslation()
  const { search, state } = useLocation<SelfCheckinIssueRoomKeyState>()
  const [isShowRoomToIssueKeyModal, setIsShowRoomToIssueKeyModal] = useState(false)
  const [latestIssueCommandReceive, setLatestIssueCommandReceive] = useState<SelfCheckinCardReaderType>()
  const [assignRooms, setAssignRooms] = useState<RoomInfoType[]>([])
  const [numberOfCards, setNumberOfCards] = useState<number>(1)
  const [currentIssueRoomIndex, setCurrentIssueRoomIndex] = useState<number>(0)
  const [watchReceiveIntervalId, setWatchReceiveIntervalId] = useState<NodeJS.Timeout>()
  const [issuedCardNum, setIssuedCardNum] = useState<number>(0)
  const [isExpiredDisplayOnComplete, setIsExpiredDisplayOnComplete] = useState(false)
  const [isCardIssueSuccess, setIsCardIssueSuccess] = useState(false)
  const [standbyNextIssueNum, setStandbyNextIssue] = useState<number>()
  const [isAllIssueCompleted, setIsAllIssueCompleted] = useState(false)

  const animationData = require('@/static/images/json/loading.json')

  const refactorRoomInfo = (roomInfo): RoomInfoType[] => {
    const refactorRoomInfo = roomInfo.map(
      (i): RoomInfoType => ({
        roomNumber: i.roomNumber,
        pax: {
          male: i.male,
          female: i.female,
        },
        assignCheckinDate: `${i.assignCheckinDate} ${i.assignCheckinTime}`,
        assignCheckoutDate: `${i.assignCheckoutDate} ${i.assignCheckoutTime}`,
      }),
    )
    refactorRoomInfo.sort((a, b) => {
      if (a.assignCheckinDate === b.assignCheckinDate) {
        return 0
      }
      return dayjs(a.assignCheckinDate).isBefore(dayjs(b.assignCheckinDate)) ? -1 : 1
    })
    return refactorRoomInfo
  }

  const isMultiPersonRoom = (roomInfo: RoomInfoType | undefined) => {
    if (!roomInfo) {
      return false
    }
    return roomInfo.pax.male + roomInfo.pax.female > 1
  }

  const initPageStatus = async () => {
    const assignRooms = await selfCheckinFetchGuestRoomAssign({
      hotelId: state.hotelId,
      checkinId: state.checkinId,
    })
    const flatRoomInfo = Object.values(assignRooms.rooms).flat()
    if (!flatRoomInfo?.length || !isEnabledIssueKey()) {
      goToNextPage()
      return
    }
    const sortedRoomInfo = refactorRoomInfo(flatRoomInfo)
    console.log({ sortedRoomInfo })
    setCurrentIssueRoomIndex(0)
    setAssignRooms(sortedRoomInfo)
    setIsShowRoomToIssueKeyModal(true)
  }

  const isEnabledIssueKey = () => {
    const storedSerialNumber = localStorage.getItem(serialNumberStorageKey) || ''
    return storedSerialNumber.length > 0
  }

  const subscribeIssueResult = () => {
    const intervalId = setInterval(async () => {
      const res = await getSelfCheckinCardReader(localStorage.getItem(serialNumberStorageKey)!)
      if (!res?.length) {
        return
      }
      if (!res[0].is_success) {
        clearInterval(intervalId)
        alert('カード発行機に接続できませんでした')
        console.error('カード発行機に接続できませんでした')
        goToNextPage()
        return
      }

      setLatestIssueCommandReceive(res[0])

      const hasRemovalMessage = res.find(r => r.command === CardReaderCommand.CardIssueRemoval)
      if (hasRemovalMessage) {
        if (!hasAdditionalRoomCard && !hasNextIssueRoom) {
          setIsAllIssueCompleted(true)
        }
        setIssuedCardNum(prev => prev + 1)
        setIsCardIssueSuccess(true)
        setTimeout(() => {
          setIsExpiredDisplayOnComplete(true)
        }, 3000)
      }

      const hasCompleteMessage = res.find(r => r.command === CardReaderCommand.CardIssueComplete)
      if (hasCompleteMessage) {
        clearInterval(intervalId)
      }
    }, 500)
    setWatchReceiveIntervalId(intervalId)
  }

  const sendIssueRequest = async (branchNumber: number) => {
    try {
      setStandbyNextIssue(undefined)
      setLatestIssueCommandReceive(undefined)
      setIsExpiredDisplayOnComplete(false)
      setIsCardIssueSuccess(false)
      await postSelfCheckinCardReaderWithCheckin(localStorage.getItem(serialNumberStorageKey)!, CardReaderCommand.CardIssueRequest, {
        checkinId: state.checkinId,
        checkinDate: assignRooms[currentIssueRoomIndex].assignCheckinDate,
        checkoutDate: assignRooms[currentIssueRoomIndex].assignCheckoutDate,
        roomNumber: Number(assignRooms[currentIssueRoomIndex].roomNumber),
        numberOfCards: isMultiPersonRoom(assignRooms[currentIssueRoomIndex]) ? 2 : 1,
        branchNumber,
      })
      subscribeIssueResult()
    } catch (e) {
      errorHandler(e)
      console.error(e)
      goToNextPage()
    }
  }

  const makeIssueAnnounceTemplate = () => {
    if (isCardIssueSuccess && (!isExpiredDisplayOnComplete || isAllIssueCompleted)) {
      return (
        <div className="operation-text highlight">
          <img className="check-image" src={require('@/static/images/self-checkin/icon_check_border.svg')} />
          <div>{t('SelfCheckIn.RoomKeyIssued')}</div>
        </div>
      )
    }
    if (latestIssueCommandReceive?.command === CardReaderCommand.CardIssueWriting) {
      return (
        <div className="operation-text highlight">
          <Lottie height="80px" width="80px" options={{ animationData, autoplay: true, loop: true }} />
          <div>{t('SelfCheckIn.RoomKeyBeingIssued')}</div>
        </div>
      )
    }
    return (
      <div className="operation-text">
        <Trans i18nKey={issuedCardNum === 0 ? 'SelfCheckIn.OnIcReader' : 'SelfCheckIn.OnNextCard'} />
      </div>
    )
  }

  const goToNextPage = () => {
    history.push({ pathname: `/self-checkin/complete`, search, state })
  }

  const hasAdditionalRoomCard = useMemo(() => {
    return numberOfCards === 1 && isMultiPersonRoom(assignRooms[currentIssueRoomIndex])
  }, [currentIssueRoomIndex, numberOfCards, assignRooms])

  const hasNextIssueRoom = useMemo(() => currentIssueRoomIndex + 1 < assignRooms.length, [currentIssueRoomIndex, assignRooms])

  useEffect(() => {
    if (!isExpiredDisplayOnComplete) {
      return
    }
    if (hasAdditionalRoomCard) {
      console.log('部屋に対する追加発行')
      setNumberOfCards(numberOfCards + 1)
      setStandbyNextIssue(numberOfCards + 1)
    } else if (hasNextIssueRoom) {
      console.log('次の部屋に対する新規発行')
      setNumberOfCards(1)
      setCurrentIssueRoomIndex(currentIssueRoomIndex + 1)
      setIsShowRoomToIssueKeyModal(true)
    } else {
      console.log('発行完了')
      goToNextPage()
    }
  }, [isExpiredDisplayOnComplete])

  useEffect(() => {
    const isPreviousNotCompleted = latestIssueCommandReceive && latestIssueCommandReceive.command !== CardReaderCommand.CardIssueComplete
    if (!standbyNextIssueNum || isPreviousNotCompleted) {
      return
    }
    sendIssueRequest(standbyNextIssueNum)
  }, [standbyNextIssueNum, latestIssueCommandReceive])

  useEffect(() => {
    initPageStatus()
    return () => {
      watchReceiveIntervalId && clearInterval(watchReceiveIntervalId)
    }
  }, [])

  return assignRooms.length ? (
    <>
      <div css={containerStyle}>
        <SelfCheckinHeader title={t('SelfCheckIn.IssueRoomKey')} />
        <div css={componentBackGroundStyle}>
          <div css={componentStyle}>
            <div className="announce-block">
              <div className="room-number-text">{assignRooms[currentIssueRoomIndex]?.roomNumber}</div>
              <div className="issue-announcement-text">
                <Trans i18nKey="SelfCheckIn.IssueRoomKeyProcess" components={{ span: <span /> }} values={{ n: numberOfCards }} />
              </div>
              {makeIssueAnnounceTemplate()}
            </div>
            <div className="card-reader-image-block">
              <img src={require('@/static/images/self-checkin/checkin-card-reader-a.png')} />
              <img src={require('@/static/images/self-checkin/checkin-card-reader-b.png')} />
            </div>
          </div>
        </div>
      </div>
      {isShowRoomToIssueKeyModal && (
        <RoomToIssueKeyModal
          roomNumber={assignRooms[currentIssueRoomIndex].roomNumber}
          totalNumber={isMultiPersonRoom(assignRooms[currentIssueRoomIndex]) ? 2 : 1}
          onClose={async () => {
            setIsShowRoomToIssueKeyModal(false)
            setStandbyNextIssue(1)
          }}
        />
      )}
    </>
  ) : (
    <></>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  '*': { fontFamily: 'Noto Sans JP' },
})
const componentBackGroundStyle = css({
  backgroundColor: '#F2F2F2',
  // padding header 70px
  padding: '70px 60px 0 60px',
  height: '100vh',
  alignContent: 'center',
})
const componentStyle = css({
  gap: 68,
  display: 'flex',
  height: '100%',
  lineHeight: 1.5,
  '.announce-block': {
    alignSelf: 'center',
    textAlign: 'center',
    width: '50%',
    '.room-number-text': {
      fontSize: 40,
      fontWeight: 'bold',
      letterSpacing: 6,
      color: '#F2A40B',
    },
    '.issue-announcement-text': {
      marginTop: 8,
      fontSize: 21,
      fontWeight: 'bold',
      letterSpacing: 3.15,
      color: '272727',
      span: {
        color: '#F2A40B',
      },
    },
    '.operation-text': {
      marginTop: 40,
      fontSize: 21,
      fontWeight: 'bold',
      letterSpacing: 3.15,
      color: '#676767',
      padding: '58px 0',
      borderTop: '2px solid #CCCCCC',
      borderBottom: '2px solid #CCCCCC',
      wordBreak: 'break-word',
      '&.highlight': {
        padding: '30px 0',
        color: '#F2A40B',
      },

      '.check-image': {
        borderRadius: '50%',
        border: '3px solid #F2A40B',
        width: 70,
        height: 70,
        padding: 12,
      },
    },
  },
  '.card-reader-image-block': {
    position: 'relative',
    width: '50%',
    minWidth: 374,
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    '> img': {
      position: 'absolute',
      width: 374,
      opacity: 0,
      animation: 'image-switch-animation 10s infinite',
      '&:nth-of-type(1)': { animationDelay: '0s' },
      '&:nth-of-type(2)': { animationDelay: '5s' },
    },
  },
  '@keyframes image-switch-animation': {
    '0%': { opacity: 0 },
    '5%': { opacity: 1 },
    '60%': { opacity: 1 },
    '65%': { opacity: 0 },
  },
})
