import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form'
import { AssetSpaceDetailFormValue } from '@/components/organisms/settings/guest-app/space-detail-modal'
import { Select } from '@/components/atoms/select'
import { Select as AntSelect } from 'antd'
import uuid from 'react-uuid'
import { useDayjsRange } from '@/hooks/use-dayjs-range'
import { Hint } from '@/components/atoms/hint'

type Props = {
  control: Control<AssetSpaceDetailFormValue, any>
  errors: FieldErrors<AssetSpaceDetailFormValue>
}

export const InputUsageHours: React.FC<Props> = ({ control, errors }) => {
  const { t } = useTranslation()
  const { dayjsRange } = useDayjsRange()
  const watchUsageTimeStart = useWatch({ control, name: 'chargePrice.usageTime.start' })
  const watchUsageTimeEnd = useWatch({ control, name: 'chargePrice.usageTime.end' })

  const defaultTimeLists = () => {
    const range = dayjsRange({ interval: 15, unit: 'minute' })
    return [
      ...range.map(r => ({
        label: r.format('H時間mm分'),
        value: r.format('HH:mm'),
      })),
      { label: '24時間00分', value: '24:00' },
    ]
  }

  const convertTimeToMinutes = timeStr => {
    const [hours, minutes] = timeStr.split(':').map(Number)
    return hours * 60 + minutes
  }

  const watchUsageTimeStartOptions = useMemo(() => {
    if (!watchUsageTimeEnd) {
      return defaultTimeLists()
    }

    const watchUsageTimeEndHour = watchUsageTimeEnd.split(':')[0]
    const watchUsageTimeEndMinute = watchUsageTimeEnd.split(':')[1]
    return defaultTimeLists().filter(time => {
      return (
        Number(watchUsageTimeEndHour) * 60 + Number(watchUsageTimeEndMinute || 0) >=
        Number(time.value.split(':')[0]) * 60 + Number(time.value.split(':')[1])
      )
    })
  }, [watchUsageTimeEnd])

  const watchUsageTimeEndOptions = useMemo(() => {
    if (!watchUsageTimeStart) {
      return defaultTimeLists()
    }

    const watchUsageTimeStartHour = watchUsageTimeStart.split(':')[0]
    const watchUsageTimeStartMinute = watchUsageTimeStart.split(':')[1]
    return defaultTimeLists().filter(time => {
      return (
        Number(watchUsageTimeStartHour) * 60 + Number(watchUsageTimeStartMinute || 0) <=
        Number(time.value.split(':')[0]) * 60 + Number(time.value.split(':')[1])
      )
    })
  }, [watchUsageTimeStart])

  return (
    <>
      <div css={inputTitleTextStyle}>
        {t('Usage time')}
        <Hint
          src={require('@/static/images/space/help_time.svg')}
          customCss={css({
            paddingLeft: 8,
            position: 'static',
            '.help-image': { top: 0 },
          })}
        />
      </div>

      <div style={{ display: 'flex', gap: 16 }}>
        <div css={inputAreaStyle}>
          <div className="label">{t('Min')}</div>
          <div className="select-box">
            <Controller
              name="chargePrice.usageTime.start"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select
                  placeholder="0"
                  placeHolderColor="#bfbfbf"
                  optionLabelProp="title"
                  value={value}
                  onChange={onChange}
                  customStyle={css({ paddingBottom: 0, width: 116 })}
                >
                  {watchUsageTimeStartOptions.map(({ value, label }) => (
                    <AntSelect.Option key={uuid()} value={value} title={convertTimeToMinutes(value)}>
                      {label}
                    </AntSelect.Option>
                  ))}
                </Select>
              )}
            />
            <div className="unit">{t('Minutes')}</div>
          </div>
        </div>

        <div css={inputAreaStyle}>
          <div className="label">{t('Max')}</div>
          <div className="select-box">
            <Controller
              name="chargePrice.usageTime.end"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select
                  placeholder="0"
                  placeHolderColor="#bfbfbf"
                  optionLabelProp="title"
                  value={value}
                  onChange={onChange}
                  customStyle={css({ paddingBottom: 0, width: 116 })}
                >
                  {watchUsageTimeEndOptions.map(({ value, label }) => (
                    <AntSelect.Option key={uuid()} value={value} title={convertTimeToMinutes(value)}>
                      {label}
                    </AntSelect.Option>
                  ))}
                </Select>
              )}
            />
            <div className="unit">{t('Minutes')}</div>
          </div>
        </div>
      </div>
    </>
  )
}

const inputTitleTextStyle = css({
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  letterSpacing: '0.6px',
  color: '#676767',
  paddingBottom: 12,
  alignItems: 'center',
})

const inputAreaStyle = css({
  display: 'flex',
  '.label, .unit': {
    fontSize: 12,
    letterSpacing: 0.6,
    color: '#676767',
    paddingRight: 8,
    lineHeight: '32px',
  },
  '.select-box': {
    margin: '0 8px',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
})
