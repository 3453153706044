import React, { useMemo } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useVisualViewport } from '@/hooks/use-visual-viewport'

// components
import { Button } from '@/components/atoms'

type Props = {
  isDisabled: () => boolean
  isDisabledButton?: boolean
  goToPreviousPage?: () => void
  goToNextPage: () => void
  isNext: 'next' | 'edit' | 'checkin' | 'submit' | 'shoot' | 'search' | 'save'
  position?: 'fixed' | 'sticky'
}

export const SelfCheckinFooter: React.FC<Props> = ({
  isDisabled,
  isDisabledButton = true,
  goToPreviousPage,
  goToNextPage,
  isNext,
  position = 'fixed',
}) => {
  const { t } = useTranslation()

  const visualState = useVisualViewport()

  const getPositionTopNumberMemo = useMemo(() => {
    const visualHeight = visualState.visualViewport.offsetTop + visualState.visualViewport.height

    // ボタンの高さと余白分を考慮する
    const buttonHeight = 55 + 20

    return visualHeight - buttonHeight
  }, [visualState.visualViewport.height, visualState.visualViewport.offsetTop])

  return (
    <>
      {goToPreviousPage && (
        <div css={[buttonStyle, { top: getPositionTopNumberMemo, left: '40px' }]}>
          <Button
            buttonType={3}
            width={156}
            height={55}
            marginLeft={40}
            fontSize={18}
            borderRadius={35}
            onClick={goToPreviousPage}
            fontWeight={'normal'}
          >
            {t('Back')}
          </Button>
        </div>
      )}
      {isDisabledButton && (
        <div css={[buttonStyle, { top: getPositionTopNumberMemo, right: '40px' }]}>
          <Button
            isDisabled={isDisabled()}
            buttonType={isDisabled() ? 6 : 1}
            width={isNext === 'checkin' ? 200 : 156}
            height={55}
            fontSize={18}
            borderRadius={35}
            onClick={goToNextPage}
            fontWeight={'normal'}
          >
            {isNext === 'next'
              ? t('Next')
              : isNext === 'search'
                ? t('Search')
                : isNext === 'edit'
                  ? t('Confirm')
                  : isNext === 'submit'
                    ? t('Submit')
                    : isNext === 'save'
                      ? t('Save')
                      : isNext === 'shoot'
                        ? t('Capture')
                        : t('Checkin')}
          </Button>
        </div>
      )}
    </>
  )
}

const buttonStyle = css({
  position: 'fixed',
})
