import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Control, Controller, FieldErrors, useFieldArray } from 'react-hook-form'
import { InputField } from '@/components/molecules/input-field'
import { AddFormFieldButton } from '@/components/molecules/add-form-field-button'
import { FormValue } from './subject-tax-detail-modal'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'

type Props = {
  control: Control<FormValue, any>
  errors: FieldErrors<FormValue>
}

export const SubjectTaxPriceRangeField: React.FC<Props> = ({ control, errors }) => {
  const { t } = useTranslation()
  const fixAmountRangeInput = useFieldArray({
    control,
    name: 'roomChargeRange',
    rules: { required: true },
  })

  return (
    <>
      {fixAmountRangeInput.fields.map((input, index) => (
        <div key={index} css={fieldStyle}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="-text -label">{t('saleSubjectTax.RoomCharge')}</div>
            <Controller
              control={control}
              rules={{
                required: t('Required field has not been entered'),
                pattern: { value: /^\d+$/, message: t('Please enter in single-byte number') },
              }}
              name={`roomChargeRange.${index}.lowerPrice`}
              render={({ field: { onChange, value } }) => (
                <InputField
                  type="text"
                  value={value}
                  handleChangeData={onChange}
                  placeholder="0"
                  marginBottom={0}
                  width="86px"
                  fieldWidth="unset"
                  error={errors?.roomChargeRange?.[index]?.lowerPrice ? ' ' : ''}
                />
              )}
            />
            <div className="-text" style={{ padding: 8 }}>
              ~
            </div>
            <Controller
              control={control}
              rules={{
                pattern: { value: /^\d+$/, message: t('Please enter in single-byte number') },
              }}
              name={`roomChargeRange.${index}.upperPrice`}
              render={({ field: { onChange, value } }) => (
                <InputField
                  type="text"
                  value={value}
                  handleChangeData={onChange}
                  placeholder="100000"
                  marginBottom={0}
                  width="86px"
                  fieldWidth="unset"
                  error={errors?.roomChargeRange?.[index]?.upperPrice ? ' ' : ''}
                />
              )}
            />
            <div className="-text -label" style={{ paddingLeft: 16, marginLeft: 16, borderLeft: '1px solid #cccccc' }}>
              {t('saleSubjectTax.Price')}
            </div>
            <Controller
              control={control}
              rules={{
                required: t('Required field has not been entered'),
                pattern: { value: /^\d+$/, message: t('Please enter in single-byte number') },
              }}
              name={`roomChargeRange.${index}.taxPrice`}
              render={({ field: { onChange, value } }) => (
                <InputField
                  type="text"
                  value={value}
                  handleChangeData={onChange}
                  placeholder="100"
                  marginBottom={0}
                  width="101px"
                  fieldWidth="unset"
                  error={errors?.roomChargeRange?.[index]?.taxPrice ? ' ' : ''}
                />
              )}
            />
            <DeleteIcon onClick={() => fixAmountRangeInput.remove(index)} />
          </div>
          {errors?.roomChargeRange?.[index] && (
            <div style={{ paddingLeft: 70 }}>
              <div css={errorMessageStyle}>
                {errors!.roomChargeRange[index]!.lowerPrice?.message ||
                  errors!.roomChargeRange[index]!.upperPrice?.message ||
                  errors!.roomChargeRange[index]!.taxPrice?.message}
              </div>
            </div>
          )}
        </div>
      ))}
      {errors?.roomChargeRange?.root && <div css={errorMessageStyle}>{t('Required field has not been entered')}</div>}

      <AddFormFieldButton
        onAdd={() =>
          fixAmountRangeInput.append({
            upperPrice: undefined,
            lowerPrice: undefined,
            taxPrice: undefined,
          })
        }
        label={t('saleSubjectTax.AddCondition')}
      />
    </>
  )
}
const errorMessageStyle = {
  fontSize: 10,
  color: 'rgba(255,0,0,0.5)',
  marginTop: 4,
}

const fieldStyle = css({
  border: '1px solid #CCCCCC',
  borderRadius: 10,
  padding: '12px 24px',
  marginBottom: 20,

  '.-text': {
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 1.2,
    color: '#676767',
  },
  '.-label': { paddingRight: 16 },
})
