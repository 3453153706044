import React, { createContext, useState } from 'react'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [auth, setAuthState] = useState(localStorage.getItem('idToken'))
  const setAuth = data => {
    if (data?.idToken) {
      localStorage.setItem('idToken', data.idToken)
      setAuthState(data.idToken)
    } else {
      localStorage.removeItem('idToken')
    }
  }
  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>
}
