import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { upperFirst } from 'lodash'
import moment from 'moment'

import { tableBodyTdStyle } from '@/constants/layout'
import { OperationLogType } from '@/models/operation-log'
import { css } from '@emotion/core'

type OperationLogRowProps = {
  operation: OperationLogType
}

export const OperationLogRow: React.FC<OperationLogRowProps> = ({ operation }) => {
  const { t, i18n } = useTranslation()
  const [showDetail, setShowDetail] = useState<boolean>(false)

  const getLogTranslation = (key: string) => {
    if (i18n.exists(`OperationLog.${key}`)) return t(`OperationLog.${key}`)

    return t(key)
  }

  const transformAllUnixDate = target => {
    if (typeof target === 'object') {
      for (const key in target) {
        if (typeof target[key] === 'object') {
          transformAllUnixDate(target[key])
        } else {
          if (String(target[key]).length === 10 && moment.unix(target[key]).isValid()) {
            target[key] = moment.unix(target[key]).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
    }
    return JSON.stringify(target, null, '\t')
  }

  return (
    <>
      <tr onClick={() => setShowDetail(!showDetail)} key={operation.id} css={{ cursor: 'pointer', color: '#272727' }}>
        <td css={[operationTdStyle, { paddingLeft: 28, width: '5%' }]}>
          <img
            src={require('@/static/images/arrow_right.svg')}
            css={{ width: 32, height: 32, cursor: 'pointer', transform: `rotate(${showDetail ? -90 : 90}deg)`, transition: 'all 300ms' }}
          />
        </td>
        <td css={[operationTdStyle, { width: '16%' }]}>{moment(operation.date).format('YYYY/MM/DD HH:mm:ss')}</td>
        <td css={[operationTdStyle, { width: '4%' }]}>{upperFirst(getLogTranslation(operation.contents)) || '-'}</td>
        <td css={[operationTdStyle, { width: '11%' }]}>
          {getLogTranslation(operation.features === 'CheckIn' ? 'Accommodation' : operation.features) || '-'}
        </td>
        <td css={[operationTdStyle, { width: '36%' }]}>{getLogTranslation(operation.table) || '-'}</td>
        <td css={[operationTdStyle, { width: '16%' }]}>{operation.account || '-'}</td>
        <td css={[operationTdStyle, { width: '12%', paddingRight: 28 }]}>{operation.staff || '-'}</td>
      </tr>
      {showDetail && (
        <tr>
          <td colSpan={7} css={{ padding: '28px 39px 28px 74px', backgroundColor: '#F5F5F5' }}>
            <div css={{ padding: 28, backgroundColor: '#fff', borderRadius: 17, whiteSpace: 'pre-wrap', color: '#272727', fontSize: 12 }}>
              {operation.json ? transformAllUnixDate(JSON.parse(operation.json)) : '-'}
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

const operationTdStyle = css(tableBodyTdStyle, {
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
})
