import React from 'react'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useTranslation } from 'react-i18next'

// models
import { AccountsReceivable } from '@/models/accounts-receivable'
import { initializeSelectBoxStyle } from '@/constants/form'

type Props = {
  paymentSettings: any
  onChangeState: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index) => void
  deletePaymentSetting: (id: number, index: number) => void
  addPaymentSetting: () => void
}

export const SettingPaymentEditForm: React.FC<Props> = ({ paymentSettings, onChangeState, deletePaymentSetting, addPaymentSetting }) => {
  const windows = useWindowSize()
  const { t } = useTranslation()

  return (
    <>
      <div css={paymentSettingEditHeaderStyle}>
        <div css={smallWidthStyle}>{t('Display order')}</div>
        <div css={smallWidthStyle}>{t('Receipt ID')}</div>
        <div css={largeWidthStyle}>{t('Method of payment')}</div>
        <div css={smallWidthStyle}>{t('Commission rate')}</div>
        <div css={smallWidthStyle}>{t('Outstanding sales')}</div>
        <div css={smallWidthStyle}>{t('Tax rate')}</div>
        <div css={smallWidthStyle}>{t('Tax classification')}</div>
        <div css={deleteSpaceStyle}></div>
      </div>
      <div
        css={scrollBox}
        style={{
          maxHeight: windows.height - 244,
        }}
      >
        <ul css={paymentSettingEditFormStyle}>
          {paymentSettings.map((paymentSetting: any, index: number) => (
            <li key={index}>
              <div css={smallWidthStyle}>
                <input
                  name="displayOrder"
                  type="number"
                  placeholder="000"
                  css={smallInputStyle}
                  value={paymentSetting.displayOrder}
                  onChange={e => onChangeState(e, index)}
                />
              </div>
              <div css={smallWidthStyle}>
                <input
                  name="methodCode"
                  type="text"
                  placeholder="000"
                  css={smallInputStyle}
                  value={paymentSetting.methodCode}
                  onChange={e => onChangeState(e, index)}
                  disabled={paymentSetting.name == '-'}
                />
              </div>
              <div css={largeWidthStyle}>
                {!!paymentSetting.isDefaultCash || !!paymentSetting.isFixed ? (
                  <div css={defaultCashStyle}>{paymentSetting.name}</div>
                ) : (
                  <input
                    name="name"
                    type="text"
                    placeholder={t('Credit card (card name)')}
                    css={largeInputStyle}
                    value={paymentSetting.name}
                    onChange={e => onChangeState(e, index)}
                  />
                )}
              </div>
              <div css={smallWidthStyle}>
                <input
                  name="commissionRate"
                  type="text"
                  placeholder="3"
                  disabled={paymentSetting.name == '-'}
                  css={smallInputStyle}
                  value={paymentSetting.commissionRate}
                  onChange={e => onChangeState(e, index)}
                />
                %
              </div>
              <div css={smallWidthStyle}>
                <select
                  name="isAccountsReceivable"
                  css={smallSelectStyle}
                  disabled={paymentSetting.name == '-'}
                  defaultValue={paymentSetting.isAccountsReceivable}
                  onChange={e => onChangeState(e, index)}
                >
                  <option value="">-</option>
                  <option value={AccountsReceivable.AccountsReceivable}>{t('Outstanding sales')}</option>
                </select>
              </div>
              <div css={smallWidthStyle}>
                <input
                  name="tax"
                  type="text"
                  placeholder="0"
                  css={smallInputStyle}
                  disabled={paymentSetting.name == '-'}
                  value={paymentSetting.tax ?? ''}
                  onChange={e => onChangeState(e, index)}
                />
                %
              </div>
              <div css={smallWidthStyle}>
                <input
                  name="taxClass"
                  type="text"
                  css={smallInputStyle}
                  disabled={paymentSetting.name == '-'}
                  placeholder="000"
                  value={paymentSetting.taxClass ?? ''}
                  onChange={e => onChangeState(e, index)}
                />
              </div>
              {!paymentSetting.isDefaultCash && !paymentSetting.isFixed ? (
                <div css={deleteButtonStyle} onClick={() => deletePaymentSetting(paymentSetting.id, index)}>
                  <img src={require('@/static/images/delete_yellow.svg')} alt={t('Delete')} />
                </div>
              ) : (
                <div css={deleteSpaceStyle}></div>
              )}
            </li>
          ))}
        </ul>
        <div css={plusButtonRowStyle} onClick={() => addPaymentSetting()}>
          <div>
            <img src={require('@/static/images/plus.svg')} alt={t('Add subject')} />
            <p>{t('Add subject')}</p>
          </div>
        </div>
      </div>
    </>
  )
}

const largeWidthStyle = css({
  width: 500,
})

const smallWidthStyle = css({
  width: 120,
  alignItems: 'center',
})

const paymentSettingEditHeaderStyle = css({
  background: '#F5F5F5',
  color: '#676767',
  display: 'flex',
  fontSize: 12,
  fontWeight: 'bold',
  padding: '10px 32px',
})

const paymentSettingEditFormStyle = css({
  padding: '16px 32px 12px',
  li: {
    display: 'flex',
    padding: '8px 0',
    div: {
      display: 'flex',
    },
    alignItems: 'center',
    fontSize: 14,
  },
})

const inputStyle = css({
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  padding: '0 16px',
  marginRight: 16,
})

const smallInputStyle = css(inputStyle, {
  width: 66,
})

const largeInputStyle = css(inputStyle, {
  width: '90%',
})

const defaultCashStyle = css({
  width: '100%',
  height: 32,
  alignItems: 'center',
  fontSize: 14,
})

const deleteButtonStyle = css({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  img: {
    width: 30,
    height: 30,
  },
})

const plusButtonRowStyle = css({
  padding: '0px 32px 0',
  div: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    cursor: 'pointer',
    margin: '0 auto 19px auto',
    p: {
      color: '#F2A40B',
      fontSize: 12,
      paddingLeft: 10,
    },
    img: {
      width: 20,
      height: 20,
    },
  },
})

const scrollBox = css({
  overflowY: 'scroll',
  // height: '68vh',
})

const selectBoxStyle = css(initializeSelectBoxStyle, {
  height: 32,
  width: '100%',
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '80% center',
  backgroundSize: 9,
  display: 'block',
  padding: '0 0 0 16px',
})

const smallSelectStyle = css(selectBoxStyle, {
  width: 80,
})

const deleteSpaceStyle = css({
  width: 30,
})
