import React, { ComponentProps } from 'react'
import { css } from '@emotion/core'
import { draggableIconStyle } from '@/components/molecules/settings/use-draggable-dom'

export const TableHeader: React.FC<ComponentProps<'div'>> = ({ children, ...props }) => {
  return (
    <div css={tableHeaderStyle} {...props}>
      {children}
    </div>
  )
}
export const TableBody: React.FC<ComponentProps<'div'>> = ({ children, ...props }) => {
  return (
    <div css={tableBodyStyle} {...props}>
      {children}
    </div>
  )
}
export const TableRow: React.FC<ComponentProps<'div'>> = ({ children, ...props }) => {
  return (
    <div css={[tableRowStyle, props.draggable && draggableIconStyle]} {...props}>
      {children}
    </div>
  )
}

const tableHeaderStyle = css({
  background: '#F2F2F2',
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  '> div': {
    alignContent: 'center',
    height: 32,
    fontSize: 12,
    lineHeight: '1.5',
    fontWeight: 'bold',
    letterSpacing: '1.2px',
    color: '#676767',
  },
  // offset scrollbar
  paddingRight: 9,
})

const tableBodyStyle = css({
  overflowY: 'scroll',
  height: '100%',
})

const tableRowStyle = css([
  {
    display: 'flex',
    minHeight: 62,
    alignItems: 'center',
    '> div': {
      fontSize: 14,
      lineHeight: '1.5',
      fontWeight: 'bold',
      letterSpacing: 0.9,
      color: '#272727',
      paddingRight: 8,
    },
    borderBottom: '1px solid #F2F2F2',
    cursor: 'pointer',
    position: 'relative',
    wordBreak: 'break-all',
  },
])
