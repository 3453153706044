import React, { useContext } from 'react'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { RoomManagerIndicatorContext } from '@/components/pages/room-manager/indicator'
import { RadioGroupField } from '../radio-group-field'
import { css } from '@emotion/core'
import { updateGuestRoomStatusForCleaningManager } from '@/apis/aipass'
import dayjs from 'dayjs'
import { RoomUsageStatusType } from '@/constants/cleaning-manager'
import { AccountContext } from '@/contexts/account'
import { PluginCode, checkActivePlugin } from '@/libs/plugins'
import { useTranslation } from 'react-i18next'

type Props = {
  guestRoomId: string
  isAway: boolean
}

export const RoomStayStatusRadio: React.FC<Props> = ({ guestRoomId, isAway }) => {
  const { errorHandler } = useErrorHandler()
  const { doReload, setIsLoading } = useContext(RoomManagerIndicatorContext)
  const { plugins } = useContext<any>(AccountContext)
  const { t } = useTranslation()

  return (
    <>
      {checkActivePlugin(plugins, PluginCode.CleaningManager) && (
        <RadioGroupField
          value={`${Number(isAway)}`}
          items={[
            { value: '0', label: t('cleaningManager.Occupied') },
            { value: '1', label: t('cleaningManager.Out') },
          ]}
          onChange={async e => {
            try {
              setIsLoading && setIsLoading(true)
              const isNewAway = e.target.value === '1'
              console.log({ a: e.target.value, isNewAway })
              await updateGuestRoomStatusForCleaningManager({
                guestRoomId,
                dateOf: dayjs(),
                staffReport: undefined,
                cleaningStatus: undefined,
                cleaningStaffId: undefined,
                roomUsageStatus: isNewAway ? RoomUsageStatusType.Away : RoomUsageStatusType.Stay,
              })
              doReload && doReload()
            } catch (error) {
              errorHandler(error)
            } finally {
              setIsLoading && setIsLoading(false)
            }
          }}
          groupCss={css({ paddingTop: 18 })}
          itemWrapperCss={css({ paddingRight: 15 })}
          itemCss={css({ fontSize: 12 })}
          style={{ radioSize: 16, labelMargin: 7 }}
        />
      )}
    </>
  )
}
