import React from 'react'
import { css } from '@emotion/core'
import { ReportTypes } from '@/models/report'

type Props = {
  title: string
  type: ReportTypes
  onDownload: (params: { type: ReportTypes; format: 'pdf' | 'csv' }) => void
}

export const ReportDownloadLink: React.FC<Props> = ({ title, type, onDownload, children }) => {
  return (
    <tr style={{ borderBottom: '1px solid #eee' }}>
      <td css={reportsTableBodyTdStyleTitle}>
        <div className="title-block">
          <div className="title-text">{title}</div>
          {children}
        </div>
      </td>
      <td css={reportsTableBodyTdStyleLink}>
        <button onClick={() => onDownload({ type, format: 'csv' })} css={reportsStyleLink} type="button">
          CSV
        </button>
        <div css={reportsStyleLinkDivider} />
        <button onClick={() => onDownload({ type, format: 'pdf' })} css={reportsStyleLink} type="button">
          PDF
        </button>
      </td>
    </tr>
  )
}

const reportsTableBodyTdStyle = css({
  fontSize: 14,
  padding: '0 32px',
  margin: '18px 0',
  fontWeight: 'bold',
})

const reportsTableBodyTdStyleTitle = css([
  reportsTableBodyTdStyle,
  {
    width: '100%',
    '.title-block': { display: 'flex', alignItems: 'center' },
    '.title-text': { width: 220, minWidth: 220, whiteSpace: 'nowrap' },
  },
])
const reportsTableBodyTdStyleLink = css([
  reportsTableBodyTdStyle,
  { whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', height: '100%' },
])
const reportsStyleLink = css([{ color: '#f2a40b', cursor: 'pointer', whiteSpace: 'nowrap', background: 'none', border: 'none' }])
const reportsStyleLinkDivider = css([{ height: 16, width: 1, backgroundColor: '#ddd', margin: '0 16px', display: 'inline-block' }])
