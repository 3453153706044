import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'

// apis
import { updateSales, backupReceiptPDF, fetchAdvanceCompany } from '@/apis/aipass'

// components
import { ConfirmedDiscount } from '@/components/molecules/customer/sales-manager/discount/confirmed-discount'
import { NotConfirmedDiscount } from '@/components/molecules/customer/sales-manager/discount/not-confirmed-discount'
import { DiscountSubject } from '@/components/molecules/customer/sales-manager/discount/subjects'
import { sectionHeaderStyle } from '@/components/pages/dashboard/_index/style'
import { Button } from '@/components/atoms/button'

// constants
import { simpleTableStyle } from '@/constants/layout'

// models
import { DiscountSettingType, EditDiscountType, PaymentSettingType, SalesDiscountType } from '@/models/sales'
import { ReservationType } from '@/models/reservation'
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'
import { ApprovedStatus } from '@/models/reservation-approved-status'

//libs
import { isDiscountChange } from '@/libs/sales-subjects'

// error
import { NOT_ENOUGH_BALANCE_CODE } from '@/errors/company-advance-money-error'

type DiscountProps = {
  checkinId: string
  customerId: string
  customerName: string
  salesDiscounts: DiscountSettingType[]
  salesDiscountPayments: PaymentSettingType[]
  setIsLoading: (v: boolean) => void
  discounts: SalesDiscountType[]
  originDiscounts: SalesDiscountType[]
  setDiscounts: (v: SalesDiscountType[]) => void
  editDiscounts: EditDiscountType[]
  setEditDiscounts: (v: EditDiscountType[]) => void
  confirmedDay: string
  useCheckinDay: string
  fetchDiscount: () => void
  accountsReceivableSettings: AccountsReceivableSettingType[]
  reservations: ReservationType[]
  currentTabReservationId: string
  salesAdvanceCompanyIds?: any
  salesDiscountPaymentsGroupBySalesDate?: any
  onUpdateDiscounts: () => void
}

export const Discount: React.FC<DiscountProps> = ({
  checkinId,
  customerId,
  customerName,
  salesDiscounts,
  salesDiscountPayments,
  setIsLoading,
  discounts,
  setDiscounts,
  originDiscounts,
  editDiscounts,
  setEditDiscounts,
  confirmedDay,
  useCheckinDay,
  fetchDiscount,
  accountsReceivableSettings,
  reservations,
  currentTabReservationId,
  salesAdvanceCompanyIds,
  salesDiscountPaymentsGroupBySalesDate,
  onUpdateDiscounts,
}) => {
  const { t } = useTranslation()

  const [deleteIds, setDeleteIds] = useState<{ id: string }[]>([])
  // Toggle edit mode
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [deletedDiscount, setDeletedDiscount] = useState<any>([])
  const [advanceCompany, setAdvanceCompany] = useState<any>({ paymentCompanies: [], paymentMethod: [] })

  useEffect(() => {
    fetchAdvanceCompany().then(res => {
      setAdvanceCompany(res)
    })
  }, [])
  // Edit Discount Item
  const changeDate = (date, index) => {
    const _editDiscounts = [...editDiscounts]
    _editDiscounts[index].salesDate = dayjs(date).format('YYYY-MM-DD')
    addIsEdit(_editDiscounts[index])
    setEditDiscounts(_editDiscounts)
  }
  const changeState = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index) => {
    const _editDiscounts = [...editDiscounts]
    const value = e.target.value
    const name = e.target.name
    _editDiscounts[index][name] = value
    if (name === 'salesDiscountId') {
      const findDiscounts = salesDiscounts.find(discount => {
        return discount.id === value
      })
      const alignmentAccountReceivable = accountsReceivableSettings?.find(receivable => {
        return receivable.code === findDiscounts?.accountsReceivableCode
      })
      if (alignmentAccountReceivable) {
        _editDiscounts[index]['salesAccountsReceivableMasterId'] = alignmentAccountReceivable.id
      } else {
        _editDiscounts[index]['salesAccountsReceivableMasterId'] = ''
      }
    }

    addIsEdit(_editDiscounts[index])
    setEditDiscounts(_editDiscounts)
    // If there is a null salesPaymentId value in _editDiscounts, hyphenate it there
    if (_editDiscounts[index]['salesPaymentId'] === '') {
      _editDiscounts[index]['salesPaymentId'] = salesDiscountPayments[0]['id']
    }
  }
  const changeSalesPrice = (price: number, index) => {
    const _editDiscounts = [...editDiscounts]
    _editDiscounts[index]['salesSubjectPrice'] = price
    addIsEdit(_editDiscounts[index])
    setEditDiscounts(_editDiscounts)
  }

  // Edit unconfirmed course
  const changeNotConfirmedDate = (date, index) => {
    const _discounts = [...discounts]
    _discounts[index].salesDate = dayjs(date).format('YYYY-MM-DD')
    addIsEdit(_discounts[index])
    setDiscounts(_discounts)
  }
  const changeNotConfirmedState = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, index) => {
    const _discounts = [...discounts]
    const value = e.target.value
    const name = e.target.name
    _discounts[index][name] = value
    if (name === 'salesDiscountId') {
      const findDiscounts = salesDiscounts.find(discount => {
        return discount.id === value
      })
      const alignmentAccountReceivable = accountsReceivableSettings?.find(receivable => {
        return receivable.code === findDiscounts?.accountsReceivableCode
      })
      if (alignmentAccountReceivable) {
        _discounts[index]['salesAccountsReceivableMasterId'] = alignmentAccountReceivable.id
      } else {
        _discounts[index]['salesAccountsReceivableMasterId'] = ''
      }
    }
    addIsEdit(_discounts[index])
    setDiscounts(_discounts)
  }

  const addDiscount = () => {
    setIsEditMode(true)
    const currentReservation = reservations.find(reservation => reservation.reservationId === currentTabReservationId)
    const isStayOrCO =
      currentReservation?.approvedStatus === +ApprovedStatus.Stay || currentReservation?.approvedStatus === +ApprovedStatus.Checkout

    const newDiscounts: EditDiscountType = {
      salesDate: isStayOrCO ? dayjs().format('YYYY-MM-DD') : useCheckinDay,
      salesDiscountId: '',
      salesSubjectPrice: 0,
      salesPaymentId: '',
      checkinId,
      reservationId: currentTabReservationId,
      isEdit: true,
      isDiscount: true,
    }
    setEditDiscounts([...editDiscounts, newDiscounts])
  }

  const deleteDiscount = (index: number) => {
    const newDetails = [...discounts]
    setDeletedDiscount([...deletedDiscount, newDetails[index]])
    newDetails.splice(index, 1)
    setDiscounts(newDetails)
    setDeleteIds([...deleteIds, { id: discounts[index].id }])
  }

  const deleteDiscountSubject = index => {
    const newSubjectDetails = [...editDiscounts]
    newSubjectDetails.splice(index, 1)
    setEditDiscounts(newSubjectDetails)
  }

  // Newly created and edited item flags
  const addIsEdit = (array: SalesDiscountType | EditDiscountType) => {
    const discountById = originDiscounts.find(originDiscount => originDiscount.id === array.id)
    const discountWithoutIsEdit = { ...array }
    delete discountWithoutIsEdit['isEdit']
    if (isDiscountChange(discountById, discountWithoutIsEdit)) {
      array['isEdit'] = true
    } else {
      array['isEdit'] = false
    }
  }

  const isDirtySalesOnConfirmed = (originSales: SalesDiscountType, editSales: SalesDiscountType | EditDiscountType): boolean => {
    if (!originSales.isConfirm) {
      return false
    }
    return originSales.salesDate !== editSales.salesDate || Number(originSales.salesSubjectPrice) !== Number(editSales.salesSubjectPrice)
  }

  const saveDiscount = () => {
    // Deep copy modified and newly created data
    const _editDiscounts = [...discounts, ...editDiscounts].map(value => ({ ...value }))
    // Saved array: data that has not been changed or newly created is deleted from the array
    const saveDiscounts = _editDiscounts.filter(discount => discount['isEdit'] === true)
    let isEditConfirmedSales = false

    // Edit confirmed data → add to history array, edit unconfirmed data && Edit target payment → set payment date to null
    originDiscounts.forEach(originDiscount => {
      if (deleteIds.findIndex(({ id }) => id === originDiscount.id) !== -1 && originDiscount.isConfirm) {
        isEditConfirmedSales = true
        return
      }
      // Get the edit item index with the same id as "original data id"
      const indexOfSameId = saveDiscounts.findIndex(sales => sales.id === originDiscount.id)
      if (indexOfSameId === -1) return
      isEditConfirmedSales = isEditConfirmedSales || isDirtySalesOnConfirmed(originDiscount, saveDiscounts[indexOfSameId])

      if (saveDiscounts[indexOfSameId]['salesPaymentId'] !== originDiscount.salesPaymentId) {
        // Set the payment date (paymentSalesManagerDailyId) to null when unconfirmed items are edited and the "target payment of original data" and "target payment of changed data" are different.
        saveDiscounts[indexOfSameId]['paymentSalesManagerDailyId'] = null
      }
    })

    // If the date of the saved data has been confirmed, add it to the history array
    saveDiscounts.forEach(discount => {
      saveDiscount['salesSubjectPrice'] = Number(saveDiscount['salesSubjectPrice']) || 0
    })

    // If the saved data is caught in validation, display an alert and end the saving process
    if (checkCompletedInput(saveDiscounts)) {
      alert(t('Select the discount item and eligible payment Please enter the amount in single-byte numbers'))
      return
    }

    // Combining "create new, modify" and "delete" (data sent to API)
    const updateDiscounts = [...saveDiscounts, ...deleteIds]

    // If there is no data, exit edit mode and end save processing
    if (updateDiscounts.length === 0) {
      setIsEditMode(false)
      return
    }

    if (
      isEditConfirmedSales &&
      !window.confirm(t('The date on which the closing process was closed. Sales totals will change, do you want to save them?'))
    ) {
      return
    }

    setIsLoading(true)
    updateSales(updateDiscounts)
      .then(async () => {
        fetchDiscount()
        const checkSalesDiscountPayments = salesDiscountPayments.find(salesDiscountPayment => salesDiscountPayment.name == '-')
        const checkSalePaymentId = updateDiscounts.some(item => {
          if (Object.prototype.hasOwnProperty.call(item, 'salesPaymentId')) {
            return (item as any).salesPaymentId != (checkSalesDiscountPayments as any).id
          }

          return false
        })
        const checkDeletedDiscount = deletedDiscount.some(item => (item as any).salesPaymentId != (checkSalesDiscountPayments as any).id)

        if (checkSalePaymentId || checkDeletedDiscount) {
          const provision = ''
          backupReceiptPDF(customerId, customerName, provision)
        }
        onUpdateDiscounts()
        setEditDiscounts([])
        setDeleteIds([])
        setDeletedDiscount([])
        setIsEditMode(false)
        setIsLoading(false)
      })
      .catch(error => {
        if (error?.response?.data?.errorCode === NOT_ENOUGH_BALANCE_CODE) {
          alert(t('Insufficient balance Please check again'))
        } else {
          console.log(t('Communication failed'))
          setEditDiscounts([])
          setIsEditMode(false)
        }
        setIsLoading(false)
      })
  }

  // Cancellation process
  const resetDiscount = () => {
    setDiscounts(originDiscounts.map(value => ({ ...value })))
    // state initialization
    setEditDiscounts([])
    setDeletedDiscount([])
    setDeleteIds([])
    setIsEditMode(false)
  }

  // Are the subject and payment method entered?
  const checkCompletedInput = (editDiscounts: (SalesDiscountType | EditDiscountType)[]) => {
    const isEmptySalesSubjectId = editDiscounts.some(editDiscount => editDiscount.salesDiscountId === '')
    const isEmptySalesPaymentId = editDiscounts.some(editDiscount => editDiscount.salesPaymentId === '')
    // Discount amount : salesSubjectPrice
    const isHalfSizeNumberSalesSubjectPrice = editDiscounts.some(editDiscount => !String(editDiscount.salesSubjectPrice).match(/^[0-9]+$/))
    if (isEmptySalesSubjectId || isEmptySalesPaymentId || isHalfSizeNumberSalesSubjectPrice) {
      return true
    } else {
      return false
    }
  }

  return (
    <div css={leftSectionStyle}>
      <div css={headerContainerStyle}>
        <div css={leftContainerStyle}>
          <div css={headerStyle}>{t('Discount')}</div>
        </div>
      </div>
      <table css={simpleTableStyle} style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th css={{ width: '112px' }}>{t('Date')}</th>
            <th css={{ width: '18.36%' }}>{t('Discount item')}</th>
            <th css={{ width: '16.14%' }}>{t('Accommodation ID/Room Number')}</th>
            <th css={{ width: '18.36%' }}>{t('Eligible payment')}</th>
            <th css={{ width: '9.58%' }}>{t('Amount of money')}</th>
            <th css={{ width: '18.36%' }}>{t('Accounts receivable')}</th>
            <th css={{ width: '6.37%' }}></th>
          </tr>
        </thead>
        <tbody>
          {discounts &&
            discounts.map((discount, index) => {
              // FIXME
              // eslint-disable-next-line no-prototype-builtins
              if (!discount.hasOwnProperty('salesDate')) return null
              const isPaid = discount.salesPaymentName !== '-' || !discount.salesPaymentName
              const isFixDiscount = (discount.isConfirm && isPaid) || discount.hasSentPos
              return (
                <React.Fragment key={index}>
                  {isFixDiscount ? (
                    <ConfirmedDiscount key={index} index={index} discount={discount} advanceCompany={advanceCompany} />
                  ) : (
                    <NotConfirmedDiscount
                      key={index}
                      index={index}
                      discount={discount}
                      salesDiscounts={salesDiscounts}
                      salesDiscountPayments={salesDiscountPayments}
                      confirmedDay={confirmedDay}
                      isEditMode={isEditMode}
                      changeNotConfirmedDate={changeNotConfirmedDate}
                      changeNotConfirmedState={changeNotConfirmedState}
                      deleteDiscount={deleteDiscount}
                      setIsEditMode={setIsEditMode}
                      accountsReceivableSettings={accountsReceivableSettings}
                      reservations={reservations}
                      currentTabReservationId={currentTabReservationId}
                      advanceCompany={advanceCompany}
                      salesDiscountPaymentsGroupBySalesDate={salesDiscountPaymentsGroupBySalesDate}
                    />
                  )}
                </React.Fragment>
              )
            })}
          {/* Newly added discount items */}
          {editDiscounts &&
            editDiscounts.map((editDiscount, index) => (
              <DiscountSubject
                key={index}
                index={index}
                editDiscount={editDiscount}
                salesDiscounts={salesDiscounts}
                salesDiscountPayments={salesDiscountPayments}
                confirmedDay={confirmedDay}
                changeDate={changeDate}
                changeState={changeState}
                changeSalesPrice={changeSalesPrice}
                deleteDiscountSubject={deleteDiscountSubject}
                reservations={reservations}
                currentTabReservationId={currentTabReservationId}
                accountsReceivableSettings={accountsReceivableSettings}
                advanceCompany={advanceCompany}
                salesDiscountPaymentsGroupBySalesDate={salesDiscountPaymentsGroupBySalesDate}
              />
            ))}
        </tbody>
      </table>
      <div css={borderStyle}>
        {currentTabReservationId ? (
          <div css={addRoomListWrapperOpenHasRoomManagerPluginStyle} onClick={addDiscount}>
            <img src={require('@/static/images/plus_yellow.svg')} alt={t('Add icon yellow')} />
            <p>{t('Add Discount Item')}</p>
          </div>
        ) : (
          <p css={isEmptyReservationRoomListWrapperStyle}></p>
        )}
      </div>
      {isEditMode && (
        <div css={saveButtonStyle}>
          <Button buttonType={3} width={80} height={32} fontSize={12} marginRight={14} marginBottom={16} onClick={resetDiscount}>
            {t('Cancel')}
          </Button>
          <Button width={80} height={32} buttonType={1} fontSize={12} marginRight={32} marginBottom={16} onClick={saveDiscount}>
            {t('Save')}
          </Button>
        </div>
      )}
    </div>
  )
}

const salesItemStyle = css({
  display: 'flex',
  fontSize: 14,
})

const leftSectionStyle = css(salesItemStyle, {
  width: '74%',
  minWidth: 600,
  border: '1px solid #CCCCCC',
  borderRadius: 5,
  flexDirection: 'column',
  height: 'fit-content',
})

const headerStyle = css(sectionHeaderStyle, {
  height: 50,
  marginLeft: 28,
  '@media(max-width: 1080px)': {
    marginLeft: 16,
  },
})

const headerContainerStyle = css({
  marginRight: 32,
  display: 'flex',
  justifyContent: 'space-between',
})

const leftContainerStyle = css({
  display: 'flex',
  float: 'left',
  alignItems: 'center',
})

const borderStyle = css({
  borderTop: '1px solid ##F2F2F2',
})

const addRoomListWrapperStyle = css({
  cursor: 'pointer',
  display: 'flex',
  height: 36,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  border: '1px dashed #CCCCCC',
  p: {
    lineHeight: '36px',
    color: '#F2A40B',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
})

const addRoomListWrapperOpenHasRoomManagerPluginStyle = css(addRoomListWrapperStyle, {
  margin: '32px 24px',
  img: {
    width: 20,
  },
  ':hover': {
    background: '#F2F2F2',
  },
})

const saveButtonStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '24px',
  paddingBottom: '8px',
})

const isEmptyReservationRoomListWrapperStyle = css({
  display: 'flex',
  height: 36,
  aliginItemsCenter: 'center',
  margin: '32px 30px',
  p: {
    lineHeight: '36px',
    color: '#676767',
    fontSize: 14,
    paddingLeft: 8,
    letterSpacing: '1.4px',
  },
})
