import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { env } from '@/libs/env'
import { signout } from '@/apis/auth0'
import { Link } from 'react-router-dom'

interface HeaderSpProps {
  title: string
}

export const HeaderSp: React.FC<HeaderSpProps> = ({ title }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const { t, i18n } = useTranslation()
  const aipassZendeskUrl = env('AIPASS_ZENDESK_URL')

  const _logout = async () => {
    await signout()
  }

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    setIsOpenMenu(false)
  }

  return (
    <header css={headerStyle}>
      <h1>
        <Link style={{ color: '#272727' }} to={'/cleaning'}>
          {title}
        </Link>
      </h1>
      <div className={`menu-button ${isOpenMenu ? 'open' : ''}`} onClick={() => setIsOpenMenu(!isOpenMenu)}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      {isOpenMenu && (
        <nav css={navMenuStyle}>
          <ul>
            <li>
              Language
              <ul className="inner">
                <li onClick={() => changeLanguage('ja')}>日本語</li>
                <li onClick={() => changeLanguage('en')}>English</li>
              </ul>
            </li>
            <li>
              <a href={`${aipassZendeskUrl}/hc/ja`} target="_brank">
                {t('Help')}
              </a>
            </li>
            <li onClick={() => _logout()}>
              <p>{t('Logout')}</p>
            </li>
          </ul>
        </nav>
      )}
    </header>
  )
}

const headerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 56,
  paddingInline: 16,
  boxShadow: '0px 3px 6px #00000029',
  position: 'relative',
  zIndex: 201,
  h1: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  '.menu-button': {
    width: 24,
    height: 24,
    position: 'relative',
    span: {
      width: '100%',
      height: 2,
      backgroundColor: '#F2A40B',
      position: 'absolute',
      '&:first-of-type': {
        top: 0,
      },
      '&:nth-of-type(2)': {
        top: 11,
      },
      '&:last-of-type': {
        bottom: 0,
      },
    },
    '&.open': {
      span: {
        '&:first-of-type': {
          transform: 'translateY(11px) rotate(-45deg)',
        },
        '&:nth-of-type(2)': {
          opacity: 0,
        },
        '&:last-of-type': {
          transform: 'translateY(-11px) rotate(45deg)',
        },
      },
    },
  },
})

const navMenuStyle = css({
  position: 'absolute',
  top: 57,
  left: 0,
  width: '100%',
  height: 'calc(100vh - 56px)',
  padding: 24,
  backgroundColor: '#fff',
  zIndex: 200,
  li: {
    fontSize: 14,
    marginBottom: 24,
    color: '#272727',
    a: {
      color: '#272727',
    },
  },
  '.inner': {
    marginTop: 24,
    li: {
      paddingLeft: 16,
    },
  },
})
