export type NoticeCenterType = {
  unreadCount: number
  badgeCount: number
  notices: HotelNoticeType[]
}
export type HotelNoticeType = {
  id: string
  type: HotelNoticeStatusType
  message: string
  messageEn: string
  messageParameter: {} | undefined
  noticeRead: boolean
  createdAt: string
}

export const HotelNoticeStatusType = {
  Smaregi: 'smaregi',
  deviceError: 'device_error',
  subjectAlertForRequest: 'subject_alert_for_request',
  subjectAlertForSubjectSet: 'subject_alert_for_subject_set',
  subjectAlertForSpace: 'subject_alert_for_space',
  subjectAlertForTax: 'subject_alert_for_tax',
  guestRoomAssignDiffReservation: 'guest_room_assign_diff_reservation',
}
export type HotelNoticeStatusType = (typeof HotelNoticeStatusType)[keyof typeof HotelNoticeStatusType]

export type MessageParameterForSmaregi = {
  roomNumber: string
  transactionId: string
  salesManagerDailyId: string
}
export type MessageParameterForGuestRoomAssignDiffReservation = {
  reservationId: string
}
