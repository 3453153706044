import React, { useState, useEffect } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { CheckinTabContainer } from '@/components/organisms/settings/checkin-tab-container'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { EditFooter } from '@/components/organisms/edit-footer'
import { Editor } from '@/components/organisms/settings/editor'
import * as Api from '@/apis/aipass'
import { useHistory, useParams } from 'react-router-dom'
import { RadioGroupField } from '@/components/molecules/radio-group-field'
import { CustomCheckinTranslateText } from '@/libs/translate-text'
import { v4 as uuid } from 'uuid'

export const CustomCheckinEdit: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id: urlId } = useParams<{ id: string | undefined }>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [jpTitle, setJpTitle] = useState<string>('')
  const [jpContent, setJpContent] = useState<string>('')
  const [rawContent, setRawContent] = useState<string>()
  const [inputType, setInputType] = useState<'none' | 'textArea' | 'selectBox'>('none')
  const [jpInputValue, setJpInputValue] = useState<string[]>([''])
  const [isRequired, setIsRequired] = useState<boolean>(false)
  const [detailLoadedId, setDetailLoadedId] = useState<string>(uuid())

  const fetchCustomCheckin = async (customCheckinId: string) => {
    await Api.fetchCustomCheckin(customCheckinId).then(res => {
      setJpTitle(res.customCheckin.jpTitle)
      setRawContent(res.customCheckin.rawContent)
      setJpContent(res.customCheckin.jpContent)
      setInputType(res.customCheckin.inputType)
      setJpInputValue(res.customCheckin.jpInputValue.length === 0 ? [''] : res.customCheckin.jpInputValue)
      setIsRequired(Boolean(res.customCheckin.isRequired))
      setDetailLoadedId(customCheckinId)
    })
  }

  const changeInputValue = (e: React.ChangeEvent<HTMLInputElement>, index) => {
    jpInputValue[index] = e.target.value
    setJpInputValue([...jpInputValue])
  }

  const deleteInputValue = index => {
    setJpInputValue(jpInputValue.filter((_, i) => i !== index))
  }

  const addInputValue = () => {
    setJpInputValue([...jpInputValue, ''])
  }

  const changeIsRequired = () => {
    setIsRequired(!isRequired)
  }

  const updateCustomCheckin = async () => {
    setIsLoading(true)
    const inputValue = inputType === 'selectBox' ? jpInputValue : []
    const translatedData = await CustomCheckinTranslateText(
      jpTitle,
      jpContent,
      inputType,
      inputValue.filter(v => !!v),
      urlId,
      isRequired,
    )
    if (urlId) {
      await Api.updateCustomCheckin({ ...translatedData, rawContent })
    } else {
      await Api.storeCustomCheckin({ ...translatedData, rawContent })
    }

    setIsLoading(false)
    history.push({ pathname: '/setting/checkin/custom-checkin' })
  }

  useEffect(() => {
    if (urlId) {
      fetchCustomCheckin(urlId)
    }
  }, [])

  const footerContent = <EditFooter onCancel={history.goBack} onSave={updateCustomCheckin} />
  return (
    <SettingsLayout loading={isLoading} footerContent={footerContent}>
      <TabContainer tabComponent={<CheckinTabContainer currentTab="Question" />} isShowFooter={true}>
        <div css={editFormContainerStyle}>
          <div className="edit-form">
            <div>
              <label htmlFor="title" css={editFormLabelStyle}>
                {t('Title')}
              </label>
              <input type="text" id="title" value={jpTitle} onChange={e => setJpTitle(e.target.value)} css={editFormInputStyle} />
            </div>
            <div css={editorContainerStyle}>
              <label htmlFor="title" css={editFormLabelStyle}>
                {t('Content')}
              </label>
              <Editor
                id={detailLoadedId}
                style={{ height: '100%' }}
                htmlContent={jpContent}
                rawContent={rawContent}
                onChange={(raw: string, html: string) => {
                  setJpContent(html)
                  setRawContent(raw)
                }}
              />
            </div>
          </div>

          <div className="edit-form">
            <label htmlFor="title" css={editFormLabelStyle}>
              {t('Customer input field')}
            </label>
            <RadioGroupField<typeof inputType>
              value={inputType}
              onChange={v => setInputType(v.target.value)}
              items={[
                { value: 'none', label: t('None') },
                { value: 'textArea', label: t('Free description') },
                { value: 'selectBox', label: t('Selection formula') },
              ]}
              itemCss={{ marginRight: 28 }}
              style={{ labelMargin: 12 }}
            />
            {inputType === 'textArea' && (
              <div css={optionWrapperStyle}>
                <label>
                  <input
                    alt="checkbox"
                    type="image"
                    css={inputCheckboxStyle}
                    onClick={() => changeIsRequired()}
                    src={require(`@/static/images/check-box${isRequired === true ? '' : '_off'}.svg`)}
                  />
                  <span css={labelStyle}>{t('Required')}</span>
                </label>
              </div>
            )}
            {inputType === 'selectBox' && (
              <div css={optionWrapperStyle}>
                <ol css={formStyle}>
                  {jpInputValue &&
                    jpInputValue.map((v, index) => (
                      <li key={index}>
                        <div className="jpInputValue">
                          <input
                            name="jpInputValue"
                            type="text"
                            placeholder={t('Enter item')}
                            css={inputStyle}
                            value={v}
                            onChange={e => changeInputValue(e, index)}
                          />
                          {index > 0 && (
                            <div css={deleteButtonStyle} onClick={() => deleteInputValue(index)}>
                              <img src={require('@/static/images/delete_yellow.svg')} alt={t('Delete')} />
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  <div css={addStyle} onClick={addInputValue}>
                    {t('Add choice')}
                  </div>
                </ol>
                <div css={borderStyle}>
                  <label>
                    <input
                      alt="checkbox"
                      type="image"
                      css={inputCheckboxStyle}
                      onClick={() => changeIsRequired()}
                      src={require(`@/static/images/check-box${isRequired === true ? '' : '_off'}.svg`)}
                    />
                    <span css={labelStyle}>{t('Required')}</span>
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
      </TabContainer>
    </SettingsLayout>
  )
}

const editFormContainerStyle = css({
  display: 'flex',
  height: '100%',
  padding: 32,
  '.edit-form': {
    width: '50%',
    overflowY: 'scroll',
    '&:first-of-type': {
      marginRight: 32,
      paddingRight: 32,
      borderRight: '1px solid #F2F2F2',
    },
  },
})

const editFormLabelStyle = css({
  display: 'block',
  color: '#676767',
  fontSize: 12,
  fontWeight: 'bold',
  marginBottom: 12,
})

const editFormInputStyle = css({
  width: '100%',
  height: 32,
  border: '1px solid #ccc',
  borderRadius: 22,
  padding: '0 16px',
})

const editorContainerStyle = css({
  marginTop: 24,
  position: 'relative',
  height: '75.6%',
})

const formStyle = css({
  display: 'inline-block',
  paddingTop: 24,
  paddingLeft: 7,
  marginLeft: 32,
  li: {
    listStyleType: 'decimal',
    // display: 'flex',
    padding: '0 0 16px 0',
    div: {
      display: 'flex',
      paddingRight: 16,
    },
  },
})

const deleteButtonStyle = css({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  img: {
    width: 30,
    height: 30,
  },
})

const addStyle = css({
  cursor: 'pointer',
  color: '#F2A40B',
  fontSize: 14,
  marginBottom: 24,
})

const inputStyle = css({
  width: 296,
  padding: '5px 0 5px 16px',
  fontSize: 14,
  borderRadius: '16px',
  border: '1px solid #CCC',
  '::placeholder': {
    color: '#CCCCCC',
  },
})

const optionWrapperStyle = css({
  border: '1px solid #CCC',
  borderRadius: 7,
  display: 'block',
  justifyContent: 'flex-start',
  margin: '15px 0',
  img: {
    width: 20,
    height: 20,
  },
})

const inputCheckboxStyle = css({
  margin: '10px 0px 10px 15px',
  width: 20,
  height: 20,
})

const borderStyle = css({
  borderTop: '1px solid #CCC',
})

const labelStyle = css({
  fontSize: 14,
  textAlign: 'left',
  color: '#272727',
  letterSpacing: '1.4px',
  marginLeft: '10px',
})
