import React, { useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { useForm, useFieldArray } from 'react-hook-form'
import Lottie from 'react-lottie'

// components
import { Label } from '@/components/molecules/label'
import { Button } from '@/components/atoms/button'

// layout
import {
  modalContainerStyle,
  modalWrapperStyle,
  modalStyle,
  modalTitleStyle,
  modalSectionStyle,
  modalItemWrapperStyle,
  modalFooterStyle,
} from '@/components/pages/setting/guest-room/room/_room/modal/modal-layout'
import { createSalesSubjectSet, updateSalesSubjectSet } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { SalesSubjectSetType, SalesSubjectType } from '@/models/sales-manager/sales'
import { initializeSelectBoxStyle } from '@/constants/form'

type Props = {
  editSubjectSet?: SalesSubjectSetType
  salesSubjects: SalesSubjectType[]
  onClickCancel: () => void
  onSuccess: () => void
}

// @todo organismsなりに移動すること
export const SubjectSetModal: React.FC<Props> = ({ salesSubjects, editSubjectSet, onClickCancel, onSuccess }) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<{
    salesSubjectSetNameId: number
    salesSubjectSetName: string
    salesSubjectSetMap: { salesSubjectMasterId: string; salesSubjectMasterName?: string; isDisplay?: boolean }[]
  }>({
    defaultValues: editSubjectSet
      ? editSubjectSet
      : {
          salesSubjectSetMap: [
            { salesSubjectMasterId: '' },
            { salesSubjectMasterId: '' },
            { salesSubjectMasterId: '' },
            { salesSubjectMasterId: '' },
            { salesSubjectMasterId: '' },
          ],
        },
  })
  const {
    fields: salesSubjectSetMap,
    append: appendSubjectSelect,
    remove: removeSubjectSelect,
  } = useFieldArray({
    control,
    name: 'salesSubjectSetMap',
  })
  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = async formData => {
    try {
      setLoading(true)

      const { salesSubjectSetMap, ...data } = formData

      data['salesSubjectSetNameId'] = Number(data['salesSubjectSetNameId'])
      data['salesSubjectMasterIds'] = salesSubjectSetMap.filter(i => i.salesSubjectMasterId).map(i => i.salesSubjectMasterId)

      if (editSubjectSet?.salesSubjectSetId) {
        await updateSalesSubjectSet({ ...data, salesSubjectSetId: editSubjectSet.salesSubjectSetId })
      } else {
        await createSalesSubjectSet(data)
      }

      onSuccess()
    } catch (error) {
      errorHandler(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div css={[modalContainerStyle, { zIndex: 104, height: '100vh' }]}>
      <div css={[modalWrapperStyle, { height: '90%' }]}>
        <form onSubmit={handleSubmit(onSubmit)} css={[modalStyle, { width: 608, position: 'relative', height: 'auto', margin: 0 }]}>
          <div css={modalTitleStyle}>{t('Add subject set')}</div>
          <div css={modalSectionStyle}>
            <div css={modalItemWrapperStyle}>
              <div css={{ marginBottom: 16 }}>
                <Label label={`${t('Subject set ID (order)')} *`} />
                <input
                  {...register('salesSubjectSetNameId', {
                    required: t('The sales subject set id field is required.'),
                    maxLength: { value: 4, message: t('The sales subject set name id must not have more than 4 digits.') },
                  })}
                  css={inputStyle}
                  placeholder={t('Course set ID (within 4 digits)')}
                  type="number"
                />
                {!!errors.salesSubjectSetNameId && (
                  <div css={{ fontSize: 10, color: 'rgba(255,0,0,0.5)', marginTop: 4 }}>{errors.salesSubjectSetNameId?.message}</div>
                )}
              </div>

              <div css={{ marginBottom: 16 }}>
                <Label label={`${t('Subject set name')} *`} />
                <input
                  {...register('salesSubjectSetName', { required: t('Please enter the subject set name.') })}
                  css={inputStyle}
                  placeholder={t('Subject set name')}
                />
                {!!errors.salesSubjectSetName && (
                  <div css={{ fontSize: 10, color: 'rgba(255,0,0,0.5)', marginTop: 4 }}>{errors.salesSubjectSetName?.message}</div>
                )}
              </div>
              <div>
                <Label label={`${t('Subject')} *`} />
                {salesSubjectSetMap.map((item, index) => {
                  return (
                    <div key={index} css={{ marginBottom: 16 }}>
                      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <select
                          {...register(`salesSubjectSetMap.${index}.salesSubjectMasterId`, {
                            // required: t('Required field has not been entered'),
                          })}
                          css={selectStyle}
                        >
                          {getValues(`salesSubjectSetMap.${index}.isDisplay`) === false ? (
                            <option value={getValues(`salesSubjectSetMap.${index}.salesSubjectMasterId`)} disabled>
                              {getValues(`salesSubjectSetMap.${index}.salesSubjectMasterName`)}
                            </option>
                          ) : (
                            <option value="" disabled>
                              {t('Please select')}
                            </option>
                          )}
                          {salesSubjects.map((value, index) => (
                            <option key={index} value={value.id}>
                              {value.subjectName}
                            </option>
                          ))}
                        </select>
                        <img
                          css={deleteButton}
                          src={require('@/static/images/room_delete_gold.svg')}
                          onClick={e => removeSubjectSelect(index)}
                        />
                      </div>
                      {/* {!!errors?.salesSubjectSetMap?.[index] && (
                        <div css={{ fontSize: 10, color: 'rgba(255,0,0,0.5)', marginTop: 4 }}>
                          {errors?.salesSubjectSetMap?.[index]?.salesSubjectMasterId?.message}
                        </div>
                      )} */}
                    </div>
                  )
                })}
                <div css={plusButtonRowStyle} onClick={() => appendSubjectSelect({ salesSubjectMasterId: '' })}>
                  <img src={require('@/static/images/plus.svg')} alt={t('Add subject')} />
                  <p>{t('Add subject')}</p>
                </div>
              </div>
            </div>
          </div>
          <div css={modalFooterStyle}>
            <Button buttonType={3} width={110} height={38} fontSize={14} marginRight={16} onClick={onClickCancel}>
              {t('Cancel')}
            </Button>
            <Button
              buttonType={1}
              // buttonType={disabledSave ? 5 : 1}
              width={110}
              height={38}
              fontSize={14}
              marginRight={32}
              type="submit"
              // isDisabled={disabledSave}
            >
              {t('Save')}
            </Button>
          </div>
          {loading && (
            <div css={loaderContainer}>
              <Lottie
                height="80px"
                width="80px"
                options={{ animationData: require('@/static/images/json/loading.json'), autoplay: true, loop: true }}
              />
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

const loaderContainer = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0,0.3)',
  borderRadius: 4,
})

const plusButtonRowStyle = css({
  cursor: 'pointer',
  display: 'flex',
  height: 36,
  backgroundColor: '#FAFAFA',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px dashed #CCCCCC',
  p: {
    lineHeight: '36px',
    color: '#F2A40B',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
  img: {
    width: 20,
    height: 20,
  },
})

const selectStyle = css(initializeSelectBoxStyle, {
  width: '100%',
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  backgroundImage: `url(${require('@/static/images/arrow_yellow.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  display: 'block',
  paddingLeft: 14,
  paddingRight: 5,
  backgroundPosition: '95% center',
})

const inputStyle = css({
  width: '100%',
  padding: '9px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  fontSize: 14,
  lineHeight: '14px',
  height: 32,
  '::placeholder': {
    color: '#CCCCCC',
  },
})

const deleteButton = css({
  cursor: 'pointer',
  opacity: 0.6,
  transition: 'opacity 300ms ease-out',
  ':hover': {
    opacity: 1,
  },
})
