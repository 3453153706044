import React from 'react'
import { css } from '@emotion/core'
import { SettingTab } from '@/components/molecules/settings/setting-tab'
import { listContainerStyle } from '@/constants/layout'

const tabs = [
  {
    name: 'Department',
    path: '/setting/sales-manager/department',
  },
  {
    name: 'Subject',
    path: '/setting/sales-manager/sales',
  },
  {
    name: 'Supplementary subjects',
    path: '/setting/sales-manager/sub-subject',
  },
  {
    name: 'Payment',
    path: '/setting/sales-manager/payment',
  },
  {
    name: 'Discount',
    path: '/setting/sales-manager/discount',
  },
  {
    name: 'Accounts receivable',
    path: '/setting/sales-manager/accounts-receivable',
  },
  {
    name: 'Cash register',
    path: '/setting/sales-manager/register',
  },
  {
    name: 'Subject set',
    path: '/setting/sales-manager/subject-set',
  },
  {
    name: 'saleSubjectTax.Tax',
    path: '/setting/sales-manager/tax',
  },
] as const
type TabType = (typeof tabs)[number]['name']

export const SettingSalesManagerTab: React.FC<{ currentTab: TabType }> = ({ currentTab, children }) => {
  return (
    <SettingTab currentTab={currentTab} tabs={tabs}>
      <div css={topImageContainerStyle}>{children}</div>
    </SettingTab>
  )
}

const topImageContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 'auto',
  marginBottom: -10,
})
