import { GuestRoomAssignType } from '@/models/guest-room/guest-room-assign'
import { reservationStatusValues } from '@/models/reservation-reservation-status'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useLocation } from 'react-router-dom'
import { entireSearchDateTypeValues } from '@/models/entire-search'
import { approvedStatusValues } from '@/models/reservation-approved-status'

const queryString = require('query-string')

const getDateString = (tabType: string) => {
  switch (tabType) {
    case 'reservation':
      return t('Check-in date')
    case 'checkin':
      return t('Date of Check-in')
    case 'stay':
    case 'checkout':
      return t('Check-out date')
    default:
      return t('Date of Check-in')
  }
}

const createDateRangeString = ({ beginDate, endDate }: { beginDate?: string; endDate?: string }) => {
  return !beginDate && !endDate ? '' : `${beginDate} ~ ${endDate}`
}

export const getSearchedConditions = () => {
  const location = useLocation()

  const urlParams: Record<string, any> = (() => {
    const parsedQuery = queryString.parse(location.search) || {}
    return {
      ...parsedQuery,
      reservationStatuses: parsedQuery.reservationStatuses
        ? [parsedQuery.reservationStatuses]
            .flat()
            .map(status => reservationStatusValues[parseInt(status)])
            .join('・')
        : [],
      approvedStatuses: parsedQuery.approvedStatuses
        ? [parsedQuery.approvedStatuses]
            .flat()
            .map(status => approvedStatusValues[parseInt(status)])
            .join('・')
        : [],
      beginDate: parsedQuery.beginDate ? dayjs(parsedQuery.beginDate).format(t('YYYY-MM-DD')) : '',
      endDate: parsedQuery.endDate ? dayjs(parsedQuery.endDate).format(t('YYYY-MM-DD')) : '',
      searchBeginDate: parsedQuery.searchBeginDate ? dayjs(parsedQuery.searchBeginDate).format(t('YYYY-MM-DD')) : '',
      searchEndDate: parsedQuery.searchEndDate ? dayjs(parsedQuery.searchEndDate).format(t('YYYY-MM-DD')) : '',
    }
  })()

  const baseConditions = {
    [t('Full name')]: urlParams.name,
    [t('Furigana')]: urlParams.nameKana,
    [t('Reservation ID')]: urlParams.searchReservationId,
    [t('Room number')]: urlParams.roomNumber,
  }

  const conditions: Record<string, string> = (() => {
    if (urlParams.tabType === 'reservation') {
      return {
        [getDateString(urlParams.tabType)]: createDateRangeString({
          beginDate: urlParams.beginDate || urlParams.searchBeginDate,
          endDate: urlParams.endDate || urlParams.searchEndDate,
        }),
        ...baseConditions,
        [t('Status')]: urlParams.reservationStatuses,
        [t('Room assignment status')]: GuestRoomAssignType[urlParams.selectGuestRoomAssign],
      }
    }

    if (location.pathname === '/entire-search') {
      return {
        [entireSearchDateTypeValues[urlParams.globalSearchDateType]]: createDateRangeString({
          beginDate: urlParams.searchBeginDate,
          endDate: urlParams.searchEndDate,
        }),
        ...baseConditions,
        [t('Room assignment status')]: GuestRoomAssignType[urlParams.selectGuestRoomAssign],
        [t('Room number')]: urlParams.roomNumber,
        [t('Memo')]: urlParams.note,
        [t('Phone number')]: urlParams.telephone,
        [t('Status')]: urlParams.reservationStatuses,
        [t('Accommodation management status')]: urlParams.approvedStatuses,
        [t('Channel')]: urlParams.channelCodeName,
      }
    }

    return {
      [getDateString(urlParams.tabType)]: createDateRangeString({
        beginDate: urlParams.beginDate || urlParams.searchBeginDate,
        endDate: urlParams.endDate || urlParams.searchEndDate,
      }),
      ...baseConditions,
    }
  })()

  return conditions
}
