import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Timeline, times } from './timeline'
import { DatePicker } from '@/components/organisms/space-manager/date-picker'
import { useWindowSize } from 'react-use'
import { HEADER_HEIGHT } from '@/constants/layout'
import { NewSpace, SpaceDetail, getTime, getTimeRangeCount, TIME_LINE_WIDTH, NOW, convertEndTime } from '@/models/space-manager/timeline'
import { PanelCardList, reservedStatusColor } from './panel-card-list'
import { SpaceAsset, SpaceDetailType } from '@/models/guest-app/asset'
import uuid from 'react-uuid'
import { SpaceReservationModal } from './space-reservation-modal'
import { getReservationSpaces, getSpaceByReservation } from '@/apis/aipass'
import { LoadingFull } from '@/components/molecules/loading-full'
import { ApprovedStatus } from '@/models/reservation-approved-status'
import { useQuery } from '@/hooks/use-query'
import { SpaceReservation } from '@/models/space-manager'

const editStatusColor = (approvedStatus: string) => {
  switch (approvedStatus) {
    case ApprovedStatus.Reservation:
      return '#F2A429'
    case ApprovedStatus.Checkin:
      return '#F47110'
    case ApprovedStatus.Stay:
      return '#3E85CC'
    case ApprovedStatus.Checkout:
      return '#7DC931'
    case 'preparation':
      return '#676767'
    default:
      return '#F2A429'
  }
}

interface AssignmentProps {
  spaceAssets: SpaceAsset[]
}
export const Assignment: React.FC<AssignmentProps> = ({ spaceAssets }) => {
  const query = useQuery()
  const [spaces, setSpaces] = useState<NewSpace>({})
  const [reservedSpaces, setReservedSpaces] = useState<SpaceDetail[]>([])
  const [viewDate, setViewDate] = useState(
    query.get('usedAt') ? dayjs(`${dayjs(query.get('usedAt') as string).format('YYYY-MM-DD')} ${dayjs().format('HH:mm:ss')}`) : NOW,
  )
  const [visibleReservationModal, setVisibleReservationModal] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [editReservedSpace, setEditReservedSpace] = useState<SpaceDetail>()
  const [isDeleteExistReservation, setIsDeleteExistReservation] = useState(false)
  const [showPanel, setShowPanel] = useState(false)
  const [loading, setLoading] = useState(false)
  const [fixedReservationId, setFixedReservationId] = useState<string | undefined>(query.get('reservationId') || undefined)

  const windows = useWindowSize()
  const { t } = useTranslation()

  const timeRangeRef = useRef<HTMLDivElement>(null)

  const createSpaceDetailsByFetch = (spaceReservations: SpaceReservation[]) => {
    const reservedDetails: SpaceDetail[] = []
    spaceReservations.forEach(reservationSpace => {
      reservationSpace.details.forEach(detail => {
        const startTime = dayjs(detail.usedAtFrom).format('HH:mm')
        const endTime = dayjs(detail.usedAtTo).format('HH:mm')
        const count = getTimeRangeCount({ start: startTime, end: endTime })
        const { hour, minute } = getTime(startTime)
        const left = hour * TIME_LINE_WIDTH + (minute / 60) * TIME_LINE_WIDTH
        const width = (count * TIME_LINE_WIDTH) / 4

        const space: SpaceDetail = {
          id: detail.id,
          spaceReservationId: reservationSpace.id,
          spaceTypeName: detail.reservedSpace.title.ja,
          date: dayjs(detail.usedAtFrom),
          spaceIndex: detail.reservedSpace.spaceIndex,
          usageTime: {
            usageFrom: startTime,
            usageTo: convertEndTime(endTime),
          },
          pax: detail.pax,
          answer: detail.answer,
          reservationUserName: detail.reservationUserName,
          reservation: reservationSpace.reservation,
          salesHour: findSpaceMaster(detail.reservedSpace.spaceId).salesHour,
          spaceId: detail.reservedSpace.spaceId,
          groupId: uuid(),
          style: {
            left,
            width,
            color: editStatusColor(reservationSpace.reservation.approvedStatus.toString()),
            backgroundColor: reservedStatusColor(reservationSpace.reservation.approvedStatus.toString()),
            border: '1px solid #CCCCCC',
          },
        }
        reservedDetails.push(space)
      })
    })

    return reservedDetails
  }

  const fetchReservationSpaces = async () => {
    setLoading(true)
    const reservationSpaces = await getReservationSpaces({ day: viewDate.format('YYYY-MM-DD') })
    const existDetails = createSpaceDetailsByFetch(reservationSpaces)

    setReservedSpaces(existDetails)
    setLoading(false)
  }

  // 新規スペース追加
  const addSpace = (space: SpaceDetail, allowEdit?: boolean) => {
    const newSpace = {
      ...space,
      style: {
        ...space.style,
        backgroundColor: space.style.backgroundColor,
      },
      date: viewDate,
    }
    let newSpaceDetails: SpaceDetail[] = []
    if (space.spaceTypeName in spaces) {
      const existDetails = spaces[space.spaceTypeName]
      existDetails.push({
        ...newSpace,
        groupId: space.groupId,
      })
      newSpaceDetails = existDetails
    } else {
      const newSpaceDetail: SpaceDetail = {
        ...newSpace,
        groupId: uuid(),
      }
      newSpaceDetails = [newSpaceDetail]
    }

    setSpaces({ ...spaces, [space.spaceTypeName]: newSpaceDetails })

    if (allowEdit) {
      setEditMode(true)
    }

    setShowPanel(true)
  }

  // 選択済み編集
  const onEditSpace = (editSpace: SpaceDetail) => {
    // 再度クリックした時は選択を解除
    if (editSpace.id && editSpace.reservation.reservationId === editReservedSpace?.reservation.reservationId) {
      const newSpace: NewSpace = Object.keys(spaces).reduce((acc, key) => {
        const details = spaces[key].filter(detail => detail.reservation.reservationId !== editSpace.reservation.reservationId)

        acc[key] = details
        return acc
      }, {})

      setEditReservedSpace(undefined)
      setSpaces(newSpace)
    }
    setShowPanel(true)
  }

  // 予約済み編集
  const onEditReservedSpace = async (editSpace: SpaceDetail) => {
    if (isDeleteExistReservation) {
      return
    }

    const fetchedReservationSpaces = await getSpaceByReservation(editSpace.reservation.reservationId).then(res => res || [])
    const sameReservationSpaces = createSpaceDetailsByFetch(fetchedReservationSpaces)

    const newSpaces: NewSpace = sameReservationSpaces.reduce((acc, sameReservationSpace) => {
      const newSpace: SpaceDetail = {
        ...sameReservationSpace,
        style: {
          ...sameReservationSpace.style,
          backgroundColor: editStatusColor(sameReservationSpace.reservation.approvedStatus.toString()),
          color: '#fff',
          border: 'none',
        },
        groupId: sameReservationSpace.groupId,
      }
      if (sameReservationSpace.spaceTypeName in acc) {
        const details = acc[sameReservationSpace.spaceTypeName]
        acc[sameReservationSpace.spaceTypeName] = [...details, newSpace]
      } else {
        acc[sameReservationSpace.spaceTypeName] = [newSpace]
      }

      return acc
    }, {})

    setEditReservedSpace(editSpace)
    setShowPanel(true)
    setSpaces(newSpaces)
  }

  const changeSpace = (spaceTypeName: string, newDetails: SpaceDetail[]) => {
    setSpaces({ ...spaces, [spaceTypeName]: newDetails })
  }

  const onChangeDate = (dateString: string) => {
    setViewDate(dayjs(`${dateString} ${viewDate.format('HH:mm')}`))
  }

  const onClosePanel = () => {
    setSpaces({})
    setShowPanel(false)
    setEditReservedSpace(undefined)
    setEditMode(false)
    setIsDeleteExistReservation(false)
    setFixedReservationId(undefined)
  }

  const onSaved = async () => {
    onClosePanel()
    setVisibleReservationModal(false)
    await fetchReservationSpaces()
  }

  const removeEditReservation = (deleteSpace: SpaceDetail) => {
    const newDetails = spaces[deleteSpace.spaceTypeName]?.filter(detail => detail.groupId !== deleteSpace.groupId)

    changeSpace(deleteSpace.spaceTypeName, newDetails)

    if (deleteSpace.id) {
      setIsDeleteExistReservation(true)
    }
  }

  const findSpaceMaster = (spaceId: string): SpaceDetailType => {
    return spaceAssets.flatMap(space => space.details).find(space => space.id === spaceId) as SpaceDetailType
  }

  const spaceLimitTimes: Record<string, { start: string; end: string }> = spaceAssets
    .flatMap(space => space.details)
    .reduce((acc, detail) => {
      acc[detail.title] = detail.spacePrice?.chargePrice.usageTime
      return acc
    }, {})

  const barStyle = useMemo(() => {
    const hour = viewDate.hour() * TIME_LINE_WIDTH
    const minute = (viewDate.minute() / 60) * TIME_LINE_WIDTH

    return css({
      left: `${hour + minute}px`,
    })
  }, [viewDate])

  useLayoutEffect(() => {
    const hour = viewDate.hour()
    const scrollDistance = (hour - 1) * TIME_LINE_WIDTH

    if (timeRangeRef.current) {
      timeRangeRef.current.scrollLeft = scrollDistance
    }
  }, [timeRangeRef, viewDate])

  useEffect(() => {
    fetchReservationSpaces()
  }, [viewDate])

  useEffect(() => {
    if (!fixedReservationId) {
      return
    }
    const reservedSpacesByQuery = reservedSpaces.filter(r => r.reservation.reservationId === fixedReservationId)
    if (reservedSpacesByQuery.length) {
      onEditReservedSpace(reservedSpacesByQuery[0])
    } else {
      setShowPanel(true)
    }
  }, [fixedReservationId, reservedSpaces])

  return (
    <>
      <div css={assignmentWrapperStyle}>
        <div ref={timeRangeRef} className="timeline-wrapper" style={{ height: windows.height - HEADER_HEIGHT - 50 }}>
          <div style={{ position: 'sticky', left: 0, zIndex: 20, background: '#fff' }}>
            <div className="date-range">
              <img
                src={require(`@/static/images/arrow_left_gray.svg`)}
                css={{ width: 36, cursor: 'pointer' }}
                onClick={() => setViewDate(viewDate.subtract(1, 'day'))}
              />
              <DatePicker isFullYear date={viewDate.format('YYYY-MM-DD')} onDateChange={dateString => onChangeDate(dateString)} />
              <img
                src={require(`@/static/images/arrow_right_gray.svg`)}
                css={{ width: 36, cursor: 'pointer' }}
                onClick={() => setViewDate(viewDate.add(1, 'day'))}
              />
            </div>

            {spaceAssets.map(space => (
              <div key={space.id}>
                <p className="space-name">{space.title}</p>

                <ul className="space-list">
                  {space.details.map(spaceDetail =>
                    Array.from(new Array(spaceDetail.stock)).map((_, stockIndex) => (
                      <li key={`${spaceDetail.title}-${stockIndex}`}>
                        <div style={{ width: '100%' }}>
                          <div className="type-time" key={stockIndex}>
                            <p className="type-name">{stockIndex === 0 ? spaceDetail.title : ''}</p>
                            <p className="amount">{++stockIndex}</p>
                          </div>
                        </div>
                      </li>
                    )),
                  )}
                </ul>
              </div>
            ))}
          </div>

          <div className="time-range-block">
            <div style={{ position: 'sticky', top: 0, zIndex: 19 }}>
              <p className="select-date">{viewDate.format(t('YYYY-MM-DD'))}</p>
              <ul className="time-list">
                {times.map(time => {
                  const isCurrent = viewDate.isSame(NOW, 'd') && dayjs(viewDate.format(`YYYY/MM/DD ${time}`)).isSame(viewDate, 'hour')
                  return (
                    <li key={time} className={isCurrent ? 'current' : ''}>
                      {time}
                    </li>
                  )
                })}
              </ul>
            </div>

            <div className="timeline">
              <ul>
                {spaceAssets.map((spaceAsset, index) => {
                  return (
                    <>
                      <li key={`${spaceAsset.id}-${index}`} style={{ background: '#FAFAFA' }} />
                      {spaceAsset.details.map((spaceDetail, spaceDetailIndex) => {
                        return Array.from(new Array(spaceDetail.stock)).map((v, stockIndex) => {
                          const spaceIndex = ++stockIndex
                          const spacesByDate = spaces[spaceDetail.title]?.filter(({ date }) => date?.isSame(viewDate, 'day')) || []
                          const sameReservedSpaces = reservedSpaces
                            .filter(reservedSpace => reservedSpace.date?.isSame(viewDate, 'day'))
                            .filter(
                              reservedSpace => reservedSpace.spaceTypeName === spaceDetail.title && reservedSpace.spaceIndex === spaceIndex,
                            )

                          return (
                            <li key={`${spaceDetail.title}-${spaceDetailIndex}-${stockIndex}`} css={timelineStyle} className="unit-list">
                              <Timeline
                                spaceIndex={spaceIndex}
                                spaceId={spaceDetail.id}
                                salesHour={spaceDetail.salesHour}
                                usageTime={spaceDetail.spacePrice?.chargePrice.usageTime}
                                toAddSpaces={spacesByDate}
                                reservedSpaces={sameReservedSpaces}
                                typeName={spaceDetail.title}
                                editMode={editMode}
                                addSpace={(space: SpaceDetail, allowEdit?: boolean) => addSpace(space, allowEdit)}
                                onEditSpace={onEditSpace}
                                removeEditReservation={removeEditReservation}
                                onEditReservedSpace={onEditReservedSpace}
                                editReservedSpace={editReservedSpace}
                              />
                            </li>
                          )
                        })
                      })}
                    </>
                  )
                })}
              </ul>
              {viewDate.isSame(NOW, 'day') && (
                <div className="current-time-bar" css={barStyle}>
                  <div>
                    <span></span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showPanel && (
          <PanelCardList
            editMode={editMode}
            onSaved={onSaved}
            editReservedSpace={editReservedSpace}
            setEditMode={setEditMode}
            onChangeDate={onChangeDate}
            isDeleteExistReservation={isDeleteExistReservation}
            spaces={spaces}
            setVisibleReservationModal={setVisibleReservationModal}
            reservedSpaces={reservedSpaces}
            changeSpace={changeSpace}
            onCancelEdit={onClosePanel}
            onClosePanel={onClosePanel}
            removeEditReservation={removeEditReservation}
            spaceLimitTimes={spaceLimitTimes}
            fixedReservationId={fixedReservationId}
          />
        )}
      </div>

      <LoadingFull isLoading={loading} />

      {visibleReservationModal && <SpaceReservationModal date={viewDate} spaces={spaces} onClose={() => onSaved()} />}
    </>
  )
}

const assignmentWrapperStyle = css({
  position: 'relative',
  '.timeline-wrapper': {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    background: '#fff',
    boxShadow: '0px 0px 6px #0000001A',
    borderRadius: 5,
    overflow: 'auto',
  },
  '.date-range': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    height: 95,
    borderRight: '1px solid #F2F2F2',
    position: 'sticky',
    top: 0,
    '.DateInput_input': {
      width: 124,
      fontWeight: 'bold',
      letterSpacing: 0.7,
    },
  },
  '.select-date': {
    padding: '11px 16px',
    height: 32,
    fontSize: 11,
    fontWeight: 'bold',
    background: '#FAFAFA',
  },
  '.date .DateInput_input': {
    fontSize: 12,
    fontWeight: 'bold',
  },
  '.space-name': {
    height: 42,
    fontSize: 12,
    fontWeight: 'bold',
    paddingBlock: '17px',
    paddingLeft: 24,
    background: '#FAFAFA',
  },
  '.time-list': {
    display: 'flex',
    width: '100%',
    height: 63,
    borderTop: '1px solid #F2F2F2',
    background: '#fff',
    li: {
      width: TIME_LINE_WIDTH,
      fontSize: 14,
      fontWeight: 'bold',
      position: 'relative',
      flex: 1,
      padding: '25px 16px',
      borderRight: '1px solid #F2F2F2',
      '&.current': {
        color: '#F2A40B',
      },
    },
  },
  '.timeline': {
    position: 'relative',
    '.current-time-bar': {
      position: 'absolute',
      width: 2,
      height: '100%',
      background: '#F2A40B',
      top: 0,
      zIndex: 18,
      '> div': {
        position: 'relative',
        width: '100%',
        height: '100%',
        span: {
          width: 14,
          height: 9,
          background: '#F2A40B',
          position: 'absolute',
          clipPath: 'polygon(0 0, 100% 0, 50% 100%)',
          top: 0,
          left: -6,
        },
      },
    },
    li: {
      height: 42,
      borderTop: '1px solid #F2F2F2',
      '&:last-of-type': {
        borderBottom: '1px solid #F2F2F2',
      },
    },
  },
  '.space-list': {
    li: {
      display: 'flex',
      height: 42,
      borderTop: '1px solid #F2F2F2',
      '&:last-of-type': {
        borderBottom: '1px solid #F2F2F2',
      },
    },
    '.type-name': {
      width: 174,
      height: '100%',
      fontSize: 12,
      fontWeight: 'bold',
      paddingBlock: 17,
      paddingLeft: 24,
    },
    '.amount': {
      width: 37,
      textAlign: 'center',
      paddingBlock: 17,
      borderLeft: '1px solid #F2F2F2',
      borderRight: '1px solid #F2F2F2',
    },
    '.type-time': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      fontSize: 12,
      fontWeight: 'bold',
    },
  },
  '.SingleDatePickerInput': {
    border: 'none',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '.SingleDatePickerInput_calendarIcon': { display: 'none' },
  },
  '.SingleDatePicker_picker.SingleDatePicker_picker__directionLeft': {
    top: '32px !important',
  },
  '.SingleDatePickerInput__withBorder, .DateInput ': {
    width: 120,
  },
})

const timelineStyle = css({
  display: 'flex',
  width: '100%',
  height: '100%',
  position: 'relative',
  '.unit': {
    display: 'inline-block',
    width: '25%',
    height: '100%',
    cursor: 'pointer',
    borderRight: '1px #F2F2F2',
    '&.overtime': {
      background: '#FAFAFA',
      pointerEvents: 'none',
    },
  },
})
