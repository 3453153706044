import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SerializedStyles, css } from '@emotion/core'
import { RoomType, CleaningStatusType, CleaningStatusValues, RoomUsageStatusValues } from '@/constants/cleaning-manager'
import dayjs from 'dayjs'
import { hasCleaningMemo } from '@/models/reservation/reservation-note'
import { useHistory } from 'react-router-dom'
import { CleaningManagerContext } from '../room'
const queryString = require('query-string')

type ContainerProps = {
  room: RoomType
  cleaningStaffId: string
}

export const RoomSp: React.FC<ContainerProps> = ({ room, cleaningStaffId }) => {
  const [cleaningStatusStyle, setCleaningStatusStyle] = useState<SerializedStyles>()
  const [hasInstruction, setHasInstruction] = useState<boolean>(false)
  const [hasComment, setHasComment] = useState<boolean>(false)
  const [hasPhoto, setHasPhoto] = useState<boolean>(false)

  const { t } = useTranslation()

  const { selectedDate } = useContext(CleaningManagerContext)

  const history = useHistory()
  const params = queryString.parse(history.location.search)

  const computedRoomStyle = (room: RoomType) => {
    switch (room.cleaningStatus) {
      case CleaningStatusType.NotCleaning:
        if (room.isStayOrScheduleOfStay) {
          setCleaningStatusStyle(css([stayCleaningStatusColor, !room.isCleaningRequired && dirtyRoomColor]))
        } else {
          setCleaningStatusStyle(css([outCleaningStatusColor, !room.isCleaningRequired && dirtyRoomColor]))
        }
        break
      case CleaningStatusType.Cleaned:
        setCleaningStatusStyle(cleanStatusColor)
        break
      case CleaningStatusType.Inspected:
        setCleaningStatusStyle(inspectedStatusColor)
        break
      case CleaningStatusType.DoNotDisturb:
        setCleaningStatusStyle(ddStatusColor)
        break
      default:
        setCleaningStatusStyle(dirtyStatusColor)
        break
    }
  }

  useEffect(() => {
    computedRoomStyle(room)
    setHasInstruction(room.adminComment.length > 0)
    setHasComment(room.staffReport.length > 0)
    setHasPhoto(room.photos.length > 0)
  }, [room])

  return (
    <div
      onClick={() =>
        history.push(
          `/cleaning/detail/${room.guestRoomId}?dateOf=${selectedDate.format('YYYY-MM-DD')}&tabIndex=${
            params.tabIndex
          }&staffId=${cleaningStaffId}`,
        )
      }
      css={[roomStyle, cleaningStatusStyle]}
    >
      <div css={roomHeaderStyle}>
        <p style={{ marginBottom: 8 }}>{room.roomNumber}</p>
        <div style={{ display: 'flex', gap: 8 }}>
          {hasPhoto && <img className="icon" src={require('@/static/images/camera_icon_gray.svg')} alt="" />}
          {(hasInstruction || hasCleaningMemo(room.nextReservation?.notes)) && (
            <img className="icon" src={require('@/static/images/cleaning_manager/icon_instruction.svg')} alt="" />
          )}
          {hasComment && <img className="icon" src={require('@/static/images/cleaning_manager/icon_comment.svg')} alt="Icon Report" />}
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div css={statusStyle}>
          <span className="cleaning">{t(CleaningStatusValues[room.cleaningStatus])}</span>
          <span className="usage">{t(RoomUsageStatusValues[room.roomUsageStatus])}</span>
        </div>
        <p css={inTimeStyle}>
          IN&nbsp;:&nbsp;
          {room.nextReservation?.roomCheckinAt && dayjs(room.nextReservation.roomCheckinAt).isSame(selectedDate, 'day')
            ? dayjs(room.nextReservation.roomCheckinAt).format('HH:mm')
            : '-'}
        </p>
      </div>
    </div>
  )
}

const roomHeaderStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: 8,
  '.icon': {
    width: 14,
  },
})
const dirtyRoomColor = css({
  backgroundColor: '#FFFFFF',
})
const outCleaningStatusColor = css({
  borderColor: '#F2A40B',
  backgroundColor: '#FFFCDD',
  '.cleaning': {
    color: '#F2A40B !important',
  },
})
const stayCleaningStatusColor = css({
  borderColor: '#F47110',
  backgroundColor: '#FEF3F0',
  '.cleaning': {
    color: '#F47110 !important',
  },
})
const dirtyStatusColor = css({
  borderColor: '#CCCCCC',
  '.cleaning': {
    color: '#CCCCCC !important',
  },
})
const ddStatusColor = css({
  borderColor: '#676767',
  backgroundColor: '#F2F2F2',
  '.cleaning': {
    color: '#676767 !important',
  },
})
const cleanStatusColor = css({
  borderColor: '#3E85CC',
  backgroundColor: '#EFF9FE',
  '.cleaning': {
    color: '#3E85CC !important',
  },
})
const inspectedStatusColor = css({
  borderColor: '#7CC931',
  backgroundColor: '#F5FDF2',
  '.cleaning': {
    color: '#7CC931 !important',
  },
})

const roomStyle = css({
  width: '100%',
  border: '1px solid #CCCCCC',
  borderLeft: '6px solid',
  backgroundColor: '#FFFFFF',
  borderRadius: 5,
  padding: '16px 22px',
})

const statusStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  '.cleaning': {
    width: 72,
    marginRight: 30,
    fontSize: 16,
    fontWeight: 'bold',
  },
  '.usage': {
    paddingLeft: 12,
    borderLeft: '1px solid #CCCCCC',
  },
})

const inTimeStyle = css({
  fontSize: 12,
  fontWeight: 'bold',
  color: '#A3A3A3',
  textAlign: 'right',
  width: 64,
})
