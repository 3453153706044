import { css } from '@emotion/core'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const ShortText = ({ children, maxLine = 2 }: { children: string; maxLine?: number }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (contentRef.current && containerRef.current) {
      if (contentRef.current?.scrollHeight > containerRef.current?.clientHeight) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    }
  }, [children])

  return (
    <div ref={containerRef} css={textContainer}>
      <div ref={contentRef} className={`${!expanded || !showButton ? 'short-text__text--short' : ''}`} css={{ lineClamp: maxLine }}>
        {children}
      </div>
      {showButton && (
        <div className={`short-text__button ${expanded ? 'short-text__button--expanded' : ''}`} onClick={() => setExpanded(!expanded)}>
          {t(!expanded ? 'Expand' : 'Restore')} <img alt="icon_arrow_up" src={require('@/static/images/icon_more_view_arrow.svg')} />
        </div>
      )}
    </div>
  )
}

const textContainer = css({
  whiteSpace: 'pre-wrap',
  '.short-text__text--short': {
    display: '-webkit-box',
    boxOrient: 'vertical',
    WebkitBoxOrient: 'vertical',
    lineClamp: 6,
    WebkitLineClamp: 6,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '9em',
  },
  '.short-text__button': {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    cursor: 'pointer',
    fontSize: 12,
    img: {
      width: 20,
      height: 20,
      translate: 'transform 300ms',
    },
    '&.short-text__button--expanded img': {
      transform: 'rotate(-180deg)',
    },
  },
})
