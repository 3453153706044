import React, { useEffect, useState } from 'react'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import {
  dashboardPageContainerStyle,
  tableHeaderThStyle,
  tableBodyTdStyle,
  dashboardPageHeaderStyle,
  HEADER_HEIGHT,
} from '@/constants/layout'
import { useWindowSize } from 'react-use'
import { EditFooter } from '@/components/organisms/edit-footer'
import { Button } from '@/components/atoms/button'
import { EditCheckListModal } from '@/components/organisms/cleaning-manager/edit-check-list-modal'
import { CheckListType, StaffType } from '@/constants/cleaning-manager'
import { deleteCleaningManagerSetting, deleteCleaningStaff, fetchCleaningManagerSetting, fetchCleaningStaff } from '@/apis/aipass'

import { TabContainer } from '@/components/organisms/settings/tab-container'
import { CleaningTab } from '@/components/molecules/settings/cleaning-manager/cleaning-tab'
import { StaffModal } from '@/components/organisms/settings/cleaning-manager/staff-modal'
import { errorHandler } from '@/libs/errors'

export const SettingCleaningManager: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [nextOrder, setNextOrder] = useState<number>(1)
  const [showFooter, setShowFooter] = useState(false)
  const [staffList, setStaffList] = useState<StaffType[]>([])
  const [deleteStaffIds, setDeleteStaffIds] = useState<string[]>([])
  const [showModal, setShowModal] = useState(false)
  const [editItem, setEditItem] = useState<StaffType>()

  const { t } = useTranslation()
  const windows = useWindowSize()

  const fetchStaffs = async () => {
    setLoading(true)
    const staffs = await fetchCleaningStaff().then(res => res || [])
    setStaffList(staffs)
    setNextOrder(staffs.length ? staffs[staffs.length - 1].displayOrder + 1 : 1)
    setLoading(false)
  }

  const onEdit = (item: StaffType) => {
    setEditItem(item)
    setShowModal(true)
  }

  const initialView = () => {
    setShowFooter(false)
    fetchStaffs()
  }

  const closeModal = () => {
    setShowModal(false)
    setEditItem(undefined)
  }

  const deleteStaff = (deleteItem: StaffType) => {
    setDeleteStaffIds([...deleteStaffIds, deleteItem.id])
    setStaffList(staffList.filter(staff => staff.id !== deleteItem.id))
    setShowFooter(true)
  }

  const onDelete = async () => {
    try {
      await deleteCleaningStaff(deleteStaffIds)
      initialView()
    } catch (error) {
      errorHandler(error)
    }
  }

  useEffect(() => {
    initialView()
  }, [])

  const footerContent = showFooter ? <EditFooter onSave={onDelete} onCancel={initialView} /> : undefined

  return (
    <>
      <SettingsLayout loading={loading} footerContent={footerContent}>
        <TabContainer tabComponent={<CleaningTab currentTab="cleaningManager.CleaningStaff" />}>
          <div css={dashboardPageContainerStyle}>
            <div css={dashboardPageHeaderStyle}>
              <Button
                buttonType={1}
                width={107}
                height={32}
                fontSize={12}
                marginRight={16}
                icon={require('@/static/images/mobile_request/add.svg')}
                onClick={() => setShowModal(true)}
              >
                {t('Add new')}
              </Button>
            </div>
            <div css={{ overflow: 'auto', height: windows.height - HEADER_HEIGHT - 24 * 2 - 123 }}>
              {!!staffList?.length && (
                <table css={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th css={[tableHeaderThStyle, { paddingLeft: 28 }]} style={{ width: 148 }}>
                        {t('Display order')}
                      </th>
                      <th css={tableHeaderThStyle}>{t('Item name')}</th>
                      <th css={[tableHeaderThStyle, { paddingRight: 28 }]}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {staffList.map(staff => (
                      <tr key={staff.id} onClick={() => onEdit(staff)} style={{ cursor: 'pointer' }}>
                        <td css={[staffTdStyle, { paddingLeft: 28 }]}>{staff.displayOrder}</td>
                        <td css={staffTdStyle}>{staff.name}</td>
                        <td css={[staffTdStyle, { width: 32, paddingRight: 28 }]}>
                          <img
                            onClick={e => {
                              e.stopPropagation()
                              deleteStaff(staff)
                            }}
                            src={require('@/static/images/delete_orange.svg')}
                            css={{ width: 32, height: 32, cursor: 'pointer' }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {!staffList?.length && !loading && (
                <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <h1>{t('No data available')}</h1>
                </div>
              )}
            </div>
          </div>
          {showModal && (
            <StaffModal nextOrder={nextOrder} editItem={editItem} onSave={initialView} onClose={closeModal} setLoading={setLoading} />
          )}
        </TabContainer>
      </SettingsLayout>
    </>
  )
}

const staffTdStyle = css(tableBodyTdStyle, {
  paddingBlock: 11,
  fontWeight: 'bold',
})

const headerSettingStaffStyle = css({
  height: '50px',
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
})

const headerDetailStyle = css({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '16px 0',
  color: '#272727',
})
