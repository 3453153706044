import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@/components/atoms/modal'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms'
import { css } from '@emotion/core'
import { Controller, useForm } from 'react-hook-form'
import { ButtonType } from '@/components/atoms/button'
import { Checkbox } from '@/components/atoms/checkbox'
import { InputField } from '@/components/molecules/input-field'
import { DateField } from '@/components/molecules/date-field'
import { TextareaField } from '@/components/molecules/textarea-field'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { ReservationSearchModal } from './reservation-search-modal'
import { ReservationType, SalesType } from '@/models/sales-invoice'
import { getSalesInvoice, createInvoice, fetchAdminAccount } from '@/apis/aipass'
import dayjs from 'dayjs'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { LoaderContextCreator } from '@/contexts/loader'

type FormParams = {
  clientName: string
  paymentDate: string
  note: string
  isIssueQuotation: boolean
}

interface Props {
  onClose: () => void
  initialReservations: ReservationType[]
}
export const InvoiceDetailModal: React.FC<Props> = ({ onClose, initialReservations }) => {
  const { t } = useTranslation()
  const tableBodyRef = useRef<HTMLDivElement>(null)
  const [isOpenReservationSearchModal, setIsOpenReservationSearchModal] = useState<boolean>(false)
  const [selectedReservations, setSelectedReservations] = useState<ReservationType[]>(initialReservations)
  const [selectedSales, setSelectedSales] = useState<SalesType[]>([])
  const [reservationSales, setReservationSales] = useState<SalesType[]>([])
  const [tableScrollbarWidth, setTableScrollbarWidth] = useState<number>(0)
  const [calenderPosition, setCalenderPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 })
  const { control, handleSubmit, formState, setValue } = useForm<FormParams>({ mode: 'all' })
  const { errorHandler } = useErrorHandler()
  const { setIsLoading } = useContext(LoaderContextCreator())

  const doIssue = async (formValue: FormParams) => {
    try {
      setIsLoading(true)
      const { invoiceUrl, quotationUrl } = await createInvoice({
        ...formValue,
        salesIds: selectedSales.map(s => s.salesId),
      })
      window.open(invoiceUrl, '_blank')
      quotationUrl && window.open(quotationUrl, '_blank')
      onClose()
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }

  const addReservations = (addReservations: ReservationType[]) => {
    const selectedReservationIds = selectedReservations.map(r => r.reservationId)
    setSelectedReservations([...selectedReservations, ...addReservations.filter(r => !selectedReservationIds.includes(r.reservationId))])
  }

  const removeReservation = (reservationId: string) => {
    setSelectedSales([...selectedSales.filter(s => s.reservationId !== reservationId)])
    setSelectedReservations([...selectedReservations.filter(r => r.reservationId !== reservationId)])
  }

  const toggleSales = (sales: SalesType) => {
    if (isSelectedSales(sales)) {
      setSelectedSales([...selectedSales.filter(s => s.salesId !== sales.salesId)])
    } else {
      setSelectedSales([...selectedSales, sales])
    }
  }

  const isSelectedSales = (sales: SalesType): boolean => {
    return selectedSales.find(s => s.salesId === sales.salesId) !== undefined
  }

  const onFocus = (e: React.FocusEvent<HTMLDivElement>) => {
    const rect = e.currentTarget.getBoundingClientRect()
    setCalenderPosition({ top: rect.top + 32, left: rect.left })
  }

  const datePickerPositionStyle = css({
    '.SingleDatePickerInput_calendarIcon': {
      marginRight: 0,
      paddingRight: 0,
      paddingLeft: 3,
    },
    'div.SingleDatePicker': {
      '.SingleDatePickerInput': {
        width: 184,
        'div.DateInput': {
          width: 134,
          'input.DateInput_input': {
            paddingRight: 0,
          },
        },
        'div.SingleDatePicker_picker': {
          '&.SingleDatePicker_picker__directionLeft': {
            position: 'fixed',
            top: `${calenderPosition.top}px!important`,
            left: `${calenderPosition.left}px!important`,
          },
          'div.DayPicker': {
            position: 'unset',
          },
        },
      },
    },
  })

  const totalSelectedPrice = useMemo(
    (): number => selectedSales.reduce((total, s) => total + s.saleItem.unitPrice * s.saleItem.quantity, 0),
    [selectedSales],
  )

  const isValid = useMemo(() => formState.isValid && selectedSales.length, [formState.isValid, selectedSales])

  useEffect(() => {
    if (!tableBodyRef?.current) {
      return
    }
    setTimeout(() => {
      setTableScrollbarWidth(tableBodyRef.current!.offsetWidth - tableBodyRef.current!.clientWidth)
    }, 100)
  }, [tableBodyRef?.current?.clientWidth])

  useEffect(() => {
    const selectedReservationIds = selectedReservations.map(r => r.reservationId)
    getSalesInvoice(selectedReservationIds).then(res => {
      const fetchedSalesId = reservationSales.map(s => s.salesId)
      const selectedSalesId = selectedSales.map(s => s.salesId)
      const newFetchSales = res.filter(
        sales => !sales.isIssued && !fetchedSalesId.includes(sales.salesId) && !selectedSalesId.includes(sales.salesId),
      )
      setSelectedSales([...selectedSales, ...newFetchSales])
      setReservationSales(res)
    })
  }, [selectedReservations])

  useEffect(() => {
    fetchAdminAccount().then(res => {
      if (res?.invoiceAccount?.note) {
        setValue('note', res.invoiceAccount.note)
      }
    })
  }, [])

  return (
    <>
      <div style={{ visibility: isOpenReservationSearchModal ? 'hidden' : 'visible' }}>
        <Modal customCss={modalStyle}>
          <ModalHeader>{t('Issue invoice')}</ModalHeader>
          <ModalBody customCss={{ padding: 0 }}>
            <div css={modalBodyStyle}>
              <div className="left-panel">
                <div className="summary-block">
                  <div>
                    <div className="-title">
                      {t('Client')}
                      <span>※</span>
                    </div>
                    <Controller
                      name="clientName"
                      control={control}
                      rules={{ required: t('Required field has not been entered') }}
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                        <InputField
                          marginBottom={0}
                          width="228px"
                          placeholder={t('Aipass Co Ltd')}
                          value={value}
                          name={name}
                          handleChangeData={onChange}
                          error={error?.message}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <div className="-title">
                      {t('Payment at')}
                      <span>※</span>
                    </div>
                    <Controller
                      name="paymentDate"
                      control={control}
                      rules={{ required: t('Required field has not been entered') }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="focus-div" onFocus={e => onFocus(e)}>
                          <DateField
                            placeholder={t('Full date')}
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            customCss={datePickerPositionStyle}
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div css={css({ textarea: { marginBottom: 0 } })}>
                    <div className="-title">{t('Remarks')}</div>
                    <Controller
                      name="note"
                      control={control}
                      render={({ field: { onChange, value, name } }) => (
                        <TextareaField marginBottom={0} placeholder="" value={value} name={name} handleChangeData={onChange} rows={3} />
                      )}
                    />
                  </div>
                </div>
                <div className="reservation-block">
                  <div className="-title">{t('Target reservation')}</div>
                  {selectedReservations &&
                    selectedReservations.map(r => (
                      <div style={{ paddingBottom: 8 }} key={r.reservationId}>
                        <div className="reservation-info-block">
                          <div className="block-header">
                            <div className="-title">{`${t('Accommodation ID')}：${r.accommodationId || '-'}`}</div>
                            <DeleteIcon onClick={() => removeReservation(r.reservationId)} />
                          </div>
                          <div className="block-body">
                            <div className="sub-text">{t('Guest')}</div>
                            <div className="text">{r.guestName || r.reservationUserName || '-'}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="add-button" onClick={() => setIsOpenReservationSearchModal(true)}>
                    <img src={require('@/static/images/plus_yellow.svg')} alt={t('Add icon yellow')} />
                    <p>{t('Add reservation')}</p>
                  </div>
                </div>
              </div>
              <div className="right-panel">
                <div className="table-header" style={{ paddingRight: tableScrollbarWidth }}>
                  <div style={{ minWidth: 68 }}></div>
                  <div style={{ minWidth: 77 }}>{t('Accommodation ID')}</div>
                  <div style={{ minWidth: 90 }}>{t('Date of use')}</div>
                  <div style={{ flexGrow: 1, paddingRight: 16 }}>{t('Reference')}</div>
                  <div style={{ minWidth: 99 }}>{t('Unit price')}</div>
                  <div style={{ minWidth: 89 }}>{t('Quantity')}</div>
                  <div style={{ minWidth: 102 }}>{t('Amount used')}</div>
                </div>
                <div className="table-body" ref={tableBodyRef}>
                  {reservationSales.map(sales => (
                    <div
                      className={`table-row ${sales.isIssued ? 'disabled' : ''}`}
                      key={sales.salesId}
                      onClick={() => !sales.isIssued && toggleSales(sales)}
                    >
                      <div style={{ minWidth: 68, paddingLeft: 24 }}>
                        <Checkbox
                          onChange={() => !sales.isIssued && toggleSales(sales)}
                          value={isSelectedSales(sales)}
                          disabled={sales.isIssued}
                        />
                      </div>
                      <div style={{ minWidth: 77 }}>{sales.accommodationId || '-'}</div>
                      <div style={{ minWidth: 90 }}>{dayjs(sales.saleItem.salesDate).format(t('MM-DD'))}</div>
                      <div style={{ flexGrow: 1, paddingRight: 16 }}>{sales.saleItem.subjectName}</div>
                      <div style={{ minWidth: 99 }}>{sales.saleItem.unitPrice.toLocaleString()}</div>
                      <div style={{ minWidth: 89 }}>{sales.saleItem.quantity.toLocaleString()}</div>
                      <div style={{ minWidth: 102 }}>{(sales.saleItem.unitPrice * sales.saleItem.quantity).toLocaleString()}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter customCss={modalFooterStyle}>
            <div className="price-block">
              {t('Amount billed')}
              <span>{`¥${totalSelectedPrice.toLocaleString()}`}</span>
            </div>
            <div className="button-block">
              <Controller
                name="isIssueQuotation"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox onChange={onChange} value={value} label={t('Also issue quotations')} style={css({ paddingRight: 32 })} />
                )}
              />
              <Button buttonType={ButtonType.Secondary} height="38px" width="110px" marginRight={16} onClick={() => onClose()}>
                {t('Cancel')}
              </Button>
              <Button
                buttonType={isValid ? ButtonType.Primary : ButtonType.PrimaryDisabled}
                height="38px"
                width="126px"
                onClick={handleSubmit(doIssue)}
              >
                {t('Issue')}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      {isOpenReservationSearchModal && (
        <ReservationSearchModal
          onClose={() => setIsOpenReservationSearchModal(false)}
          onNext={(r: ReservationType[]) => {
            setIsOpenReservationSearchModal(false)
            addReservations(r)
          }}
        />
      )}
    </>
  )
}

const modalStyle = css({
  width: 1200,
  height: 'calc(100vh - 10%)',
  top: 'calc((100vh - (100vh - 10%)) / 2)',
  left: 'calc((100% - 1200px) / 2)',
})

const modalBodyStyle = css({
  display: 'flex',
  height: '100%',
  '.-title': {
    fontWeight: 'bold',
    fontSize: 12,
    color: '#676767',
    letterSpacing: 0.6,
    paddingBottom: 12,
    position: 'relative',
    '> span': {
      paddingLeft: 4,
      fontWeight: 'bold',
      fontSize: 8,
      position: 'absolute',
    },
  },
  '.left-panel': {
    width: 336,
    borderRight: '1px solid #CCCCCC',
    overflow: 'auto',
    '.summary-block': {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      padding: 24,
      '> div': {
        paddingTop: 16,
        ':first-of-type': { paddingTop: 0 },
      },
    },
    '.reservation-block': {
      padding: 24,
      '.reservation-info-block': {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: '1px solid #CCCCCC',
        borderRadius: 10,
        marginBottom: 8,
        ':first-of-type': { marginBottom: 0 },
        '.block-header': {
          display: 'flex',
          padding: '2px 6px 2px 16px',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #CCCCCC',
          '.-title': { paddingBottom: 0 },
        },
        '.block-body': {
          padding: 16,
          '.sub-text': {
            fontSize: 10,
            lineHeight: '18px',
            fontWeight: 'bold',
            letterSpacing: 0.5,
            color: '#676767',
          },
          '.text': {
            paddingTop: 8,
            fontSize: 12,
            letterSpacing: 0.6,
            color: '#272727',
          },
        },
      },
      '.add-button': {
        marginTop: 8,
        cursor: 'pointer',
        display: 'flex',
        height: 38,
        backgroundColor: '#FFFFFF',
        justifyContent: 'center',
        border: '1px dashed #CCCCCC',
        flex: 1,
        p: {
          lineHeight: '36px',
          color: '#F2A40B',
          fontSize: 12,
          fontWeight: 'bold',
          paddingLeft: 10.8,
        },
        img: { width: 20 },
        ':hover': { background: '#F2F2F2' },
      },
    },
  },
  '.right-panel': {
    width: 864,
    margin: 24,
    border: '1px solid #CCCCCC',
    borderRadius: 10,
    background: '#F5F5F5 0% 0% no-repeat padding-box',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '.table-header': {
      display: 'flex',
      borderBottom: '1px solid #CCCCCC',
      alignItems: 'center',
      fontSize: 12,
      fontWeight: 'bold',
      letterSpacing: 1.2,
      color: '#676767',
      '> div': { height: 32, lineHeight: '32px' },
    },
    '.table-body': {
      overflow: 'auto',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      height: '100%',
      '.table-row': {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        minHeight: 50,
        fontSize: 14,
        letterSpacing: 1.4,
        color: '#272727',
        borderBottom: '1px solid #F2F2F2',
        '&.disabled': {
          backgroundColor: '#f6f6f6',
          color: '#a3a3a3',
        },
      },
    },
  },
})

const modalFooterStyle = css({
  justifyContent: 'space-between',
  '.price-block': {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#676767',
    letterSpacing: 0.7,
    '> span': {
      paddingLeft: 14,
      fontSize: 25,
      letterSpacing: 1.25,
    },
  },
  '.button-block': { display: 'flex' },
})
