import React, { useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

// constants
import { wholeContainerStyle, mainColumnStyle } from '@/constants/layout'

// components
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { LoadingFull } from '@/components/molecules/loading-full'
import dayjs from 'dayjs'
import { fetchStaffByMonth, fetchMonthSummary, toggleCleaningStaffShift } from '@/apis/aipass'
import { StaffType } from '@/constants/cleaning-manager'
import { ScheduleList, ScheduleType } from '@/components/organisms/cleaning-manager/schedule'

export const Schedule = () => {
  const [dateOf, setDateOf] = useState<dayjs.Dayjs>(dayjs())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [monthSummary, setMonthSummary] = useState<ScheduleType[]>([])
  const [staffList, setStaffList] = useState<StaffType[]>([])

  const { t } = useTranslation()

  const _fetchMonthSummary = async () => {
    setIsLoading(true)
    await fetchMonthSummary(dateOf.format('YYYY-MM-DD')).then(result => {
      setMonthSummary(result.summary)
    })
    await fetchStaffByMonth(dateOf.format('YYYY-MM-DD')).then(result => {
      setStaffList(result)
    })

    setIsLoading(false)
  }

  const onShiftChange = async ({ staffId, dateOf }: { staffId: string; dateOf: string }) => {
    await toggleCleaningStaffShift({ staffId, dateOf })
    await fetchStaffByMonth(dateOf).then(result => {
      setStaffList(result)
    })
  }

  const createDates = useMemo(() => {
    const startOfMonth = dateOf.startOf('month')
    const endOfMonth = dateOf.endOf('month')
    const daysInMonth = endOfMonth.diff(startOfMonth, 'day') + 1

    return Array.from({ length: daysInMonth }, (_, index) => {
      return startOfMonth.add(index, 'day').format('YYYY-MM-DD')
    })
  }, [dateOf])

  useEffect(() => {
    _fetchMonthSummary()
  }, [dateOf])

  return (
    <>
      <div css={wholeContainerStyle}>
        <SideMenu />
        <div className="mainColumn" css={mainStyle}>
          <Header title={t('Cleaning management')} />
          <ScheduleList
            dateOf={dateOf}
            setDateOf={setDateOf}
            monthSummary={monthSummary}
            staffList={staffList}
            createDates={createDates}
            onShiftChange={onShiftChange}
          />
        </div>
      </div>
      <LoadingFull isLoading={isLoading} />
    </>
  )
}

const mainStyle = css(mainColumnStyle, {
  '.sum': {
    width: 32,
    padding: '6px 9px',
    fontSize: 12,
    fontWeight: 'bold',
    backgroundColor: '#FAFAFA',
  },
})
