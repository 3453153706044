import { css } from '@emotion/core'

export const HEADER_HEIGHT = 55
export const SIDE_MENU_WIDTH = 47
// 24 = top and bottom padding
export const MAIN_CONTAINER_PADDING_VERTICAL = 24 + 24
// 64 = header bar height
export const MAIN_CONTAINER_WITH_HEADER_BAR_PADDING_VERTICAL = 64 + 24
export const MAIN_CONTAINER_FOOTER_BAR_HEIGHT = 60
// Margin with button at top of list
export const CONTAINER_WITH_HEADER_BUTTON_LIST_MARGIN = 40
// Sales management list margins in settings
export const SETTING_MANAGER_LIST_MARGIN = 150
// Margins of the accommodation management list
export const ACCOMMODATION_MANAGER_LIST_MARGIN = 100
// tab height
export const TAB_HEIGHT = 45
// background gray label height
export const GRAY_LABEL_HEIGHT = 32
// the height of the footer at the bottom of the list
export const LIST_FOOTER_HEIGHT = 58

export const wholeContainerStyle = css({
  display: 'flex',
  minHeight: '100vh',
})

export const mainColumnStyle = css({
  width: 'calc(100% - 47px)',
  marginLeft: 47,
  background: '#F2F2F2',
  // Commented for display sidebar only by icons
  // '@media (min-width: 1180px)': {
  //   width: 'calc(100% - 180px)',
  // }
})

export const mainContainerStyle = css({
  padding: '24px 24px 0',
})

// Type with footerbar in mainContainer
export const mainContainerWithFooterBarStyle = css({
  padding: '24px 24px 24px',
  height: '85vh',
  overflow: 'auto',
})

// headerbar in mainContainer
export const mainContainerHeaderBarStyle = css({
  width: '100%',
  height: 32,
  marginTop: -8,
  marginBottom: 16,
  display: 'flex',
  justifyContent: 'space-between',
})

export const mainContainerHeaderStyle = css({
  display: 'flex',
  minWidth: '1000px',
  height: 66,
  padding: '16px 32px',
  justifyContent: 'space-between',
  backgroundColor: '#FFFFFF',
})

export const headerBarLeftWrapperStyle = css({
  display: 'flex',
  alignItems: 'center',
})

export const headerBarRightWrapperStyle = css({
  display: 'flex',
})
// footerbar in mainContainer
export const mainContainerFooterBarStyle = css({
  padding: '11px 32px',
  boxShadow: '0px 0px 6px #0000001A',
  position: 'fixed',
  bottom: 0,
  width: '100%',
  background: 'white',
  display: 'flex',
  justifyContent: 'flex-start',
})

// list system
export const listContainerStyle = css({
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
})

export const listContainerGrayStyle = css(listContainerStyle, {
  minwidth: 500,
})

// 32px space on both sides is required for padding, but on the right side, add the amount of scrolling
export const listHeaderStyle = css({
  textAlign: 'left',
  color: '#676767',
  fontSize: 12,
  display: 'flex',
  backgroundColor: '#FFF',
  borderBottom: '1px solid #f2f2f2',
  padding: '14px 41px 14px 32px',
  overflow: 'hidden',
  fontWeight: 'bold',
})

export const listHeaderGrayStyle = css({
  display: 'flex',
  minWidth: '1000px',
  height: 32,
  textAlign: 'left',
  color: '#676767',
  fontSize: 12,
  backgroundColor: '#F5F5F5',
  fontWeight: 'bold',
})

export const listContentsWrapperStyle = css({
  overflowY: 'scroll',
  overflowX: 'hidden',
})

export const listContentsStyle = css({
  backgroundColor: '#FFF',
  fontSize: 14,
  color: '#272727',
})

export const listItemUnreadStyle = css({
  padding: '12px 32px',
  cursor: 'pointer',
  display: 'flex',
  borderBottom: '1px solid #f2f2f2',
  fontWeight: 'bold',
  width: '100%',
  backgroundColor: '#FFF',
  alignItems: 'center',
  '&:hover': {
    boxShadow: '0px 3px 6px #0000000f',
    position: 'relative',
  },
})

export const listItemReadStyle = css(listItemUnreadStyle, {
  background: '#F6F6F6',
  fontWeight: 'normal',
})

export const dataStatusApprovedCheckboxStyle = css({
  paddingLeft: 32,
})

export const titleXsmallWidthStyle = css({
  width: 'calc(9%)',
  flex: 'auto',
})

export const titleSmallWidthStyle = css({
  width: 'calc(13%)',
  flex: 'auto',
})

export const titleMediumWidthStyle = css({
  width: 'calc(16%)',
  flex: 'auto',
})

export const titleXlargeWidthStyle = css({
  width: 'calc(32%)',
  flex: 'auto',
})

export const titleLargeWidthStyle = css({
  width: 'calc(30%)',
  flex: 'auto',
})

export const deleteStyle = css({
  marginLeft: 'auto',
  height: 30,
  width: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    borderRadius: '50%',
    background: '#f2f2f2',
    cursor: 'pointer',
  },
})
export const headSortButtonStyle = css({
  paddingLeft: 5,
  cursor: 'pointer',
})

// Accommodation management (reservation, check-in, stay, check-out)
export const checkinDetailContainerStyle = css({
  display: 'flex',
  backgroundColor: '#FFF',
  boxShadow: '0px 0px 6px #0000001A',
  alignItems: 'stretch',
  overflow: 'hidden',
  borderRadius: 5,
  marginBottom: 24,
  minHeight: 270,
})

export const leftContainerStyle = css({
  borderRight: '1px solid #F2F2F2',
  flex: '1 1 70%',
})

export const rightContainerStyle = css({
  minHeight: 209,
  width: '29%',
  backgroundColor: '#FFF',
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  height: 'max-content',
})

export const dashboardPageContainerStyle = css(listContainerStyle, {
  width: '100%',
  background: '#FFF',
  minHeight: 410,
  boxShadow: '0px 0px 6px #0000001a',
  borderRadius: 5,
  height: '100%',
  overflowY: 'auto',
})

export const dashboardPageHeaderStyle = css({
  height: '75px',
  padding: '0 32px',
  borderBottom: '1px solid #F2F2F2',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export const tableHeaderThStyle = css({
  background: '#F5F5F5',
  color: '#676767',
  fontSize: 12,
  fontWeight: 'bold',
  padding: '8px',
  whiteSpace: 'nowrap',
  position: 'sticky',
  top: 0,
  zIndex: 1,
})

export const tableBodyTdStyle = css({
  padding: '8px',
  verticalAlign: 'middle',
  borderBottom: '1px solid #eee',
  fontSize: 14,
})

export const tableBodyTdStyleRead = css({
  background: '#F6F6F6',
  fontWeight: 'normal',
  color: '#000',
  cursor: 'pointer',
})

export const tableBodyTdStyleUnread = css({
  fontWeight: 'bold',
  backgroundColor: '#FFF',
  color: '#000',
  '&:hover': {
    boxShadow: '0px 3px 6px #0000000f',
    position: 'relative',
    cursor: 'pointer',
  },
})

export const simpleTableStyle = css({
  width: '100%',
  color: '#676767',
  tableLayout: 'auto',

  thead: {
    th: {
      fontWeight: 'bold',
      backgroundColor: '#F5F5F5',
      fontSize: 12,
      borderBottom: '1px solid #F2F2F2',
      padding: '10px 8px',
      verticalAlign: 'middle',
      ':first-child': {
        paddingLeft: 32,
        '@media(max-width: 1080px)': {
          paddingLeft: 16,
        },
      },
      ':last-child': {
        paddingRight: 32,
        '@media(max-width: 1080px)': {
          paddingRight: 16,
        },
      },
    },
  },
  tbody: {
    td: {
      borderBottom: '1px solid #F2F2F2',
      padding: '12px 8px',
      fontSize: 14,
      color: '#272727',
      verticalAlign: 'middle',
      ':first-child': {
        paddingLeft: 32,
        '@media(max-width: 1080px)': {
          paddingLeft: 16,
        },
      },
      ':last-child': {
        paddingRight: 32,
        '@media(max-width: 1080px)': {
          paddingRight: 16,
        },
      },

      'div.SingleDatePicker': {
        position: 'relative',
        '.SingleDatePickerInput': {
          width: 80,
          'div.DateInput': {
            'input.DateInput_input': {
              width: 80,
            },
          },
        },
      },
    },
  },

  '.unpaidStyle': {
    color: '#F2A40B',
  },

  '.ant-cascader-picker-label': {
    fontSize: 14,
    fontWeight: 'normal',
    letterSpacing: '1.4px',
    color: '#272727',
  },
  '.ant-cascader-picker-label:hover + .ant-cascader-input': {
    borderColor: '#CCCCCC',
    borderRightWidth: 'unset',
  },
  '.ant-cascader-picker:focus .ant-cascader-input': {
    borderColor: '#CCCCCC',
    borderRightWidth: 'unset',
    boxShadow: 'unset',
  },
  '.ant-input:focus': {
    boxShadow: 'unset',
  },
  '.cascader-customize': {
    width: '100%',
    marginLeft: 0,
    height: 32,
    backgroundImage: `url(${require('@/static/images/select.svg')})`,
    backgroundPosition: 'calc(100% - 8px) center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 10,
    input: {
      borderRadius: 16,
      border: '1px solid #CCCCCC',
    },
    '&.not-edit-mode-cascader': {
      backgroundImage: 'none',
    },
  },
  input: {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
  },
  select: {
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    textOverflow: 'ellipsis',
  },
})
