export const CardReaderCommand = {
  CheckSerialNumber: 'checkSerialNumber',
  CardIssueRequest: 'cardIssueRequest',
  CardIssueWaiting: 'cardIssueWaiting',
  CardIssueWriting: 'cardIssueWriting',
  CardIssueRemoval: 'cardIssueRemoval',
  CardIssueComplete: 'cardIssueComplete',
} as const
export type CardReaderCommand = (typeof CardReaderCommand)[keyof typeof CardReaderCommand]

export type SelfCheckinCardReaderType = {
  serial_number: string
  command: CardReaderCommand
  message: string | undefined
  process_id: string
  is_success: boolean
}

export const serialNumberStorageKey = 'selfCheckin.cardReader.serialNumber'
