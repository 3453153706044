import React, { useEffect, useState } from 'react'
import { fetchCleaningManagerSetting, deleteCleaningManagerSetting } from '@/apis/aipass'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { EditFooter } from '@/components/organisms/edit-footer'
import { useTranslation } from 'react-i18next'
import {
  dashboardPageContainerStyle,
  tableHeaderThStyle,
  tableBodyTdStyle,
  dashboardPageHeaderStyle,
  HEADER_HEIGHT,
} from '@/constants/layout'
import { CheckListType } from '@/constants/cleaning-manager'
import { useWindowSize } from 'react-use'
import { Button } from '@/components/atoms/button'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { CleaningTab } from '@/components/molecules/settings/cleaning-manager/cleaning-tab'
import { EditCheckListModal } from '@/components/organisms/cleaning-manager/edit-check-list-modal'

export const CheckList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [storedCheckList, setStoredCheckList] = useState<CheckListType[]>([])
  const [deleteCheckListId, setDeleteCheckListId] = useState<number[]>([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [editingCheckList, setEditingCheckList] = useState<CheckListType>()
  const [nextOrder, setNextOrder] = useState<number>(1)
  const [showFooter, setShowFooter] = useState(false)

  const windows = useWindowSize()
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()

  const fetchCheckList = async () => {
    setLoading(true)
    const checkList = (await fetchCleaningManagerSetting()) || []
    setStoredCheckList(checkList)
    setNextOrder(checkList.length ? checkList[checkList.length - 1].displayOrder + 1 : 1)
    setLoading(false)
  }

  const removeList = (editItem: CheckListType) => {
    setDeleteCheckListId([...deleteCheckListId, editItem.id])
    setStoredCheckList(storedCheckList.filter(i => i.id !== editItem.id))
    setShowFooter(true)
  }

  const doDelete = async () => {
    try {
      await deleteCleaningManagerSetting(deleteCheckListId)
      initialView()
    } catch (error) {
      errorHandler(error)
    }
  }

  const doEdit = (editItem: CheckListType) => {
    setEditingCheckList(editItem)
    setShowEditModal(true)
  }

  const initialView = () => {
    setShowFooter(false)
    setShowEditModal(false)
    setEditingCheckList(undefined)
    fetchCheckList()
    setDeleteCheckListId([])
  }

  useEffect(initialView, [])

  const footerContent = showFooter ? <EditFooter onSave={doDelete} onCancel={initialView} /> : undefined

  return (
    <SettingsLayout loading={loading} footerContent={footerContent}>
      <TabContainer tabComponent={<CleaningTab currentTab="cleaningManager.CheckList" />}>
        <div css={dashboardPageHeaderStyle}>
          <Button
            buttonType={1}
            width={107}
            height={32}
            fontSize={12}
            marginRight={16}
            icon={require('@/static/images/mobile_request/add.svg')}
            onClick={() => setShowEditModal(true)}
          >
            {t('Add new')}
          </Button>
        </div>
        <div css={{ overflow: 'auto', height: windows.height - HEADER_HEIGHT - 24 * 2 - 123 }}>
          {!!storedCheckList?.length && (
            <table css={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th css={[tableHeaderThStyle, { paddingLeft: 28 }]} style={{ width: 148 }}>
                    {t('Display order')}
                  </th>
                  <th css={tableHeaderThStyle}>{t('Item name')}</th>
                  <th css={[tableHeaderThStyle, { paddingRight: 28 }]}></th>
                </tr>
              </thead>
              <tbody>
                {storedCheckList?.map(checkItem => (
                  <tr onClick={() => doEdit(checkItem)} key={checkItem.id} css={{ cursor: 'pointer' }}>
                    <td css={[tableBodyTdStyle, { paddingLeft: 28, fontWeight: 'bold' }]}>{checkItem.displayOrder}</td>
                    <td css={[tableBodyTdStyle, { fontWeight: 'bold' }]}>{checkItem.name}</td>
                    <td css={[tableBodyTdStyle, { paddingRight: 28, width: 32 }]}>
                      <img
                        onClick={e => {
                          e.stopPropagation()
                          removeList(checkItem)
                        }}
                        src={require('@/static/images/delete_orange.svg')}
                        css={{ width: 32, height: 32, cursor: 'pointer' }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {!storedCheckList?.length && !loading && (
            <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <h1>{t('No data available')}</h1>
            </div>
          )}
        </div>
        {showEditModal && (
          <EditCheckListModal
            nextOrder={nextOrder}
            editing={editingCheckList}
            onSave={initialView}
            onClose={initialView}
            setLoading={setLoading}
          />
        )}
      </TabContainer>
    </SettingsLayout>
  )
}
