import React, { ChangeEvent, useState } from 'react'
import moment from 'moment'
import { Cascader, Select } from 'antd'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'

//components
import { DatePicker } from '@/components/organisms/customer/sales-manager/date-picker'

// constants
import { deleteStyle } from '@/constants/layout'

// models
import { EditSalesType, PaymentSettingType, SubjectSettingType } from '@/models/sales'
import { AccountsReceivableSettingType } from '@/models/accounts-receivable'
import { ReservationType } from '@/models/reservation'
import { getRoomCascadeOptionsByReservations, findDefaultRoomCascadeOption, roomCascadeOptionsLabelRender } from '@/utils/reservations'
import { initializeSelectBoxStyle } from '@/constants/form'

type UsageDetailSubjectProps = {
  editUsageDetail: EditSalesType
  salesSubjects: SubjectSettingType[]
  salesPayments: PaymentSettingType[]
  changeDate: (date, id) => void
  changeState: (e, id) => void
  deleteUsageDetailSubject: (id) => void
  advanceCompany: any
  index: number
  confirmedDay: string
  accountsReceivableSettings: AccountsReceivableSettingType[]
  reservations?: ReservationType[]
  reservation?: ReservationType
  salesSubjectsWithoutSameSubjectCode: SubjectSettingType[]
  currentTabReservationId: string
}

export const UsageDetailSubject: React.FC<UsageDetailSubjectProps> = ({
  editUsageDetail,
  salesSubjects,
  salesPayments,
  changeDate,
  changeState,
  advanceCompany,
  deleteUsageDetailSubject,
  index,
  confirmedDay,
  accountsReceivableSettings,
  reservations,
  reservation,
  salesSubjectsWithoutSameSubjectCode,
  currentTabReservationId,
}) => {
  const { t } = useTranslation()
  const [filteredSubjectLists, setFilteredSubjectLists] = useState<SubjectSettingType[]>([])

  const onChangeSelectSubject = (value?: string) => {
    if (value) {
      const filteredSubjectIds = salesSubjects.filter(salesSubject => value === salesSubject.id)
      const subjectLists = salesSubjects.filter(salesSubject => salesSubject?.subjectCode === filteredSubjectIds[0]?.subjectCode)
      setFilteredSubjectLists(subjectLists)
    } else {
      const filteredSubjectIds = salesSubjects.filter(salesSubject => editUsageDetail.salesSubjectId === salesSubject.id)
      const subjectLists = salesSubjects.filter(salesSubject => salesSubject?.subjectCode === filteredSubjectIds[0]?.subjectCode)
      setFilteredSubjectLists(subjectLists)
    }
  }

  const changeSubject = salesSubjectId => {
    const changedSubjectMaster = salesSubjects?.find(salesSubject => salesSubject.id === salesSubjectId)
    if (!changedSubjectMaster) {
      return
    }
    changeState(
      {
        target: {
          name: 'salesSubjectPrice',
          value: changedSubjectMaster.price.price || '0',
        },
      } as unknown as ChangeEvent,
      index,
    )
  }

  const changeSubSubject = salesSubSubjectId => {
    const matchedSubSubject = salesSubjects?.find(salesSubject => salesSubject?.subSubjectId === salesSubSubjectId)
    if (matchedSubSubject) {
      changeState(
        {
          target: {
            name: 'salesSubjectPrice',
            value: matchedSubSubject?.subSubjectPrice || '0',
          },
        } as unknown as ChangeEvent,
        index,
      )
    } else {
      changeSubject(editUsageDetail?.salesSubjectId)
    }
  }

  const newAdvanceCompany = advanceCompany?.paymentCompanies?.map(item => ({
    value: item?.id,
    label: item?.name,
    disabled: !item.currentBalance,
  }))
  const salesPaymentOptions =
    salesPayments?.map(item => {
      if (Object.keys(advanceCompany?.paymentMethod || {}).length && item?.id === advanceCompany?.paymentMethod?.id) {
        return { value: item?.id, label: item?.name, children: newAdvanceCompany }
      } else {
        return { value: item?.id, label: item?.name }
      }
    }) || []

  const roomsOptions = getRoomCascadeOptionsByReservations({ reservation, reservations })

  const displayRender = label => label[label.length - 1]

  const salesSubjectIds: string[] = []
  salesSubjectsWithoutSameSubjectCode.map(salesSubject => salesSubject.subIds?.map(id => salesSubjectIds.push(id)))

  // FIXME
  // eslint-disable-next-line no-prototype-builtins
  if (!editUsageDetail.hasOwnProperty('salesDate')) return null

  return (
    <tr>
      <td>
        <DatePicker
          date={editUsageDetail.salesDate ? moment(editUsageDetail.salesDate) : null}
          setDate={date => changeDate(date, index)}
          confirmedDay={confirmedDay}
        />
      </td>
      <td>
        <select
          name="salesSubjectId"
          css={largeSelectBoxStyle}
          onChange={e => {
            changeState(e, index)
            onChangeSelectSubject(e.target.value)
            changeSubject(e.target.value)
          }}
        >
          <option selected={editUsageDetail.salesSubjectId === ''} value="">
            {t('Please select')}
          </option>
          {salesSubjectsWithoutSameSubjectCode.map(salesSubject => (
            <option key={salesSubject.id} selected={editUsageDetail.salesSubjectId === salesSubject.id} value={salesSubject.id}>
              {salesSubject.subjectName}
            </option>
          ))}
        </select>
      </td>
      <td>
        <select
          name="salesSubSubjectId"
          css={largeSelectBoxStyle}
          onChange={e => {
            changeState(e, index)
            changeSubSubject(e.target.value)
          }}
        >
          <option value="null">-</option>
          {filteredSubjectLists.map(
            subSubject =>
              subSubject?.subSubjectName !== '-' && (
                <option key={subSubject.subSubjectId} value={subSubject.subSubjectId}>
                  {subSubject.subSubjectName}
                </option>
              ),
          )}
        </select>
      </td>
      <td>
        <Cascader
          options={roomsOptions}
          expandTrigger="hover"
          className="cascader-customize"
          defaultValue={findDefaultRoomCascadeOption({
            reservations: reservations ? reservations : [reservation],
            reservationId: currentTabReservationId,
            usageDetail: editUsageDetail,
            roomsOptions,
          })}
          displayRender={roomCascadeOptionsLabelRender}
          popupClassName="popup-cascader-customize"
          onChange={async value => {
            changeState(
              {
                target: { value: value[0], name: 'reservationId' },
              },
              index,
            )
            changeState(
              {
                target: { value: value[1], name: 'guestRoomAssignId' },
              },
              index,
            )
          }}
          allowClear={false}
          suffixIcon={<span></span>}
          changeOnSelect
        />
      </td>
      <td>
        <input
          name="salesSubjectPrice"
          type="number"
          value={editUsageDetail.salesSubjectPrice}
          css={unitPriceStyle}
          onChange={e => changeState(e, index)}
          placeholder="0000"
          className="unitPriceStyle"
        />
      </td>
      <td>
        <input
          name="quantity"
          type="number"
          min={0}
          css={smallSelectBoxStyle}
          value={editUsageDetail.quantity ? Number(editUsageDetail.quantity).toString() : '0'}
          onChange={e => changeState(e, index)}
          placeholder="0"
          className="quantityStyle"
        />
      </td>
      <td>
        <input type="text" css={hiddenInputStyle} />
      </td>
      <td>
        <Cascader
          options={salesPaymentOptions}
          expandTrigger="hover"
          className="cascader-customize"
          defaultValue={[salesPaymentOptions[0]?.value]}
          displayRender={displayRender}
          popupClassName="popup-cascader-customize"
          onChange={async value => {
            const eventChangeSalesPaymentId = {
              target: { value: value[0], name: 'salesPaymentId' },
            }

            const eventChangeCompanyId = {
              target: { value: value[1] ? value[1] : '', name: 'companyId' },
            }
            await changeState(eventChangeSalesPaymentId, index)
            changeState(eventChangeCompanyId, index)
          }}
          allowClear={false}
          suffixIcon={<span></span>}
        />
      </td>
      <td>
        <select
          name="isPaid"
          css={middleSelectBoxStyle}
          className={!editUsageDetail.isPaid ? 'unpaidStyle' : 'paidStyle'}
          defaultValue="0"
          onChange={e => changeState(e, index)}
        >
          <option selected={!editUsageDetail.isPaid} value="0">
            {t('Unpaid')}
          </option>
          <option selected={!!editUsageDetail.isPaid} value="1">
            {t('Paid')}
          </option>
        </select>
      </td>
      <td>
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={true}
          suffixIcon={<img src={require('@/static/images/arrow_down_yellow.svg')} />}
          onChange={value => {
            changeState(
              {
                target: { value, name: 'salesAccountsReceivableMasterId' },
              },
              index,
            )
          }}
          value={editUsageDetail.salesAccountsReceivableMasterId}
        >
          <Select.Option value="">-</Select.Option>
          {accountsReceivableSettings.map((accountsReceivable, index) => (
            <Select.Option key={index} value={accountsReceivable.id}>
              {accountsReceivable.name}
            </Select.Option>
          ))}
        </Select>
      </td>
      <td>
        <div css={deleteStyle} onClick={() => deleteUsageDetailSubject(index)}>
          <img src={require('@/static/images/delete_yellow.svg')} />
        </div>
      </td>
    </tr>
  )
}

const selectBoxStyle = css(initializeSelectBoxStyle, {
  height: 32,
  border: '1px solid #CCCCCC',
  borderRadius: 16,
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  display: 'block',
  paddingLeft: 14,
  paddingRight: 14,
  marginRight: 10,
})

const smallSelectBoxStyle = css(selectBoxStyle, {
  width: '100%',
  backgroundPosition: '80% center',
  backgroundImage: 'none',
})

const middleInputStyle = css(selectBoxStyle, {
  backgroundImage: 'none',
  '::placeholder': {
    color: '#ccc',
  },
})

export const unitPriceStyle = css(middleInputStyle, {
  marginLeft: 12,
})

const middleSelectBoxStyle = css(selectBoxStyle, {
  backgroundPosition: 'calc(100% - 8px) center',
})

const largeSelectBoxStyle = css(selectBoxStyle, {
  backgroundPosition: 'calc(100% - 8px) center',
})

export const xlargeSelectBoxStyle = css(selectBoxStyle, {
  margin: '0 2% 0 1.5%',
  backgroundPosition: 'calc(100% - 8px) center',
})

const hiddenInputStyle = css(middleInputStyle, {
  visibility: 'hidden',
})
