import { ReservationStatusType } from '@/models/reservation-reservation-status'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { t } from 'i18next'

export type EntireSearchContentType = {
  name: string
  nameKana: string
  searchReservationId: string
  roomNumber: string
  beginDate: string | undefined
  endDate: string | undefined
  selectGuestRoomAssign: any
  approvedStatuses: ApprovedStatusType[]
  reservationStatuses: ReservationStatusType[]
  channelCodeName: string | undefined
  telephone: string
  note: string
  globalSearchDateType: EntireSearchDateType
}

export const EntireSearchDateType = {
  Stay: 1,
  Checkin: 2,
  Checkout: 3,
  Reservation: 4,
} as const
export type EntireSearchDateType = (typeof EntireSearchDateType)[keyof typeof EntireSearchDateType]

export const entireSearchDateTypeValues: Record<EntireSearchDateType, string> = {
  [EntireSearchDateType.Stay]: t('Date of stay'),
  [EntireSearchDateType.Checkin]: t('Check-in date'),
  [EntireSearchDateType.Checkout]: t('Check-out date'),
  [EntireSearchDateType.Reservation]: t('Reservation date'),
}

export const EntireSearchContentInitial: EntireSearchContentType = {
  name: '',
  nameKana: '',
  searchReservationId: '',
  roomNumber: '',
  beginDate: '',
  endDate: '',
  selectGuestRoomAssign: 'all',
  approvedStatuses: [ApprovedStatusType.Reservation, ApprovedStatusType.Checkin, ApprovedStatusType.Stay, ApprovedStatusType.Checkout],
  reservationStatuses: [],
  telephone: '',
  note: '',
  channelCodeName: '',
  globalSearchDateType: EntireSearchDateType.Stay,
}
