import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import parse from 'html-react-parser'

// components
import { SelfCheckinHeader } from '@/components/molecules'
import { SelfCheckinFooter } from '@/components/molecules/self-checkin/footer'

// context
import { CustomCheckin } from '@/components/organisms/self-checkin/custom-checkin'

//apis
import { AccommodationForm } from '../accommodation-info/form'
import { AccommodationInput } from '@/models/self-checkin/accommodation-info'

// utils
import { changeRequiredIdentify } from '@/utils/required-identify'
import { addValidateGuidesRequired, changeValidateGuidesRequired } from '@/utils/guide'

export const CheckedinEdit: React.FC<{}> = () => {
  const { t, i18n } = useTranslation()
  const [guides, setGuides] = useState<any>([])
  const [options, setOptions] = useState<any>()
  const [selectedInputValue, setSelectedInputValue] = useState<any>([])
  const { search, state } = useLocation<{
    identificationFrontImage: string
    identificationBackImage: string
    representativePassportImage: string
    guides: any
    checkinMemo: any
    from: string
    hotelId: string | null
    imageType: 'front' | 'back' | 'representativePassport' | 'companionPassport'
    edit?: string
    accommodationInfo: any
    requiredIdentify: boolean
    requiredJapaneseIdentify: boolean
    requiredForeignIdentify: boolean
    requiredAccompanyNameInput: boolean
    requiredFuriganaInput: boolean
    requiredBirthDateInput: boolean
    requiredGenderInput: boolean
    requiredTelephoneInput: boolean
    requiredEmailInput: boolean
    requiredAddressInput: boolean
    checkBox: string
  }>()

  const [accommodationInfo, setAccommodationInfo] = useState<AccommodationInput>(state.accommodationInfo)
  const [isFormValidRequiredFields, setIsFormValidRequiredFields] = useState(true)
  const [validGuides, setValidGuides] = useState<any>([])
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [isPostCodeValid, setIsPostCodeValid] = useState(true)
  const [isLastNameValid, setIsLastNameValid] = useState(true)
  const [isFirstNameValid, setIsFirstNameValid] = useState(true)

  const lang = i18n.language
  const history = useHistory()

  const [listValidateProps, setListValidateProps] = useState(['firstName', 'lastName'])

  const checkValidateFormRequired = () => {
    for (const property in accommodationInfo) {
      if (listValidateProps.includes(property)) {
        if (!accommodationInfo[property] || (typeof accommodationInfo[property] === 'string' && !accommodationInfo[property].trim())) {
          return false
        }
      }
    }
    if (lang === 'ja' && accommodationInfo.nationality === 'japanese' && state.requiredFuriganaInput) {
      if (!accommodationInfo.firstNameFurigana.trim() || !accommodationInfo.lastNameFurigana.trim()) {
        return false
      }
    }
    const validAccompany = accommodationInfo?.accompany?.filter(v => !v.lastName.trim() || !v.firstName.trim()) ?? []
    if (!accommodationInfo.hasContactForAllGuest && validAccompany?.length > 0) {
      return false
    }

    return true
  }

  const checkEmailValid = value => {
    const halfByteRegex = /^[!-~]+$/
    const mailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return halfByteRegex.test(value) && mailRegex.test(value)
  }

  const checkNumberOnly = value => {
    return /^[0-9]*$/.test(value)
  }

  const checkOnlyAnphabet = value => {
    return /^[a-zA-Z]*$/.test(value)
  }

  const isDisabled = (): boolean => {
    const isValid = checkValidateFormRequired()
    if (validGuides.length > 0 || !isValid) {
      return true
    }
    return false
  }

  const _checkBlank = () => {
    replaceInputValue()
    const nav = t('Information from the facility')
    const notes = guides.map(guide => {
      if (guide.inputType !== 'none') {
        return `${guide.jpTitle}:${guide.selectedAnswer}`
      }
    })
    const filteredNotes = notes.filter(v => v)
    let checkinMemo: string = ''
    if (filteredNotes.length !== 0) {
      checkinMemo = [nav, ...filteredNotes].join('\n')
    } else {
      checkinMemo = ''
    }
    onClickConfirm(checkinMemo)
  }
  // Replace form content with input content, not filled in with -
  const replaceInputValue = () => {
    const _guides = [...guides]
    _guides.map(guide => {
      selectedInputValue.map(v => {
        if (v.customCheckinId === guide.customCheckinId) {
          // When you reselect Please select
          if (v.value === '') {
            guide.selectedAnswer = '-'
          } else {
            guide.selectedAnswer = v.value
          }
        }
      })
      // when not choosing
      if (!guide.selectedAnswer) {
        guide.selectedAnswer = '-'
      }
    })
    setGuides(_guides)
  }

  const onClickConfirm = checkinMemo => {
    setIsEmailValid(true)
    setIsPhoneValid(true)
    setIsPostCodeValid(true)
    setIsFirstNameValid(true)
    setIsLastNameValid(true)
    const isValid = checkValidateFormRequired()
    setIsFormValidRequiredFields(isValid)
    if (isValid) {
      const checkEmail = accommodationInfo.mail ? checkEmailValid(accommodationInfo.mail) : true
      const checkPhone = checkNumberOnly(accommodationInfo.phoneNumber)

      const checkLastName = !(accommodationInfo.nationality == 'japanese' && lang === 'ja')
        ? checkOnlyAnphabet(accommodationInfo.lastName)
        : true
      const checkFirstName = !(accommodationInfo.nationality === 'japanese' && lang === 'ja')
        ? checkOnlyAnphabet(accommodationInfo.firstName)
        : true

      setIsEmailValid(checkEmail)
      setIsPhoneValid(checkPhone)
      setIsFirstNameValid(checkFirstName)
      setIsLastNameValid(checkLastName)

      const isValidForm = checkEmail && checkPhone && checkFirstName && checkLastName
      if (!isValidForm) {
        return
      }

      if (accommodationInfo.hasContactForAllGuest) {
        accommodationInfo.accompany = []
      }

      let nextPageUrl: string = '/self-checkin/qr-checkin-camera/checkedin-confirm'
      if (accommodationInfo?.nationality) {
        const responseRequiredIdentify = changeRequiredIdentify(
          accommodationInfo?.nationality,
          state.requiredJapaneseIdentify,
          state.requiredForeignIdentify,
        )
        state.requiredIdentify = responseRequiredIdentify
        const isSelectJaLanguage = i18n.language === 'ja'
        if (
          (isSelectJaLanguage && state?.requiredIdentify && (!state.identificationFrontImage || !state.identificationBackImage)) ||
          (!isSelectJaLanguage && state?.requiredIdentify && !state.representativePassportImage)
        ) {
          nextPageUrl = `/self-checkin/qr-checkin-camera/${isSelectJaLanguage ? 'upload-license' : 'upload-passport'}`
        }
      }

      history.push({
        pathname: nextPageUrl,
        search,
        state: {
          ...state,
          reservationInfo: accommodationInfo,
          checkinMemo,
        },
      })
    }
  }

  const onChangeState = (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>, customCheckinId) => {
    if (!e.target.value) {
      const addValidateGuides = addValidateGuidesRequired(customCheckinId, guides)
      if (addValidateGuides.length > 0) {
        const errorMessage = document.getElementById(addValidateGuides[0]['customCheckinId'] + '-error')
        errorMessage ? (errorMessage.style.visibility = 'visible') : ''
      }
      setValidGuides(addValidateGuides.concat(validGuides))
    } else {
      const changeValidateGuides = changeValidateGuidesRequired(customCheckinId, validGuides)
      const errorMessage = document.getElementById(customCheckinId + '-error')
      errorMessage && errorMessage.style.visibility === 'visible' ? (errorMessage.style.visibility = 'hidden') : ''
      setValidGuides(changeValidateGuides)
    }
    const value = e.target.value
    const name = e.target.name
    setSelectedInputValue([...selectedInputValue, { customCheckinId: customCheckinId, [name]: value }])
  }

  const getTitle = guide => {
    if (lang === 'ja') {
      return parse(guide.jpTitle)
    }
    if (lang === 'en') {
      return parse(guide.enTitle)
    }
    if (lang === 'ko') {
      return parse(guide.koTitle)
    }
    if (lang === 'zh') {
      return parse(guide.zhTitle)
    }
  }

  const getContent = guide => {
    if (lang === 'ja') {
      return parse(guide.jpContent)
    }
    if (lang === 'en') {
      return parse(guide.enContent)
    }
    if (lang === 'ko') {
      return parse(guide.koContent)
    }
    if (lang === 'zh') {
      return parse(guide.zhContent)
    }
  }

  const getOption = guide => {
    if (lang === 'ja') {
      return guide.jpInputValue
    }
    if (lang === 'en') {
      return guide.enInputValue
    }
    if (lang === 'ko') {
      return guide.koInputValue
    }
    if (lang === 'zh') {
      return guide.zhInputValue
    }
  }

  useEffect(() => {
    if (state?.hotelId) {
      setAccommodationInfo(state.accommodationInfo)
      if (state.guides) {
        setGuides(state.guides)
      }
      const addValidateProps: string[] = []
      if (state.requiredAccompanyNameInput) {
        addValidateProps.push('accompany')
      }
      if (state.requiredBirthDateInput) {
        addValidateProps.push('dateOfBirth')
      }
      if (state.requiredGenderInput) {
        addValidateProps.push('gender')
      }
      if (state.requiredTelephoneInput) {
        addValidateProps.push('phoneNumber')
      }
      if (state.requiredEmailInput) {
        addValidateProps.push('mail')
      }
      if (state.requiredAddressInput) {
        addValidateProps.push('address')
      }
      if (addValidateProps.length) {
        setListValidateProps([...listValidateProps, ...addValidateProps])
      }
    }
  }, [state?.hotelId])

  useEffect(() => {
    if (guides) {
      setOptions({})
      guides.map((guide, index) => {
        const translatedOption = getOption(guide)
        setOptions(prevOptions => ({
          ...prevOptions,
          [index]: translatedOption,
        }))
      })
    }
  }, [guides, lang])

  const goBackConfirm = () => {
    delete state.edit
    history.push({
      pathname: '/self-checkin/qr-checkin-camera/checkedin-confirm',
      search,
      state,
    })
  }

  const onChangeAccommodationInfo = (name, value) => {
    setAccommodationInfo(accomInfo => ({ ...accomInfo, [name]: value }))
  }

  const onChangeRequiredIdentify = responseRequiredIdentify => {
    state.requiredIdentify = responseRequiredIdentify
  }

  const uploadImage = (imageType: 'front' | 'back' | 'representativePassport' | 'companionPassport'): void => {
    switch (imageType) {
      case 'front':
      case 'back':
        history.push({
          pathname: '/self-checkin/identity-verify/camera',
          search,
          state: {
            ...state,
            imageType,
            edit: 'edit',
            accommodationInfo,
            from: 'upload-license',
            backPage: window.location.pathname,
          },
        })

        break

      default:
        history.push({
          pathname: '/self-checkin/identity-verify/camera',
          search,
          state: { ...state, imageType, edit: 'edit', accommodationInfo, from: 'upload-passport', backPage: window.location.pathname },
        })
        break
    }
  }
  return (
    <div css={containerStyle}>
      <SelfCheckinHeader goToPreviousPage={goBackConfirm} title={t('Please confirm the contents')} />
      <div css={mainStyle}>
        <div css={guestFormWrapperStyle}>
          <AccommodationForm
            accommodationInfo={accommodationInfo}
            onChange={onChangeAccommodationInfo}
            requiredAccompanyNameInput={state.requiredAccompanyNameInput}
            requiredFuriganaInput={state.requiredFuriganaInput}
            requiredBirthDateInput={state.requiredBirthDateInput}
            requiredGenderInput={state.requiredGenderInput}
            requiredTelephoneInput={state.requiredTelephoneInput}
            requiredEmailInput={state.requiredEmailInput}
            requiredAddressInput={state.requiredAddressInput}
            imageInfo={{
              from: state.from,
              identificationBackImage: state.identificationBackImage,
              identificationFrontImage: state.identificationFrontImage,
              representativePassportImage: state.representativePassportImage,
              uploadImage,
            }}
            onChangeRequiredIdentify={onChangeRequiredIdentify}
            requiredIdentifyInfo={{
              requiredJapaneseIdentify: state.requiredJapaneseIdentify,
              requiredForeignIdentify: state.requiredForeignIdentify,
            }}
            isFormValidRequiredFields={isFormValidRequiredFields}
            isEmailValid={isEmailValid}
            isPhoneValid={isPhoneValid}
            isPostCodeValid={isPostCodeValid}
            isFirstNameValid={isFirstNameValid}
            isLastNameValid={isLastNameValid}
          />
        </div>
        {guides && !!guides?.length && (
          <div css={guidesInnerStyle}>
            <div css={descriptionStyle}>
              <p>{t('Guidance')}</p>
            </div>
            {guides.map(
              (guide, index) =>
                guide.inputType !== 'none' && (
                  <CustomCheckin
                    key={index}
                    index={index}
                    guide={guide}
                    options={options}
                    selectedInputValue={selectedInputValue}
                    onChangeState={onChangeState}
                    getTitle={getTitle}
                    getContent={getContent}
                    isEdit={true}
                  />
                ),
            )}
          </div>
        )}
        <SelfCheckinFooter isNext={'save'} isDisabled={isDisabled} isDisabledButton={true} goToNextPage={_checkBlank} />
      </div>
    </div>
  )
}

const containerStyle = css({
  width: '100%',
  position: 'relative',
  backgroundColor: '#F2F2F2',
  '*': {
    fontFamily: 'Noto Sans JP',
  },
})

const mainStyle = css({
  padding: '14px 0 32px ',
})

const guidesInnerStyle = css({
  maxWidth: 874,
  backgroundColor: '#fff',
  margin: '0 auto 80px',
  borderRadius: 5,
})

const descriptionStyle = css({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  padding: '40px 0 10px 0',
  p: {
    fontSize: 21,
    lineHeight: '27px',
    letterSpacing: '1.8px',
    textAlign: 'center',
    fontWeight: 'bold',
    '@media(max-width: 1080px)': {
      fontSize: 24,
    },
  },
})

const guestFormWrapperStyle = css({
  maxWidth: 874,
  margin: '86px auto 76px',
})
