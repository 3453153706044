import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@/components/atoms/modal'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms'
import { css } from '@emotion/core'
import { RHFInputField } from '@/components/molecules/RHFInput-field'
import { CreateSpaceReservation, fetchReservation, getSpaceByReservation } from '@/apis/aipass'
import { useForm } from 'react-hook-form'
import { ApprovedStatusType } from '@/models/reservation-approved-status'
import { AlignmentReservationInput, AssignSpaceInput } from '@/models/space-manager'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import { errorHandler } from '@/libs/errors'
import { AccommodationPaging } from '@/components/molecules/accommodation-paging'
import { LoadingFull } from '@/components/molecules/loading-full'
dayjs.extend(minMax)

type SearchReservationParams = {
  name?: string
  nameKana?: string
  telephone?: string
}

interface SpaceReservationModalProps {
  onClose: () => void
  assignSpaces: AssignSpaceInput[]
}
export const SpaceSearchReservationModal: React.FC<SpaceReservationModalProps> = ({ onClose, assignSpaces }) => {
  const [reservations, setReservations] = useState<any>([])
  const [targetReservation, setTargetReservation] = useState<any>()
  const [page, setPage] = useState(1)
  const [listTotal, setListTotal] = useState(0)
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const useFormReturn = useForm<SearchReservationParams>({})
  const { control, handleSubmit, getValues } = useFormReturn

  const usageFromDates = assignSpaces.map(assignSpace => dayjs(assignSpace.usageFrom))
  const minUsageFrom = dayjs.min(usageFromDates)
  const maxUsageFrom = dayjs.max(usageFromDates)

  const onSearch = async () => {
    const values = getValues()
    const result = await fetchReservation({
      page,
      limit: 50,
      approvedStatuses: [ApprovedStatusType.Reservation, ApprovedStatusType.Checkin, ApprovedStatusType.Stay],
      reservationStatuses: [],
      note: '',
      searchReservationId: '',
      roomNumber: '',
      selectGuestRoomAssign: null,
      beginDate: minUsageFrom.format('YYYY-MM-DD'),
      endDate: maxUsageFrom.format('YYYY-MM-DD'),
      name: values.name || '',
      nameKana: values.nameKana || '',
      globalSearchDateType: 1,
      telephone: values.telephone || '',
      channelCodeName: '',
    })

    setListTotal(result.total)
    setReservations(result.reservations)
  }

  const onLodgingCreate = async () => {
    // 同一スペース同一時間予約の場合はその利用者名を入れる
    const fetchedReservationSpaces = await getSpaceByReservation(targetReservation.reservationId).then(res => res || [])
    const fetchedReservationSpaceDetails = fetchedReservationSpaces.flatMap(fetchedReservationSpace => fetchedReservationSpace.details)

    const alignmentData: AlignmentReservationInput = {
      reservationId: targetReservation.reservationId,
      assignSpaces: assignSpaces.map(assignSpace => {
        const sameSpace = fetchedReservationSpaceDetails.find(
          fetchedReservationSpaceDetail =>
            fetchedReservationSpaceDetail.reservedSpace.title.ja === assignSpace.spaceTypeName &&
            fetchedReservationSpaceDetail.usedAtFrom === assignSpace.usageFrom &&
            fetchedReservationSpaceDetail.usedAtTo === assignSpace.usageTo,
        )

        return {
          ...assignSpace,
          reservationUserName: sameSpace?.reservationUserName || targetReservation.guestName || '',
        }
      }),
    }

    try {
      setLoading(true)
      await CreateSpaceReservation(alignmentData)
    } catch (error) {
      errorHandler(error)
    } finally {
      onClose()
    }
  }

  return (
    <>
      <Modal customCss={modalStyle}>
        <ModalHeader>{t('Search your reservation')}</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSearch)}>
            <div className="search-block">
              <RHFInputField control={control} marginBottom={0} label={t('Full name')} placeholder={t('John Doe')} name="name" />
              <RHFInputField control={control} marginBottom={0} label={t('Furigana')} placeholder={'ヤマダ タロウ'} name="nameKana" />
              <RHFInputField
                control={control}
                marginBottom={0}
                label={t('Phone number')}
                width="95%"
                placeholder="08012345678"
                name="telephone"
              />
              <div>
                <Button type="submit" buttonType={1} width={58} height={32} fontSize={12}>
                  {t('Search')}
                </Button>
              </div>
            </div>
          </form>
          <div className="reservation-container">
            <div className="container-label">
              <p className="col-1">{t('Reservation person')}</p>
              <p className="col-2">{t('Confirmed guest')}</p>
              <p className="col-3">{t('Date of stay')}</p>
            </div>
            <ul className="reservation-list">
              {reservations &&
                reservations.map(reservation => {
                  const isSelected = targetReservation?.reservationId === reservation.reservationId
                  return (
                    <li key={reservation.id} onClick={() => setTargetReservation(reservation)} className={isSelected ? 'selected' : ''}>
                      <img className="check-box" alt="checkbox" src={require(`@/static/images/radio${isSelected ? '_on' : '_off'}.svg`)} />
                      <div className="col-1">
                        <p>
                          <span className="ruby">{reservation.nameKana || '-'}</span>
                          <span>{reservation.name || '-'}</span>
                        </p>
                      </div>
                      <div className="col-2">
                        <span>{reservation.guestNameKana || '-'}</span>
                        <span>{reservation.guestName || '-'}</span>
                      </div>
                      <div className="col-3">{dayjs(reservation.checkinDate).format(t('MM-DD(ddd)'))}</div>
                    </li>
                  )
                })}
            </ul>
          </div>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AccommodationPaging page={page} setPage={setPage} total={listTotal} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onClose()}>
            {t('Cancel')}
          </Button>
          <Button
            buttonType={targetReservation ? 1 : 5}
            height="38px"
            width="110px"
            onClick={() => onLodgingCreate()}
            isDisabled={!targetReservation}
          >
            {t('Alignment')}
          </Button>
        </ModalFooter>
      </Modal>

      <LoadingFull isLoading={loading} />
    </>
  )
}

const modalStyle = css({
  top: '10%',
  height: 'auto',
  minHeight: 0,
  maxHeight: 'calc(100vh - 10%)',
  '.reservation-container': {
    height: 256,
    border: '1px solid #ccc',
    borderRadius: 5,
    background: '#fff',
    overflow: 'auto',
    '.col-1': {
      width: '30%',
    },
    '.col-2': {
      width: '50%',
    },
    '.col-3': {
      width: '20%',
    },
    '.container-label': {
      display: 'flex',
      padding: '10px 48px',
      fontSize: 12,
      color: '#676767',
      fontWeight: 'bold',
      borderBottom: '1px solid #F2F2F2',
    },
    '.reservation-list': {
      fontSize: 14,
      li: {
        display: 'flex',
        gap: 24,
        cursor: 'pointer',
        minHeight: 64,
        position: 'relative',
        alignItems: 'center',
        padding: '12px 27px 12px 48px',
        borderBottom: '1px solid #f2f2f2',
        ':hover': {
          boxShadow: '0px 3px 6px #0000000f',
        },
        '&.selected': {
          background: 'rgb(255, 252, 221)',
        },
        '.check-box': {
          width: 18,
          position: 'absolute',
          top: 0,
          bottom: 0,
          margin: 'auto',
          left: 12,
        },
      },
      span: {
        display: 'block',
      },
      '.ruby': {
        fontSize: 12,
      },
    },
  },
  '.search-block': {
    display: 'flex',
    alignItems: 'flex-end',
    gap: 8,
    marginBottom: 16,
  },
  '.label-block': {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFF',
    padding: '10px 48px',
    color: '#676767',
    fontWeight: 'bold',
    fontSize: 12,
  },
})
