import React, { useState, useEffect, useMemo, Dispatch } from 'react'
import { css } from '@emotion/core'
import { DatePicker } from '@/components/organisms/reservation/_detail-edit-info/date-picker'
import { useTranslation } from 'react-i18next'
import { times } from '@/libs/times'
import { fetchReservationChannelCode, fetchReservationMarketSegment, updateAdminReservationMarketSegment } from '@/apis/aipass'
import dayjs from 'dayjs'
import { initializeSelectBoxStyle } from '@/constants/form'

export type ReservationFormType = {
  plan: any
  checkinDate: any
  checkoutDate: any
  checkinTime: any
  checkoutTime: any
  male: any
  female: any
  childA: any
  childB: any
  childC: any
  childD: any
  childE: any
  childF: any
  childOther: any
  accommodationFee: any
  pointDiscount: any
  payment: any
  notes: any
  channelCodeId: any
  marketSegmentId: any
  meal: any
}

type Props = {
  reservationForm: ReservationFormType
  setReservationForm: Dispatch<ReservationFormType>
  setErrors: Dispatch<string[]>
}

export const ReservationForm: React.FC<Props> = ({ reservationForm, setReservationForm, setErrors }) => {
  const { t } = useTranslation()
  const [initializedComponent, setInitializedComponent] = useState<boolean>(false)
  const [checkedBreakfast, setCheckedBreakfast] = useState<boolean>(false)
  const [checkedLunch, setCheckedLunch] = useState<boolean>(false)
  const [checkedDinner, setCheckedDinner] = useState<boolean>(false)
  const [checkedIncluded1Meal, setCheckedIncluded1Meal] = useState<boolean>(false)
  const [checkedIncluded2Meal, setCheckedIncluded2Meal] = useState<boolean>(false)
  const [reservationChannelCode, setReservationChannelCode] = useState<any>([])
  const [marketSegment, setMarketSegment] = useState<any>([])
  const defaultPaymentList = ['現地決済', 'カード決済', 'エージェント精算', 'その他']
  const isCustomPaymentMethod = useMemo(() => {
    return reservationForm.payment && !defaultPaymentList.includes(reservationForm.payment)
  }, [reservationForm])

  const parseMealText = (meal: string) => {
    if (meal.includes('朝')) {
      setCheckedBreakfast(true)
    }
    if (meal.includes('昼')) {
      setCheckedLunch(true)
    }
    if (meal.includes('夕')) {
      setCheckedDinner(true)
    }
    if (meal.includes('1食')) {
      setCheckedIncluded1Meal(true)
    }
    if (meal.includes('2食')) {
      setCheckedIncluded2Meal(true)
    }
  }

  const onChangeFormValue = (key: keyof ReservationFormType, value: any) => {
    setReservationForm({ ...reservationForm, [key]: value })
  }

  const validateNumber = ({
    male,
    female,
    childA,
    childB,
    childC,
    childD,
    childE,
    childF,
    childOther,
    accommodationFee,
    pointDiscount,
  }: ReservationFormType) => {
    const isHalfSizeNumberPaxMale = Boolean(!String(male).match(/^[0-9]+$/))
    const isHalfSizeNumberPaxFemale = Boolean(!String(female).match(/^[0-9]+$/))
    const isHalfSizeNumberPaxChildA = Boolean(!String(childA).match(/^[0-9]+$/))
    const isHalfSizeNumberPaxChildB = Boolean(!String(childB).match(/^[0-9]+$/))
    const isHalfSizeNumberPaxChildC = Boolean(!String(childC).match(/^[0-9]+$/))
    const isHalfSizeNumberPaxChildD = Boolean(!String(childD).match(/^[0-9]+$/))
    const isHalfSizeNumberPaxChildE = Boolean(!String(childE).match(/^[0-9]+$/))
    const isHalfSizeNumberPaxChildF = Boolean(!String(childF).match(/^[0-9]+$/))
    const isHalfSizeNumberPaxChildOther = Boolean(!String(childOther).match(/^[0-9]+$/))
    const isHalfSizeNumberAccommodationFee = accommodationFee && Boolean(!String(accommodationFee).match(/^[0-9]+$/))
    const isHalfSizeNumberPointDiscount = pointDiscount && Boolean(!String(pointDiscount).match(/^[0-9]+$/))
    if (
      isHalfSizeNumberPaxMale ||
      isHalfSizeNumberPaxFemale ||
      isHalfSizeNumberPaxChildA ||
      isHalfSizeNumberPaxChildB ||
      isHalfSizeNumberPaxChildC ||
      isHalfSizeNumberPaxChildD ||
      isHalfSizeNumberPaxChildE ||
      isHalfSizeNumberPaxChildF ||
      isHalfSizeNumberPaxChildOther ||
      isHalfSizeNumberAccommodationFee ||
      isHalfSizeNumberPointDiscount
    ) {
      return 'Please enter the number of people accommodation fee and point discount in single-byte numbers'
    }
  }

  const validateCheckinDate = ({ checkinDate, checkoutDate, checkinTime, checkoutTime }: ReservationFormType) => {
    // Accommodation date before change
    const checkinDateWithoutHour = checkinDate?.includes(' ') ? checkinDate.split(' ')[0] : checkinDate // Default BE return YYYY-MM-DD HH:MM:SS
    const checkoutDateWithoutHour = checkoutDate?.includes(' ') ? checkoutDate.split(' ')[0] : checkoutDate // Default BE return YYYY-MM-DD HH:MM:SS

    if (dayjs(checkoutDate).format('YYYY-MM-DD') < dayjs(checkinDate).format('YYYY-MM-DD')) {
      return 'Please set the check-out date after the check-in date'
    }

    if (
      checkinTime &&
      checkoutTime &&
      dayjs(`${checkoutDateWithoutHour} ${checkoutTime}`).isSameOrBefore(`${checkinDateWithoutHour} ${checkinTime}`)
    ) {
      return 'Checkout time before checkin time'
    }
  }

  useEffect(() => {
    setErrors([validateCheckinDate(reservationForm), validateNumber(reservationForm)].filter((msg): msg is string => !!msg))
  }, [reservationForm])

  useEffect(() => {
    if (!initializedComponent) {
      return
    }
    const saveMealList: string[] = []
    if (checkedBreakfast) {
      saveMealList.push('朝食')
    }
    if (checkedLunch) {
      saveMealList.push('昼食')
    }
    if (checkedDinner) {
      saveMealList.push('夕食')
    }
    if (checkedIncluded1Meal) {
      saveMealList.push('1食付き')
    }
    if (checkedIncluded2Meal) {
      saveMealList.push('2食付き')
    }
    onChangeFormValue('meal', saveMealList.join('/') || '-')
  }, [checkedBreakfast, checkedLunch, checkedDinner, checkedIncluded1Meal, checkedIncluded2Meal])

  useEffect(() => {
    parseMealText(reservationForm?.meal || '')
    fetchReservationChannelCode().then(res => {
      if (res) setReservationChannelCode(res.channelCodes)
    })
    fetchReservationMarketSegment().then(res => {
      if (res?.marketSegments) setMarketSegment(res.marketSegments)
    })
    setInitializedComponent(true)
  }, [])

  return (
    <>
      <div css={topContentStyle}>
        <div css={checkinDateCalenderStyle}>
          <div>
            <div css={checkinDateStyle}>{t('Date of stay')}</div>
            <DatePicker date={reservationForm.checkinDate} onDateChange={v => onChangeFormValue('checkinDate', v)} />
            <span css={wavyLineStyle}>~</span>
            <DatePicker date={reservationForm.checkoutDate} onDateChange={v => onChangeFormValue('checkoutDate', v)} />
          </div>
          <div css={arrivalWrapper}>
            <div css={checkinDateStyle}>{t('Checkin time')}</div>
            <select
              name="checkin_time"
              css={checkinTimeForArrival}
              value={reservationForm.checkinTime ?? ''}
              onChange={e => onChangeFormValue('checkinTime', e.target.value)}
            >
              {times.map((item, index) => (
                <option key={index} value={item['value']}>
                  {item['name'] === '指定なし' ? '-' : item['name']}
                </option>
              ))}
            </select>
          </div>
          <div css={arrivalWrapper}>
            <div css={checkinDateStyle}>{t('Checkout time')}</div>
            <select
              name="checkout_time"
              css={checkinTimeForArrival}
              value={reservationForm.checkoutTime ?? ''}
              onChange={e => onChangeFormValue('checkoutTime', e.target.value)}
            >
              {times.map((item, index) => (
                <option key={index} value={item['value']}>
                  {item['name'] === '指定なし' ? '-' : item['name']}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div css={topContainerStyle}>
        <div css={numberOfCustomerStyle}>
          <div css={numberOfCustomerInnerStyle}>
            <div>
              <p css={subTitleStyle}>{t('Number of people')}</p>
            </div>
          </div>
          <div>
            <ul css={numberOfCustomerListStyle}>
              <li>
                <p css={genderStyle}>{t('Male')}</p>
              </li>
              <li>
                <input
                  name="number"
                  type="text"
                  css={numberStyle}
                  value={reservationForm.male}
                  onChange={e => onChangeFormValue('male', e.target.value)}
                  placeholder="0"
                  className="numberStyle"
                />
              </li>
              <p css={textStyle}>{t('Person')}</p>
              <li>
                <p css={genderStyle}>{t('Female')}</p>
              </li>
              <li>
                <input
                  name="number"
                  type="text"
                  css={numberStyle}
                  value={reservationForm.female}
                  onChange={e => onChangeFormValue('female', e.target.value)}
                  placeholder="0"
                  className="numberStyle"
                />
              </li>
              <p css={textStyle}>{t('Person')}</p>
            </ul>
          </div>
          <div>
            <ul css={numberOfCustomerListStyle}>
              <li>
                <p css={childrenTypeStyle}>A</p>
              </li>
              <li>
                <input
                  name="number"
                  type="text"
                  css={numberStyle}
                  value={reservationForm.childA}
                  onChange={e => onChangeFormValue('childA', e.target.value)}
                  placeholder="0"
                  className="numberStyle"
                />
              </li>
              <p css={textStyle}>{t('Person')}</p>
              <li>
                <p css={childrenTypeStyle}>B</p>
              </li>
              <li>
                <input
                  name="number"
                  type="text"
                  css={numberStyle}
                  value={reservationForm.childB}
                  onChange={e => onChangeFormValue('childB', e.target.value)}
                  placeholder="0"
                  className="numberStyle"
                />
              </li>
              <p css={textStyle}>{t('Person')}</p>
              <li>
                <p css={childrenTypeStyle}>C</p>
              </li>
              <li>
                <input
                  name="number"
                  type="text"
                  css={numberStyle}
                  value={reservationForm.childC}
                  onChange={e => onChangeFormValue('childC', e.target.value)}
                  placeholder="0"
                  className="numberStyle"
                />
              </li>
              <p css={textStyle}>{t('Person')}</p>
              <li>
                <p css={childrenTypeStyle}>D</p>
              </li>
              <li>
                <input
                  name="number"
                  type="text"
                  css={numberStyle}
                  value={reservationForm.childD}
                  onChange={e => onChangeFormValue('childD', e.target.value)}
                  placeholder="0"
                  className="numberStyle"
                />
              </li>
              <p css={textStyle}>{t('Person')}</p>
            </ul>

            <ul css={numberOfCustomerListStyle}>
              <li>
                <p css={childrenTypeStyle}>E</p>
              </li>
              <li>
                <input
                  name="number"
                  type="text"
                  css={numberStyle}
                  value={reservationForm.childE}
                  onChange={e => onChangeFormValue('childE', e.target.value)}
                  placeholder="0"
                  className="numberStyle"
                />
              </li>
              <p css={textStyle}>{t('Person')}</p>
              <li>
                <p css={childrenTypeStyle}>F</p>
              </li>
              <li>
                <input
                  name="number"
                  type="text"
                  css={numberStyle}
                  value={reservationForm.childF}
                  onChange={e => onChangeFormValue('childF', e.target.value)}
                  placeholder="0"
                  className="numberStyle"
                />
              </li>
              <p css={textStyle}>{t('Person')}</p>
              <li>
                <p css={childrenOtherTypeStyle}>{t('Others')}</p>
              </li>
              <li>
                <input
                  name="number"
                  type="text"
                  css={numberStyle}
                  value={reservationForm.childOther}
                  onChange={e => onChangeFormValue('childOther', e.target.value)}
                  placeholder="0"
                  className="numberStyle"
                />
              </li>
              <p css={textStyle}>{t('Person')}</p>
            </ul>
          </div>
        </div>
      </div>

      <div css={bottomContainerStyle}>
        <div css={topFormWrapperStyle}>
          <div css={inputWrapperStyle}>
            <p css={subTitleStyle}>{t('Channel')}</p>
            <div>
              {reservationForm && (
                <select
                  name="channelCode"
                  css={selectStyle}
                  onChange={e => onChangeFormValue('channelCodeId', e.target.value)}
                  value={reservationForm.channelCodeId ?? ''}
                >
                  <option value="">-</option>
                  {reservationChannelCode.map(itemChannelCode => (
                    <option key={itemChannelCode.id} value={itemChannelCode.id}>
                      {itemChannelCode.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>

          <div>
            <p css={subTitleStyle}>{t('Market segment')}</p>
            <select
              name="marketSegmentId"
              css={selectStyle}
              onChange={e => onChangeFormValue('marketSegmentId', e.target.value)}
              value={reservationForm.marketSegmentId ?? ''}
            >
              <option value="">{t('Market segment')}</option>
              {marketSegment.map(itemSegment => (
                <option key={itemSegment.id} value={itemSegment.id}>
                  {itemSegment.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <p css={subTitleStyle}>{t('Plan name')}</p>
        <div>
          <input
            type="value"
            name="plan"
            value={reservationForm.plan}
            onChange={e => onChangeFormValue('plan', e.target.value)}
            css={planStyle}
          ></input>
        </div>

        <div css={sharedContainerStyle}>
          <div>
            <p css={subTitleStyle}>{t('Meal')}</p>
            <div css={mealPlanInnerStyle}>
              <label>
                <input
                  alt="checkbox"
                  type="image"
                  onClick={() => setCheckedBreakfast(!checkedBreakfast)}
                  src={require(`@/static/images/check-box${checkedBreakfast ? '' : '_off'}.svg`)}
                />
                <p>{t('Breakfast')}</p>
              </label>
              <label>
                <input
                  alt="checkbox"
                  type="image"
                  onClick={() => setCheckedLunch(!checkedLunch)}
                  src={require(`@/static/images/check-box${checkedLunch ? '' : '_off'}.svg`)}
                />
                <p>{t('Lunch')}</p>
              </label>
              <label>
                <input
                  alt="checkbox"
                  type="image"
                  onClick={() => setCheckedDinner(!checkedDinner)}
                  src={require(`@/static/images/check-box${checkedDinner ? '' : '_off'}.svg`)}
                />
                <p>{t('Dinner')}</p>
              </label>
              <label>
                <input
                  alt="checkbox"
                  type="image"
                  onClick={() => setCheckedIncluded1Meal(!checkedIncluded1Meal)}
                  src={require(`@/static/images/check-box${checkedIncluded1Meal ? '' : '_off'}.svg`)}
                />
                <p>{t('1 meal included')}</p>
              </label>
              <label>
                <input
                  alt="checkbox"
                  type="image"
                  onClick={() => setCheckedIncluded2Meal(!checkedIncluded2Meal)}
                  src={require(`@/static/images/check-box${checkedIncluded2Meal ? '' : '_off'}.svg`)}
                />
                <p>{t('2 meal included')}</p>
              </label>
            </div>
          </div>
        </div>
        <div css={paymentPlanStyle}>
          <div>
            <ul>
              <li css={subTitleStyle}>{t('Accommodation fee')}</li>
              <li>
                <input
                  type="value"
                  name="cost"
                  value={reservationForm.accommodationFee}
                  onChange={e => onChangeFormValue('accommodationFee', e.target.value)}
                  css={paymentCostStyle}
                ></input>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li css={subTitleStyle}>{t('Point discount')}</li>
              <li>
                <input
                  type="value"
                  name="point"
                  value={reservationForm.pointDiscount}
                  onChange={e => onChangeFormValue('pointDiscount', e.target.value)}
                  css={paymentCostStyle}
                ></input>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li css={subTitleStyle}>{t('Method of payment')}</li>
              <li>
                <select
                  name="type"
                  css={paymentMethodStyle}
                  value={reservationForm.payment}
                  onChange={e => onChangeFormValue('payment', e.target.value)}
                >
                  {defaultPaymentList.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                  {isCustomPaymentMethod && <option value={reservationForm.payment}>{reservationForm.payment}</option>}
                </select>
              </li>
            </ul>
          </div>
        </div>
        <div css={memoStyle}>
          <div>
            <p css={subTitleStyle}>{t('Remarks')}</p>
            <div>
              <textarea
                name="memo"
                value={reservationForm.notes}
                onChange={e => onChangeFormValue('notes', e.target.value)}
                css={memoTextStyle}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const selectStyle = css({
  width: 376,
  display: 'block',
  height: 32,
  borderRadius: 16,
  marginBottom: 24,
  textAlign: 'left',
  border: '1px solid #CCCCCC',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  paddingLeft: 20,
  paddingRight: 40,
  cursor: 'pointer',
  backgroundPosition: '92% center',
})

const arrivalWrapper = css({
  marginLeft: 16,
})

const topContainerStyle = css({
  padding: '32px 0 30px 37px',
  borderBottom: '1px solid #E3E3E3',
  backgroundColor: '#FFFFFF',
  borderRadius: '5px 5px 0 0',
})

const topContentStyle = css({
  padding: '32px 0 6px 37px',
  borderBottom: '1px solid #E3E3E3',
  backgroundColor: '#FFFFFF',
  borderRadius: '5px 5px 0 0',
})

const bottomContainerStyle = css({
  padding: '24px 32px 30px 32px',
  backgroundColor: '#FFFFFF',
  borderRadius: '0 0 5px 5px',
})

const methodStyle = css({
  margin: '0 0 24px 0',
  padding: '9px 0 9px 16px',
  width: '90%',
  height: 32,
  fontSize: 14,
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  backgroundColor: '#FFFFFF',
})

const planStyle = css(methodStyle, {
  minWidth: 631,
  width: '100%',
})

const checkinDateCalenderStyle = css({
  margin: '0 0 23px 0',
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  '.DateInput': {
    width: 115,
  },
})

const wavyLineStyle = css({
  padding: '0 7px',
})

const textStyle = css({
  margin: '0 40px 0 8px',
  padding: '10px 0',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#676767',
})

const subTitleStyle = css(textStyle, {
  margin: '0 16px 12px 0',
  padding: 0,
  fontSize: 12,
})

const nightsStyle = css({
  width: 78,
  height: 32,
  padding: '8px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  textAlign: 'left',
  appearance: 'none',
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 52,
  backgroundSize: 10,
})

const numberOfCustomerStyle = css({
  fontSize: 14,
  img: {
    width: 18,
    margin: '0 18px 0 0',
  },
})

const numberOfCustomerInnerStyle = css({
  display: 'flex',
  alignItems: 'end',
})

const numberOfCustomerListStyle = css({
  display: 'flex',
  marginTop: 12,
})

const genderStyle = css({
  margin: '0 29px 0 0',
  padding: '10px 0',
  color: '#272727',
  fontSize: 12,
})

const numberStyle = css({
  width: 78,
  height: 32,
  padding: '8px 16px',
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  textAlign: 'left',
  appearance: 'none',
})

const childrenTypeStyle = css({
  margin: '0 45px 0 0',
  padding: '10px 0',
  color: '#272727',
  fontSize: 12,
})

const childrenOtherTypeStyle = css({
  margin: '0 16px 0 0',
  padding: '10px 0',
  color: '#272727',
  fontSize: 12,
})

const sharedContainerStyle = css({
  margin: '0 0 24px 0',
  display: 'flex',
  alignItems: 'start',
  fontSize: 14,
})

const mealPlanInnerStyle = css({
  fontSize: 14,
  img: {
    width: 20,
    height: 20,
  },
  p: {
    display: 'inline-table',
    margin: '0 24px 0 10px',
    padding: '4px 0',
  },
})

const paymentPlanStyle = css({
  display: 'flex',
  margin: '0 0 24px 0',
  alignItems: 'start',
  fontSize: 14,
})

const paymentCostStyle = css(nightsStyle, {
  width: 187,
  margin: '0 16px 0 0',
  background: 'none',
  fontSize: 14,
})

const paymentMethodStyle = css(nightsStyle, {
  width: 187,
  backgroundPosition: 150,
  fontSize: 14,
})

const memoStyle = css({
  display: 'flex',
  alignItems: 'start',
  fontSize: 14,
})

const memoTextStyle = css(nightsStyle, {
  width: 636,
  height: 'auto',
  background: 'none',
  border: '1px solid #CCCCCC',
  borderRadius: 12,
  resize: 'none',
})

const checkinDateStyle = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: 12,
  color: '#676767',
  fontWeight: 'bold',
  marginBottom: 12,
})

const inputWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  marginRight: 0,
})

const topFormWrapperStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const checkinTimeSelectBoxStyle = css(initializeSelectBoxStyle, {
  width: 107,
  backgroundPosition: 72,
  backgroundImage: `url(${require('@/static/images/select.svg')})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 10,
  marginBottom: 24,
  height: 32,
  padding: '8px 20px',
  border: '1px solid #CCCCCC',
  borderRadius: 17,
  textAlign: 'left',
})

const checkinTimeForArrival = css(checkinTimeSelectBoxStyle, {
  marginBottom: 0,
})
