import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@/components/atoms/modal'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Button } from '@/components/atoms'
import { updateCheckinReception } from '@/apis/aipass'
import { useParams } from 'react-router-dom'
import { ApprovedStatusType } from '@/models/reservation-approved-status'

interface SelectCreationModalProps {
  onClose: () => void
  onSelectSearchRelevant: () => void
}

export const SelectCreationModal = ({ onClose, onSelectSearchRelevant }: SelectCreationModalProps) => {
  const [isSendingCreateReservation, setIsSendingCreateReservation] = useState<boolean>(false)
  const [creationType, setCreationType] = useState<'pegging' | 'new'>()

  const { t } = useTranslation()
  const params = useParams<any>()

  // Create new reservation
  const _createReservation = async () => {
    if (isSendingCreateReservation) {
      return
    } else {
      setIsSendingCreateReservation(true)
    }

    await updateCheckinReception([params.id], ApprovedStatusType.Checkin, 'accommodationDetail')
      .then(() => {
        window.location.reload()
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Modal
      isLoading={isSendingCreateReservation}
      customCss={css({ height: 275, position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', margin: 0 })}
    >
      <ModalHeader>{t('Add Reservations')}</ModalHeader>
      <ModalBody customCss={{ paddingBlock: 24 }}>
        <div css={reservationCheckinWrapperStyle}>
          <div
            css={[reservationCheckinLinkStyle, creationType === 'pegging' ? selectedStyle : undefined]}
            onClick={() => setCreationType('pegging')}
          >
            <p>{t('Linked to reservations (reservation status only)')}</p>
          </div>
          <div
            css={[reservationCheckinLinkStyle, creationType === 'new' ? selectedStyle : undefined]}
            onClick={() => {
              setCreationType('new')
            }}
          >
            <p>{t('Create a reservation')}</p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button width={110} height={38} buttonType={3} marginRight={16} onClick={() => onClose()}>
          {t('Cancel')}
        </Button>
        <Button
          width={110}
          height={40}
          buttonType={creationType ? 1 : 5}
          onClick={() => {
            if (creationType === 'pegging') {
              onSelectSearchRelevant()
            }

            if (creationType === 'new') {
              _createReservation()
            }
          }}
        >
          {t('Next')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const reservationCheckinWrapperStyle = css({
  width: '100%',
  backgroundColor: '#FFF',
  border: '1px solid #CCCCCC',
  display: 'flex',
  padding: 32,
  borderRadius: 5,
  gap: 23,
})

const reservationCheckinLinkStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 264,
  height: 42,
  cursor: 'pointer',
  border: '1px solid #CCCCCC',
  borderRadius: 5,
  p: {
    textAlign: 'center',
    color: '#676767',
    fontSize: 14,
    fontWeight: 'bold',
  },
})

const selectedStyle = css({
  borderColor: '#F2A40B',
  P: {
    color: '#F2A40B',
  },
})
