import React from 'react'
import { HeaderListCleaning } from './header-list-cleaning'
import { MainListCleaning } from './main-list-cleaning'
import { css } from '@emotion/core'

type ListCleaningRoomProps = {
  collapsedTracking: object
  setCollapsedTracking: (arg0: any) => void
  rooms: object
  selectedCleaningStaffId: string
  setIsOpenRoomDetailModal: (arg0: any) => void
  setSelectedRoomId: (arg0: any) => void
}

export const ListCleaningRoom = ({
  collapsedTracking,
  setCollapsedTracking,
  rooms,
  selectedCleaningStaffId,
  setIsOpenRoomDetailModal,
  setSelectedRoomId,
}: ListCleaningRoomProps) => {
  return (
    <div css={cleaningLayoutWrapper}>
      <HeaderListCleaning collapsedTracking={collapsedTracking} setCollapsedTracking={setCollapsedTracking} rooms={rooms} />
      <MainListCleaning
        collapsedTracking={collapsedTracking}
        setCollapsedTracking={setCollapsedTracking}
        rooms={rooms}
        selectedCleaningStaffId={selectedCleaningStaffId}
        setIsOpenRoomDetailModal={setIsOpenRoomDetailModal}
        setSelectedRoomId={setSelectedRoomId}
      />
    </div>
  )
}

const cleaningLayoutWrapper = css({
  position: 'relative',
})
