import React, { useContext, useEffect, useState } from 'react'

import { Button } from '@/components/atoms'
import { modalContainerStyle } from '@/components/pages/accommodation-management/list-layout'
import { DateRangeField } from '@/components/molecules/date-range-field'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import {
  fetchAdminCheckin,
  fetchReservationConfirmedSales,
  fetchReservationLink,
  insertAdminReservationCheckin,
  updateSalesAdvancePayment,
} from '@/apis/aipass'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import { AccountContext } from '@/contexts/account'
import { ApprovedStatusType } from '@/models/reservation-approved-status'

const DISPLAY_LIMIT_IN_PAGE = 30

interface SelectRelevantModalProps {
  reservation: any
  onClose: () => void
}

export const SelectRelevantModal: React.FC<SelectRelevantModalProps> = ({ reservation, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [lastPage, setLastPage] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isInitial, setIsInitial] = useState<boolean>(true)
  const [totalNumber, setTotalNumber] = useState<number>(0)
  const [reservationList, setReservationList] = useState<any>([])
  const [reservationId, setReservationId] = useState<any>('')
  const [reservationCheckinDate, setReservationCheckinDate] = useState<any>('')
  const [reservationCheckoutDate, setReservationCheckoutDate] = useState<any>('')
  const [checkinReservations, setCheckinReservations] = useState<any>([])
  const [beginDate, setBeginDate] = useState<string>(dayjs(reservation?.checkinDate).format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState<string>(dayjs(reservation?.checkinDate).add(1, 'day').format('YYYY-MM-DD'))
  const [isCheckboxActive, setIsCheckboxActive] = useState<boolean>(false)

  // "1" part of 1-30 (30*(1-1)+1 = 1st page)
  const displayFrom = DISPLAY_LIMIT_IN_PAGE * (currentPage - 1) + 1
  // "1" part of 1-30 (30*(1-1)+1 = 1st page)
  const displayTo = Math.min(DISPLAY_LIMIT_IN_PAGE * currentPage, totalNumber)
  const params = useParams<any>()
  const { id } = params

  const { t } = useTranslation()
  const { account } = useContext<any>(AccountContext)

  // go back
  const onClickPrevious = () => {
    if (currentPage === 1) return
    setCurrentPage(currentPage - 1)
  }

  const _fetchAdminCheckin = async () => {
    await fetchAdminCheckin(id).then(res => {
      setCheckinReservations(res?.reservations)
      setIsLoading(false)
    })
  }

  // move on
  const onClickNext = () => {
    if (currentPage === lastPage) return
    setCurrentPage(currentPage + 1)
  }

  // Check if there are confirmed reservation details
  const _hasConfirmedSales = async reservationId => {
    let hasConfirmedSales = false
    await fetchReservationConfirmedSales(reservationId)
      .then(res => {
        // Returns true if there are confirmed details
        if (res.sales.length > 0) hasConfirmedSales = true
      })
      .catch(() => {
        console.log(t('Communication failed'))
      })

    return hasConfirmedSales
  }

  // Reservation and linking from check-in
  const _setReservationCheckin = async () => {
    if (!reservationId) return
    setIsLoading(true)
    // If the reservation has confirmed details, confirm whether to link them
    if (
      (await _hasConfirmedSales(reservationId)) &&
      !window.confirm(t('Includes items with confirmed dates Please pay attention to sales management'))
    ) {
      setIsLoading(false)
      return
    }

    // Match the time to overwrite and update the check-in time and check-out time of the corresponding check-in information in the reservation information
    await insertAdminReservationCheckin(id, reservationId, reservationCheckinDate, reservationCheckoutDate)
      .then(() => {
        setIsLoading(false)
        // setIsModalOpen(false) // Close the modal
        window.location.reload()
      })
      .catch(error => {
        console.log(error)
      })

    const reservationIds = reservationId.split(',')
    // Give Advance Receipt Flag
    await updateSalesAdvancePayment(reservationIds)
  }

  // Acquisition of all reservation information for displaying all items
  const _fetchAllReservationInfo = async () => {
    const formatedBeginDate = `${beginDate} 00:00:00`
    const formatedEndDate = `${endDate} 23:59:59`
    if (isInitial) {
      await fetchReservationLink(formatedBeginDate, formatedEndDate, reservation?.telephone, Number(isInitial)).then(res => {
        if (res) {
          setTotalNumber(res?.length)
          setLastPage(Math.ceil(res?.length / DISPLAY_LIMIT_IN_PAGE))
        }
      })
    } else {
      await fetchReservationLink(formatedBeginDate, formatedEndDate, reservation?.telephone, Number(isInitial)).then(res => {
        if (res) {
          setTotalNumber(res?.length)
          setLastPage(Math.ceil(res?.length / DISPLAY_LIMIT_IN_PAGE))
        }
      })
    }
  }
  // Reservation information acquisition by paging
  const _fetchReservationInfoByPaging = async () => {
    const formatedBeginDate = `${beginDate} 00:00:00`
    const formatedEndDate = `${endDate} 23:59:59`
    if (isInitial) {
      await fetchReservationLink(
        formatedBeginDate,
        formatedEndDate,
        reservation?.telephone,
        Number(isInitial),
        currentPage,
        DISPLAY_LIMIT_IN_PAGE,
      ).then(res => {
        if (res) {
          // Reservation information is set in the reservation information list displayed in the pop-up linking reservation from check-in
          setReservationList(res)
          // Automatically select isSameTelphone (the first data with the same phone number)
          if (res?.[0]?.isSameTelphone) {
            setReservationId(res[0]?.reservationId)
            setIsCheckboxActive(true)
            setReservationCheckinDate(res[0]?.reservationCheckinDate)
            setReservationCheckoutDate(res[0]?.reservationCheckoutDate)
          }
        }
        setIsLoading(false)
      })
    } else {
      await fetchReservationLink(
        formatedBeginDate,
        formatedEndDate,
        reservation?.telephone,
        Number(isInitial),
        currentPage,
        DISPLAY_LIMIT_IN_PAGE,
      ).then(res => {
        if (res) {
          setReservationList(res)
        }
        setIsLoading(false)
      })
    }
  }

  const _changeRangeDate = (beginDate, endDate) => {
    setIsInitial(false)
    setReservationId('')
    setBeginDate(beginDate)
    setEndDate(endDate)
  }

  useEffect(() => {
    if (account && account?.hotel) {
      if (beginDate && endDate) {
        _fetchAllReservationInfo()
        _fetchReservationInfoByPaging()
      }
    }
  }, [account, currentPage, beginDate, endDate])

  useEffect(() => {
    if (account && account?.hotel) {
      setIsLoading(true)
      _fetchAdminCheckin()
    }
  }, [account])

  useEffect(() => {
    // If the check-in details screen and there is no reservation linked to the check-in, the clicked part of "reservation and link" is initially displayed.
    if (
      account &&
      account?.hotel &&
      checkinReservations &&
      reservation &&
      checkinReservations.length === 0 &&
      reservation.approvedStatus === ApprovedStatusType.Checkin
    ) {
      const addedReservations = [...checkinReservations]
      // Display the click part of "reservation and link" with reservationCheckinLink=true
      addedReservations.push({ reservationCheckinLink: true })
      setCheckinReservations(addedReservations)
    }
  }, [checkinReservations])

  return (
    <>
      <div css={[modalContainerStyle, { zIndex: 103 }]}></div>
      <div css={modalBodyContainerStyle}>
        {isLoading && <div css={loadingOverlayStyle} />}
        <h3 css={modalHeaderStyle}>{t('Select relevant booking')}</h3>
        <div css={modalMainContainerStyle}>
          <div css={dateRangeFieldContainerStyle}>
            <DateRangeField beginDate={beginDate} endDate={endDate} changeRangeDate={_changeRangeDate} />
          </div>
          <div css={modalMainTopContainerStyle}>
            <ul css={modalMainTopContentsListStyle}>
              <li css={modalTopContentsLabelStyle}>{t('Guests')} :</li>
              <li css={modalTopContentsStyle}>{reservation && reservation.name ? reservation.name : '-'}</li>
            </ul>
            <ul css={modalMainTopContentsListRightStyle}>
              <li css={modalTopContentsLabelStyle}>{t('Date of stay')} :</li>
              <li css={modalTopContentsStyle}>{dayjs(reservation && reservation.userCheckinTime).format(t('MM-DD(ddd)'))}</li>
            </ul>
          </div>
          <ul css={reservationLabelContainerStyle}>
            <li css={reservationUserLabelStyle}>{t('Reservation name')}</li>
            <li css={reservationGuestLabelStyle}>{t('Guests')}</li>
            <li css={reservationCheckinLabelStyle}>{t('Date of stay')}</li>
          </ul>
          <div css={modalMainCheckInListContainerStyle}>
            {reservationList &&
              reservationList?.length !== 0 &&
              reservationList?.map(v => (
                <li
                  key={v.reservationId}
                  css={reservationId === v?.reservationId ? reservationContentsActiveStyle : reservationContentsStyle}
                  onClick={() => {
                    setReservationId(v?.reservationId)
                    setIsCheckboxActive(true)
                    setReservationCheckinDate(v?.reservationCheckinDate)
                    setReservationCheckoutDate(v?.reservationCheckoutDate)
                  }}
                >
                  <img
                    alt="checkbox"
                    src={require(`@/static/images/radio${reservationId === v.reservationId ? '_on' : '_off'}.svg`)}
                    css={reservationCheckBoxStyle}
                  />
                  <ul css={reservationUserWrapperStyle}>
                    <li css={reservationGuestNameKanaStyle}>
                      {v?.reservationUserNameKana === null || v?.reservationUserNameKana === '' ? '-' : v.reservationUserNameKana}
                    </li>
                    <li css={reservationGuestNameStyle}>
                      {v?.reservationUserName === null || v?.reservationUserName === '' ? '-' : v.reservationUserName}
                    </li>
                  </ul>
                  <ul css={reservationGuestWrapperStyle}>
                    <li css={reservationGuestNameKanaStyle}>
                      {v?.reservationGuestNameKana === null || v?.reservationGuestNameKana === '' ? '-' : v.reservationGuestNameKana}
                    </li>
                    <li css={reservationGuestNameStyle}>
                      {v?.reservationGuestName === null || v?.reservationGuestName === '' ? '-' : v.reservationGuestName}
                    </li>
                  </ul>
                  <div css={reservationScheduleDayStyle}>
                    {v?.reservationCheckinDate ? dayjs(v?.reservationCheckinDate).format('MM月DD日(ddd)') : '-'}
                  </div>
                </li>
              ))}
          </div>
          <div css={detailListPaginationContainerStyle}>
            <p css={paginationLineCountStyle}>
              {displayFrom}-{displayTo} / {totalNumber} {t('Rows')}
            </p>
            <div css={paginationArrowStyle} onClick={onClickPrevious}>
              {displayFrom === 1 ? (
                <img alt="arrow_disabled_left" src={require('@/static/images/arrow_disabled_left.svg')} onClick={onClickPrevious} />
              ) : (
                <img alt="arrow_left" src={require('@/static/images/arrow_left.svg')} onClick={onClickPrevious} />
              )}
            </div>
            <div css={paginationArrowStyle} onClick={onClickNext}>
              {displayTo === totalNumber ? (
                <img alt="arrow_disabled_right" src={require('@/static/images/arrow_disabled_right.svg')} onClick={onClickNext} />
              ) : (
                <img alt="arrow_right" src={require('@/static/images/arrow_right.svg')} onClick={onClickNext} />
              )}
            </div>
          </div>
        </div>
        <div css={modalButtonSectionStyle}>
          <Button width={110} height={38} buttonType={3} marginRight={16} onClick={() => onClose()}>
            {t('Cancel')}
          </Button>
          <Button
            width={110}
            height={40}
            buttonType={isCheckboxActive ? 1 : 5}
            onClick={() => {
              isCheckboxActive && _setReservationCheckin()
            }}
          >
            {t('Completion')}
          </Button>
        </div>
      </div>
    </>
  )
}

// Modal
const modalBodyContainerStyle = css({
  position: 'fixed',
  top: '50%',
  left: '50%',
  minWidth: 680,
  zIndex: 103,
  opacity: 1,
  background: '#FFF',
  borderRadius: 5,
  transform: 'translate(-50%, -50%)',
})

// 処理中オーバーレイ
const loadingOverlayStyle = css({
  position: 'absolute',
  zIndex: 105,
  width: '100%',
  height: '100%',
  background: 'rgba(255, 255, 255, 0.8)',
  opacity: 0.7,
  alignItems: 'center',
  borderRadius: 5,
})

const modalHeaderStyle = css({
  margin: '22px 32px',
  fontWeight: 'bold',
  fontSize: 18,
  color: '#676767',
})

const modalMainContainerStyle = css({
  padding: '26px 32px',
  backgroundColor: '#F2F2F2',
})

const dateRangeFieldContainerStyle = css({
  marginBottom: 24,
})

const modalMainTopContainerStyle = css({
  display: 'flex',
  marginBottom: 24,
})

const modalMainTopContentsListStyle = css(modalMainTopContainerStyle, {
  marginBottom: 0,
})

const modalMainTopContentsListRightStyle = css(modalMainTopContentsListStyle, {
  marginLeft: 'auto',
})

const modalTopContentsLabelStyle = css({
  fontSize: 14,
  color: '#A3A3A3',
  marginRight: 12,
  fontWeight: 'bold',
})

const reservationUserLabelStyle = css({
  marginLeft: 38,
  width: 140,
})

const reservationGuestLabelStyle = css({
  marginLeft: 20,
  width: 140,
})

const reservationCheckinLabelStyle = css({
  marginLeft: 'auto',
  minWidth: 92,
})

const modalTopContentsStyle = css({
  maxWidth: '111px',
  fontSize: 14,
  fontWeight: 'bold',
  color: '#676767',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const modalMainCheckInListContainerStyle = css({
  width: '100%',
  height: 256,
  backgroundColor: '#FFF',
  border: '1px solid #CCCCCC',
  borderTop: 'none',
  borderRadius: '0 0 5px 5px',
  overflow: 'auto',
})

const reservationLabelContainerStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#FFF',
  padding: '10px 36px',
  color: '#676767',
  fontWeight: 'bold',
  fontSize: 12,
  borderRadius: '5px 5px 0 0',
  borderLeft: '1px solid #CCCCCC',
  borderTop: '1px solid #CCCCCC',
  borderRight: '1px solid #CCCCCC',
  borderBottom: '1px solid #ccc',
})

const reservationContentsStyle = css({
  cursor: 'pointer',
  minHeight: 64,
  display: 'flex',
  alignItems: 'center',
  padding: '12px 27px 12px 32px',
  borderBottom: '1px solid #ccc',
  ':hover': {
    boxShadow: '0px 3px 6px #0000000f',
  },
})

const reservationContentsActiveStyle = css(reservationContentsStyle, {
  backgroundColor: '#fef3f0',
})

const reservationUserWrapperStyle = css({
  color: '#676767',
  fontSize: 14,
  width: 140,
  justifyContent: 'space-between',
  fontWeight: 'bold',
  display: 'flex',
  marginLeft: 25,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  flexDirection: 'column',
})

const reservationGuestWrapperStyle = css({
  color: '#676767',
  fontSize: 14,
  width: 140,
  justifyContent: 'space-between',
  fontWeight: 'bold',
  display: 'flex',
  marginLeft: 20,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  flexDirection: 'column',
})

const reservationScheduleDayStyle = css({
  color: '#676767',
  fontSize: 14,
  marginLeft: 'auto',
  minWidth: 92,
  textAlign: 'center',
})

const modalButtonSectionStyle = css({
  display: 'flex',
  padding: '11px 32px 11px 32px',
  justifyContent: 'flex-end',
  boxShadow: '0px 0px 6px #0000001A',
})

const reservationGuestNameKanaStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: 12,
  paddingBottom: 6,
})

const reservationGuestNameStyle = css({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: 14,
})

const reservationCheckBoxStyle = css({
  width: 18,
})

const detailListPaginationContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 30,
  width: '100%',
  justifyContent: 'flex-end',
  marginTop: 15,
})

const paginationLineCountStyle = css({
  fontSize: 12,
  color: '#676767',
  marginRight: 16,
  padding: '8px 0',
  fontWeight: 'bold',
})

const paginationArrowStyle = css({
  borderRadius: '50%',
  height: 30,
  ':hover': {
    backgroundColor: '#F2F2F2',
    cursor: 'pointer',
  },
})
