import React, { useState, useEffect, useContext, useMemo } from 'react'
import { css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/atoms/button'
import { SettingSalesManagerTab } from '@/components/organisms/settings/sales-manager/tab'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { SettingsLayout } from '@/components/layouts/settings-layout'
import { TabContainer } from '@/components/organisms/settings/tab-container'
import { LoaderContextCreator } from '@/contexts/loader'
import { SalesSubjectTaxPriceType, SalesSubjectTaxType } from '@/models/sales-manager/sales'
import { EditFooter } from '@/components/organisms/edit-footer'
import * as api from '@/apis/aipass'
import { TableBody, TableHeader, TableRow } from '@/components/organisms/settings/sales-manager/table'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { SubjectTaxDetailModal } from '@/components/organisms/settings/sales-manager/subject-tax-detail-modal'

export const SettingSalesManagerTax: React.FC = () => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const [salesSubjectTaxList, setSalesSubjectTaxList] = useState<SalesSubjectTaxType[]>([])
  const [showDetailProp, setShowDetailProp] = useState<SalesSubjectTaxType | null>()
  const [deleteIds, setDeleteIds] = useState<string[]>([])

  const initializeComponent = async (opt?: { noIndicator: boolean }) => {
    !opt?.noIndicator && setIsLoading(true)
    const salesSubjectTaxList = await api.fetchSalesSubjectTax()
    setSalesSubjectTaxList([])
    setSalesSubjectTaxList(salesSubjectTaxList)
    setDeleteIds([])
    !opt?.noIndicator && setIsLoading(false)
  }

  const addDeleteTarget = (id: string) => {
    setDeleteIds([...deleteIds, id])
  }

  const doDelete = async () => {
    try {
      setIsLoading(true)
      if (deleteIds.length) {
        await api.deleteSalesSubSubjectTax(deleteIds)
        await initializeComponent()
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      setIsLoading(false)
    }
  }

  const doDeleteCancel = () => {
    setDeleteIds([])
  }

  const computedHasMultiLineRow = (detail: SalesSubjectTaxType): boolean => {
    return detail.priceType === SalesSubjectTaxPriceType.FixAmount && detail.roomChargeRange.length > 1
  }

  const makePriceDetail = (detail: SalesSubjectTaxType) => {
    if (detail.priceType === SalesSubjectTaxPriceType.FixRate) {
      return <div>{t('saleSubjectTax.RateOfRoomCharge', { rate: detail.roomChargeRate })}</div>
    } else {
      return detail.roomChargeRange.map((range, i) => {
        let priceRangeText = `¥${range.lowerPrice.toLocaleString()}~`
        if (range.upperPrice) {
          priceRangeText = `¥${range.lowerPrice.toLocaleString()}~¥${range.upperPrice.toLocaleString()}`
        }
        return (
          <div key={i}>
            {t('saleSubjectTax.FixOfRoomCharge', {
              range: priceRangeText,
              price: `¥${range.taxPrice.toLocaleString()}`,
            })}
          </div>
        )
      })
    }
  }

  useEffect(() => {
    initializeComponent()
  }, [])

  const isShowFooter = useMemo(() => deleteIds.length > 0, [deleteIds])
  const deleteFilteredSalesSubject = useMemo(
    () => salesSubjectTaxList.filter(detail => !deleteIds.includes(detail.id)),
    [salesSubjectTaxList, deleteIds],
  )
  const footerContent = isShowFooter ? <EditFooter onSave={doDelete} onCancel={doDeleteCancel} /> : undefined

  return (
    <SettingsLayout loading={false} footerContent={footerContent}>
      <TabContainer tabComponent={<SettingSalesManagerTab currentTab="saleSubjectTax.Tax" />} customStyle={taxTableStyle}>
        <>
          <div css={containerHeaderStyle}>
            <Button
              buttonType={1}
              width={107}
              height={32}
              fontSize={12}
              onClick={() => setShowDetailProp(null)}
              icon={require('@/static/images/plus-white.svg')}
            >
              {t('Add new')}
            </Button>
          </div>
          <TableHeader className="table-header">
            <div className="name-col">{t('saleSubjectTax.Name')}</div>
            <div className="class-col">{t('saleSubjectTax.TargetChildClassification')}</div>
            <div className="price-col">{t('Amount')}</div>
            <div className="subject-col">{t('Subject')}</div>
            <div className="delete-col"></div>
          </TableHeader>
          <TableBody>
            {deleteFilteredSalesSubject.map(detail => (
              <TableRow
                className={`table-row ${computedHasMultiLineRow(detail) ? '-multi-line' : ''}`}
                css={css({
                  ...(computedHasMultiLineRow(detail)
                    ? {
                        alignItems: 'start',
                        padding: '18px 0',
                      }
                    : {}),
                })}
                key={detail.id}
                onClick={() => setShowDetailProp(detail)}
              >
                <div className="name-col">{detail.name}</div>
                <div className="class-col">{detail.childrenClass.map(cl => t(`ChildrenClass.${cl}`)).join(', ') || '-'}</div>
                <div className="price-col">{makePriceDetail(detail)}</div>
                <div className="subject-col">{detail.salesSubject.salesSubjectMasterName}</div>
                <div className="delete-col" style={{ marginTop: computedHasMultiLineRow(detail) ? '-10px' : 'inherit' }}>
                  <DeleteIcon onClick={() => addDeleteTarget(detail.id)} />
                </div>
              </TableRow>
            ))}
          </TableBody>
          {showDetailProp !== undefined && (
            <SubjectTaxDetailModal
              detail={showDetailProp}
              onSave={() => {
                initializeComponent()
                setShowDetailProp(undefined)
              }}
              onCancel={() => setShowDetailProp(undefined)}
            />
          )}
        </>
      </TabContainer>
    </SettingsLayout>
  )
}

const containerHeaderStyle = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 32px 19px 32px',
  borderBottom: '1px solid #F2F2F2',
  '.subject-master-csv-uploader': { display: 'none' },
})

const taxTableStyle = css({
  '.table-row': {
    '> div': { fontWeight: 500 },
    minHeight: 49,
  },
  '.name-col': { marginLeft: 32, width: 108 },
  '.class-col': { marginLeft: 32, width: 208 },
  '.price-col': { marginLeft: 32, flexGrow: 1 },
  '.subject-col': { marginLeft: 27, width: 128 },
  '.delete-col': { padding: '0 32px', width: 30 },
})
