import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { css } from '@emotion/core'
import dayjs from 'dayjs'
import 'dayjs/locale/ja'

// constants
import {
  wholeContainerStyle,
  mainColumnStyle,
  mainContainerWithFooterBarStyle,
  headerBarLeftWrapperStyle,
  headerBarRightWrapperStyle,
  mainContainerHeaderStyle,
} from '@/constants/layout'

// components
import { Button } from '@/components/atoms/button'
import { Header } from '@/components/organisms/header'
import { SideMenu } from '@/components/organisms/side-menu'
import { LoadingFull } from '@/components/molecules/loading-full'
import { DateRangeField } from '@/components/molecules/date-range-field'

// apis
import { fetchSalesDetailCsv, fetchSalesDetailList } from '@/apis/aipass'

// libis
import { csvDownload } from '@/libs/csv-download'

// models
import { ApprovedStatusType } from '@/models/reservation-approved-status'

const DISPLAY_LIMIT_IN_PAGE = 30

export const SalesManagerDetailList: React.FC = () => {
  const searchParams: {
    beginDate?: string
    endDate?: string
    salesDailyId?: string
    page?: string
  } = require('query-string').parse(window.location.search)
  const { t } = useTranslation()
  const tableContainer = useRef<HTMLDivElement>(null)
  const [beginDate, setBeginDate] = useState<string>(searchParams.beginDate || '')
  const [endDate, setEndDate] = useState<string>(searchParams.endDate || '')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [salesDetailList, setSalesDetailList] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(Number(searchParams.page) || 1)
  const [lastPage, setLastPage] = useState<number>(1)
  const [salesDailyId, setSalesDailyId] = useState<string | null>(searchParams.salesDailyId || null)
  const [totalNumber, setTotalNumber] = useState<number>(0)
  const history = useHistory()

  const displayFrom = DISPLAY_LIMIT_IN_PAGE * (currentPage - 1) + 1
  const displayTo = Math.min(DISPLAY_LIMIT_IN_PAGE * currentPage, totalNumber)

  const changeRangeDate = (beginDate, endDate) => {
    setSalesDailyId(null)
    setBeginDate(beginDate)
    setEndDate(endDate)
    setCurrentPage(1)
  }

  // csv download
  const downloadSalesCsv = async () => {
    setIsLoading(true)
    fetchSalesDetailCsv(beginDate, endDate, salesDailyId).then(res => {
      const data = res?.salesDetailListCsv
      const fileName = 'sales-detail-list.csv'
      csvDownload(data, fileName)
      setIsLoading(false)
    })
  }

  const searchSalesDetail = async () => {
    setIsLoading(true)
    await Promise.all([
      fetchSalesDetailList(beginDate, endDate, null, null, salesDailyId).then(res => {
        if (res?.sales) {
          setTotalNumber(res?.sales.length)
          setLastPage(Math.ceil(res?.sales.length / DISPLAY_LIMIT_IN_PAGE))
        }
      }),
      fetchSalesDetailList(beginDate, endDate, currentPage, DISPLAY_LIMIT_IN_PAGE, salesDailyId).then(res => {
        if (res?.sales) {
          setSalesDetailList(res?.sales)
          tableContainer.current?.scrollTo(0, 0)
        }
      }),
    ])
    history.replace({
      pathname: '',
      search: new URLSearchParams({
        beginDate,
        endDate,
        salesDailyId: salesDailyId || '',
        page: `${currentPage}`,
      }).toString(),
    })
    setIsLoading(false)
  }

  const onClickPrevious = () => {
    if (currentPage === 1) return
    setCurrentPage(currentPage - 1)
  }

  const onClickNext = () => {
    if (currentPage === lastPage) return
    setCurrentPage(currentPage + 1)
  }

  const getAccommodationDetailUrl = (checkinId: string, approvedStatus: ApprovedStatusType): string => {
    let accommodationDetailUrl = '#'
    if (approvedStatus === ApprovedStatusType.Checkin) {
      accommodationDetailUrl = `/checkin/${checkinId}?tabType=checkin`
    }
    if (approvedStatus === ApprovedStatusType.Stay) {
      accommodationDetailUrl = `/stay/${checkinId}?tabType=stay`
    }
    if (approvedStatus === ApprovedStatusType.Checkout) {
      accommodationDetailUrl = `/checkout/${checkinId}?tabType=checkout`
    }
    return accommodationDetailUrl
  }

  useEffect(() => {
    if ((!!beginDate && !!endDate) || (!beginDate && !endDate)) {
      searchSalesDetail()
    }
  }, [beginDate, endDate, currentPage])

  const nameDomBySales = salesDetail => {
    if (salesDetail.checkinId) {
      return (
        <a href={getAccommodationDetailUrl(salesDetail.checkinId, salesDetail.approvedStatus)}>
          <p css={nameStyle}>{salesDetail.reservationGuestName}</p>
          <p>様</p>
        </a>
      )
    } else if (salesDetail.isCompany) {
      return (
        <a href={`/sales-manager/advance-money?companyName=${salesDetail.reservationGuestName}&dateTime=${salesDetail.salesDate}`}>
          <p css={nameStyle} style={{ width: '100%' }}>
            {salesDetail.reservationGuestName}
          </p>
        </a>
      )
    } else if (salesDetail.pmsTransactionId && salesDetail.salesManagerDailyId) {
      return (
        <a
          href={`/sales-manager/detail?id=${salesDetail.salesManagerDailyId}&transactionId=${salesDetail.pmsTransactionId}&mode=daily`}
          style={{ width: 'fit-content' }}
        >
          -
        </a>
      )
    } else {
      return '-'
    }
  }

  return (
    <div css={wholeContainerStyle}>
      <SideMenu />
      <div className="mainColumn" css={mainColumnStyle}>
        <Header isBack title={t('Sales management')} goBack={() => history.goBack()} />
        <div className="mainContainer" css={[mainContainerWithFooterBarStyle, { height: 'auto' }]}>
          <div css={headerContainerStyle}>
            <div css={headerBarLeftWrapperStyle}>
              <DateRangeField beginDate={beginDate} endDate={endDate} changeRangeDate={changeRangeDate} />
            </div>
            <div css={headerBarRightWrapperStyle}>
              {/* csv download button */}
              <Button
                buttonType={4}
                width={160}
                height={32}
                fontSize={12}
                marginRight={12}
                icon={require('@/static/images/download.svg')}
                onClick={downloadSalesCsv}
              >
                {t('CSV download')}
              </Button>
            </div>
          </div>
          <div css={detailListContainerStyle} ref={tableContainer}>
            <div css={detailListHeaderContainerStyle}>
              <div css={detailListHeaderStyle}>
                <div css={detailListTitleStyle}>{t('SaleDetail')}</div>
                <div css={detailListPaginationContainerStyle}>
                  <p css={paginationLineCountStyle}>
                    {displayFrom}-{displayTo} / {totalNumber} {t('Rows')}
                  </p>
                  <div css={paginationArrowStyle} onClick={onClickPrevious}>
                    {displayFrom === 1 ? (
                      <img alt="arrow_left" src={require('@/static/images/arrow_disabled_left.svg')} onClick={onClickPrevious} />
                    ) : (
                      <img alt="arrow_left" src={require('@/static/images/arrow_left.svg')} onClick={onClickPrevious} />
                    )}
                  </div>
                  <div css={paginationArrowStyle} onClick={onClickNext}>
                    {displayTo === totalNumber ? (
                      <img alt="arrow_right" src={require('@/static/images/arrow_disabled_right.svg')} onClick={onClickNext} />
                    ) : (
                      <img alt="arrow_right" src={require('@/static/images/arrow_right.svg')} onClick={onClickNext} />
                    )}
                  </div>
                </div>
              </div>
              <ul css={detailListLabelContainerStyle}>
                <li css={dateLabelStyle}>{t('Date')}</li>
                <li css={nameLabelStyle}>{t('Guest')}</li>
                <li css={accommodationLabelStyle}>{t('Accommodation ID')}</li>
                <li css={subjectsLabelStyle}>{t('Subject')}</li>
                <li css={unitPriceLabelStyle}>{t('Unit price')}</li>
                <li css={quantityLabelStyle}>{t('Quantity')}</li>
                <li css={salesLabelStyle}>{t('Earnings')}</li>
                <li css={paymentMethodsLabelStyle}>{t('Method of payment')}</li>
                <li css={paymentLabelStyle}>{t('Payment')}</li>
                <li css={unpaidMoneyLabelStyle}>{t('Amount to be collected')}</li>
                <li css={carriedForwardLabelStyle}>{t('Balance carried forward')}</li>
              </ul>
            </div>
            <div css={detailListMainContainerStyle}>
              {salesDetailList.length > 0 &&
                salesDetailList?.map((salesDetail, index) => (
                  <div key={index}>
                    <ul css={!salesDetail.isConfirm ? detailListItemContainerStyle : confirmedDetailListItemContainerStyle}>
                      {salesDetail.salesSubjectId !== null || salesDetail.isCompany ? (
                        <>
                          <li css={dateStyle}>{dayjs(salesDetail.salesDate).format(t('MM-DD'))}</li>
                          <li css={nameWrapperStyle}>{nameDomBySales(salesDetail)}</li>
                          <li css={accommodationStyle}>{salesDetail?.accommodationId ? salesDetail?.accommodationId : '-'}</li>
                          <li css={subjectsStyle}>{salesDetail?.salesSubjectName ? salesDetail?.salesSubjectName : ''}</li>
                          <li css={unitPriceStyle}>
                            {salesDetail?.salesSubjectPrice ? salesDetail?.salesSubjectPrice.toLocaleString() : 0}
                          </li>
                          <li css={quantityStyle}>{salesDetail?.quantity ? salesDetail?.quantity : 0}</li>
                          <li css={salesStyle}>{salesDetail?.salesPrice ? salesDetail?.salesPrice.toLocaleString() : 0}</li>
                          <li css={paymentMethodsStyle}>{salesDetail?.salesPaymentName ? salesDetail?.salesPaymentName : ''}</li>
                          <li css={paymentStyle}>{salesDetail?.salesPaymentPrice ? salesDetail?.salesPaymentPrice.toLocaleString() : 0}</li>
                          <li css={salesDetail?.unpaid > 0 ? isUnpaidStyle : unpaidMoneyStyle}>
                            {salesDetail?.unpaid ? salesDetail?.unpaid.toLocaleString() : 0}
                          </li>
                          <li css={carriedForwardStyle}>
                            {salesDetail?.carriedForward ? salesDetail?.carriedForward.toLocaleString() : 0}
                          </li>
                        </>
                      ) : (
                        <>
                          <li css={dateStyle}>{dayjs(salesDetail.salesDate).format(t('MM-DD'))}</li>
                          <li css={nameWrapperStyle}>{nameDomBySales(salesDetail)}</li>
                          <li css={accommodationStyle}>{salesDetail?.accommodationId ? salesDetail?.accommodationId : '-'}</li>
                          <li css={subjectsStyle}>{salesDetail?.salesDiscountName ? salesDetail?.salesDiscountName : ''}</li>
                          <li css={unitPriceStyle}>{'-'}</li>
                          <li css={quantityStyle}>{'-'}</li>
                          <li css={salesStyle}>{'-'}</li>
                          <li css={paymentMethodsStyle}>{salesDetail?.salesPaymentName ? salesDetail?.salesPaymentName : ''}</li>
                          <li css={paymentStyle}>
                            {salesDetail?.salesSubjectPrice > 0 ? `-${salesDetail?.salesSubjectPrice.toLocaleString()}` : 0}
                          </li>
                          <li css={unpaidMoneyStyle}>{'-'}</li>
                          <li css={carriedForwardStyle}> {'-'}</li>
                        </>
                      )}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <LoadingFull isLoading={isLoading} />
      </div>
    </div>
  )
}

const headerContainerStyle = css(mainContainerHeaderStyle, {
  backgroundColor: '#F2F2F2',
  padding: '0 0 14px 0',
  height: 'auto',
})

const detailListContainerStyle = css({
  boxShadow: '0px 0px 6px #0000001A',
  borderRadius: 5,
  backgroundColor: '#FFF',
  minWidth: 1200,
  height: '78vh',
  overflowY: 'scroll',
})

const detailListHeaderContainerStyle = css({
  position: 'sticky',
  top: 0,
})

const detailListHeaderStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 32px',
  height: 50,
  alignItems: 'center',
  backgroundColor: '#FFF',
})

const detailListTitleStyle = css({
  color: '#272727',
  fontSize: 18,
  fontWeight: 'bold',
  padding: '8px 0',
})

const detailListPaginationContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: 30,
})

const paginationLineCountStyle = css({
  fontSize: 12,
  color: '#676767',
  marginRight: 16,
  padding: '8px 0',
  fontWeight: 'bold',
})

const paginationArrowStyle = css({
  borderRadius: '50%',
  height: 30,
  width: 30,
  ':hover': {
    backgroundColor: '#F2F2F2',
    cursor: 'pointer',
  },
})

const detailListLabelContainerStyle = css({
  display: 'flex',
  backgroundColor: '#F5F5F5',
  height: 32,
  fontSize: 12,
  color: '#676767',
  padding: '10px 10px 10px 32px',
  fontWeight: 'bold',
})

const ellipsisStyle = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const dateLabelStyle = css({
  width: '7%',
  marginRight: '2%',
})

const dateStyle = css(dateLabelStyle, {
  whiteSpace: 'nowrap',
})

const nameLabelStyle = css({
  width: '10%',
  marginRight: '2%',
  minWidth: 80,
})

const nameWrapperStyle = css(nameLabelStyle, {
  color: '#F2A40B',
  '> a': {
    color: '#F2A40B',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
})

const nameStyle = css(ellipsisStyle, {
  width: '80%',
  lineHeight: '14px',
})

const subjectsLabelStyle = css({
  width: '14%',
  marginRight: '2%',
})

const accommodationLabelStyle = css(subjectsLabelStyle, {
  width: '6%',
})

const subjectsStyle = css(subjectsLabelStyle, ellipsisStyle)

const accommodationStyle = css(subjectsLabelStyle, ellipsisStyle, {
  width: '6%',
})

const unitPriceLabelStyle = css({
  width: '8%',
  marginRight: '2%',
})

const unitPriceStyle = css(unitPriceLabelStyle, ellipsisStyle)

const quantityLabelStyle = css({
  width: '4%',
  marginRight: '2%',
})

const quantityStyle = css(quantityLabelStyle)

const salesLabelStyle = css({
  width: '8%',
  marginRight: '2%',
})

const salesStyle = css(salesLabelStyle)

const paymentMethodsLabelStyle = css({
  width: '15%',
  marginRight: '2%',
})

const paymentMethodsStyle = css(paymentMethodsLabelStyle, ellipsisStyle)

const paymentLabelStyle = css({
  width: '8%',
  marginRight: '2%',
})

const paymentStyle = css(paymentLabelStyle)

const unpaidMoneyLabelStyle = css({
  width: '7%',
  marginRight: '2%',
})

const unpaidMoneyStyle = css(unpaidMoneyLabelStyle, {
  color: '#272727',
})

const isUnpaidStyle = css(unpaidMoneyLabelStyle, {
  color: '#F2A40B',
})

const carriedForwardLabelStyle = css({
  width: '7%',
  marginRight: '2%',
})

const carriedForwardStyle = css(carriedForwardLabelStyle)

const detailListMainContainerStyle = css({
  height: '78vh',
})

const detailListItemContainerStyle = css({
  display: 'flex',
  color: '#272727',
  fontSize: 14,
  height: 46,
  padding: '16px 10px 16px 32px',
  borderBottom: '1px solid #F2F2F2',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    cursor: 'default',
  },
})

const confirmedDetailListItemContainerStyle = css(detailListItemContainerStyle, {
  background: '#f5f5f5',
  '&:hover': {
    boxShadow: 'none',
  },
})
