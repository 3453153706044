import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SerializedStyles, css } from '@emotion/core'
import { getSpaceByReservation } from '@/apis/aipass'
import dayjs from 'dayjs'
import { SpaceReservation, SpaceReservationDetail } from '@/models/space-manager'
import { useHistory } from 'react-router-dom'
import uuid from 'react-uuid'

type Props = {
  reservationId: string
  customStyle?: SerializedStyles
}
export const ListSpaceForReservation: React.FC<Props> = ({ reservationId, customStyle }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [fetchedSpaces, setFetchedSpaces] = useState<SpaceReservation[]>([])

  const computedUsedTime = (from: string, to: string) => {
    return `${dayjs(from).format(t('MM-DD HH:mm'))}~${dayjs(to).format(t('HH:mm'))}`
  }

  const initializeComponent = async () => {
    getSpaceByReservation(reservationId).then(res => setFetchedSpaces(res || []))
  }

  useEffect(() => {
    reservationId && initializeComponent()
  }, [reservationId])

  const viewSpaces: {
    id: string
    usedAtFrom: string
    usedAtTo: string
    reservationUserName: string
    pax: number
    answer: string
    details: SpaceReservationDetail[]
  }[] = useMemo(() => {
    const sameTimeUsedSpaces: {
      [key: string]: {
        id: string
        usedAtFrom: string
        usedAtTo: string
        reservationUserName: string
        pax: number
        answer: string
        details: SpaceReservationDetail[]
      }
    } = {}
    fetchedSpaces.forEach(spacesByReserve => {
      spacesByReserve.details.forEach(detail => {
        const groupKey = `${detail.reservedSpace.spaceId}-${detail.usedAtFrom}-${detail.usedAtTo}`
        if (!sameTimeUsedSpaces[groupKey]) {
          Object.assign(sameTimeUsedSpaces, {
            [groupKey]: {
              id: spacesByReserve.id,
              usedAtFrom: detail.usedAtFrom,
              usedAtTo: detail.usedAtTo,
              reservationUserName: detail.reservationUserName,
              pax: detail.pax,
              answer: detail.answer,
              details: [],
            },
          })
        }
        sameTimeUsedSpaces[groupKey].details.push(detail)
      })
    })
    return Object.values(sameTimeUsedSpaces)
  }, [fetchedSpaces])

  return (
    <div css={css(blockStyle, customStyle)}>
      <div className="title">{t('Space')}</div>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th className="usage-hour-col">{t('Date and time of use')}</th>
              <th>{t('User name')}</th>
              <th className="pax-col">{t('Number of people')}</th>
              <th className="space-name-col">{t('Space name')}</th>
              <th className="key-col">{t('Key number')}</th>
              <th className="answer-col">{t('Answers and notes')}</th>
            </tr>
          </thead>
          <tbody>
            {viewSpaces.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <div
                    className="add-button"
                    onClick={() =>
                      history.push({
                        pathname: '/space-manager',
                        search: `reservationId=${reservationId}`,
                      })
                    }
                  >
                    <img src={require('@/static/images/plus.svg')} />
                    <p>{t('Add space')}</p>
                  </div>
                </td>
              </tr>
            )}
            {viewSpaces.length > 0 &&
              viewSpaces.map(viewSpace =>
                viewSpace.details.map((detail, i) => (
                  <tr
                    className={`
                      detail-row
                      ${i === 0 && 'first-line'}
                      ${i === viewSpace.details.length - 1 && 'last-line'}
                    `}
                    key={uuid()}
                    onClick={() =>
                      history.push({
                        pathname: '/space-manager',
                        search: `reservationId=${reservationId}&usedAt=${viewSpace.usedAtFrom}`,
                      })
                    }
                  >
                    {i === 0 && <td rowSpan={viewSpace.details.length}>{computedUsedTime(viewSpace.usedAtFrom, viewSpace.usedAtTo)}</td>}
                    {i === 0 && <td rowSpan={viewSpace.details.length}>{viewSpace.reservationUserName}</td>}
                    {i === 0 && <td rowSpan={viewSpace.details.length}>{`${viewSpace.pax}${t('Person')}`}</td>}
                    <td>{`${detail.reservedSpace.title.ja} - ${detail.reservedSpace.spaceIndex}`}</td>
                    <td>{detail.reservedSpace.keyNumber || '-'}</td>
                    {i === 0 && <td rowSpan={viewSpace.details.length}>{viewSpace.answer || '-'}</td>}
                  </tr>
                )),
              )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const blockStyle = css({
  border: '1px solid #CCCCCC',
  borderRadius: 10,
  margin: '-16px 32px 32px 32px',
  '@media(max-width: 1080px)': {
    marginInline: 16,
  },
  '.title': {
    padding: '16px 32px',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0.7,
    color: '#272727',
    '@media(max-width: 1080px)': {
      paddingInline: 16,
    },
  },
  '.add-button': {
    cursor: 'pointer',
    display: 'flex',
    height: 36,
    backgroundColor: '#FAFAFA',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dashed #CCCCCC',
    margin: '16px 32px',
    p: {
      lineHeight: '36px',
      color: '#F2A40B',
      fontSize: 12,
      fontWeight: 'bold',
      paddingLeft: 8,
    },
    '>img': {
      width: 13,
      height: 13,
    },
  },
  table: {
    width: '100%',
    '.usage-hour-col': { width: 210 },
    '.pax-col': { width: 62 },
    '.space-name-col': { width: 256 },
    '.key-col': { width: 177 },
    '.answer-col': { width: 315 },
    th: {
      fontWeight: 'bold',
      backgroundColor: '#F5F5F5',
      fontSize: 12,
      borderBottom: '1px solid #F2F2F2',
      padding: '10px 0px',
      verticalAlign: 'middle',
      ':first-of-type': {
        paddingLeft: 32,
        '@media(max-width: 1080px)': {
          paddingLeft: 16,
        },
      },
    },
    '.detail-row': {
      cursor: 'pointer',
      '> td': {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.7,
        color: '#272727',
        lineHeight: '19px',
        wordBreak: 'break-word',
        paddingBottom: 8,
      },
      ':first-of-type': {
        borderTop: 'none',
      },
      '&.first-line': {
        borderTop: '1px solid #F2F2F2',
        '> td': {
          paddingTop: 16,
          ':first-of-type': {
            paddingLeft: 32,
            '@media(max-width: 1080px)': {
              paddingLeft: 16,
            },
          },
          ':last-of-type': { paddingRight: 42 },
        },
      },
      '&.last-line': {
        '> td': {
          paddingBottom: 16,
        },
      },
    },
  },
})
