import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '@/components/atoms/modal'
import { Button } from '@/components/atoms/button'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { TextareaField } from '@/components/molecules/textarea-field'
import { useErrorHandler } from '@/hooks/use-error-handler'
import { KeywordGroup } from '@/models/reservation/keyword-group'
import { InputField } from '@/components/molecules/input-field'
import { AddFormFieldButton } from '@/components/molecules/add-form-field-button'
import { DeleteIcon } from '@/components/molecules/settings/icon/delete-icon'
import { LoaderContextCreator } from '@/contexts/loader'
import { storeKeywordGroupSetting, updateKeywordGroupSetting } from '@/apis/aipass'
import { useDraggableDom } from '@/components/molecules/settings/use-draggable-dom'

type Props = {
  detail: KeywordGroup | null
  onClose: () => void
  doReload: () => void
}

export const AddPlanGroupModal: React.FC<Props> = ({ detail, onClose, doReload }) => {
  const { t } = useTranslation()
  const { errorHandler } = useErrorHandler()
  const { setIsLoading } = useContext(LoaderContextCreator())
  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<KeywordGroup>({
    defaultValues: {
      name: detail?.name,
      keywords: detail?.keywords || [{ priority: 1, frontNote: '', cleaningNote: '', mealNote: '' }],
    },
  })
  const keywordInputArray = useFieldArray({
    control,
    name: 'keywords',
  })
  const watchKeywords = useWatch({ control, name: 'keywords' })
  const [activeKeywordListIndex, setActiveKeywordListIndex] = useState(0)
  const [isRenderChildForm, setIsRenderChildForm] = useState(true)
  const changeDisplayOrder = (newList: KeywordGroup['keywords']) => {
    setIsRenderChildForm(false)
    clearErrors()
    keywordInputArray.replace(newList.map((list, i) => ({ ...list, priority: i + 1 })))
  }
  const { dragStart, dragOver, dragDrop } = useDraggableDom({
    list: keywordInputArray.fields,
    onChange: changeDisplayOrder,
    key: 'priority',
  })
  useEffect(() => {
    if (isRenderChildForm === false) setIsRenderChildForm(true)
  }, [isRenderChildForm])

  const nextKeywordPriority = () => {
    return Math.max(...keywordInputArray.fields.map(({ priority }) => priority)) + 1
  }
  const onSave = async (formValue: KeywordGroup) => {
    try {
      setIsLoading(true)
      if (detail) {
        await updateKeywordGroupSetting(detail.id, formValue)
      } else {
        await storeKeywordGroupSetting(formValue)
      }
      doReload()
      onClose()
    } catch (e) {
      errorHandler(e)
    } finally {
      setIsLoading(false)
    }
  }
  const onCancel = () => {
    onClose()
  }
  console.log({ f: keywordInputArray.fields, activeKeywordListIndex })
  return (
    <Modal customCss={modalStyle}>
      <ModalHeader>{t(detail ? 'Edit keyword group' : 'Add keyword group')}</ModalHeader>
      <ModalBody customCss={{ padding: 'unset' }}>
        <div css={modalBodyStyle}>
          <div className="left-panel">
            <div className="keyword-group-panel">
              <div>
                <div className="title">
                  {t('keyword group')}
                  <div className="require-icon">※</div>
                </div>
                <Controller
                  control={control}
                  rules={{ required: t('Required field has not been entered') }}
                  name="name"
                  render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                    <InputField
                      name={name}
                      value={value}
                      handleChangeData={onChange}
                      placeholder={t('Dinner')}
                      marginBottom={0}
                      error={error?.message}
                    />
                  )}
                />
              </div>
            </div>
            <div className="keyword-list-panel">
              <div className="title">
                {t('keyword')}
                <div className="require-icon">※</div>
              </div>
              <div>
                {keywordInputArray.fields.map((keywordInput, index) => (
                  <div
                    id={keywordInput.priority.toString()}
                    className="keyword-list"
                    key={index}
                    draggable={true}
                    onDragStart={dragStart}
                    onDragOver={dragOver}
                    onDrop={dragDrop}
                  >
                    <div className="draggable-icon">
                      <img src={require('@/static/images/icon_list.svg')} />
                    </div>
                    <div
                      className={`keyword-list-item 
                        ${activeKeywordListIndex === index ? 'active' : ''} 
                        ${errors.keywords?.[index] ? 'error' : ''}`}
                      onClick={() => setActiveKeywordListIndex(index)}
                    >
                      <div className="col-priority">{keywordInput.priority}</div>
                      <div className="col-keyword">{watchKeywords[index]?.keyword}</div>
                      {activeKeywordListIndex === index && (
                        <div className="col-icon">
                          <img src={require('@/static/images/icon_caalender_right.svg')} />
                        </div>
                      )}
                    </div>
                    <div className="delete-icon">
                      {keywordInputArray.fields.length !== 1 && (
                        <DeleteIcon
                          onClick={() => {
                            keywordInputArray.remove(index)
                            setActiveKeywordListIndex(0)
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
                <div className="keyword-list">
                  <div className="draggable-icon"></div>
                  <div style={{ flexGrow: 1 }}>
                    <AddFormFieldButton
                      label={t('Add keyword')}
                      onAdd={() => {
                        keywordInputArray.append({
                          priority: nextKeywordPriority(),
                          keyword: '',
                          frontNote: '',
                          mealNote: '',
                          cleaningNote: '',
                        })
                      }}
                    />
                  </div>
                  <div className="delete-icon"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="right-panel">
            {isRenderChildForm &&
              keywordInputArray.fields.map((keywordInput, index) => (
                <div
                  key={index}
                  style={
                    index === activeKeywordListIndex
                      ? {
                          display: 'block',
                          visibility: 'visible',
                        }
                      : {
                          display: 'table',
                          visibility: 'collapse',
                        }
                  }
                >
                  <div className="first-area">
                    <div>
                      <div className="title">
                        {t('keyword')} {keywordInput.keyword}
                        <div className="require-icon">※</div>
                      </div>
                      <Controller
                        control={control}
                        rules={{ required: t('Required field has not been entered') }}
                        name={`keywords.${index}.keyword`}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                          <InputField
                            name={name}
                            value={value}
                            handleChangeData={onChange}
                            placeholder={t('Half board')}
                            marginBottom={0}
                            error={error?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="second-area">
                    <div style={{ marginBottom: 24 }}>
                      <div className="title">{t('Front note')}</div>
                      <Controller
                        control={control}
                        name={`keywords.${index}.frontNote`}
                        render={({ field: { onChange, value, name } }) => (
                          <TextareaField
                            name={name}
                            value={value}
                            handleChangeData={onChange}
                            placeholder={t('Front note')}
                            rows={2}
                            marginBottom={0}
                            css={css({ textarea: { marginBottom: 0 } })}
                          />
                        )}
                      />
                    </div>
                    <div style={{ marginBottom: 24 }}>
                      <div className="title">{t('Cleaning note')}</div>
                      <Controller
                        control={control}
                        name={`keywords.${index}.cleaningNote`}
                        render={({ field: { onChange, value, name } }) => (
                          <TextareaField
                            name={name}
                            value={value}
                            handleChangeData={onChange}
                            placeholder={t('Cleaning note')}
                            rows={2}
                            marginBottom={0}
                            css={css({ textarea: { marginBottom: 0 } })}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <div className="title">{t('Meal note')}</div>
                      <Controller
                        control={control}
                        name={`keywords.${index}.mealNote`}
                        render={({ field: { onChange, value, name } }) => (
                          <TextareaField
                            name={name}
                            value={value}
                            handleChangeData={onChange}
                            placeholder={t('Meal note')}
                            rows={2}
                            marginBottom={0}
                            css={css({ textarea: { marginBottom: 0 } })}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button buttonType={3} height="38px" width="110px" marginRight={16} onClick={() => onCancel()}>
          {t('Cancel')}
        </Button>
        <Button buttonType={isValid ? 1 : 5} height="38px" width="110px" onClick={handleSubmit(onSave)}>
          {t('Save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const modalStyle = { width: '90%', height: '90%' }
const modalBodyStyle = css({
  display: 'flex',
  color: '#676767',
  '.title': {
    display: 'flex',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: '0.6px',
    paddingBottom: 12,
  },
  '.require-icon': {
    fontSize: 8,
    fontWeight: 'bold',
    letterSpacing: '0.4px',
    paddingLeft: 8,
  },
  '.left-panel': {
    width: '50%',
    borderRight: '1px solid #CCCCCC',
    '.keyword-group-panel': {
      backgroundColor: '#FFFFFF',
      padding: '24px 32px',
    },
    '.keyword-list-panel': {
      padding: '24px 32px',
      '.keyword-list': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,

        '.draggable-icon': { width: 30, marginRight: 16, cursor: 'pointer' },
        '.keyword-list-item': {
          flexGrow: 1,
          border: '1px solid #CCCCCC',
          borderRadius: 5,
          backgroundColor: '#FFFFFF',
          padding: '13px 24px',
          fontSize: 14,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          '&.active': {
            color: '#F2A40B !important',
            fontWeight: 'bold',
          },
          '&.error': { border: '1px solid red' },
          '.col-priority': { width: 20, lineHeight: '30px' },
          '.col-keyword': { paddingRight: 24, flexGrow: 1 },
          '.col-icon': { width: 30, height: 30 },
        },
        '.delete-icon': { width: 30, marginLeft: 16 },
      },
    },
  },
  '.right-panel': {
    width: '50%',
    height: '100%',
    margin: '24px 32px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #CCCCCC',
    borderRadius: 10,
    '.first-area': {
      borderBottom: '1px solid #CCCCCC',
      padding: 24,
    },
    '.second-area': {
      padding: 24,
    },
  },
})
